import React, {useMemo} from 'react';

import cn from 'classnames';
import flatMap from 'lodash/flatMap';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {useLanguage, useReduxForm} from 'client/services/hooks';

import DateCell from 'client/common/cells/date-cell';

import AnimationReportingTable from 'client/components/animations/components/animation-reporting-table';
import {AnimationConfigContainerFormName} from 'client/components/animations/containers/animation-config-container';

import './animation-anomalies-list.scss';

const b = bem('animation-anomalies-list');

const AnimationAnomaliesList = ({className}) => {
  const {formValues} = useReduxForm(AnimationConfigContainerFormName);
  const lang = useLanguage('ANIMATIONS.LISTS.ANIMATION_ANOMALIES_LIST');

  const data = useMemo(() => {
    const result = flatMap(formValues?.animation_days, 'anomaly_occurrences')
      .filter(Boolean)
      .map((occurrence) => ({
        name: occurrence.anomaly?.name,
        date: occurrence.date,
        description: occurrence.description,
      }));
    return sortBy(result, 'date');
  }, [formValues?.animation_days]);

  const columns = [
    {
      name: 'name',
      path: 'name',
      width: 130,
      label: lang.NAME_COLUMN,
      title: true,
    },
    {
      name: 'date',
      path: 'date',
      label: lang.DATE_COLUMN,
      cellClassName: b('cell', ['date']),
      width: 120,
      render: ({value}) => <DateCell value={value} className={b('date')} timeFormat="HH:mm" />,
    },
    {
      name: 'description',
      path: 'description',
      label: lang.DESCRIPTION_COLUMN,
      cellClassName: b('cell'),
      hintRender: ({description}) => description,
    },
  ];

  return (
    <AnimationReportingTable
      className={cn(b(), className)}
      title={lang.TITLE}
      columns={columns}
      data={data}
      tableClassName={b('table')}
    />
  );
};

AnimationAnomaliesList.propTypes = {
  className: PropTypes.string,
};

AnimationAnomaliesList.defaultProps = {
  className: '',
};

export default AnimationAnomaliesList;
