import keyBy from 'lodash/keyBy';

import {setIsLoading, addTranslationsItem, updateTranslationsItem, updateLanguage, updateTranslation} from './helpers';
import {flattenTranslationsTree, prepareTranslations} from './mappers';
import types from './types';

const initialState = {
  isLoading: false,
  translations: [],
  translationsById: {},
  languages: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_TRANSLATIONS_REQUEST:
      return setIsLoading(state, true);
    case types.GET_TRANSLATIONS_SUCCESS:
      // @todo ask backend team to alter GET method to include translation_items
      const translationsArray = flattenTranslationsTree(action.payload.translations);
      state.translationsById = keyBy(translationsArray, 'id');

      return {
        ...state,
        translations: prepareTranslations(state.translationsById),
        isLoading: false,
      };
    case types.GET_TRANSLATIONS_ERROR:
      return setIsLoading(state, false);
    case types.POST_TRANSLATION_REQUEST:
      return {...state, isLoading: true};
    case types.POST_TRANSLATION_SUCCESS:
      state.translationsById = {
        ...state.translationsById,
        [action.payload.translation.id]: action.payload.translation,
      };
      return {
        ...state,
        translations: prepareTranslations(state.translationsById),
        isLoading: false,
      };
    case types.POST_TRANSLATION_ERROR:
      return setIsLoading(state, false);
    case types.PATCH_TRANSLATION_REQUEST:
      return setIsLoading(state, true);
    case types.PATCH_TRANSLATION_SUCCESS:
      state.translationsById[action.payload.translation.id] = updateTranslation(
        state.translationsById,
        action.payload.translation,
      );
      return {
        ...state,
        translations: prepareTranslations(state.translationsById),
        isLoading: false,
      };
    case types.PATCH_TRANSLATION_ERROR:
      return setIsLoading(state, false);
    case types.DELETE_TRANSLATION_REQUEST:
      return setIsLoading(state, true);
    case types.DELETE_TRANSLATION_SUCCESS:
      return setIsLoading(state, false);
    case types.DELETE_TRANSLATION_ERROR:
      return setIsLoading(state, false);
    case types.DELETE_TRANSLATION:
      const nextById = state.translationsById;
      delete nextById[action.payload.id];
      return {
        ...state,
        translationsById: nextById,
        translations: prepareTranslations(nextById),
      };
    case types.POST_TRANSLATION_ITEM_REQUEST:
      return setIsLoading(state, true);
    case types.POST_TRANSLATION_ITEM_SUCCESS:
      state.translationsById[action.payload.translation_item.translation_id] = addTranslationsItem(
        state.translationsById,
        action.payload.translation_item,
      );
      return {
        ...state,
        translations: prepareTranslations(state.translationsById),
        isLoading: false,
      };
    case types.POST_TRANSLATION_ITEM_ERROR:
      return setIsLoading(state, false);
    case types.PATCH_TRANSLATION_ITEM_REQUEST:
      return setIsLoading(state, true);
    case types.PATCH_TRANSLATION_ITEM_SUCCESS:
      state.translationsById[action.payload.translation_item.translation_id] = updateTranslationsItem(
        state.translationsById,
        action.payload.translation_item,
      );
      return {
        ...state,
        translations: prepareTranslations(state.translationsById),
        isLoading: false,
      };
    case types.PATCH_TRANSLATION_ITEM_ERROR:
      return setIsLoading(state, false);
    case types.GET_LANGUAGES_SUCCESS:
      return {
        ...state,
        languages: action.payload.languages,
      };
    case types.PATCH_LANGUAGE_SUCCESS:
      return {
        ...state,
        languages: updateLanguage(state.languages, action.payload.language),
      };
    default:
      return state;
  }
}
