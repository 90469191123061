/**
 * Normalize date to ISO string format.
 * If date is null or undefined, returns null.
 * If date is string, returns the same string.
 * If date is Date object, returns ISO string representation of the date.
 * @param {Date|string} date
 * @returns {string}
 */

// This function is for normalizing dates in an old component located at src/client/components/common/field/index.jsx.
export const normalizeDate = (date: string | Date): string => {
  if (!date) {
    return '';
  }

  return typeof date === 'object' ? date.toISOString() : date;
};
