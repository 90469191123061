import React, {useMemo} from 'react';

import {InferProps} from 'prop-types';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';
import {getLanguageTranslations} from 'client/services/utils/languages';

import Icon from 'client/common/icon';
import SelectDropdown from 'client/common/selects/select-dropdown';

import {AllowedLanguages} from 'client/models/users/types';

import cssModule from './select-language.module.scss';

type SelectLanguageProps = InferProps<typeof SelectDropdown.propTypes> & {
  languages: AllowedLanguages[];
};

const b = bem('select-language', {cssModule});

export const SelectLanguage: React.FC<SelectLanguageProps> = (props) => {
  const {languages = [], ...restProps} = props;
  const langLanguages = useLanguage('COMMON.LANGUAGES');

  const options = useMemo(() => {
    const valuesLanguages = getLanguageTranslations(langLanguages);
    return languages.map((language) => ({
      label: (
        <span className={b('option')}>
          {valuesLanguages[language]}
          <Icon name={`flag-${language}`} />
        </span>
      ),
      value: language,
    }));
  }, [langLanguages, languages]);

  return <SelectDropdown options={options} {...restProps} />;
};
