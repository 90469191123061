import {selectAutotaskTypes} from 'client/ducks/autotask/selectors';
import {selectOperation} from 'client/ducks/operations/selectors';

import {SEGMENTATION_TYPES} from 'client/components/prizes/constants';

const mapValuesForOnlineStore = (prize, operation, isLocal) => {
  const isOnlineOnly = operation.client_interface?.online && !operation.client_interface?.device;

  if (isOnlineOnly && isLocal) {
    return {hybrid: true};
  }

  if (!isOnlineOnly || !prize?.id) {
    return {};
  }

  return prize.geo_level === SEGMENTATION_TYPES.PER_PLACE && prize.hybrid && !prize.local
    ? {
        geo_level: SEGMENTATION_TYPES.NONE,
        online_level: SEGMENTATION_TYPES.STORE,
        online_stock: prize.device_stock?.toString(),
        device_stock: null,
        hybrid: false,
      }
    : {};
};

export default ({prize, state, isLocal}) => {
  const {isDevicesType, isOnlineType} = selectAutotaskTypes(state);
  const isDeviceAndOnlineType = Boolean(isDevicesType && isOnlineType);
  const operation = selectOperation(state);

  return {
    device_online_segmentation:
      !prize?.hybrid && isDeviceAndOnlineType && Boolean(prize?.device_stock || prize?.online_stock),
    geo_level: [SEGMENTATION_TYPES.PER_PLACE, SEGMENTATION_TYPES.PER_DEVICE].includes(prize?.geo_level)
      ? prize?.geo_level
      : SEGMENTATION_TYPES.NONE,
    online_level: [SEGMENTATION_TYPES.PER_CAMPAIGN, SEGMENTATION_TYPES.PER_CHANNEL].includes(prize?.online_level)
      ? prize?.online_level
      : SEGMENTATION_TYPES.NONE,
    device_stock: prize?.device_stock?.toString(),
    online_stock: prize?.online_stock?.toString(),
    default_initial_stock: prize?.default_initial_stock?.toString(),
    device_online_type: isDeviceAndOnlineType, // for mapping form  values
    hybrid: prize?.hybrid,
    local: prize?.id ? prize.local : isLocal,
    ...mapValuesForOnlineStore(prize, operation, isLocal),
  };
};
