import React, {useCallback, useEffect, useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage, useReduxFetch} from 'client/services/hooks';
import useReduxForm, {reduxForm} from 'client/services/hooks/use-redux-form';
import {ReduxFormFC} from 'client/services/hooks/use-redux-form/types';
import {getCurrentTimezone} from 'client/services/utils/date';

import {
  getPrizeMapsByLevel,
  GetPrizeMapsLevelType,
  getPrizes,
  updatePrizeMaps,
  updatePrizeOrder,
} from 'client/ducks/games/actions';
import {selectOperation} from 'client/ducks/operations/selectors';

import AppButton from 'client/common/buttons';
import Modal from 'client/common/modals/modal';
import Offscreen from 'client/common/offscreen';

import Spinner from 'client/components/common/spinner';

import {useFetchDiyGame} from 'client/components/diy-operation/hooks';
import validateCampaignPeriodFieldset from 'client/components/prizes/fields/prize-customization-campaign-period/validate';
import PrizeCustomizationForm from 'client/components/prizes/forms/prize-customization-form';
import PrizeMapCustomizationForm from 'client/components/prizes/forms/prizemap-customization-form';
import {PrizeCustomizationModalForm} from 'client/components/prizes/modals/prize-customization-modal/types';
import PrizeDiyModal from 'client/components/prizes/modals/prize-diy-modal';
import {isOperationArchived} from 'client/models/operations/helpers';
import {Prize} from 'client/models/prizes/types';
import {Game, PrizeMap} from 'client/models/prizes/types';
import {ApiDispatch} from 'client/types';

import initial from './initial';
import mapFormValues, {mapPrizeOrder} from './mapFormValues';

import cssModule from './prize-customization-modal.module.scss';

const b = bem('prize-customization-modal', {cssModule});

type PrizeCustomizationModalProps = {
  onClose: () => void;
  level: GetPrizeMapsLevelType;
  game: Game;
  onFetch?: () => Promise<any>;
  levelName?: string;
  sourceIds?: number[];
  disabled: boolean;
};

export const PrizeCustomizationModalFormName = 'PrizeCustomizationModalForm';

const PrizeCustomizationModal: ReduxFormFC<PrizeCustomizationModalProps, PrizeCustomizationModalForm> = (props) => {
  const {game, onClose, level, handleSubmit, onFetch, levelName, sourceIds, disabled} = props;
  const operation = useSelector(selectOperation);
  const timezone = operation?.timezone || getCurrentTimezone();

  const dispatch: ApiDispatch = useDispatch();
  const lang = useLanguage('PRIZES.MODALS.CUSTOMIZATION_MODAL');

  const isOpArchived = isOperationArchived(operation);
  const [submitting, toggleSubmitting] = useToggle(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [selectedPrizeMapIndex, setSelectedPrizeMapIndex] = useState<number | null>(null);

  const [showPrizeModal, setShowPrizeModal] = useState(false);
  const [editingPrizeId, setEditingPrizeId] = useState<number | null>(null);

  const openPrizeModal = useCallback((prizeId?: number) => {
    if (prizeId) {
      setEditingPrizeId(prizeId);
    }
    setShowPrizeModal(true);
  }, []);

  const closePrizeModal = useCallback(() => {
    setEditingPrizeId(null);
    setShowPrizeModal(false);
  }, []);

  const {invalid, formValues, change, initialize} = useReduxForm<PrizeCustomizationModalForm>(
    PrizeCustomizationModalFormName,
    {
      validate: (values) => validateCampaignPeriodFieldset(values, {lang}),
    },
  );
  const editable = !!operation?.editable;
  const openedPrizeMap = selectedPrizeMapIndex !== null;

  const {data, loading, fetch} = useReduxFetch<{prize_maps: PrizeMap[]}>({
    action: getPrizeMapsByLevel,
    actionArgs: {
      level,
      sourceIds,
      queryParams: {
        include_prize_total_quantities: true,
        include: [
          'coupon',
          'instant_win_configuration',
          'participation_instant_win_configuration',
          'schedule_instant_win_configuration.prize_map_timestamps',
          'time_instant_win_configuration',
          'prize.coupon',
          'prize.total_quantities',
        ],
        q: {
          prize_game_id_eq: game?.id,
        },
      },
    },
  });

  const {data: dataPrizes, fetch: fetchAllPrizes} = useReduxFetch<{prizes: Prize[]}>({
    action: getPrizes,
    actionArgs: {
      q: {
        game_id_eq: game?.id,
      },
    },
  });

  useEffect(() => {
    if (!loading && data) {
      initialize(initial(data.prize_maps, {level, sourceIds: sourceIds || [], timezone}));
      setIsInitialized(true);
    }
  }, [data, loading, initialize, level, sourceIds, timezone]);

  const onSave = async (values: PrizeCustomizationModalForm) => {
    toggleSubmitting();
    const body = mapFormValues(values, {timezone});
    await Promise.all([
      dispatch(updatePrizeMaps(body)),
      dispatch(updatePrizeOrder(game.id, mapPrizeOrder(values, {prizes: dataPrizes?.prizes || []}))),
      onFetch?.(),
    ]);
    // TODO: check fetchGame
    // await fetchGame(); // apply changes to prizes
    toggleSubmitting();
    onClose();
  };

  const {fetchGame, prizeBlock} = useFetchDiyGame();

  const fetchPrizeMapsAndGame = useCallback(() => {
    return Promise.all([fetch(), fetchAllPrizes(), fetchGame()]);
  }, [fetch, fetchAllPrizes, fetchGame]);

  const updatePrizeMap = (values: PrizeMap) => {
    change(`prizeMaps[${selectedPrizeMapIndex}]`, values);
    setSelectedPrizeMapIndex(null);
    fetch();
  };

  let subtitle = game.name;
  const levelTranslation = lang.LEVELS[level as keyof typeof lang.LEVELS];

  if (levelName || levelTranslation) {
    subtitle += `/ ${levelName || levelTranslation}`;
  }

  const title = (
    <div className={b('title')}>
      {lang.TITLE}
      {level && <span className={b('game-title')}>{subtitle}</span>}
    </div>
  );

  const gerPrizeModalProps = () => {
    const {prizes} = prizeBlock;
    const editingPrizeIndex = prizes.findIndex((prize: {data: Prize}) => prize.data.id === editingPrizeId);

    return {
      editingPrize: prizes[editingPrizeIndex]?.data,
      prevPrize: (editingPrizeId ? prizes[editingPrizeIndex - 1] : prizes[prizes.length - 1])?.data,
      nextPrize: editingPrizeId ? prizes[editingPrizeIndex + 1]?.data : null,
    };
  };

  return (
    <>
      <Modal
        show
        onClose={onClose}
        className={b({hidden: openedPrizeMap})}
        classNames={{body: b('modal'), content: b('modal-content')}}
      >
        {!openedPrizeMap && (
          <div className={b('header')}>
            {title}
            {editable && !openedPrizeMap && (
              <div className={b('buttons')}>
                <AppButton color="clients" transparent label={lang.CANCEL_BUTTON} onClick={onClose} />
                <AppButton
                  color="clients"
                  label={lang.SAVE_BUTTON}
                  onClick={handleSubmit(onSave)}
                  loading={submitting}
                  disabled={invalid || disabled}
                />
              </div>
            )}
          </div>
        )}
        {loading || !isInitialized ? (
          <Spinner color="primary" centered className={b('spinner')} />
        ) : (
          <>
            <Offscreen hidden={openedPrizeMap} className={b('offscreen')}>
              <PrizeCustomizationForm
                editable={editable}
                disabled={disabled}
                allowCreateLocal={level.startsWith('PLACE')}
                onSelectPrizeMap={setSelectedPrizeMapIndex}
                onOpenPrizeModal={openPrizeModal}
              />
            </Offscreen>

            {openedPrizeMap && (
              <PrizeMapCustomizationForm
                onClose={() => setSelectedPrizeMapIndex(null)}
                onSave={updatePrizeMap}
                editable={editable && !disabled}
                initialValues={formValues?.prizeMaps?.[selectedPrizeMapIndex]}
                title={title}
              />
            )}
          </>
        )}
        {showPrizeModal && (
          <PrizeDiyModal
            onClose={closePrizeModal}
            operation={operation}
            fetchData={fetchPrizeMapsAndGame}
            game={prizeBlock}
            isOperationArchived={isOpArchived}
            storeId={sourceIds?.[0]}
            isLocal
            {...gerPrizeModalProps()}
          />
        )}
      </Modal>
    </>
  );
};

export default reduxForm<PrizeCustomizationModalProps, PrizeCustomizationModalForm>({
  form: PrizeCustomizationModalFormName,
})(PrizeCustomizationModal);
