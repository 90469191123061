import React, {useEffect, useState, useCallback} from 'react';

import get from 'lodash/get';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import {connect, useSelector} from 'react-redux';
import {Field, reduxForm, formValueSelector} from 'redux-form';

import {mapSelectList} from 'client/services/helpers';

import {getAutotasks} from 'client/ducks/autotask/actions';
import {selectAutotasksForFilterModal} from 'client/ducks/autotask/selectors';
import {getFirstSources} from 'client/ducks/first-sources/actions';
import {getOperations} from 'client/ducks/operations/actions';
import {selectOperationsForFilterModal} from 'client/ducks/operations/selectors';
import {getOptInsAction} from 'client/ducks/opt-in-columns/actions';
import {selectOptInsForFilterModal} from 'client/ducks/opt-in-columns/selectors';

import Modal from 'client/common/modals/modal';

import AsyncDropdownAny from 'client/components/common/async-dropdown-any';
import fieldTemplate from 'client/components/common/field';
import FormFieldWrap from 'client/components/common/form-field-wrap';

import {
  getInitialValues,
  areStatesEqual,
  convertDate,
} from 'client/components/leads/components/modals/leads-filters-modal/helpers';
import {getSourceParams} from 'client/components/leads/leads-list/helper';

const LeadsFiltersModal = (props) => {
  const {
    onClose,
    clientsList,
    leadsList,
    clientId,
    firstSources,
    selectedOperations,
    filter,
    show,
    lang,
    onFilterChange,
    getFirstSourcesAction,
    getOptIns,
  } = props;
  const formValues = useSelector((store) => store.form[LeadsFiltersModal.formName]?.values || {});
  const [prevFormValues, setPrevFormValues] = useState(formValues);

  useEffect(() => {
    if (!areStatesEqual(prevFormValues, formValues)) {
      onFilterChange(formValues);
      setPrevFormValues(formValues);
    }
  }, [formValues, prevFormValues, setPrevFormValues, onFilterChange]);

  const fetchFirstSources = useCallback(() => {
    if (clientId) {
      getFirstSourcesAction({
        include: {
          source: ['client', 'interaction.interface.automation_task.operation'],
        },
        q: {
          client_id_eq: clientId,
        },
        uniq_by_source: true,
      });
    }
  }, [clientId, getFirstSourcesAction]);

  useEffect(() => {
    fetchFirstSources();
  }, [fetchFirstSources]);

  const initSourceFilter = (list) => {
    const sourceList = [];
    list.forEach((el) => {
      const {id, date, time, name} = getSourceParams(el.source, true);
      sourceList.push({id, name: String(name).toUpperCase(), custom: `${date} ${time}`});
    });
    return sourceList;
  };

  const getOptInsOptions = async (search = '') => {
    if (!search) {
      return [];
    }

    const response = await getOptIns({
      include_opt_in_column_leads_count: null,
      q: {
        client_id_eq: clientId,
        name_cont: search,
      },
    });

    const optIns = response.payload.opt_in_columns;
    return optIns.filter((optIn) => optIn.leads_count > 0).map((i) => ({label: i.name, value: i.id}));
  };

  const getOperationsOptions = async (search = '') => {
    if (!search) {
      return [];
    }

    await props.getOperations({
      q: {
        leads_count_gt: 0,
        client_id_eq: props.clientId,
        name_cont: search,
      },
    });
    return props.operations;
  };

  const getAutotasksOptions = async (search = '') => {
    if (!search) {
      return [];
    }

    await props.getAutotasks({
      q: {
        leads_count_gt: 0,
        client_id_eq: props.clientId,
        operation_id_in: map(props.selectedOperations, 'value'),
        name_cont: search,
      },
    });

    return props.autotasks;
  };

  const participated = formValues.participated === 'true';
  const created = formValues.created === 'true';

  const initSources = initSourceFilter(firstSources.payload);
  const sources = mapSelectList(initSources);
  sources.unshift({value: '0', label: lang.TABLE_FILTERS_MODAL_NO_MATTER_RADIO});

  const clients = mapSelectList(clientsList.payload.data.sort((a, b) => a.name.localeCompare(b.name)));
  clients.unshift({value: '0', label: lang.TABLE_FILTERS_MODAL_NO_MATTER_RADIO});

  const totalItems = get(leadsList, 'payload.meta.total_count', 0);

  return (
    <Modal
      show={show}
      dialogClassName="modal-window--width-1 modal-window--theme-2"
      backdropClassName="modal-window__backdrop"
      onClose={onClose}
      title={lang.TABLE_FILTERS_MODAL_TITLE}
    >
      <form>
        <FormFieldWrap>
          <div className="form-field-wrap__group-title">{lang.TABLE_FILTERS_MODAL_ATTRIBUTES_LABEL}</div>
          <div className="filters__items">
            <Field
              cssModifier="checkbox-button--switcher-theme-1 filters__item filters__item--view-3"
              label={lang.TABLE_FILTERS_MODAL_WITH_PHONE_RADIO}
              name="phone"
              type="checkbox"
              value="phone"
              checkboxType="switcher-2"
              component={fieldTemplate}
            />
            <Field
              cssModifier="checkbox-button--switcher-theme-1 filters__item filters__item--view-3"
              label={lang.TABLE_FILTERS_MODAL_WITH_EMAIL_RADIO}
              name="email"
              type="checkbox"
              checkboxType="switcher-2"
              value="email"
              component={fieldTemplate}
            />
            <Field
              cssModifier="checkbox-button--switcher-theme-1 filters__item filters__item--view-3"
              label={lang.TABLE_FILTERS_MODAL_WITH_VISUALS_RADIO}
              name="visuals"
              type="checkbox"
              value="visuals"
              checkboxType="switcher-2"
              component={fieldTemplate}
            />
          </div>
        </FormFieldWrap>
        <FormFieldWrap>
          <div className="form-field-wrap__group-title">{lang.TABLE_FILTERS_MODAL_PARTICIPATED_LABEL}</div>
          <div>
            <div className="filters__items">
              <Field
                cssModifier="radio-button--switcher-theme-1 filters__item filters__item--view-3"
                label={lang.TABLE_FILTERS_MODAL_NO_MATTER_RADIO}
                name="participated"
                type="radio"
                value="false"
                radioType="switcher-2"
                component={fieldTemplate}
              />
              <Field
                cssModifier="radio-button--switcher-theme-1 filters__item filters__item--view-3"
                label={lang.TABLE_FILTERS_MODAL_TIME_PERIOD_RADIO}
                name="participated"
                type="radio"
                value="true"
                radioType="switcher-2"
                component={fieldTemplate}
              />
            </div>
            <div className="form-field-wrap__group">
              <Field
                cssModifier="datepicker--theme-color-1 filters__item filters__item--view-4"
                label={lang.TABLE_FILTERS_MODAL_FROM_LABEL}
                name="participatedFrom"
                disabled={!participated}
                type="datepicker"
                component={fieldTemplate}
              />
              <Field
                cssModifier="datepicker--theme-color-1 filters__item filters__item--view-4"
                label={lang.TABLE_FILTERS_MODAL_TO_LABEL}
                name="participatedTo"
                disabled={!participated}
                disabledDayBefore={convertDate(formValues.participatedFrom)}
                type="datepicker"
                component={fieldTemplate}
              />
            </div>
          </div>
        </FormFieldWrap>
        <FormFieldWrap>
          <div className="form-field-wrap__group-title">{lang.TABLE_FILTERS_MODAL_GENDER_LABEL}</div>
          <div className="filters__items">
            <Field
              cssModifier="radio-button--switcher-theme-1 filters__item filters__item--view-3"
              label={lang.TABLE_FILTERS_MODAL_NO_MATTER_RADIO}
              name="gender"
              value="false"
              type="radio"
              radioType="switcher-2"
              component={fieldTemplate}
            />
            <Field
              cssModifier="radio-button--switcher-theme-1 filters__item filters__item--view-3"
              label={lang.TABLE_FILTERS_MODAL_MALE_RADIO}
              name="gender"
              value="male"
              type="radio"
              radioType="switcher-2"
              component={fieldTemplate}
            />
            <Field
              cssModifier="radio-button--switcher-theme-1 filters__item filters__item--view-3"
              label={lang.TABLE_FILTERS_MODAL_FEMALE_RADIO}
              name="gender"
              value="female"
              type="radio"
              radioType="switcher-2"
              component={fieldTemplate}
            />
            <Field
              cssModifier="radio-button--switcher-theme-1 filters__item filters__item--view-3"
              label={lang.TABLE_FILTERS_MODAL_OTHER_RADIO}
              name="gender"
              value="other"
              type="radio"
              radioType="switcher-2"
              component={fieldTemplate}
            />
            <Field
              cssModifier="radio-button--switcher-theme-1 filters__item filters__item--view-3"
              label={lang.TABLE_FILTERS_MODAL_N_A_RADIO}
              name="gender"
              value="N/A"
              type="radio"
              radioType="switcher-2"
              component={fieldTemplate}
            />
          </div>
        </FormFieldWrap>
        <FormFieldWrap>
          <div className="form-field-wrap__group-title">{lang.TABLE_FILTERS_MODAL_CREATED_LABEL}</div>
          <div className="filters__items">
            <Field
              cssModifier="radio-button--switcher-theme-1 filters__item filters__item--view-3"
              label={lang.TABLE_FILTERS_MODAL_NO_MATTER_RADIO}
              name="created"
              value="false"
              type="radio"
              radioType="switcher-2"
              component={fieldTemplate}
            />
            <Field
              cssModifier="radio-button--switcher-theme-1 filters__item filters__item--view-3"
              label={lang.TABLE_FILTERS_MODAL_TIME_PERIOD_RADIO}
              name="created"
              value="true"
              type="radio"
              radioType="switcher-2"
              component={fieldTemplate}
            />
          </div>
          <div className="form-field-wrap__group">
            <Field
              cssModifier="datepicker--theme-color-1 filters__item filters__item--view-4"
              label={lang.TABLE_FILTERS_MODAL_FROM_LABEL}
              name="createdFrom"
              disabled={!created}
              type="datepicker"
              component={fieldTemplate}
            />
            <Field
              cssModifier="datepicker--theme-color-1 filters__item filters__item--view-4"
              label={lang.TABLE_FILTERS_MODAL_TO_LABEL}
              disabled={!created}
              name="createdTo"
              disabledDayBefore={convertDate(formValues.createdFrom)}
              type="datepicker"
              component={fieldTemplate}
            />
          </div>
        </FormFieldWrap>
        {!clientId && (
          <FormFieldWrap>
            <Field
              cssModifier="select--no-min-height"
              label={lang.TABLE_FILTERS_MODAL_CLIENT_LABEL}
              name="client"
              type="select"
              searchable={false}
              options={clients}
              component={fieldTemplate}
            />
          </FormFieldWrap>
        )}
        {clientId && (
          <FormFieldWrap>
            <Field
              cssModifier="select--no-min-height select--view-6"
              label={lang.TABLE_FILTERS_MODAL_SOURCE_LABEL}
              name="source"
              type="select"
              searchable={false}
              options={sources}
              component={fieldTemplate}
            />
          </FormFieldWrap>
        )}

        <div className="form-field-wrap">
          <Field
            className="select--view-2"
            label={lang.TABLE_FILTERS_MODAL_OPERATION_LABEL}
            name="operation"
            getOptions={getOperationsOptions}
            component={AsyncDropdownAny}
          />
        </div>

        {selectedOperations.length > 0 && (
          <div className="form-field-wrap">
            <Field
              className="select--view-2"
              label={lang.TABLE_FILTERS_MODAL_AUTOTASK_LABEL}
              name="autotask"
              getOptions={getAutotasksOptions}
              component={AsyncDropdownAny}
            />
          </div>
        )}
        <FormFieldWrap>
          <div className="form-field-wrap__group-title">{lang.OPTIN_LABEL}</div>
          <div className="filters__items">
            <Field
              cssModifier="radio-button--switcher-theme-1 filters__item filters__item--view-3"
              label={lang.TABLE_FILTERS_MODAL_NO_MATTER_RADIO}
              name="optInRadio"
              type="radio"
              radioType="switcher-2"
              value={LeadsFiltersModal.optInValues.noMatter}
              component={fieldTemplate}
            />
            <Field
              cssModifier="radio-button--switcher-theme-1 filters__item filters__item--view-3"
              label={lang.SELECT_OPTIN_LABEL}
              name="optInRadio"
              type="radio"
              radioType="switcher-2"
              value={LeadsFiltersModal.optInValues.custom}
              component={fieldTemplate}
            />
            <Field
              cssModifier="radio-button--switcher-theme-1 filters__item filters__item--view-3"
              label={lang.NO_ACTIVE_RADIO}
              name="optInRadio"
              type="radio"
              radioType="switcher-2"
              value={LeadsFiltersModal.optInValues.noActive}
              component={fieldTemplate}
            />
          </div>
          <Field
            className="select--view-2"
            label={lang.SELECT_OPTIN_LABEL}
            disabled={!(filter.optInRadio === 'true')}
            name="opt_in"
            getOptions={getOptInsOptions}
            component={AsyncDropdownAny}
          />
        </FormFieldWrap>
        {filter.optInRadio === 'true' && (
          <div className="field-group__row field-group__row--vert-center">
            <div className="field-group__field">
              <div className="field-group__text">{lang.USE_BOOLEAN_LABEL}</div>
            </div>
            <div className="field-group__field">
              <Field label={lang.OR_LABEL} name="m" value="or" type="radio" component={fieldTemplate} />
            </div>
            <div className="field-group__field">
              <Field label={lang.AND_LABEL} name="m" value="and" type="radio" component={fieldTemplate} />
            </div>
          </div>
        )}
        <div className="modal-window__footer modal-window__footer--centered">
          <button className="button button--bg-2 modal-window__footer-btn" type="button" onClick={onClose}>
            {`${lang.TABLE_FILTERS_MODAL_CONFIRM_BUTTON} (${totalItems})`}
          </button>
        </div>
      </form>
    </Modal>
  );
};

LeadsFiltersModal.propTypes = {
  show: PropTypes.bool,
  clientId: PropTypes.string,
  filter: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  autotasks: PropTypes.array.isRequired,
  leadsList: PropTypes.object.isRequired,
  operations: PropTypes.array.isRequired,
  getAutotasks: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  clientsList: PropTypes.object.isRequired,
  getOperations: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  firstSources: PropTypes.object.isRequired,
  lang: PropTypes.object.isRequired,
  getFirstSourcesAction: PropTypes.func.isRequired,
  selectedOperations: PropTypes.array,
  getOptIns: PropTypes.func.isRequired,
  optIns: PropTypes.array,
};

LeadsFiltersModal.defaultProps = {
  filter: {
    optInRadio: 'false',
  },
  show: false,
  selectedOperations: [],
  optIns: [],
};

LeadsFiltersModal.formName = 'LeadsFiltersModalForm';

LeadsFiltersModal.optInValues = {
  custom: 'true',
  noMatter: 'false',
  noActive: 'noActive',
};

const filterNewProps = {
  participated: 'false',
  gender: 'false',
  created: 'false',
  client: {value: '0'},
  source: {value: '0'},
  opt_in: [],
  operation: [],
  autotask: [],
  optInRadio: 'false',
  m: 'and',
};

const LeadsFiltersModalForm = reduxForm({
  form: LeadsFiltersModal.formName,
  enableReinitialize: true,
})(LeadsFiltersModal);

export default connect(
  (state) => {
    const selector = formValueSelector(LeadsFiltersModal.formName);

    return {
      lang: state.languageState.payload.LEADS_LIST,
      initialValues: getInitialValues(filterNewProps),
      clientsList: state.clientsList,
      leadsList: state.leadsList,
      firstSources: state.firstSources,
      operations: selectOperationsForFilterModal(state),
      autotasks: selectAutotasksForFilterModal(state),
      optIns: selectOptInsForFilterModal(state),
      selectedOperations: selector(state, 'operation'),
    };
  },
  {
    getFirstSourcesAction: getFirstSources,
    getOperations,
    getAutotasks,
    getOptIns: getOptInsAction,
  },
)(LeadsFiltersModalForm);
