import {parseDate} from 'client/services/utils/date';

export default (values, timezone) => {
  const {from, to, fromTime, toTime, ...rest} = values;

  return {
    ...rest,
    from: parseDate(from, {time: fromTime, timezone, timezoneForce: true}),
    to: parseDate(to, {time: toTime, timezone, timezoneForce: true}),
  };
};
