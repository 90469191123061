import {callApi} from 'client/services/call-api';

import {API_METHODS, API_PATH} from 'client/common/config';

import {ApiAction} from 'client/types';

import types from './types';

export * from './actions-js';

export const saveOfflineInteractionBulk: ApiAction = (body) => (dispatch) =>
  callApi(dispatch, {
    method: 'PATCH',
    endpoint: `${API_PATH}${API_METHODS.OFFLINE_INTERACTIONS}/bulk_action`,
    types: [
      types.SAVE_OFFLINE_INTERACTION_BULK_REQUEST,
      types.SAVE_OFFLINE_INTERACTION_BULK_SUCCESS,
      types.SAVE_OFFLINE_INTERACTION_BULK_ERROR,
    ],
    body,
  });
