import React, {useCallback, useMemo, useState} from 'react';

import get from 'lodash/get';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import bem from 'client/services/bem';
import {useLanguage, useReduxFetch} from 'client/services/hooks';
import {parseDate} from 'client/services/utils/date';

import {selectAutotask} from 'client/ducks/autotask/selectors';
import {getDeviceAffectations} from 'client/ducks/device-affectation/actions';
import {saveOfflineInteractionBulk} from 'client/ducks/interactions/actions';
import {selectOperation} from 'client/ducks/operations/selectors';

import AwaitContainer from 'client/common/await-container';
import AppButton from 'client/common/buttons';
import {useToast} from 'client/common/hooks/useToast';
import {ErrorMessage} from 'client/common/inputs';
import Modal from 'client/common/modals/modal';

import {StoreInteractionBody} from 'client/components/client-stores/types';
import {DeviceAffectation} from 'client/models/device-affectations';

import {StoreDevice} from './store-device';
import {StoreDevicesModalChangingType, StoreDevicesModalErrorType} from './types';

import cssModule from './client-stores-devices-modal.module.scss';

const b = bem('client-sores-devices-modal', {cssModule});

type ClientStoresDevicesModalProps = {
  onClose: () => void;
  onSave: () => void;
  storeId: number;
};

export const ClientStoresDevicesModal: React.FC<ClientStoresDevicesModalProps> = (props) => {
  const {onClose, onSave, storeId} = props;

  const lang = useLanguage('CLIENT_STORES.MODALS.ATTACH_DEVICES');
  const langErrors = useLanguage('CLIENT_STORES.ERRORS');
  const langCommon = useLanguage('COMMON');

  const {appendToastNotification} = useToast();

  const [checkMap, setCheckMap] = useState<Map<number, StoreInteractionBody>>(new Map());
  const [uncheckMap, setUncheckMap] = useState<Map<number, StoreInteractionBody>>(new Map());
  const [errors, setErrors] = useState<Map<number, StoreDevicesModalErrorType>>(new Map());

  const isValid = !errors?.size;
  const isTouched = !!checkMap?.size || !!uncheckMap.size;

  const operation = useSelector(selectOperation);
  const autotask = useSelector(selectAutotask);

  const {clientId} = useParams<{clientId: string}>();

  const {data, loading} = useReduxFetch({
    action: getDeviceAffectations,
    actionArgs: {
      filter_interactions_by_client: true,
      q: {
        place_id_eq: storeId,
        client_id_eq: clientId,
      },
      page: 1,
      per_page: 100,
    },
  });

  const affectations = data?.device_affectations || [];

  const {fetch: saveOfflineInteraction, loading: loadingCreate} = useReduxFetch({
    action: saveOfflineInteractionBulk,
    fetchOnMount: false,
  });

  const reset = (id: number) => {
    setCheckMap((prev) => {
      prev.delete(id);
      return new Map(prev);
    });
    setUncheckMap((prev) => {
      prev.delete(id);
      return new Map(prev);
    });
  };

  const handeChange = useCallback(
    (type: StoreDevicesModalChangingType, affectation: DeviceAffectation, body: StoreInteractionBody) => {
      if (type === 'reset') {
        reset(affectation.id);
      } else if (type === 'check') {
        setCheckMap((prev) => {
          prev.set(affectation.id, body);
          return new Map(prev);
        });
      } else if (type === 'uncheck') {
        setUncheckMap((prev) => {
          prev.set(affectation.id, body);
          return new Map(prev);
        });
      }
    },
    [],
  );

  const handleSave = useCallback(async () => {
    const body: any[] = [];
    [...checkMap.values()].forEach((item) => {
      body.push({
        id: item.id,
        name: operation.name,
        from: parseDate(item.from, {timezone: operation.timezone}),
        to: parseDate(item.to, {timezone: operation.timezone}),
        default_language: item.default_language,
        device_id: item.device_id,
        interface_id: operation.client_interface?.id,
        place_id: item.place_id,
        store_id: item.place_id,
        interaction_group_id: autotask.client_plan_id,
      });
    });
    [...uncheckMap.values()].forEach((item) => {
      body.push({
        id: item.id,
        _destroy: true,
      });
    });

    const response = await saveOfflineInteraction({offline_interaction: body});

    if (response.error) {
      const responseErrors: Record<string, string>[] = get(response, 'payload.response.errors');
      const errorDescription =
        responseErrors.find((errorItem) => Array.isArray(errorItem.base) && errorItem.base.length > 0)?.base[0] ||
        langCommon.ERROR;

      appendToastNotification({
        type: 'error',
        description: errorDescription,
      });
    }

    onSave();
    onClose();
  }, [
    appendToastNotification,
    autotask.client_plan_id,
    checkMap,
    langCommon.ERROR,
    onClose,
    onSave,
    operation.client_interface?.id,
    operation.name,
    operation.timezone,
    saveOfflineInteraction,
    uncheckMap,
  ]);

  const handleValidateDevice = useCallback((affectationId: number, nextError?: StoreDevicesModalErrorType) => {
    setErrors((prev) => {
      if (nextError) {
        prev.set(affectationId, nextError);
      } else {
        prev.delete(affectationId);
      }
      return new Map(prev);
    });
  }, []);

  const totalError = useMemo(() => {
    return !errors.size || [...errors.values()].some((error) => error.type === 'validation')
      ? ''
      : langErrors.TOTAL_PERIOD_ERROR;
  }, [errors, langErrors]);

  return (
    <Modal onClose={onClose} isCloseHidden className={b()} classNames={{body: b('body')}} title={lang.TITLE}>
      <AwaitContainer loading={loading} overlay>
        <div className={b('content-wrap')}>
          <div className={b('content')}>
            {affectations.map((affectation) => (
              <StoreDevice
                key={affectation.id}
                affectation={affectation}
                onChange={handeChange}
                onError={handleValidateDevice}
              />
            ))}
          </div>
        </div>
      </AwaitContainer>

      <div className={b('footer')}>
        <div className={b('buttons')}>
          <AppButton label={lang.CANCEL_BUTTON} transparent onClick={onClose} />
          <AppButton
            label={lang.OK_BUTTON}
            loading={loadingCreate}
            onClick={handleSave}
            disabled={!isValid || !isTouched}
            size="small"
          />
        </div>
        {!isValid && <ErrorMessage errorMessage={totalError} className={b('error')} />}
      </div>
    </Modal>
  );
};
