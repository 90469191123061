import {callApi} from 'client/services/call-api';

import types from 'client/ducks/games/types';

import {API_METHODS, API_PATH} from 'client/common/config';

import {ApiAction} from 'client/types';

export type GetPrizeMapsLevelType =
  | 'PLACE_ONLINE'
  | 'PLACE_DEVICE'
  | 'DEVICE_GLOBAL'
  | 'ONLINE_GLOBAL'
  | 'DEVICE'
  | 'CHANNEL'
  | 'CAMPAIGN';

const isGeoLevel = (level: GetPrizeMapsLevelType) => ['GLOBAL_DEVICE', 'PLACE_DEVICE', 'DEVICE'].includes(level);

type GetActionParams = {
  level: GetPrizeMapsLevelType;
  sourceIds: number[];
};

const levelMappings = {
  DEVICE_GLOBAL: {queryKey: 'geo_level_eq', queryValue: 'global'},
  DEVICE: {queryKey: 'geo_level_eq', queryValue: 'device'},
  ONLINE_GLOBAL: {queryKey: 'online_level_eq', queryValue: 'global'},
  CHANNEL: {queryKey: 'online_level_eq', queryValue: 'source'},
  CAMPAIGN: {queryKey: 'online_level_eq', queryValue: 'campaign'},
  PLACE_ONLINE: {queryKey: 'online_level_in', queryValue: ['global', 'source', 'campaign']},
  PLACE_DEVICE: {queryKey: 'geo_level_in', queryValue: ['global', 'device']},
} as const;

const getActionParams = ({level, sourceIds}: GetActionParams) => {
  const {queryKey, queryValue} = levelMappings[level];

  if (!level.startsWith('PLACE')) {
    return {
      [isGeoLevel(level) ? 'geo_source_id_in' : 'online_source_id_in']: sourceIds,
      [queryKey]: queryValue,
    };
  }
  return {
    type_eq: 'NoneLevelPrizeMap',
    geo_level_eq: 'global',
    m: 'or',
    g: {
      0: {
        geo_level_eq: 'plan',
        geo_source_of_InteractionGroup_type_interactions_place_id_in: sourceIds,
      },
      1: {
        geo_level_eq: 'place',
        geo_source_id_in: sourceIds,
      },
      2: {
        geo_level_eq: 'device',
        geo_source_of_Interaction_type_place_id_in: sourceIds,
      },
    },
  };
};

type Params = GetActionParams & {
  queryParams: Record<string, any>;
};

export const getPrizeMapsByLevel: ApiAction = ({queryParams, ...params}: Params) => {
  return (dispatch) =>
    callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.PRIZE_MAPS}`,
        method: 'GET',
        types: [
          types.GET_DIY_PRIZE_MAPS_BY_LEVEL_REQUEST,
          types.GET_DIY_PRIZE_MAPS_BY_LEVEL_SUCCESS,
          types.GET_DIY_PRIZE_MAPS_BY_LEVEL_ERROR,
        ],
        queryParams: {
          ...queryParams,
          q: {
            g: {
              0: queryParams.q,
              1: getActionParams(params),
            },
          },
        },
      },
      true,
    );
};
