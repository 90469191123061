import React, {useEffect, useState} from 'react';

import debounce from 'lodash/debounce';
import {CustomPicker, Color, CustomPickerInjectedProps} from 'react-color';
import {Hue, Saturation} from 'react-color/lib/components/common';
import {useToggle} from 'react-use';
import tinycolor, {ColorInput} from 'tinycolor2';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import TextInput from 'client/common/inputs/text-input';

import {isColorHexValid} from './helpers';

import cssModule from './color-picker-simple.module.scss';

const b = bem('color-picker-simple', {cssModule});

const CustomSlider = () => {
  return <div className={b('slider')} />;
};

const CustomPointer = () => {
  return <div className={b('pointer')} />;
};

type ColorPickerSimpleProps = CustomPickerInjectedProps & {
  value: string;
  onChange: (color: string) => void;
  colorSwatches?: string[];
  position?: 'bottom' | 'top';
  hideInput?: boolean;
};

const ColorPickerSimple: React.FC<ColorPickerSimpleProps> = (props) => {
  const {value, onChange, children: trigger, colorSwatches, position = 'bottom', hideInput = false} = props;
  const [isPickerVisible, toggleIsPickerVisible] = useToggle(false);
  const [hsl, setHsl] = useState({h: 0, s: 0, l: 0});
  const [hsv, setHsv] = useState({h: 0, s: 0, v: 0});
  const [hex, setHex] = useState('');
  const [manualValue, setManualValue] = useState(value);
  const lang = useLanguage('COMMON.COLOR_PICKER_SIMPLE');
  const wrapperRef = React.useRef(null);

  const handleSaturationChange = (hsvColor: Color) => {
    const color = tinycolor(hsvColor as ColorInput);
    const hexColor = color.toHexString();
    onChange(hexColor);
  };

  useEffect(() => {
    if (value && value !== hex) {
      const color = tinycolor(value);
      setHsl(color.toHsl());
      setHsv(color.toHsv());
      setHex(color.toHexString());
    }
  }, [value, hex]);

  useEffect(() => {
    setManualValue(value);
  }, [value]);

  const debouncedClose = debounce(() => toggleIsPickerVisible(false), 200);

  const handleMouseEnter = () => {
    toggleIsPickerVisible(true);
    debouncedClose.cancel();
  };

  const handleManualColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const maxLength = 7;
    const newValue = e.target.value.trim().slice(0, maxLength);
    setManualValue(newValue);
    if (value && isColorHexValid(newValue)) {
      onChange(newValue);
    }
  };

  const isColorSwatchesVisible = colorSwatches && colorSwatches.length > 0;

  return (
    <div className={b()} ref={wrapperRef} onMouseEnter={handleMouseEnter} onMouseLeave={debouncedClose}>
      {isPickerVisible && (
        <div className={b('picker', [position])}>
          <div className={b('saturation')}>
            <Saturation {...props} pointer={CustomPointer} hsl={hsl} hsv={hsv} onChange={handleSaturationChange} />
          </div>
          {!hideInput && (
            <div className={b('manual-input-wrap')}>
              <TextInput
                onChange={handleManualColorChange}
                onBlur={() => setManualValue(value)}
                value={manualValue}
                inputClassName={b('manual-input', {invalid: !isColorHexValid(manualValue)})}
              />
            </div>
          )}
          <div className={b('hue')}>
            <Hue pointer={CustomSlider} direction="horizontal" hsl={hsl} onChange={setHsl} />
          </div>
          {isColorSwatchesVisible && (
            <>
              <span className={b('colors-title')}>{lang.DOCUMENT_COLORS}</span>
              <div className={b('swatches')}>
                {colorSwatches.slice(0, 5).map((color) => (
                  <button
                    key={color}
                    className={b('swatch')}
                    style={{backgroundColor: color}}
                    onClick={() => onChange(color)}
                    type="button"
                  />
                ))}
              </div>
            </>
          )}
        </div>
      )}
      {typeof trigger === 'function' ? trigger(isPickerVisible) : trigger}
    </div>
  );
};

export default CustomPicker(ColorPickerSimple);
