import React, {useEffect, useMemo} from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage, useReduxForm} from 'client/services/hooks';

import {setHeaderLinkBack} from 'client/ducks/header-link-back/actions';
import {getInstoreMonitoringList} from 'client/ducks/instore-tasks/actions';
import {getInstoreTaskAnimationPhotos} from 'client/ducks/instore-tasks/actions';
import {selectInstoreTask} from 'client/ducks/instore-tasks/selectors';
import {selectInstoreTaskMonitoringList} from 'client/ducks/instore-tasks/selectors';
import {selectAnimationPhotos} from 'client/ducks/instore-tasks/selectors';

import AppButton from 'client/common/buttons';
import ConfirmationModal from 'client/common/modals/confirmation-modal';
import LayoutPanel from 'client/common/panels/layout-panel';

import AnimationDetailsMobileBlock from 'client/components/animations/blocks/animation-details-mobile-block';
import AnimationBrandTitle from 'client/components/animations/components/animation-brand-title';
import {AnimationConfigContainerFormName} from 'client/components/animations/containers/animation-config-container/index';
import AnimationIntervalFieldset from 'client/components/animations/fieldsets/animation-interval-fieldset';
import AnimationPlanningFieldset from 'client/components/animations/fieldsets/animation-planning-fieldset';
import AnimationPositionKitFieldset from 'client/components/animations/fieldsets/animation-position-kit-fieldset';
import {ANIMATION_STATUSES} from 'client/components/instore/constants';

import AnimationAvailableDays from './animation-config-mobile-container-available-days';

import './animation-config-mobile-container.scss';

const b = bem('animation-config-mobile-container');

const AnimationConfigMobileContainer = (props) => {
  const {
    animation,
    readOnly,
    onSubmit,
    onSaveDraft,
    onClose,
    onOpenMonitoring,
    onOpenPhotos,
    onDelete,
    anomaliesContent = null,
  } = props;
  const lang = useLanguage('ANIMATIONS.CONTAINER.ANIMATION_CONFIG_MOBILE_CONTAINER');
  const {formValues} = useReduxForm(AnimationConfigContainerFormName);
  const {animation_days = [], kit_mapping = {}} = formValues;
  const task = useSelector(selectInstoreTask);
  const monitoringList = useSelector(selectInstoreTaskMonitoringList);
  const animationPhotos = useSelector(selectAnimationPhotos);
  const dispatch = useDispatch();
  const [showAnimationDaysPage, toggleShowAnimationDaysPage] = useToggle(false);
  const [isDeleteModalVisible, toggleIsDeleteModalVisible] = useToggle(false);

  const backLinkText = useMemo(() => {
    if (showAnimationDaysPage) {
      return lang.KIT_AVAILABILITY;
    }
    return animation?.id ? lang.EDIT_ANIMATION_TITLE : lang.NEW_ANIMATION_TITLE;
  }, [animation, lang, showAnimationDaysPage]);

  const backLinkCallback = useMemo(() => {
    if (showAnimationDaysPage) {
      return toggleShowAnimationDaysPage;
    }
    return onClose;
  }, [onClose, showAnimationDaysPage, toggleShowAnimationDaysPage]);

  const isAnimationDetailsBlockShown = useMemo(() => {
    const {details_reco, details_products, details_event} = kit_mapping || {};
    return Boolean(details_reco || details_products || details_event);
  }, [kit_mapping]);

  useEffect(() => {
    dispatch(
      setHeaderLinkBack({
        name: backLinkText,
        rootPage: false,
        callback: backLinkCallback,
      }),
    );

    return () => {
      dispatch(setHeaderLinkBack({name: task?.name, rootPage: false}));
    };
  }, [backLinkText, dispatch, task.name, backLinkCallback]);

  const showSaveButtons = !formValues?.status || [ANIMATION_STATUSES.EDIT].includes(formValues.status);
  const disabledPlanning = !showSaveButtons;
  const isPhotosButtonDisabled = animation?.status === ANIMATION_STATUSES.REPORT && isEmpty(animationPhotos);

  const showPositionKitFieldset =
    !formValues?.status ||
    ![
      ANIMATION_STATUSES.PRODUCTION,
      ANIMATION_STATUSES.RUNNING,
      ANIMATION_STATUSES.FINISHED,
      ANIMATION_STATUSES.REPORT,
    ].includes(formValues.status);
  const showPlanningActivation = showPositionKitFieldset;
  const isDeleteAvailable = !readOnly && animation?.status === ANIMATION_STATUSES.EDIT;

  useEffect(() => {
    if (animation?.id && !showPlanningActivation) {
      Promise.all([
        dispatch(
          getInstoreMonitoringList({
            q: {
              kpiable_id_eq: animation.id,
              kpiable_type_eq: 'Instore::Animation',
              task_kpi_nature_eq: 'report',
            },
            include: ['task_kpi'],
          }),
        ),
        dispatch(getInstoreTaskAnimationPhotos(animation.id)),
      ]);
    }
  }, [dispatch, animation?.id, showPlanningActivation]);

  if (showAnimationDaysPage) {
    return <AnimationAvailableDays readOnly={readOnly} />;
  }

  return (
    <div className={b()}>
      <AnimationBrandTitle />
      {showPositionKitFieldset && (
        <LayoutPanel>
          <AnimationPositionKitFieldset readOnly={readOnly} />
        </LayoutPanel>
      )}
      <LayoutPanel className={b('interval-panel')}>
        <AnimationIntervalFieldset readOnly={readOnly} />
        {showPlanningActivation && (
          <AppButton
            className={b('animation-days-btn')}
            label={lang.ANIMATION_DAYS}
            iconName="calendar-2"
            color="light-clients"
            disabled={!formValues?.kit_mapping?.id || disabledPlanning}
            onClick={toggleShowAnimationDaysPage}
          />
        )}
        {showPlanningActivation && animation_days.length > 0 && <AnimationPlanningFieldset readOnly />}
        {!showPlanningActivation && (
          <div className={b('animation-days-controls')}>
            <AppButton
              color="light-clients"
              onClick={onOpenMonitoring}
              iconName="settings-1"
              label={lang.MONITORING}
              size="full"
              disabled={isEmpty(monitoringList)}
            />
            <AppButton
              color="light-clients"
              onClick={onOpenPhotos}
              iconName="photos"
              label={lang.PHOTO}
              size="full"
              disabled={isPhotosButtonDisabled}
            />
          </div>
        )}
      </LayoutPanel>

      {anomaliesContent}

      {isAnimationDetailsBlockShown && (
        <LayoutPanel>
          <AnimationDetailsMobileBlock />
        </LayoutPanel>
      )}
      {(isDeleteAvailable || showSaveButtons) && (
        <div className={b('buttons')}>
          {isDeleteAvailable && (
            <AppButton label={lang.DELETE_BUTTON} color="error" transparent onClick={toggleIsDeleteModalVisible} />
          )}
          {showSaveButtons && (
            <>
              <AppButton
                label={lang.SAVE_DRAFT_BUTTON}
                color="light-clients"
                onClick={onSaveDraft}
                disabled={!formValues?.animation_days?.length}
              />
              <AppButton
                label={lang.SUBMIT_BUTTON}
                color="clients"
                onClick={onSubmit}
                disabled={!formValues?.animation_days?.length}
              />
            </>
          )}
        </div>
      )}
      <ConfirmationModal
        show={isDeleteModalVisible}
        message={lang.DELETE_CONFIRMATION}
        buttonConfirm={{label: lang.YES_BUTTON}}
        buttonCancel={{label: lang.NO_BUTTON}}
        onCancel={toggleIsDeleteModalVisible}
        onConfirm={onDelete}
        clientSide
      />
    </div>
  );
};

AnimationConfigMobileContainer.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSaveDraft: PropTypes.func,
  onSubmit: PropTypes.func,
  animation: PropTypes.object,
  onOpenMonitoring: PropTypes.func,
  onOpenPhotos: PropTypes.func,
  onCancelSubmitting: PropTypes.func,
  onDelete: PropTypes.func.isRequired,
  anomaliesContent: PropTypes.element,
};

AnimationConfigMobileContainer.defaultProps = {
  animation: null,
  onClose: null,
  onSaveDraft: null,
  onSubmit: null,
  onOpenPhotos: null,
  onOpenMonitoring: null,
  onCancelSubmitting: null,
};

export default AnimationConfigMobileContainer;
