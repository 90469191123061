import React, {ChangeEvent, useMemo} from 'react';

import get from 'lodash/get';
import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage, useReduxForm} from 'client/services/hooks';

import {selectAutotask} from 'client/ducks/autotask/selectors';
import {selectCurrentClient} from 'client/ducks/clients-list/selectors';
import {selectPrizeLanguages} from 'client/ducks/operations/selectors';

import {NumberField, TextField, ToggleField} from 'client/common/fields';

import LanguageDevicePanel from 'client/components/diy-operation/controls/language-device-panel';
import {COMMON_INPUT} from 'client/components/prizes/constants';
import {PrizeMapCustomizationFormName} from 'client/components/prizes/forms/prizemap-customization-form';

import cssModule from './prizemap-customization-general-fieldset.module.scss';

const b = bem('prizemap-customization-general-fieldset', {cssModule});

type PrizeMapCustomizationGeneralFieldsetProps = {
  disabled?: boolean;
};

const PrizeMapCustomizationGeneralFieldset: React.FC<PrizeMapCustomizationGeneralFieldsetProps> = ({disabled}) => {
  const lang = useLanguage('PRIZES.FIELDSETS.PRIZEMAP_CUSTOMIZATION_GENERAL_FIELDSET');
  const languages = useSelector(selectPrizeLanguages);
  const autotask = useSelector(selectAutotask);
  const client = useSelector(selectCurrentClient);
  const languageOptions = useMemo(() => languages.map((language) => ({value: language, label: language})), [languages]);

  const {formValues, change} = useReduxForm(PrizeMapCustomizationFormName);
  const {activeLanguage} = formValues;

  const defaultLanguage = languageOptions[0]?.value;

  const handleActiveLanguageChange = (language: string) => {
    change('activeLanguage', language);

    if (language !== defaultLanguage) {
      const currentLanguagePath = `external_prize_name_translations[${language}]`;
      const localName = get(formValues, currentLanguagePath);
      const defaultName = get(formValues, `external_prize_name_translations[${defaultLanguage}]`);

      if (!localName && defaultName) {
        change(currentLanguagePath, defaultName);
      }
    }
  };

  const handleCommonLanguageToggle = (isCommon: boolean) => {
    change('activeLanguage', isCommon ? COMMON_INPUT : defaultLanguage);

    if (isCommon) {
      languages.forEach((language) => {
        change(
          `external_prize_name_translations[${language}]`,
          get(formValues, `external_prize_name_translations[${defaultLanguage}]`),
        );
      });
    }
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value;

    if (activeLanguage === COMMON_INPUT) {
      languages.forEach((language) => {
        change(`external_prize_name_translations[${language}]`, value);
      });
    }
  };

  return (
    <>
      <p className={b('title')}>{lang.GENERAL_INFO}</p>
      <div className={b('row')}>
        <NumberField
          name="level"
          label={lang.LEVEL}
          className={b('level-field')}
          disabled={client.auto_configuration && autotask.validated}
        />
        <ToggleField
          label={lang.DISTRIBUTION}
          rounded
          borderColor="current"
          className={b('toggle', ['distribution'])}
          name="distribution"
          disabled={client.auto_configuration && autotask.validated}
        />
      </div>
      <LanguageDevicePanel
        languages={languageOptions}
        prefix="prize-customization"
        language={formValues.activeLanguage}
        onChangeLanguage={handleActiveLanguageChange}
        langInitiallyCommon={formValues.language_unique_mode}
        onLanguageCommonToggle={handleCommonLanguageToggle}
      />
      <TextField
        label={lang.PRIZE_NAME}
        name={`external_prize_name_translations[${activeLanguage === COMMON_INPUT ? defaultLanguage : activeLanguage}]`}
        onChange={handleNameChange}
        disabled={disabled}
      />
      <div className={b('toggles-row')}>
        <ToggleField
          label={lang.SEND_EMAIL}
          rounded
          borderColor="current"
          className={b('toggle')}
          name="email_send"
          disabled={disabled}
        />
        <ToggleField
          label={lang.SEND_SMS}
          rounded
          borderColor="current"
          className={b('toggle')}
          name="sms_send"
          disabled={disabled}
        />
        <ToggleField
          label={lang.TICKET_PRINTING}
          rounded
          borderColor="current"
          className={b('toggle')}
          name="ticket_print"
          disabled={disabled}
        />
      </div>
    </>
  );
};

export default PrizeMapCustomizationGeneralFieldset;
