import {Prize, Game} from 'client/models/prizes/types';

import getMainInitialValues from './main-params-fieldset/getInitialValues';
import getSegmentationInitialValues from './segmentation-params-fieldset/getInitialValues';
import {LotteryPrizeValues} from './types';
import getWinInitialValues from './win-params-fieldset/getInitialValues';

type Params = {
  game: Game;
  languages: string[];
  storeId?: number;
  isLocal: boolean;
  timezone: string;
};

export default (prize: Prize | null, {game, languages, storeId, isLocal, timezone}: Params): LotteryPrizeValues => {
  return {
    winParams: getWinInitialValues(prize, languages),
    mainParams: getMainInitialValues(prize, {timezone}),
    segmentationParams: getSegmentationInitialValues(game, prize, isLocal),
    storeId: prize?.id ? prize.store_id : storeId,
  };
};
