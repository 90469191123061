export const getDateFormat = (date?: string) => {
  let dateFormat = '';

  if (date) {
    // there are handling general project date formats (from backend or from datepicker)
    if (date.includes('/')) {
      const d = date.split(' ')[0];
      const time = date.split(' ')[1];

      const splittedDate = d.split('/');
      if (
        splittedDate.length &&
        splittedDate[0]?.length === 2 &&
        splittedDate[1]?.length === 2 &&
        splittedDate[2]?.length === 4
      ) {
        dateFormat = 'DD/MM/YYYY';

        if (time) {
          dateFormat += ' HH:mm';
        }
        if (time?.split(':').length === 3) {
          dateFormat += ':ss';
        }
      }
    } else if (date.includes('-')) {
      if (date.length === 10) {
        const splitted = date.split('/');
        if (splitted.length && splitted[0]?.length === 4 && splitted[1]?.length === 2 && splitted[2]?.length === 2) {
          dateFormat = 'YYYY-MM-DD';
        }
      }
    }
  }

  return dateFormat;
};
