import {ACCESS_LEVEL} from 'client/models/common/constants';
import {CATALOG_TEMPLATE_TABS} from 'client/models/templates/constants';

const isObject = (val) => typeof val === 'object' && !Array.isArray(val) && val !== null;
const isEmptyObject = (val) => (Array.isArray(val) && !val.length) || (isObject(val) && !Object.keys(val).length);

export function filterByCatalogType(templates, type) {
  return templates.filter(({catalog}) => catalog?.type === type);
}

export function removeEmptyKeys(obj) {
  const keys = Object.keys(obj);
  const newObj = {};
  if (!keys.length) {
    return obj;
  }

  keys.forEach((key) => {
    let value = obj[key];
    if (value && !isObject(value)) {
      newObj[key] = value;
    } else if (isObject(value) && !isEmptyObject(value)) {
      newObj[key] = removeEmptyKeys(value);
    }
  });
  return newObj;
}

export function filterPages(pages) {
  return pages.filter((page) => !page.default_hide);
}

export function getFormItems(formPages) {
  return formPages.length
    ? formPages.reduce((pages, {form_sections}) => {
        return {
          ...pages,
          ...(form_sections || []).reduce((sections, {form_items}) => {
            return {
              ...sections,
              ...(form_items || []).reduce(
                (items, {id, display_type, item_type, template_answers, special_selector}) => {
                  return {
                    ...items,
                    [id]: {
                      id,
                      display_type,
                      item_type,
                      template_answers,
                      special_selector,
                    },
                  };
                },
                {},
              ),
            };
          }, {}),
        };
      }, {})
    : {};
}

export const getQueryParamsByTab = ({tab, accessLevel}) => {
  switch (tab) {
    case CATALOG_TEMPLATE_TABS.MY_TEMPLATES:
      return {creator_team_access_eq: accessLevel};
    case CATALOG_TEMPLATE_TABS.WEEZIO:
      return {creator_team_id_null: true};
    case CATALOG_TEMPLATE_TABS.SHARED:
      return {creator_team_access_not_eq: accessLevel};
    default:
      return {};
  }
};

const filterClientTemplateByTab =
  ({tab, access}) =>
  (template) => {
    if (tab === CATALOG_TEMPLATE_TABS.WEEZIO) {
      return !template.creator_team_id;
    }
    if (!template.creator_team) {
      return false;
    }
    if (tab === CATALOG_TEMPLATE_TABS.MY_TEMPLATES) {
      return (
        template.creator_team?.access === access ||
        (access === ACCESS_LEVEL.CLIENT_ADMIN && template.creator_team?.access === ACCESS_LEVEL.NATIONAL)
      );
    }
    if (tab === CATALOG_TEMPLATE_TABS.SHARED) {
      return (
        template.user_access_levels.includes(access) ||
        (template.creator_team?.access !== access &&
          access !== ACCESS_LEVEL.CLIENT_ADMIN &&
          template.creator_team?.access !== ACCESS_LEVEL.NATIONAL)
      );
    }
    return false;
  };

/**
 *
 * @param {import('client/models/templates/types').ClientTemplate[]} clientTemplates
 * @param accessLevel
 * @returns {Record<import('client/models/templates/types').CatalogTabType, import('client/models/templates/types').ClientTemplate[]>}
 */
export const groupClientTemplateByTabs = (clientTemplates, {access = null} = {}) => ({
  [CATALOG_TEMPLATE_TABS.MY_TEMPLATES]: clientTemplates.filter(
    filterClientTemplateByTab({tab: CATALOG_TEMPLATE_TABS.MY_TEMPLATES, access}),
  ),
  [CATALOG_TEMPLATE_TABS.SHARED]: clientTemplates.filter(
    filterClientTemplateByTab({tab: CATALOG_TEMPLATE_TABS.SHARED, access}),
  ),
  [CATALOG_TEMPLATE_TABS.WEEZIO]: clientTemplates.filter(
    filterClientTemplateByTab({tab: CATALOG_TEMPLATE_TABS.WEEZIO, access}),
  ),
});
