import React from 'react';

import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import {useToggle} from 'react-use';
import {reduxForm} from 'redux-form';

import bem from 'client/services/bem';
import {useLanguage, useReduxForm} from 'client/services/hooks';

import {createInternalInstoreDashboard, updateInternalInstoreDashboard} from 'client/ducks/instore-dashboards/actions';
import {selectCurrentAccessLevel} from 'client/ducks/user-clients/selectors';

import AppButton from 'client/common/buttons/app-button';
import CheckboxField from 'client/common/fields/checkbox-field';
import TextField from 'client/common/fields/text-field';
import Modal from 'client/common/modals/modal';

import {ACCESS_LEVEL} from 'client/models/common/constants';

import mapFormValues from './mapFormValues';
import mapInitialValues from './mapInitialValues';
import validateValues from './validateValues';

import cssModule from './instore-dashboard-modal.module.scss';

const b = bem('instore-dashboard-modal', {cssModule});

const FORM_NAME = 'INSTORE_DASHBOARD_MODAL_FORM';

const InstoreDashboardModal = (props) => {
  const {handleSubmit, dashboard, task, onClose, user, fetchDashboards, onCreate} = props;
  const dispatch = useDispatch();
  const lang = useLanguage('INSTORE_DASHBOARDS.MODALS.INSTORE_DASHBOARD_MODAL');
  const currentLevel = useSelector(selectCurrentAccessLevel);
  const [loading, toggleLoading] = useToggle(false);

  const {invalid} = useReduxForm(FORM_NAME, {
    initialValues: mapInitialValues(dashboard),
    validate: validateValues(lang.ERRORS),
  });

  const showSharing = [ACCESS_LEVEL.NATIONAL, ACCESS_LEVEL.CLIENT_ADMIN].includes(currentLevel);

  const handleSave = async (values) => {
    const data = mapFormValues(values, task, user);
    toggleLoading();
    if (dashboard) {
      await dispatch(updateInternalInstoreDashboard(dashboard.id, data));
    } else {
      const res = await dispatch(createInternalInstoreDashboard(data));
      onCreate(res?.payload?.instore_internal_dashboard?.id);
    }
    await fetchDashboards();
    toggleLoading();
    onClose();
  };

  return (
    <Modal className={b()} title={dashboard ? lang.EDIT_DASHBOARD : lang.CREATE_DASHBOARD} onClose={onClose}>
      <form onSubmit={handleSubmit(handleSave)}>
        <div className={b('block')}>
          <TextField name="name" inputClassName={b('name-input')} label={lang.DASHBOARD_NAME} required={true} />
        </div>
        {showSharing && (
          <div className={b('block')}>
            <p className={b('sharing-label')}>{lang.SHARE_WITH}</p>
            <CheckboxField
              name={`sharing.${ACCESS_LEVEL.NATIONAL}`}
              label={lang.HEADQUARTER}
              color="primary"
              inversionColor={true}
            />
            <CheckboxField
              name={`sharing.${ACCESS_LEVEL.REGION}`}
              label={lang.REGION}
              color="primary"
              inversionColor={true}
            />
            <CheckboxField
              name={`sharing.${ACCESS_LEVEL.LOCAL}`}
              label={lang.FIELD_SALES}
              color="primary"
              inversionColor={true}
            />
          </div>
        )}
        <div className={b('footer')}>
          <AppButton label={dashboard ? lang.SAVE : lang.CREATE} submit={true} loading={loading} disabled={invalid} />
        </div>
      </form>
    </Modal>
  );
};

InstoreDashboardModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  dashboard: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  task: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  fetchDashboards: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  onCreate: PropTypes.func.isRequired,
};

InstoreDashboardModal.defaultProps = {
  dashboard: null,
};

export default reduxForm({form: FORM_NAME})(InstoreDashboardModal);
