import {useCallback, useRef} from 'react';

import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import {useDispatch} from 'react-redux';
import {useDeepCompareEffect} from 'react-use';
import {
  startAsyncValidation as startAsyncValidationForm,
  stopAsyncValidation as stopAsyncValidationForm,
} from 'redux-form';

type ReduxFormValidationParams = {
  formName: string;
  validate?: (() => Record<string, any>) | null;
  validateAsync?: (() => Promise<Record<string, any>>) | null;
};

let timeout: NodeJS.Timeout;

const PROTECT_DEP = {};

export const useReduxFormValidation = (params: ReduxFormValidationParams, deps: Array<any> = []) => {
  const {formName, validate: validateFn, validateAsync: validateAsyncFn} = params;

  const dispatch = useDispatch();
  const prevSyncErrorsRef = useRef<Record<string, any>>();

  const updateErrors = useCallback(
    (errors) => {
      dispatch({
        type: '@@redux-form/UPDATE_SYNC_ERRORS',
        meta: {
          form: formName,
        },
        payload: {
          syncErrors: errors,
        },
      });
    },
    [formName, dispatch],
  );
  const startAsyncValidation = useCallback(() => {
    dispatch(startAsyncValidationForm(formName));
  }, [formName, dispatch]);

  const stopAsyncValidation = useCallback(
    (errors) => {
      dispatch(stopAsyncValidationForm(formName, errors));
    },
    [formName, dispatch],
  );

  const updateSyncErrorsIfNeeded = useCallback(
    (errors, previousErrors) => {
      if (!(isEmpty(errors) && isEmpty(previousErrors)) && !isEqual(previousErrors, errors)) {
        updateErrors(errors);
      }
    },
    [updateErrors],
  );

  const validate = async () => {
    if (validateFn) {
      const previousErrors = prevSyncErrorsRef.current;
      const errors = validateFn();
      updateSyncErrorsIfNeeded(errors, previousErrors);
      prevSyncErrorsRef.current = errors;
    }
  };

  const validateAsync = async () => {
    if (validateAsyncFn) {
      clearTimeout(timeout);
      stopAsyncValidation({});
      timeout = setTimeout(async () => {
        startAsyncValidation();
        const errors = await validateAsyncFn();
        stopAsyncValidation(errors);
      }, 300);
    }
  };

  useDeepCompareEffect(() => {
    validate();
    validateAsync();
  }, [...deps, PROTECT_DEP]);
};
