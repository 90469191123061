import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {DatetimePickerField} from 'client/common/fields';

import cssModule from './lottery-period-block.module.scss';

const b = bem('lottery-period-block', {cssModule});

type LotteryPeriodBlockProps = {
  isReadOnly?: boolean;
  className?: string;
  classNames?: {
    title?: string;
    input?: string;
    dates?: string;
  };
  name: string;
  timezone: string;
};

const LotteryPeriodBlock: React.FC<LotteryPeriodBlockProps> = (props) => {
  const {isReadOnly, className, classNames, name, timezone} = props;
  const lang = useLanguage('LOTTERY.FIELDSETS.LOTTERY_PERIOD_BLOCK');

  return (
    <div className={cn(b(), className)}>
      <p className={cn(b('title'), classNames?.title)}>{lang.TITLE}</p>
      <div className={cn(b('dates'), classNames?.dates)}>
        <DatetimePickerField
          className={cn(b('input'), classNames?.input)}
          name={`${name}.default_from`}
          label={lang.FROM}
          withSeconds={false}
          alwaysShowError
          disabled={isReadOnly}
          timezone={timezone}
        />
        <DatetimePickerField
          className={cn(b('input'), classNames?.input)}
          name={`${name}.default_to`}
          label={lang.TO}
          withSeconds={false}
          alwaysShowError
          disabled={isReadOnly}
          timezone={timezone}
        />
      </div>
    </div>
  );
};

export default LotteryPeriodBlock;
