import React, {ComponentType} from 'react';

import {Field} from 'redux-form';
import {BaseFieldProps} from 'redux-form/lib/Field';

import {PartialKeys} from 'client/types/common';

import {BaseFieldComponent, BaseFieldComponentProps, BaseFieldExternProps} from './base-field';

type FormFieldProps = Omit<BaseFieldProps, 'props' | 'component'>;

type PartialCProps<TProps extends Record<string, any>> = PartialKeys<TProps, 'onChange' | 'value'>;

/**
 * fabric method for creating form field component with right Typing;
 */
export const createFormField = <CProps extends Record<string, any> = Record<string, any>>(
  Component: ComponentType<CProps>,
) => {
  function InputComponent(props: Omit<BaseFieldComponentProps<CProps>, 'Component'>) {
    return <BaseFieldComponent Component={Component} {...props} />;
  }

  return (props: FormFieldProps & BaseFieldExternProps & PartialCProps<CProps>) => {
    return <Field component={InputComponent} {...props} />;
  };
};
