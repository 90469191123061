import React, {useMemo, useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage, useQueryParams, useReduxFetch} from 'client/services/hooks';

import {getClientPlaces} from 'client/ducks/client-stores/actions';
import {linkPlaces} from 'client/ducks/client-stores/actions';
import {selectClientPlaces} from 'client/ducks/client-stores/selectors';
import {selectedClientGames} from 'client/ducks/games/selectors';

import AppButton from 'client/common/buttons/app-button';
import PaginationBar from 'client/common/paginations/pagination-bar';
import PerPageDropdown from 'client/common/selects/per-page-dropdown';

import ClientRegionPlacesFilterModal from 'client/components/client-autotask/modals/client-region-places-filter-modal';
import {ClientStoresDevicesModal} from 'client/components/client-stores/modals/client-stores-devices-modal';
import ClientStoresSourcesModal from 'client/components/client-stores/modals/client-stores-sources-modal';
import {StoreListModalState} from 'client/components/client-stores/types';
import LotteryPrizeCustomizationModal from 'client/components/lottery/modals/lottery-prize-customization-modal';
import PrizeCustomizationModal from 'client/components/prizes/modals/prize-customization-modal';
import {Client} from 'client/models/client/types';
import {Operation} from 'client/models/operations/types';
import {ApiAction} from 'client/types';
import {getDefaultMeta} from 'client/types/common/helpers';

import ClientStoresTable from './client-stores-table';
import {getCalculations} from './helpers';
import {PlaceWithCalculations} from './types';

import cssModule from './client-stores-list.module.scss';

const b = bem('client-stores-list', {cssModule});

type ClientStoresListProps = {
  client: Client;
  operation: Operation;
};

const ClientStoresList: React.FC<ClientStoresListProps> = (props) => {
  const {client, operation} = props;
  const dispatch: ApiAction = useDispatch();
  const lang = useLanguage('CLIENT_STORES.LISTS.CLIENT_STORES_LIST');
  const [queryParams, setQueryParams] = useQueryParams(null, {page: 1, perPage: 25});

  const {instantWinGame, lotteryGame} = useSelector(selectedClientGames);

  const [storeModal, setStoreModal] = useState<null | StoreListModalState>(null);

  const [checkedStores, setCheckedStores] = useState<PlaceWithCalculations[]>([]);

  const isDevice = !!operation?.client_interface?.device;

  const {
    data = {items: [], meta: getDefaultMeta()},
    loading,
    fetch: fetchPlaces,
  } = useReduxFetch({
    action: getClientPlaces,
    selector: selectClientPlaces,
    actionArgs: {
      clientId: client.id,
      operationId: operation.id,
      page: queryParams.page,
      perPage: queryParams.perPage,
      regionId: queryParams.region,
      placeId: queryParams.store,
    },
    skip: !client.id || !operation.id,
  });

  const stores = useMemo(() => {
    return data.items.map((place) => ({...place, calculations: getCalculations(place, operation)}));
  }, [data, operation]);

  const handleCheck = (checked: boolean, store: PlaceWithCalculations) => {
    setCheckedStores((prev) => {
      if (checked) {
        return [...prev, store];
      }
      return prev.filter((item) => item?.id !== store.id);
    });
  };

  const handleCheckAll = (checked: boolean) => {
    if (checked) {
      const newStores: PlaceWithCalculations[] = [];
      stores.forEach((store) => {
        const isNew = !checkedStores.find((checkedStore) => checkedStore.id === store.id);
        if (isNew && store.calculations.canCheck) {
          newStores.push(store);
        }
      });
      setCheckedStores([...checkedStores, ...newStores]);
    } else {
      const ids = stores.map((i) => i.id);
      setCheckedStores(checkedStores.filter((i) => !ids.includes(i.id)));
    }
  };

  const handleLink = async () => {
    const placeIds = checkedStores.map((i) => i.id);
    await dispatch(linkPlaces({operationId: operation.id, placeIds}));
    fetchPlaces();
    setCheckedStores([]);
  };

  const handlePageChange = ({page}: {page: string}) => {
    setQueryParams({page});
  };

  const handlePerPageChange = ({value}: {value: string}) => {
    setQueryParams({
      ...queryParams,
      page: 1,
      perPage: value,
    });
  };

  const handleOpenModal = (state: StoreListModalState) => {
    setStoreModal(state);
  };

  const handleCloseModal = () => {
    setStoreModal(null);
  };

  const sourceIds = useMemo(() => (storeModal ? [storeModal?.place.id] : []), [storeModal]);

  return (
    <div className={b()}>
      <h1 className={b('title')}>{lang.TITLE}</h1>
      <div className={b('bar')}>
        <div className={b('linking-block')}>
          <AppButton label={lang.LINK_TO_OPERATION} onClick={handleLink} disabled={!checkedStores.length} />
          {checkedStores.length > 0 && (
            <span>
              {checkedStores.length} {lang.STORES_SELECTED}
            </span>
          )}
          <ClientRegionPlacesFilterModal />
        </div>
        <PerPageDropdown className={b('per-page')} value={String(queryParams.perPage)} onChange={handlePerPageChange} />
      </div>
      <ClientStoresTable
        data={stores}
        loading={loading}
        operation={operation}
        onOpenModal={handleOpenModal}
        onCheckAll={handleCheckAll}
        onCheck={handleCheck}
        checkedStores={checkedStores}
      />
      <div className={b('pagination')}>
        <PaginationBar
          data={data.items}
          onPageChange={handlePageChange}
          currentPage={data.meta.current_page}
          totalPages={data.meta.total_pages}
          totalItems={data.meta.total_count}
          perPage={Number(queryParams.perPage)}
        />
      </div>
      {storeModal?.type === 'device' && (
        <ClientStoresDevicesModal onClose={handleCloseModal} storeId={storeModal.place.id} onSave={fetchPlaces} />
      )}
      {storeModal?.type === 'source' && operation.client_interface?.id && (
        <ClientStoresSourcesModal
          onClose={handleCloseModal}
          onSave={fetchPlaces}
          storeId={storeModal.place.id}
          operation={operation}
          interfaceId={operation.client_interface.id}
        />
      )}

      {storeModal?.type === 'prize_instant_win' && instantWinGame && (
        <PrizeCustomizationModal
          level={!isDevice ? 'PLACE_ONLINE' : 'PLACE_DEVICE'}
          onClose={handleCloseModal}
          game={instantWinGame}
          sourceIds={sourceIds}
          levelName={storeModal.place.name}
          onFetch={fetchPlaces}
          disabled={false}
        />
      )}

      {storeModal?.type === 'prize_prize_draw' && lotteryGame && (
        <LotteryPrizeCustomizationModal
          level={!isDevice ? 'PLACE_ONLINE' : 'PLACE_DEVICE'}
          onClose={handleCloseModal}
          game={lotteryGame}
          sourceIds={sourceIds}
          sourceName={storeModal.place.name}
          disabled={false}
        />
      )}
    </div>
  );
};

export default ClientStoresList;
