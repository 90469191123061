import sortBy from 'lodash/sortBy';

export const updateOrdersForNonMandatory = (optIns) =>
  optIns?.map((optIn, index) => {
    return {
      ...optIn,
      order: optIn.mandatory_order || index + 1,
    };
  });

export const sortOptIns = (optIns) => {
  return sortBy(updateOrdersForNonMandatory(optIns), ['order', 'mandatory_order']);
};
