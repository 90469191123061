import {useReduxFetch} from 'client/services/hooks';

import {getLotteryPrizes} from 'client/ducks/lottery/actions';
import {selectLotteryPrizes} from 'client/ducks/lottery/selectors';

export default (gameId?: number) => {
  const {
    data: prizes = [],
    loading: loadingPrizes,
    fetch: fetchPrizes,
  } = useReduxFetch({
    skip: !gameId,
    action: getLotteryPrizes,
    selector: selectLotteryPrizes,
    actionArgs: {
      q: {
        s: 'order asc',
        game_id_eq: gameId,
        active_eq: true,
      },
      include_prize_total_quantities: null,
      include_prize_email_send_state: null,
      include_prize_sms_send_state: null,
      include_prize_email_image_translations: null,
      include_prize_picto_translations: null,
      include_prize_images: null,
    },
  });

  return {
    prizes,
    fetchPrizes,
    loadingPrizes,
  };
};
