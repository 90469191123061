import {callApi} from 'client/services/call-api';

import {API_METHODS, API_PATH} from 'client/common/config';

import {DeviceAffectation} from 'client/models/device-affectations';
import {ApiAction} from 'client/types';
import {PaginationMeta} from 'client/types/common';

import types from './types';

export const getDeviceAffectations: ApiAction<{device_affectations: DeviceAffectation[]; meta?: PaginationMeta}> =
  (queryParams) => (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.DEVICE_AFFECTATIONS}/client`,
      method: 'GET',
      types: [
        types.GET_DEVICE_AFFECTATIONS_REQUEST,
        types.GET_DEVICE_AFFECTATIONS_SUCCESS,
        types.GET_DEVICE_AFFECTATIONS_ERROR,
      ],
      queryParams,
    });

export const getDeviceAffectationsForFilers: ApiAction<{device_affectations: DeviceAffectation[]}> =
  (queryParams) => (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.DEVICE_AFFECTATIONS}`,
      method: 'GET',
      types: [
        types.GET_DEVICE_AFFECTATIONS_FOR_FILTERS_REQUEST,
        types.GET_DEVICE_AFFECTATIONS_FOR_FILTERS_SUCCESS,
        types.GET_DEVICE_AFFECTATIONS_FOR_FILTERS_ERROR,
      ],
      queryParams,
    });

export const getDeviceAffectation: ApiAction<{device_affectation: DeviceAffectation}> =
  ({id, queryParams}) =>
  (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.DEVICE_AFFECTATIONS}/${id}`,
      method: 'GET',
      types: [
        types.GET_DEVICE_AFFECTATION_REQUEST,
        types.GET_DEVICE_AFFECTATION_SUCCESS,
        types.GET_DEVICE_AFFECTATION_ERROR,
      ],
      queryParams,
    });
