import React, {useState, useCallback, useMemo} from 'react';

import {useParams} from 'react-router-dom';

import bem from 'client/services/bem';
import {useLanguage, useQueryParams, useReduxFetch} from 'client/services/hooks';

import {getDeviceAffectations} from 'client/ducks/device-affectation/actions';

import PaginationBar from 'client/common/paginations/pagination-bar';

import ClientDeviceModal, {ClientDeviceModalTab} from 'client/components/devices/modals/client-device-modal';
import MediasSummaryDashboard from 'client/components/medias/dashboards/medias-summary-dashboard';
import PerPage from 'client/components/tables/common/per-page';
import {DeviceAffectation} from 'client/models/device-affectations';

import ClientDevicesListFilterModal from './client-devices-list-filter-modal';
import ClientDevicesListTable from './client-devices-list-table';
import ClientDevicesListFilterBar from './client-devices-list-tool-bar';
import {getFilters} from './helpers';

import ClientDevicesListMapModal from '../../modals/devices-map-modal';

import './client-devices-list.scss';

const DEFAULT_PER_PAGE = 25;

const b = bem('client-devices-list');

type DeviceModalState = null | {affectation: DeviceAffectation; tab: ClientDeviceModalTab};

const ClientDevicesList = () => {
  const {clientId} = useParams<{clientId: string}>();
  const lang = useLanguage('CLIENT_DEVICES');

  // state
  const [deviceModal, setDeviceModal] = useState<DeviceModalState>(null);
  const [openModal, setOpenModal] = useState('');
  const [queryParams, setQueryParams] = useQueryParams(
    null,
    {
      sort: {
        sortField: 'place_city_name',
        sortOrder: 'asc',
      },
      page: 1,
      perPage: DEFAULT_PER_PAGE,
    },
    {parse: {depth: 3}},
  );

  const fetchArgs = useMemo(() => {
    return {
      include_device_media_status: true,
      include_place_region_name: true,
      include_interaction_current: true,
      filter_interactions_by_client: true,
      page: queryParams.page,
      per_page: queryParams.perPage,
      q: {
        s: `${queryParams.sort?.sortField} ${queryParams.sort?.sortOrder}`,
        client_id_eq: clientId,
        g: {
          search: {
            m: 'or',
            place_region_name_cont: queryParams.search,
            place_city_name_cont: queryParams.search,
            place_name_cont: queryParams.search,
            device_code_cont: queryParams.search,
            device_current_interaction_automation_task_name_cont: queryParams.search,
          },
        },
        ...getFilters(queryParams.filters || {}),
      },
    };
  }, [
    clientId,
    queryParams.filters,
    queryParams.page,
    queryParams.perPage,
    queryParams.search,
    queryParams.sort?.sortField,
    queryParams.sort?.sortOrder,
  ]);

  const {data, loading, fetch} = useReduxFetch({
    action: getDeviceAffectations,
    actionArgs: fetchArgs,
  });

  // callbacks
  const handlePageParamsChange = useCallback(
    ({perPage}) => {
      setQueryParams({page: 1, perPage: Number(perPage)});
    },
    [setQueryParams],
  );

  const handleCurrentPageChange = useCallback(
    ({page}) => {
      setQueryParams({page});
    },
    [setQueryParams],
  );

  const closeModal = useCallback(() => {
    setOpenModal('');
  }, []);

  const handleFilterModal = useCallback(() => setOpenModal('filterModal'), []);

  const handleChangeSort = (params: {sortOrder: string; sortField: string; page: number}) => {
    setQueryParams({
      sort: {
        sortField: params.sortField,
        sortOrder: params.sortOrder,
      },
      page: params.page,
    });
  };

  const handleMapModal = useCallback(() => setOpenModal('mapModal'), []);

  const handleCloseDeviceModal = useCallback(() => setDeviceModal(null), []);

  return (
    <div className={b()}>
      <div className={b('row')}>
        <div className={b('col')}>
          <div className={b('title')}>{lang.TITLE}</div>
          <ClientDevicesListFilterBar
            totalCount={data?.meta?.total_count}
            onOpenFilterModal={handleFilterModal}
            onOpenMapModal={handleMapModal}
          />
        </div>
        <MediasSummaryDashboard clientId={clientId} />
      </div>
      <ClientDevicesListTable
        clientId={clientId}
        fetchData={fetch}
        data={data?.device_affectations || []}
        sortParams={queryParams.sort}
        onParamsChange={handleChangeSort}
        onOpenDeviceModal={(affectation, tab) => setDeviceModal({affectation, tab})}
        loading={loading}
      />
      <div className={b('row')}>
        <PaginationBar
          data={data?.device_affectations}
          onPageChange={handleCurrentPageChange}
          currentPage={data?.meta?.current_page}
          totalPages={data?.meta?.total_pages}
          totalItems={data?.meta?.total_count}
          perPage={queryParams.perPage}
        />
        <PerPage currentValue={queryParams.perPage.toString()} onChange={handlePageParamsChange} />
      </div>

      <ClientDevicesListFilterModal onClose={closeModal} show={openModal === 'filterModal'} />

      {openModal === 'mapModal' && (
        <ClientDevicesListMapModal points={data?.device_affectations || []} onClose={closeModal} />
      )}

      {deviceModal?.affectation && (
        <ClientDeviceModal
          affectationId={deviceModal.affectation.id}
          onClose={handleCloseDeviceModal}
          defaultTab={deviceModal.tab}
          fetchData={fetch}
        />
      )}
    </div>
  );
};

export default ClientDevicesList;
