import React, {useEffect} from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';

import bem from 'client/services/bem';
import {normalizeStringToUpperCase} from 'client/services/formatters';
import {transformDate} from 'client/services/helpers';
import {useLanguage, useReduxFetch, useReduxForm} from 'client/services/hooks';
import {reduxForm} from 'client/services/hooks/use-redux-form';
import {getTimezoneParams, timezoneOptions} from 'client/services/utils/date';

import {getAvailableForAttributionUsers} from 'client/ducks/client-users/actions';
import {selectAvailableForAttributionUsers} from 'client/ducks/client-users/selectors';
import {updateOperation} from 'client/ducks/operations/actions';

import {SelectField} from 'client/common/fields';

import fieldTemplate from 'client/components/common/field';
import DataRetentionTooltip from 'client/components/common/tooltips/data-retention-tooltip';

import DataRetentionField from './data-retention-field';
import initial from './initial';
import {validate} from './validate';

import cssModule from './general-information-form.module.scss';

const b = bem('general-information-form', {cssModule});

const mapData = (data) => {
  return {
    operation: {
      name: data.name,
      code: data.code,
      from: transformDate(data.from, false),
      to: transformDate(data.to, false),
      status: data.status.value,
      client_user_id: data.client_user_id.value,
      common_retention_years: +data.common_retention_years,
      common_retention_months: +data.common_retention_months,
      leads_retention_years: +data.leads_retention_years,
      leads_retention_months: +data.leads_retention_months,
      timezone: data.timezone,
    },
  };
};
const GeneralInformationForm = (props) => {
  const {clientId, operation, edit, onCancel, onSave, handleSubmit} = props;

  const lang = useLanguage('OPERATIONS');
  const langOperationPage = useLanguage('OPERATION_PAGE');

  const {data: users} = useReduxFetch({
    action: getAvailableForAttributionUsers,
    selector: selectAvailableForAttributionUsers,
    actionArgs: clientId,
  });
  const {fetch: update} = useReduxFetch({
    action: updateOperation,
    fetchOnMount: false,
  });

  const {initialize, reset, formValues, pristine, setErrors} = useReduxForm('generalInformationForm', {
    validate: (values) => validate(values, {lang, langOperationPage, initialValues: operation}),
  });

  useEffect(() => {
    initialize(initial(operation));
  }, [operation, initialize]);

  useEffect(() => {
    if (!edit) {
      reset();
    }
  }, [edit, reset]);

  const handleCancelClick = () => {
    reset();
    onCancel();
  };

  const save = (data) => {
    return update(operation.id, mapData(data))
      .then((response) => {
        initialize(initial({...operation, ...response.payload.operation}));
        onSave();
      })
      .catch(({errors}) => {
        if (errors) {
          const submitErrors = {};
          if (errors.name) {
            submitErrors.name = lang.NAME_IS_TAKEN_ERROR;
          }
          if (errors.code) {
            submitErrors.code = lang.CODE_IS_TAKEN_ERROR;
          }
          setErrors(submitErrors);
        }
      });
  };

  const endDate = formValues.to && new Date(transformDate(formValues.to, false));
  const startDate = formValues.from && new Date(transformDate(formValues.from, false));

  if (endDate) {
    endDate.setDate(endDate.getDate() - 1);
  }
  if (startDate) {
    startDate.setDate(startDate.getDate() + 1);
  }

  return (
    <div>
      <form
        className="general-information-form general-information-form--expand"
        // noValidate='noValidate'
        onSubmit={handleSubmit(save)}
      >
        <div className={`general-information-form__inner ${edit ? 'general-information-form__inner_expand' : ''}`}>
          <div className="form-field-wrap">
            <Field
              cssModifier="input--disabled-view-1 input--view-4"
              disabled={!edit}
              label={langOperationPage.GENERAL_INFORMATION.NAME_LABEL}
              name="name"
              type="text"
              component={fieldTemplate}
            />
            <Field
              cssModifier={`select--disabled-view-${edit ? 1 : 2} select--view-5`}
              disabled={!edit}
              label={langOperationPage.GENERAL_INFORMATION.STATUS_LABEL}
              name="status"
              type="select"
              searchable={false}
              options={[
                {value: 'active', label: lang.OPERATION_STATUS.active},
                {value: 'finished', label: lang.OPERATION_STATUS.finished},
              ]}
              component={fieldTemplate}
            />
            <Field
              cssModifier={cn('datepicker--disabled-view-1 datepicker--view-4', {
                disabled: !edit,
              })}
              disabled={!edit}
              label={langOperationPage.GENERAL_INFORMATION.START_DATE_LABEL}
              placeholder={langOperationPage.GENERAL_INFORMATION.DATE_PLACEHOLDER}
              name="from"
              disabledDayAfter={endDate}
              type="datepicker"
              component={fieldTemplate}
            />
          </div>
          <div className="form-field-wrap">
            <Field
              cssModifier="input--disabled-view-1 input--view-4"
              disabled={!edit}
              label={langOperationPage.GENERAL_INFORMATION.CODE_LABEL}
              name="code"
              type="text"
              normalize={normalizeStringToUpperCase}
              onChange={(e, newValue) => (/^[A-Z0-9]*$/.test(newValue) && newValue.length <= 10) || e.preventDefault()}
              component={fieldTemplate}
            />
            <Field
              cssModifier="input--disabled-view-1 input--view-4"
              disabled
              label={langOperationPage.GENERAL_INFORMATION.CLIENT_LABEL}
              name="client_name"
              type="text"
              component={fieldTemplate}
            />
            <Field
              cssModifier={cn('datepicker--disabled-view-1 datepicker--view-4', {
                disabled: !edit,
              })}
              disabled={!edit}
              label={langOperationPage.GENERAL_INFORMATION.END_DATE_LABEL}
              placeholder={langOperationPage.GENERAL_INFORMATION.DATE_PLACEHOLDER}
              name="to"
              disabledDayBefore={startDate}
              type="datepicker"
              component={fieldTemplate}
            />
          </div>
          <div className="form-field-wrap">
            <div className="input--view-4">
              <div className="select__label">{lang.PARTICIPATION_DATA_RETENTION}</div>
              <DataRetentionField
                name="common_retention_years"
                value={formValues.common_retention_years}
                unit="year"
                disabled={!edit}
              />
              <DataRetentionField
                name="common_retention_months"
                value={formValues.common_retention_months}
                unit="month"
                disabled={!edit}
              />
              {edit && <DataRetentionTooltip />}
            </div>
            <div className="input--view-4">
              <div className="select__label">{lang.LEADS_DATA_RETENTION}</div>
              <DataRetentionField
                value={formValues.leads_retention_years}
                name="leads_retention_years"
                unit="year"
                disabled={!edit}
              />
              <DataRetentionField
                value={formValues.leads_retention_months}
                name="leads_retention_months"
                unit="month"
                disabled={!edit}
              />
              {edit && <DataRetentionTooltip />}
            </div>
            <Field
              cssModifier={`select--disabled-view-${edit ? 1 : 2} select--view-5 select--no-min-height`}
              component={fieldTemplate}
              type="select"
              label={lang.ATTRIBUTED_TO_LABEL}
              name="client_user_id"
              disabled={!edit}
              options={users.map((i) => ({label: i.full_name, value: i.id}))}
            />
          </div>
          <div className="form-field-wrap">
            {edit ? (
              <SelectField
                options={timezoneOptions}
                label={lang.TIMEZONE}
                name="timezone"
                readOnly={!edit}
                simpleValue={true}
                searchable={true}
                className={b('timezone-field')}
              />
            ) : (
              <div>
                <span className={b('field-label')}>{lang.TIMEZONE} </span>
                <div className={b('field', ['readonly'])}>{getTimezoneParams(formValues.timezone)?.formattedName}</div>
              </div>
            )}
          </div>
        </div>
        {edit && (
          <div className="general-information-form__bottom">
            <button className="button button--bg-11 general-information-form__btn" onClick={handleCancelClick}>
              {langOperationPage.CANCEL_BUTTON}
            </button>
            <button
              className="button button--bg-operations general-information-form__btn"
              type="submit"
              disabled={pristine}
            >
              {langOperationPage.SAVE_BUTTON}
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

GeneralInformationForm.propTypes = {
  operation: PropTypes.object,
  formValues: PropTypes.object,
  edit: PropTypes.bool,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  clientId: PropTypes.number.isRequired,
};

const generalInformationForm = reduxForm({
  form: 'generalInformationForm',
})(GeneralInformationForm);

export default generalInformationForm;
