import {SEGMENTATION_TYPES} from 'client/components/prizes/constants';

import {SegmentationParamsValues} from './types';

export default (values: SegmentationParamsValues) => {
  return values.fullGlobal || values.fullNone
    ? {
        default_initial_stock: values.commonQuantity,
        geo_level: values.local ? SEGMENTATION_TYPES.PER_PLACE : values.deviceSegmentation,
        online_level: values.local ? SEGMENTATION_TYPES.NONE : values.onlineSegmentation,
        hybrid: values.hybrid,
        local: values.local,
        device_stock: values.local ? values.deviceQuantity : '',
      }
    : {
        online_stock: values.onlineQuantity,
        geo_level: values.local ? SEGMENTATION_TYPES.PER_PLACE : values.deviceSegmentation,
        online_level: values.local ? SEGMENTATION_TYPES.NONE : values.onlineSegmentation,
        device_stock: values.deviceQuantity,
        hybrid: values.hybrid,
        local: values.local,
      };
};
