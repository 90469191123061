import find from 'lodash/find';

import {parseDate} from 'client/services/utils/date';

import {findInteractionOfOperation} from 'client/models/devices/utils';

const initDevicePrizes = (prizes, devicePrizes) => {
  return prizes?.map((prize) => {
    const currentDevicePrize = find(devicePrizes, {prize_id: prize.id});

    return {
      prize_id: prize.id,
      imports: {
        tokens_imported: 0,
        tokens_used: 0,
      },
      ...currentDevicePrize,
      prize,
    };
  });
};

export default (device, prizes, operation) => {
  const currentInteraction = findInteractionOfOperation(device, operation);

  if (device && currentInteraction) {
    return {
      from:
        parseDate(currentInteraction?.from, {
          timezone: operation?.timezone,
        }) ||
        parseDate(operation?.from, {
          timezone: operation?.timezone,
        }) ||
        '',
      to:
        parseDate(currentInteraction?.to, {
          timezone: operation?.timezone,
        }) ||
        parseDate(operation?.to, {
          timezone: operation?.timezone,
        }) ||
        '',
      default_language: currentInteraction?.default_language || operation.default_language,
      device_prizes: initDevicePrizes(prizes, device.device_prizes),
    };
  }
  return {
    from: parseDate(operation?.from, {
      timezone: operation?.timezone,
      timezoneForce: true,
      time: 'startDay',
    }),
    to: parseDate(operation?.to, {
      timezone: operation?.timezone,
      timezoneForce: true,
      time: 'endDay',
    }),
  };
};
