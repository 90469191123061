import {selectPrizeLanguages} from 'client/ducks/operations/selectors';

import {PRIZE_TYPES, LOYALTY_COUPON_TYPES, COMMON_INPUT} from 'client/components/prizes/constants';
import getValidityInitialValues from 'client/components/prizes/fieldsets/prize-diy-validity-fieldset/initialValues';

const getInitialPrizeNames = (prize, languages) => {
  const prizeNames = languages.reduce(
    (result, lang) => ({
      ...result,
      [lang]: prize?.external_name_translations?.[lang] || '',
    }),
    {},
  );

  // If in existing prize there is no any name, copy external_name to first
  if (prize && Object.values(prizeNames).filter((i) => i).length === 0) {
    const firstLang = Object.keys(prizeNames)?.[0];

    if (firstLang) {
      prizeNames[firstLang] = prize.external_name;
    }
  }

  return prizeNames;
};

const getInitialLegal = (prize, languages) => {
  return languages.reduce(
    (result, lang) => ({
      ...result,
      [lang]: prize?.legal_translations?.[lang] || '',
    }),
    {},
  );
};

const getInitialPictos = (prize, languages, includeImageId) => {
  return languages.reduce((result, lang) => {
    if (includeImageId) {
      result[`${lang}_id`] = prize?.picto_translations?.[`${lang}_id`] || 0;
    }

    result[lang] = prize?.picto_translations?.[lang] || '';

    return result;
  }, {});
};

export default ({prize = {}}, state, {timezone}) => {
  const languages = selectPrizeLanguages(state);
  const defaultLanguage = languages[0];

  return {
    prize_names: getInitialPrizeNames(prize, languages),
    legal: getInitialLegal(prize, languages),
    prize_pictos: getInitialPictos(prize, languages, true),
    create_ticket: prize?.ticket_print || false,
    email_send: prize?.email_send || false,
    sms_send: prize?.sms_send || false,
    level: prize?.level ?? null,
    distribution: prize?.distribution ?? false,
    language: prize?.language_unique_mode ? COMMON_INPUT : defaultLanguage,
    defaultLanguage: defaultLanguage,
    loyalty_unit: prize?.loyalty_unit,
    loyalty_only: Boolean(prize?.loyalty_only),
    loyalty_amount: String(prize?.loyalty_amount || ''),
    type: [PRIZE_TYPES.COUPON, PRIZE_TYPES.LOYALTY].includes(prize?.type) ? prize?.type : PRIZE_TYPES.COMMON,
    internal_name: prize?.internal_name,
    loyalty_coupon_type: prize?.loyalty_coupon_type || LOYALTY_COUPON_TYPES.NONE,
    full_win_ratio: prize?.full_win_ratio,
    full_win: prize.full_win,
    ...getValidityInitialValues(prize, false, {timezone}),
  };
};
