import {Campaign} from 'client/models/campaign/types';

export const filterCampaigns = (campaigns: Campaign[]) => {
  const result: Campaign[] = [];

  campaigns.forEach((campaign) => {
    const interactions = campaign.interactions?.filter((i) => i.track_stores) || [];
    if (interactions.length) {
      result.push({
        ...campaign,
        interactions,
      });
    }
  });

  return result;
};
