import filter from 'lodash/filter';
import moment from 'moment';

import {findInteractionOfOperation} from 'client/models/devices/utils';

export default (formValues, {device, operation}) => {
  const errors = {};

  const currentInteraction = findInteractionOfOperation(device, operation);
  const linkedInteractions = filter(device?.interactions, (i) => i.id !== currentInteraction?.id).slice(0, 3);

  const someInRange = linkedInteractions.some(({from, to}) => {
    return (
      (moment(formValues?.from).isSameOrAfter(moment(from)) && moment(formValues?.from).isSameOrBefore(moment(to))) ||
      (moment(formValues?.to).isSameOrAfter(moment(from)) && moment(formValues?.to).isSameOrBefore(moment(to)))
    );
  });

  if (!formValues?.from || !formValues?.to) {
    errors.period = 'FILL_ALL';
  } else if (moment(formValues?.from).isSameOrAfter(moment(formValues?.to))) {
    errors.period = 'INVALID_DATE';
  } else if (someInRange) {
    errors.period = 'NOT_AVAILABLE_DATES';
  }

  return errors;
};
