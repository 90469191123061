import isEmpty from 'lodash/isEmpty';

export const mapFormValues = (values, clientId) => {
  const mappedValues = {...values};
  if (values.opt_in_text_translations) {
    Object.entries(values.opt_in_text_translations).forEach(([lang, text]) => {
      mappedValues[`opt_in_text_${lang}`] = text;
    });
  }

  if (values.opt_out_text_translations) {
    Object.entries(values.opt_out_text_translations).forEach(([lang, text]) => {
      mappedValues[`opt_out_text_${lang}`] = text;
    });
  }

  return {
    opt_in_column: {
      ...mappedValues,
      client_id: clientId,
    },
  };
};

export const getInitialLanguageOptions = (optIn) => {
  if (isEmpty(optIn?.opt_in_text_translations)) {
    return [{label: 'Fr', value: 'fr'}];
  }

  return Object.keys(optIn.opt_in_text_translations).map((lang) => ({label: lang, value: lang}));
};

export const initializeFormValues = (optIn) => {
  const languages = getInitialLanguageOptions(optIn);
  const optInValues = isEmpty(optIn) ? {level: 1} : optIn;

  return {
    ...optInValues,
    languages, // add to redux state for validation purposes
  };
};
