import React, {useState, useEffect, useCallback} from 'react';

import get from 'lodash/get';
import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';
import {formatOperationTimezone, parseDate} from 'client/services/utils/date';

import {selectOperation} from 'client/ducks/operations/selectors';

import {Tasklogo, TaskMenu, TaskName} from 'client/common/header/task-header/blocks';
import TaskInfo from 'client/common/header/task-header/blocks/task-info';
import LayoutPanel from 'client/common/panels/layout-panel';
import Popover from 'client/common/popovers/popover';

import {TaskHeaderProps} from './types';

import cssModule from './task-header.module.scss';

const b = bem('task-header', {cssModule});

const TaskHeader: React.FC<TaskHeaderProps> = (props) => {
  const {handleBackClick, tabs, task} = props;

  const lang = useLanguage('COMMON.TASK_HEADER');
  const [scrolled, setScrolled] = useState(false);
  const operation = useSelector(selectOperation);

  const operationName = operation?.name;
  const operationFrom = parseDate(operation?.from, {timezone: operation?.timezone, outputFormat: 'DATE_SHORT'});
  const operationTo = parseDate(operation?.to, {timezone: operation?.timezone, outputFormat: 'DATE_SHORT'});
  const imageUrl = get(task, 'image_url');

  const handleScroll = useCallback(() => {
    const offset = window.pageYOffset;

    if (offset > 1 && !scrolled) {
      setScrolled(true);
    } else if (offset === 0 && scrolled) {
      setScrolled(false);
    }
  }, [scrolled]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <header className={b({scrolled})}>
      <div className={b('header-content')}>
        <div className={b('task')}>
          <div className={b('task-main-info')}>
            <Tasklogo className={b('logo')} src={imageUrl} />
            <TaskName
              classNames={{
                taskName: b('task-name'),
              }}
              name={task.name}
              handleBackClick={handleBackClick}
            />
          </div>
          {tabs && <TaskMenu tabs={tabs} className={b('menu')} />}
        </div>
        <LayoutPanel className={b('operation')}>
          <TaskInfo label={lang.OPERATION} value={operationName} />
          <TaskInfo
            classNames={{
              value: b('datetime'),
            }}
            value={`${operationFrom} - ${operationTo}`}
          />
          <Popover
            overlay={<p className={b('popover-text')}>{formatOperationTimezone(operation?.timezone).fullLabel}</p>}
            position="top"
            shiftTop={-10}
            shiftLeft={-5}
            arrowConfig={{
              fill: '#4384AB',
              stroke: '#8996ac',
              tipRadius: 1,
              width: 35,
              height: 16,
            }}
          >
            <TaskInfo
              classNames={{
                value: b('datetime'),
              }}
              value={formatOperationTimezone(operation?.timezone).label}
            />
          </Popover>
        </LayoutPanel>
      </div>
    </header>
  );
};

export default TaskHeader;
