import unset from 'lodash/unset';
import toFormData from 'object-to-formdata';

import {callApi} from 'client/services/call-api';
import {getEmail, getToken} from 'client/services/cookie-data-source';

import {API_METHODS, API_PATH} from 'client/common/config';

import types from './types';

export const getGames = (queryParams = {}) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.GAMES}`,
      method: 'GET',
      noCache: true,
      types: [types.GET_GAMES_REQUEST, types.GET_GAMES_SUCCESS, types.GET_GAMES_ERROR],
      queryParams: {
        ...queryParams,
        q: {
          s: 'win_number',
          ...queryParams.q,
        },
      },
    });
};

// The special action for getting any game without saving it in store
export const fetchGame = ({id, queryParams = {}}) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.GAMES}/${id}`,
      method: 'GET',
      noCache: true,
      types: [types.FETCH_GAME_REQUEST, types.FETCH_GAME_SUCCESS, types.FETCH_GAME_ERROR],
      queryParams: {
        ...queryParams,
      },
    });
};

export const createGame = (body) => {
  return (dispatch) =>
    callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.GAMES}`,
        method: 'POST',
        noCache: true,
        types: [types.CREATE_GAME_REQUEST, types.CREATE_GAME_SUCCESS, types.CREATE_GAME_ERROR],
        body,
      },
      true,
    );
};

export const updateGame = (id, body, queryParams) => {
  return (dispatch) =>
    callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.GAMES}/${id}`,
        method: 'PATCH',
        noCache: true,
        types: [types.UPDATE_GAME_REQUEST, types.UPDATE_GAME_SUCCESS, types.UPDATE_GAME_ERROR],
        body,
        queryParams,
      },
      true,
    );
};

export const deleteGame = (id) => {
  return (dispatch) =>
    callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.GAMES}/${id}`,
        method: 'DELETE',
        noCache: true,
        types: [types.DELETE_GAME_REQUEST, types.DELETE_GAME_SUCCESS, types.DELETE_GAME_ERROR],
      },
      true,
    );
};

export const getPrizes = (queryParams = {}) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.PRIZES}`,
      method: 'GET',
      noCache: true,
      types: [types.GET_PRIZES_REQUEST, types.GET_PRIZES_SUCCESS, types.GET_PRIZES_ERROR],
      queryParams,
    });
};

export const createPrize = (body) => {
  return (dispatch) =>
    fetch(`${API_PATH}${API_METHODS.PRIZES}`, {
      method: 'POST',
      headers: {
        'X-User-Email': getEmail(),
        'X-User-Token': getToken(),
      },
      body: toFormData(body),
    }).then((response) => {
      dispatch(() => ({
        type: types.CREATE_PRIZES_SUCCESS,
        payload: response,
      }));
    });
};

export const updatePrize = (bodyRaw, idPrize) => {
  const body = {
    prize: {
      ...bodyRaw.prize,
    },
  };
  unset(body, 'prize.image');
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.PRIZES}/${idPrize}`,
      method: 'PATCH',
      noCache: true,
      types: [types.UPDATE_PRIZES_REQUEST, types.UPDATE_PRIZES_SUCCESS, types.UPDATE_PRIZES_ERROR],
      body,
    }).then((res) => {
      if (bodyRaw.prize.image) {
        return fetch(`${API_PATH}${API_METHODS.PRIZES}/${idPrize}`, {
          method: 'PATCH',
          headers: {
            'X-User-Email': getEmail(),
            'X-User-Token': getToken(),
          },
          body: toFormData({
            prize: {
              image: bodyRaw.prize.image,
            },
          }),
        })
          .then((response) => {
            dispatch(() => ({
              type: types.UPDATE_PRIZES_SUCCESS,
              payload: response,
            }));
          })
          .catch(() =>
            dispatch(() => ({
              type: types.UPDATE_PRIZES_ERROR,
            })),
          );
      }
      return res;
    });
};

export const updatePrizeOrdersInStore = (prizes = []) => ({
  type: types.UPDATE_PRIZE_ORDER,
  payload: prizes,
});

export const updatePrizeMapsOrdersInStore = (prizeMaps = []) => ({
  type: types.UPDATE_PRIZE_MAP_ORDER,
  payload: prizeMaps,
});

export const deletePrize = (id) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.PRIZES}/${id}`,
      method: 'DELETE',
      noCache: true,
      types: [types.DELETE_PRIZES_REQUEST, types.DELETE_PRIZES_SUCCESS, types.DELETE_PRIZES_ERROR],
    });
};

export const getLevels = (queryParams = {}) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.INTERFACE_LEVELS}`,
      method: 'GET',
      noCache: true,
      types: [types.GET_LEVELS_REQUEST, types.GET_LEVELS_SUCCESS, types.GET_LEVELS_ERROR],
      queryParams,
    });
};

export const getPrizeMaps = (queryParams = {}) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.PRIZE_MAPS}`,
      method: 'GET',
      noCache: true,
      types: [types.GET_PRIZE_MAPS_REQUEST, types.GET_PRIZE_MAPS_SUCCESS, types.GET_PRIZE_MAPS_ERROR],
      queryParams: {
        ...queryParams,
        q: {
          s: 'prize_order',
          ...queryParams.q,
        },
      },
    });
};

export const updatePrizeMap = (id, bodyRaw, queryParams) => {
  const body = {
    prize_map: {
      ...bodyRaw.prize_map,
    },
  };
  unset(body, 'prize_map.image');
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.PRIZE_MAPS}/${id}`,
      method: 'PATCH',
      noCache: true,
      types: [types.UPDATE_PRIZE_MAP_REQUEST, types.UPDATE_PRIZE_MAP_SUCCESS, types.UPDATE_PRIZE_MAP_ERROR],
      body,
      queryParams,
    }).then((res) => {
      if (bodyRaw.prize_map.image) {
        return fetch(`${API_PATH}${API_METHODS.PRIZE_MAPS}/${id}`, {
          method: 'PATCH',
          headers: {
            'X-User-Email': getEmail(),
            'X-User-Token': getToken(),
          },
          body: toFormData({
            prize_map: {
              image: bodyRaw.prize_map.image,
            },
          }),
        })
          .then((response) => {
            dispatch(() => ({
              type: types.UPDATE_PRIZE_MAP_SUCCESS,
              payload: response,
            }));
          })
          .catch(() =>
            dispatch(() => ({
              type: types.UPDATE_PRIZE_MAP_ERROR,
            })),
          );
      }
      return res;
    });
};

export const updatePrizeMaps = (body) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.PRIZE_MAPS}/bulk_action`,
      method: 'PATCH',
      noCache: true,
      types: [types.UPDATE_PRIZE_MAPS_REQUEST, types.UPDATE_PRIZE_MAPS_SUCCESS, types.UPDATE_PRIZE_MAPS_ERROR],
      body,
    });
};
