import find from 'lodash/find';

import {parseDate} from 'client/services/utils/date';

const mapDate = (value, timezone) => (value ? parseDate(value, {timezone, timezoneForce: true}) : null);

const mapLevels = (currentItems, initialItems = []) => {
  const result = [];

  const currentIds = currentItems ? currentItems.split(',') : [];
  const initialIds = initialItems.map(({interface_level_id}) => interface_level_id);

  initialIds.forEach((id) => {
    const initialLevel = find(initialItems, {interface_level_id: id});
    if (!currentIds.includes(id)) {
      result.push({
        id: initialLevel.id,
        interface_level_id: id,
        _destroy: true,
      });
    }
  });

  currentIds.forEach((id) => {
    if (!initialIds.includes(id)) {
      result.push({interface_level_id: id});
    }
  });

  return result;
};

export const mapGameFormValues = (values, {game, autotask}) => {
  const {timezone} = autotask?.operation || {};

  const data = {
    game: {
      name: values.name,
      game_type: values.game_type,
      validation: values.validation,
      default_from: mapDate(values.default_from, timezone),
      default_to: mapDate(values.default_to, timezone),
      default_draw_time: mapDate(values.default_draw_time, timezone),
      game_validation_levels: mapLevels(values.levels, game.game_validation_levels),
    },
  };

  if (game.game_draws?.length) {
    data.game.game_draws = [...game.game_draws];
    const initialDraw = game.game_draws[0];
    data.game.game_draws[0] = {
      default_draw_time: mapDate(data.game.default_draw_time, timezone),
      default_from: mapDate(data.game.default_from, timezone),
      default_to: mapDate(data.game.default_to, timezone),
      id: initialDraw.id,
      loss_email: initialDraw.loss_email,
      loss_sms: initialDraw.loss_sms,
      name: data.game.name,
    };
  }

  return data;
};

export const mapPrizeMapFormValues = (values, {autotask}) => {
  const {timezone} = autotask?.operation || {};

  return {
    prize_map: {
      from: mapDate(values.default_from, timezone),
      to: mapDate(values.default_to, timezone),
      draw_time: mapDate(values.default_draw_time, timezone),
    },
  };
};
