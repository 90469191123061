import mapCommonCouponValues from 'client/components/prizes/fieldsets/prize-diy-common-coupon-fieldset/mapFormValues';
import mapValidityValues from 'client/components/prizes/fieldsets/prize-diy-validity-fieldset/mapFormValues';
import {COUPON_TYPES} from 'client/models/prizes/constants';

export default (values, {timezone}) => {
  return {
    coupon: {
      id: values.id,
      internal_name: values.internal_name,
      internal_code: values.internal_code,
      external_name: values.external_name,
      type: values.type,
      comment: values.description,
      loyalty_amount: values.loyalty_amount,
      loyalty_unit: values.loyalty_unit,
      barcode_format: values.barcode_format,
      ...mapValidityValues(values, {timezone}),
      ...mapCommonCouponValues(values, values.type === COUPON_TYPES.COMMON),
    },
  };
};
