import {ACCESS_LEVEL} from 'client/models/common/constants';

/**
 * @deprecated use ACCESS_LEVEL from 'client/models/common/constants'
 */
export const ACCESS_LEVEL_TYPES = {
  LOCAL: ACCESS_LEVEL.LOCAL,
  STORES: ACCESS_LEVEL.STORE,
  REGIONS: ACCESS_LEVEL.REGION,
  NATIONAL: ACCESS_LEVEL.NATIONAL,
  CLIENT_ADMIN: ACCESS_LEVEL.CLIENT_ADMIN,
};
