import React, {useEffect} from 'react';

import {useDispatch} from 'react-redux';
import {useParams} from 'react-router';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage, useReduxFetch} from 'client/services/hooks';
import {reduxForm} from 'client/services/hooks/use-redux-form';
import {ReduxFormFC} from 'client/services/hooks/use-redux-form/types';

import {getUserPasswordExpiration, setUserPasswordExpiration} from 'client/ducks/user-password-expiration/actions';
import {selectUserPasswordExpirationConfig} from 'client/ducks/user-password-expiration/selectors';

import AppButton from 'client/common/buttons';
import {ToggleField} from 'client/common/fields';

import InfoBlock from 'client/components/common/info-block';
import Spinner from 'client/components/common/spinner';

import {ACCESS_LEVEL} from 'client/models/common/constants';
import {ApiDispatch} from 'client/types';

import {PasswordExpirationFormData, mapFormValues, initialFormValues} from './helpers';

import cssModule from './password-expiration-card.module.scss';

const b = bem('password-expiration-card', {cssModule});

type PasswordExpirationCardProps = {
  isAgency?: boolean;
};

const PasswordExpirationCard: ReduxFormFC<PasswordExpirationCardProps, PasswordExpirationFormData> = (props) => {
  const {pristine, handleSubmit, initialize, isAgency} = props;

  const lang = useLanguage('AGENCY.PASSWORD_EXPIRATION_CARD');
  const [isSubmitting, toggleIsSubmitting] = useToggle(false);
  const dispatch: ApiDispatch = useDispatch();
  const {id: clientId} = useParams<{id: string}>();

  const {data: passwordExpirationConfigs, loading} = useReduxFetch({
    action: getUserPasswordExpiration,
    actionArgs: clientId,
    selector: selectUserPasswordExpirationConfig,
  });

  useEffect(() => {
    if (passwordExpirationConfigs) {
      initialize(initialFormValues(passwordExpirationConfigs));
    }
  }, [passwordExpirationConfigs, initialize]);

  const onSubmit = (values: PasswordExpirationFormData) => {
    if (passwordExpirationConfigs) {
      toggleIsSubmitting(true);
      const body = mapFormValues(values, clientId);
      dispatch(setUserPasswordExpiration(body, passwordExpirationConfigs.id)).finally(() => toggleIsSubmitting());
    }
  };

  const isSubmitButtonDisabled = pristine || isSubmitting;

  return (
    <InfoBlock className="theme-color-14">
      <InfoBlock.Title>{lang.TITLE}</InfoBlock.Title>
      <InfoBlock.Content>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InfoBlock.Body className={b('body')}>
            {loading && <Spinner centered className={b('spinner')} />}
            <div className={b('row')}>
              <span>{lang.NATIONAL}</span>
              <ToggleField name={ACCESS_LEVEL.NATIONAL} />
            </div>
            {!isAgency && (
              <div className={b('row')}>
                <span>{lang.REGION}</span>
                <ToggleField name={ACCESS_LEVEL.REGION} />
              </div>
            )}
            {!isAgency && (
              <div className={b('row')}>
                <span>{lang.STORE}</span>
                <ToggleField name={ACCESS_LEVEL.STORE} />
              </div>
            )}
            {!isAgency && (
              <div className={b('row')}>
                <span>{lang.LOCAL}</span>
                <ToggleField name={ACCESS_LEVEL.LOCAL} />
              </div>
            )}
          </InfoBlock.Body>
          <AppButton
            label={lang.SAVE_BUTTON}
            loading={isSubmitting}
            disabled={isSubmitButtonDisabled}
            size="full"
            submit
          />
        </form>
      </InfoBlock.Content>
    </InfoBlock>
  );
};

export default reduxForm<PasswordExpirationCardProps, PasswordExpirationFormData>({
  form: 'PasswordExpirationForm',
})(PasswordExpirationCard);
