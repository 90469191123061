import cloneDeep from 'lodash/cloneDeep';

import {Prize} from 'client/models/prizes/types';

const WEIGHT_LOCAL_ORDER = 0.001; // if you want to change this value, you should inform backend team about that

/**
 * Setting prize orders while considering local prizes
 */
const setOrders = (prizes: Prize[], byArrayIndex?: boolean) => {
  let currentOrder = 0;
  let localOffset = 0;

  return prizes.map((prize, index) => {
    if (prize) {
      if (!prize.local) {
        if (!byArrayIndex) {
          currentOrder = Number(prize.order);
        } else if (index !== 0) {
          currentOrder++;
        }
        localOffset = 0;
        prize.order = currentOrder;
        return prize;
      }
      localOffset += WEIGHT_LOCAL_ORDER;

      prize.order = currentOrder + localOffset;
    }
    return prize;
  });
};

/**
 * Sorting prizes including form's prizes and all prizes from game
 */
const sortPrizesByInitial = (gamePrizes: Prize[], valuePrizes: Prize[]) => {
  let resultPrizes =
    cloneDeep(gamePrizes)
      ?.sort((a, b) => +a.order - +b.order)
      .map((prize) => {
        return {
          ...prize,
          order: +prize.order,
        };
      }) || [];

  const sortedValuePrizes = setOrders(valuePrizes).reduce<Record<number, number>>((acc, item) => {
    const prize = cloneDeep(item);
    acc[prize?.id] = prize?.order;

    return acc;
  }, {});

  resultPrizes = resultPrizes.map((prize) => {
    if (sortedValuePrizes[prize.id]) {
      prize.order = sortedValuePrizes[prize.id];
    }
    return prize;
  });
  resultPrizes?.sort((a, b) => {
    if (a.full_win === b.full_win) {
      return a.order - b.order;
    }
    return a.full_win ? 1 : -1;
  });

  if (resultPrizes[0].order < 0) {
    resultPrizes = resultPrizes.map((prize) => {
      prize.order++;
      return prize;
    });
  }

  return resultPrizes;
};

export const preparePrizeLocalOrders = (gamePrizes: Prize[], valuePrizes: Prize[]) => {
  return setOrders(sortPrizesByInitial(gamePrizes, valuePrizes), true);
};
