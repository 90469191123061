import React, {useRef} from 'react';

import cn from 'classnames';
import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';
import {parseDate} from 'client/services/utils/date';

import {selectOperation} from 'client/ducks/operations/selectors';

import {FieldWrap} from 'client/common/fields';
import Icon from 'client/common/icon';
import {ErrorMessage} from 'client/common/inputs';
import DatetimePicker from 'client/common/inputs/datetime-picker';
import {SelectLanguage} from 'client/common/selects/select-language';

import {Translation} from 'client/models/language/types';
import {AllowedLanguages} from 'client/models/users/types';

import cssModule from './store-device-fieldset.module.scss';

const b = bem('store-device-fieldset', {cssModule});

type StoreDevicePeriodLangFieldsetProps = {
  value: StoreDevicePeriodLangFieldsetType;
  onChange: (value: StoreDevicePeriodLangFieldsetType) => void;
  disabled?: boolean;
  hideStarted: boolean;
  className?: string;
  errorMessage?: Translation;
};

export type StoreDevicePeriodLangFieldsetType = {
  from: string;
  to: string;
  default_language: AllowedLanguages;
};

const StoreDevicePeriodLangFieldset: React.FC<StoreDevicePeriodLangFieldsetProps> = (props) => {
  const {value, onChange, disabled, hideStarted, className, errorMessage} = props;

  const ref = useRef<HTMLDivElement>(null);

  const operation = useSelector(selectOperation);

  const lang = useLanguage('CLIENT_STORES.FIELDSETS.PERIOD_LANG_FIELDSET');

  return (
    <FieldWrap className={cn(b(), className)} ref={ref}>
      <div className={b('row')}>
        <div className={b('period')}>
          {!hideStarted && (
            <>
              <DatetimePicker
                timePattern="HH:mm:ss"
                label={lang.FROM}
                value={parseDate(value.from, {timezone: operation.timezone}) || ''}
                onChange={(from) => onChange({...value, from})}
                className={b('field', ['date'])}
                classNames={{
                  requiredLabel: b('field-required-label'),
                }}
                required={true}
                disabled={disabled}
                timezone={operation.timezone}
              />
              <Icon name="arrow" className={b('period-arrow')} />
            </>
          )}
          <DatetimePicker
            timePattern="HH:mm:ss"
            value={parseDate(value.to, {timezone: operation.timezone}) || ''}
            onChange={(to) => onChange({...value, to})}
            label={lang.TO}
            className={b('field', ['date'])}
            classNames={{
              requiredLabel: b('field-required-label'),
            }}
            required={true}
            disabled={disabled}
            datepickerProps={{
              classNames: {},
            }}
            timezone={operation.timezone}
          />
        </div>
        <SelectLanguage
          languages={operation.languages}
          simpleValue
          value={value.default_language}
          onChange={(default_language: AllowedLanguages) => onChange({...value, default_language})}
          name="default_language"
          label={lang.DEFAULT_LANGUAGE}
          className={b('field', ['language'])}
          disabled={disabled}
          inputHeight="small"
        />
      </div>
      {errorMessage && <ErrorMessage errorMessage={errorMessage} className={b('error-message')} />}
    </FieldWrap>
  );
};

export default StoreDevicePeriodLangFieldset;
