import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';
import {useAppMedia} from 'client/services/hooks';

import Icon from 'client/common/icon';

import {TaskLogoProps} from './types';

import cssModule from './task-logo.module.scss';

const b = bem('task-logo', {cssModule});

const Tasklogo: React.FC<TaskLogoProps> = (props) => {
  const {src, className, classNames} = props;
  const {isTablet} = useAppMedia();

  const taskImageSize = {
    width: isTablet ? 36 : 90,
    height: isTablet ? 36 : 90,
  };

  return (
    <figure className={cn(b(), className)}>
      {src ? (
        <img className={cn(b('image'), classNames?.image)} src={src} alt="" {...taskImageSize} />
      ) : (
        <Icon className={cn(b('image'), classNames?.placeholder)} name="task-image-placeholder" {...taskImageSize} />
      )}
    </figure>
  );
};
export default Tasklogo;
