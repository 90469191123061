import React, {useMemo, useCallback, useState} from 'react';

import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import bem from 'client/services/bem';
import {useLanguage, useQueryParams} from 'client/services/hooks';

import {selectCurrentClient} from 'client/ducks/clients-list/selectors';
import {selectClientAccessLevel, selectCurrentMembership} from 'client/ducks/user-clients/selectors';
import {selectViewMode} from 'client/ducks/user/selectors';

import Icon from 'client/common/icon';
import SelectDropdown from 'client/common/selects/select-dropdown';

import ClientTable from 'client/components/common/client-table';
import {ClientTableColumn} from 'client/components/common/client-table/types';

import ClientDeviceContentCell from 'client/components/devices/components/client-device-content-cell';
import ClientDevicePeriod from 'client/components/devices/components/client-device-period';
import {MAP_ICONS_DEVICE_ACTIVITY} from 'client/components/devices/constants';
import {ClientDeviceModalTab} from 'client/components/devices/modals/client-device-modal';
import {getMediaLevel} from 'client/components/medias/helpers';
import MediasConfigModal from 'client/components/medias/modals/medias-config-modal';
import {DeviceAffectation} from 'client/models/device-affectations';
import {Interaction} from 'client/models/interactions/types';
import {generateClientOperationRoute, operationHasConfigTab} from 'client/models/operations/helpers';

import ClientDeviceListTableCellWrapper from './client-device-list-table-cell-wrapper';
import ClientDevicesActivityCell from './client-devices-activity-cell';
import ClientDevicesLogisticCell from './client-devices-logistic-cell';
import {filterComments, isMedia} from './helpers';

import cssModule from './client-devices-list-table.module.scss';

type ClientDevicesListTableProps = {
  clientId: string;
  loading: boolean;
  data: DeviceAffectation[];
  sortParams: {sortField: string; sortOrder: string};
  onParamsChange: (params: {sortField: string; sortOrder: string; page: number}) => void;
  fetchData: () => Promise<any>;
  onOpenDeviceModal?: (affectation: DeviceAffectation, tab: ClientDeviceModalTab) => void;
};

const b = bem('client-devices-list-table', {cssModule});

export const ClientDevicesListTable: React.FC<ClientDevicesListTableProps> = (props) => {
  const {clientId, loading, data, sortParams, onParamsChange, fetchData, onOpenDeviceModal} = props;
  const lang = useLanguage('CLIENT_DEVICES');
  const history = useHistory();
  const [queryParams, setQueryParams] = useQueryParams(null, {comments: 'all'});

  const {id: viewUserId} = useSelector(selectViewMode);
  const client = useSelector(selectCurrentClient);
  const accessLevel = useSelector(selectClientAccessLevel);
  const clientUserMembership = useSelector(selectCurrentMembership);

  const hasConfigTab = operationHasConfigTab({
    client,
    accessLevel,
    membershipIsEditor: clientUserMembership?.editor,
    viewUserId,
  });

  const goToAutotask = useCallback(
    (interaction: Interaction) => {
      if (interaction.operation && interaction.automation_task) {
        const link = generateClientOperationRoute({
          operation: interaction.operation,
          task: interaction.automation_task,
          isConfigurationBlock: hasConfigTab,
          clientId: +clientId,
        });
        if (link) {
          history.push(link);
        }
      }
    },
    [clientId, hasConfigTab, history],
  );

  const [configAffectation, setConfigAffectation] = useState<DeviceAffectation | null>(null);
  const level = useMemo(getMediaLevel, []);

  const columns: ClientTableColumn<DeviceAffectation>[] = useMemo(() => {
    return [
      {
        name: 'status',
        path: 'status',
        label: lang.TABLE.DEVICE,
        width: 80,
        cellClassName: b('cell', ['highlight', 'start', 'status', 'general']),
        render: ({item}) => (
          <ClientDeviceListTableCellWrapper
            affectation={item}
            className={b('cell-content')}
            deviceTab="general"
            onOpenDeviceModal={onOpenDeviceModal}
          >
            {item.device?.status_activity && <Icon name={MAP_ICONS_DEVICE_ACTIVITY[item.device?.status_activity]} />}
          </ClientDeviceListTableCellWrapper>
        ),
      },
      {
        name: 'device_code',
        path: 'device.code',
        label: lang.TABLE.CODE,
        sortable: true,
        width: 110,
        cellClassName: b('cell', ['highlight', 'general']),
        initialSortOrder: 'asc',
        render: ({value, item}) => (
          <ClientDeviceListTableCellWrapper
            affectation={item}
            className={b('cell-content')}
            deviceTab="general"
            onOpenDeviceModal={onOpenDeviceModal}
          >
            {value}
          </ClientDeviceListTableCellWrapper>
        ),
      },
      {
        name: 'place_region_name',
        path: 'place.region_name',
        label: lang.TABLE.REGION,
        sortable: true,
        cellClassName: b('cell', ['highlight', 'general']),
        width: 100,
        render: ({value, item}) => (
          <ClientDeviceListTableCellWrapper
            affectation={item}
            className={b('cell-content')}
            deviceTab="general"
            onOpenDeviceModal={onOpenDeviceModal}
          >
            {value}
          </ClientDeviceListTableCellWrapper>
        ),
      },
      {
        name: 'place_city_name',
        path: 'place.city_name',
        label: lang.TABLE.CITY,
        sortable: true,
        cellClassName: b('cell', ['highlight', 'general']),
        width: 115,
        render: ({value, item}) => (
          <ClientDeviceListTableCellWrapper
            affectation={item}
            className={b('cell-content')}
            deviceTab="general"
            onOpenDeviceModal={onOpenDeviceModal}
          >
            {value}
          </ClientDeviceListTableCellWrapper>
        ),
      },
      {
        name: 'place_name',
        path: 'place.name',
        label: lang.TABLE.STORE,
        sortable: true,
        cellClassName: b('cell', ['highlight', 'end', 'general']),
        width: 115,
        render: ({value, item}) => (
          <ClientDeviceListTableCellWrapper
            affectation={item}
            className={b('cell-content')}
            deviceTab="general"
            onOpenDeviceModal={onOpenDeviceModal}
          >
            {value}
          </ClientDeviceListTableCellWrapper>
        ),
      },
      {
        name: 'logistics',
        path: '',
        label: lang.TABLE.LOGISTICS,
        cellClassName: b('cell', ['highlight', 'start', 'end', 'logistics']),
        width: 120,
        render: ({item}) => (
          <ClientDeviceListTableCellWrapper
            affectation={item}
            className={b('cell-content')}
            deviceTab="logistics"
            onOpenDeviceModal={onOpenDeviceModal}
          >
            <ClientDevicesLogisticCell affectation={item} />
          </ClientDeviceListTableCellWrapper>
        ),
      },
      {
        name: 'device_media_status',
        path: 'device_media_status',
        label: lang.TABLE.ACTIVITY,
        width: 100,
        cellClassName: b('cell', ['highlight', 'start', 'general']),
        render: ({item}) => (
          <ClientDeviceListTableCellWrapper
            affectation={item}
            className={b('cell-content')}
            deviceTab="general"
            onOpenDeviceModal={onOpenDeviceModal}
          >
            <ClientDevicesActivityCell affectation={item} />
          </ClientDeviceListTableCellWrapper>
        ),
      },
      {
        name: 'comments',
        path: '',
        label: ' ',
        width: 150,
        headerClassName: b('header-select-container'),
        renderHeader: () => (
          <SelectDropdown
            simpleValue
            menuPosition="fixed"
            options={[
              {
                value: 'all',
                label: lang.TABLE.COMMENTS_ALL,
              },
              {
                value: 'activity',
                label: lang.TABLE.COMMENTS_ACTIVITY,
              },
              {
                value: 'logistic',
                label: lang.TABLE.COMMENTS_LOGISTICS,
              },
            ]}
            className={b('header-select')}
            value={queryParams.comments}
            classNames={{
              singleValue: () => b('header-select-value'),
            }}
            onChange={(value: string) => setQueryParams({comments: value}, true)}
          />
        ),
        cellClassName: b('cell', ['highlight', 'end', 'comments', 'general']),
        render: ({item}) => (
          <ClientDeviceListTableCellWrapper
            affectation={item}
            className={b('cell-content')}
            deviceTab="general"
            onOpenDeviceModal={onOpenDeviceModal}
          >
            {filterComments(item.device_affectation_infos || [], queryParams.comments)}
          </ClientDeviceListTableCellWrapper>
        ),
      },
      {
        name: 'content',
        path: '',
        label: lang.TABLE.CONTENT,
        width: 180,
        cellClassName: (item) =>
          b('cell', {
            highlight: true,
            start: true,
            end: true,
            grey: true,
            media: isMedia(item),
            operation: item.device?.current_interaction,
          }),
        render: ({item}) => {
          const currentInteraction = item.device?.interactions?.find((i) => i.current);
          return (
            <ClientDeviceListTableCellWrapper
              affectation={item}
              className={b('cell-content')}
              deviceTab={isMedia(item) ? 'media' : 'general'}
              onOpenDeviceModal={isMedia(item) ? onOpenDeviceModal : () => {}}
            >
              <ClientDeviceContentCell
                onClick={() => !isMedia(item) && currentInteraction && goToAutotask(currentInteraction)}
                isMedia={item.device?.mediaplayer_active}
                interaction={currentInteraction}
              />
            </ClientDeviceListTableCellWrapper>
          );
        },
      },
      {
        name: 'period',
        path: 'from',
        label: lang.TABLE.PERIOD,
        width: 70,
        cellClassName: b('cell', ['grey', 'period']),
        render: ({item}) => (
          <ClientDeviceListTableCellWrapper
            affectation={item}
            className={b('cell-content')}
            onOpenDeviceModal={onOpenDeviceModal}
          >
            <ClientDevicePeriod {...item} />
          </ClientDeviceListTableCellWrapper>
        ),
      },
      {
        name: 'media_player',
        path: '',
        label: lang.TABLE.MEDIA_PLAYER,
        width: 66,
        cellClassName: b('cell', ['highlight', 'start', 'end', 'media']),
        render: ({item}) => (
          <ClientDeviceListTableCellWrapper
            affectation={item}
            className={b('cell-content')}
            deviceTab="media"
            tooltip={lang.TABLE.MEDIA_PLAYER}
            onOpenDeviceModal={onOpenDeviceModal}
          >
            <Icon name="media_player-2" className={b('icon-media')} />
          </ClientDeviceListTableCellWrapper>
        ),
      },
    ];
  }, [lang, onOpenDeviceModal, queryParams.comments, setQueryParams, goToAutotask]);

  const onCloseConfigModal = useCallback(() => {
    setConfigAffectation(null);
    fetchData();
  }, [fetchData]);

  return (
    <React.Fragment>
      {!!configAffectation && (
        <MediasConfigModal clientId={Number(clientId)} onClose={onCloseConfigModal} level={level} />
      )}
      <ClientTable
        data={data}
        columns={columns}
        sortField={sortParams.sortField}
        sortOrder={sortParams.sortOrder}
        onSortChange={onParamsChange}
        loading={loading}
        classNames={{
          row: b('row'),
          cell: b('cell'),
          header: {
            th: b('th'),
          },
        }}
      />
    </React.Fragment>
  );
};

export default ClientDevicesListTable;
