import reject from 'lodash/reject';

import types from './types';

const initialState = {
  dashboards: null,
  dashboardBlocks: null,
  taskKpis: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_INSTORE_DASHBOARDS_SUCCESS:
      return {
        ...state,
        dashboards: action?.payload?.instore_dashboards || initialState.dashboards,
      };
    case types.GET_INSTORE_DASHBOARD_BLOCKS_SUCCESS:
      return {
        ...state,
        dashboardBlocks: action?.payload?.instore_dashboard_blocks || initialState.dashboardBlocks,
      };
    case types.DELETE_INSTORE_DASHBOARD_BLOCK_FROM_STORE:
      return {
        ...state,
        dashboardBlocks: reject(state.dashboardBlocks, {id: action.payload}),
      };

    case types.CLEAR_INSTORE_DASHBOARDS:
      return {
        ...state,
        dashboards: initialState.dashboards,
      };

    case types.GET_INSTORE_DASHBOARD_KPIS_SUCCESS:
      return {
        ...state,
        taskKpis: action?.payload?.instore_task_kpis,
      };

    default:
      return state;
  }
}
