
import {PRIZE_COUPON_VALIDITY_TYPES} from 'client/components/prizes/constants';

import {ValidityValues} from './types';
import { parseDate } from 'client/services/utils/date';

export default (values: ValidityValues, {timezone}: {timezone: string}) => {
  const result: Record<string, any> = {};

  const validityType = values.validity_type;

  switch (validityType) {
    case PRIZE_COUPON_VALIDITY_TYPES.FIXED_VALUE:
      result.validity_duration = false;
      result.validity_days = null;
      result.validity_from = parseDate(values.valid_from, {timezone});
      result.validity_to = parseDate(values.valid_to, {timezone});
      break;
    case PRIZE_COUPON_VALIDITY_TYPES.DURATION:
      result.validity_duration = true;
      result.validity_days = values.validity_days;
      result.validity_from = null;
      result.validity_to = null;
      break;
    case PRIZE_COUPON_VALIDITY_TYPES.NONE:
      result.validity_duration = false;
      result.validity_days = null;
      result.validity_from = null;
      result.validity_to = null;
      break;
    default:
      break;
  }

  return result;
};
