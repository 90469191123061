import React, {PureComponent} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {getCoupons} from 'client/ducks/coupons/actions';
import {selectCoupons} from 'client/ducks/coupons/selectors';

import AppButton from 'client/common/buttons';
import CountBlock from 'client/common/count-block';

import InfoBlock from 'client/components/common/info-block';

import CouponEditModal from './coupon-edit-modal';
import CouponInfoModal from './coupon-info-modal';
import CouponsGrid from './coupons-grid';

import './coupons-card.scss';

class CouponsCard extends PureComponent {
  static propTypes = {
    lang: PropTypes.object.isRequired,
    operation: PropTypes.object.isRequired,
    getCoupons: PropTypes.func.isRequired,
    coupons: PropTypes.array.isRequired,
  };

  state = {
    showCouponEditModal: false,
    showCouponInfoModal: false,
    editingCoupon: null,
    viewingCoupon: null,
  };

  componentDidMount() {
    this.fetchCoupons();
  }

  componentDidUpdate(prevProps) {
    if (this.props.operation?.id !== prevProps.operation?.id) {
      this.fetchCoupons();
    }
  }

  fetchCoupons = () => {
    const params = {
      q: {
        operation_id_eq: this.props.operation?.id,
      },
      include: ['coupon_prefixes', 'sogec_configuration', 'prizes'],
    };

    return this.props.getCoupons(params);
  };

  toggleCouponEditModal = (editingCoupon = null) => {
    this.setState(({showCouponEditModal}) => ({
      showCouponEditModal: !showCouponEditModal,
      editingCoupon,
    }));
  };

  toggleCouponInfoModal = (viewingCoupon = null) => {
    this.setState(({showCouponInfoModal}) => ({
      showCouponInfoModal: !showCouponInfoModal,
      viewingCoupon,
    }));
  };

  renderCouponEditModal = () => {
    const {operation} = this.props;
    const {showCouponEditModal, editingCoupon} = this.state;

    if (showCouponEditModal) {
      return (
        <CouponEditModal
          operation={operation}
          editingCoupon={editingCoupon}
          onClose={this.toggleCouponEditModal}
          fetchData={this.fetchCoupons}
        />
      );
    }

    return null;
  };

  renderCouponInfoModal = () => {
    const {showCouponInfoModal, viewingCoupon} = this.state;

    if (showCouponInfoModal) {
      return (
        <CouponInfoModal
          viewingCoupon={viewingCoupon}
          onClose={this.toggleCouponInfoModal}
          timezone={this.props.operation.timezone}
        />
      );
    }

    return null;
  };

  render() {
    const {
      lang,
      coupons,
      operation: {timezone},
    } = this.props;

    return (
      <InfoBlock className="coupons-card theme-color-14">
        <InfoBlock.Title>{lang.COUPONS}</InfoBlock.Title>
        <InfoBlock.Content>
          <InfoBlock.Header className="clearfix">
            <CountBlock
              totalText={lang.TOTAL_PART_1}
              itemText={lang.TOTAL_PART_2}
              itemName={lang.TOTAL_PART_3}
              className="pull-left"
              num={coupons.length}
            />
            <InfoBlock.HeaderLink
              link={{
                url: location.pathname + '/tokens',
                text: lang.GO_TO_TOKENS,
              }}
              description={lang.OF_THIS_OPERATION}
            />
          </InfoBlock.Header>
          <InfoBlock.Body>
            {this.renderCouponEditModal()}
            {this.renderCouponInfoModal()}
            <CouponsGrid
              data={coupons}
              timezone={timezone}
              onNameClick={this.toggleCouponInfoModal}
              onEditClick={this.toggleCouponEditModal}
            />
            {!coupons.length && <p className="coupons-card__empty">{lang.NO_COUPONS}</p>}
            <AppButton
              iconName="plus"
              label={lang.ADD_COUPON}
              color="coupons"
              className="coupons-card__add-button"
              onClick={() => this.toggleCouponEditModal()}
            />
          </InfoBlock.Body>
        </InfoBlock.Content>
      </InfoBlock>
    );
  }
}

export default connect(
  (state) => ({
    lang: state.languageState.payload.OPERATION_PAGE.COUPONS_CARD,
    coupons: selectCoupons(state),
  }),
  {
    getCoupons,
  },
)(CouponsCard);
