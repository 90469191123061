import React from 'react';

import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {selectedClientGames} from 'client/ducks/games/selectors';

import AppButton from 'client/common/buttons/app-button';
import Popover from 'client/common/popovers/popover';
import {DateTime} from 'client/common/typography/date-time';

import ClientTable from 'client/components/common/client-table';
import Icon from 'client/components/common/icon';

import {PlaceWithCalculations} from 'client/components/client-stores/lists/client-stores-list/types';
import {StoreListModalState} from 'client/components/client-stores/types';
import PrizeMapSelectingButton from 'client/components/prizes/controls/prize-map-selecting-button';
import {Operation} from 'client/models/operations/types';

import {getCheckable} from '../helpers';

import cssModule from './client-stores-table.module.scss';

const b = bem('client-stores-table', {cssModule});

type ClientStoresTableProps = {
  data: PlaceWithCalculations[];
  checkedStores: PlaceWithCalculations[];
  loading: boolean;
  operation: Operation;
  onOpenModal: (state: StoreListModalState) => void;
  onCheck: (checked: boolean, store: PlaceWithCalculations) => void;
  onCheckAll: (checked: boolean) => void;
};

const ClientStoresTable: React.FC<ClientStoresTableProps> = (props) => {
  const {data, loading, operation, onOpenModal, onCheck, onCheckAll, checkedStores} = props;
  const lang = useLanguage('CLIENT_STORES.LISTS.CLIENT_STORES_LIST.CLIENT_STORES_TABLE');
  const {instantWinGame, lotteryGame} = useSelector(selectedClientGames);

  const renderDisabledPopover = (value: any) => {
    return (
      <Popover
        className={b('popover')}
        position="top-end"
        overlay={<p className={b('disabled-message')}>{lang.DISABLE_MESSAGE}</p>}
      >
        <div className={b('disabled-value', {center: Number.isInteger(value)})}>{value}</div>
      </Popover>
    );
  };

  const renderCheckbox = (place: PlaceWithCalculations) => {
    return place.calculations.showDisabled ? renderDisabledPopover(<Icon name="calendar-edit" />) : null;
  };

  const renderDisabledCell = ({value, item}: {value: any; item: PlaceWithCalculations}) => {
    return item.calculations.showDisabled ? renderDisabledPopover(value) : value;
  };

  return (
    <ClientTable
      className={b()}
      loading={loading}
      data={data}
      checkable={getCheckable(operation)}
      onCheckAll={onCheckAll}
      checkedRows={checkedStores}
      onCheck={onCheck}
      renderCheckbox={renderCheckbox}
      columns={[
        {
          name: 'name',
          path: 'name',
          label: lang.STORE_NAME,
          render: renderDisabledCell,
        },
        {
          name: 'region_name',
          path: 'region_name',
          label: lang.REGION,
          render: renderDisabledCell,
        },
        {
          name: 'city_name',
          path: 'city_name',
          label: lang.CITY,
          render: renderDisabledCell,
        },
        {
          name: 'device_affectations_count',
          path: 'device_affectations_count',
          label: lang.NUMBER_OF_DEVICES,
          width: 95,
          align: 'center',
          render: renderDisabledCell,
        },
        {
          name: 'operation_name',
          path: 'calculations.operation',
          label: lang.OPERATION_NAME,
          cellClassName: b('highlight'),
          render: ({value}) => {
            if (!value) {
              return null;
            }
            const src = value.operation_image?.url;
            return (
              <div className={b('op-wrap')}>
                {src ? (
                  <img src={src} alt="" className={b('op-image')} />
                ) : (
                  <Icon name="images" width={32} height={32} />
                )}
                <span>{value.name}</span>
              </div>
            );
          },
        },
        {
          name: 'attached_online_interactions_count',
          path: 'attached_online_interactions_count',
          label: lang.ATTACHED_SOURCES,
          show: operation.client_interface?.online,
          width: 80,
          align: 'center',
          cellClassName: b('highlight'),
          render: ({item, value}) => {
            const half = !!value;
            return (
              <AppButton
                label={<figure className={b('attach', {half})}>{value || ''}</figure>}
                asWrap
                onClick={() => onOpenModal({place: item, type: 'source'})}
              />
            );
          },
        },
        {
          name: 'attached_device_affectations_count',
          path: 'attached_device_affectations_count',
          label: lang.ATTACHED_DEVICES,
          show: operation.client_interface?.device,
          width: 80,
          align: 'center',
          cellClassName: b('highlight'),
          render: ({item, value}) => {
            const full = !!value && value === item.device_affectations_count;
            return (
              <AppButton
                label={<figure className={b('attach', {half: !!value, full})}>{value || ''}</figure>}
                asWrap
                onClick={() => onOpenModal({place: item, type: 'device'})}
              />
            );
          },
        },
        {
          name: 'from',
          path: 'calculations.from',
          label: lang.FROM,
          cellClassName: b('highlight'),
          width: 115,
          render: ({value}) => <DateTime showBase={false} value={value} timezone={operation?.timezone} />,
        },
        {
          name: 'to',
          path: 'calculations.to',
          label: lang.TO,
          cellClassName: b('highlight'),
          render: ({value}) => <DateTime showBase={false} value={value} timezone={operation?.timezone} />,
        },
        {
          name: 'actions',
          label: lang.ACTIONS,
          align: 'center',
          cellClassName: b('highlight'),
          render: ({item}) =>
            (!!item.attached_device_affectations_count || !!item.attached_online_interactions_count) && (
              <PrizeMapSelectingButton
                budget={false}
                exclude={{
                  prizeDrawGame: !lotteryGame,
                  instantWinGame: !instantWinGame,
                }}
                onSelect={(gameType) => onOpenModal({place: item, type: `prize_${gameType}`})}
                className={b('prize-icon')}
              />
            ),
        },
      ]}
    />
  );
};

export default ClientStoresTable;
