import {DEFAULT_PASSWORD_EXPIRATION, UserPasswordExpirationConfig} from 'client/ducks/user-password-expiration/reducer';

import {ACCESS_LEVEL} from 'client/models/common/constants';

export type PasswordExpirationFormData = {
  [ACCESS_LEVEL.NATIONAL]: boolean;
  [ACCESS_LEVEL.REGION]: boolean;
  [ACCESS_LEVEL.STORE]: boolean;
  [ACCESS_LEVEL.LOCAL]: boolean;
};

export const initialFormValues = (config: UserPasswordExpirationConfig) => {
  return {
    [ACCESS_LEVEL.NATIONAL]: config?.[ACCESS_LEVEL.NATIONAL] > 0,
    [ACCESS_LEVEL.REGION]: config?.[ACCESS_LEVEL.REGION] > 0,
    [ACCESS_LEVEL.STORE]: config?.[ACCESS_LEVEL.STORE] > 0,
    [ACCESS_LEVEL.LOCAL]: config?.[ACCESS_LEVEL.LOCAL] > 0,
  };
};

export const mapFormValues = (values: PasswordExpirationFormData, clientId: string) => {
  return {
    user_password_expiration_config: {
      client_id: clientId,
      [ACCESS_LEVEL.NATIONAL]: values[ACCESS_LEVEL.NATIONAL] ? DEFAULT_PASSWORD_EXPIRATION : null,
      [ACCESS_LEVEL.REGION]: values[ACCESS_LEVEL.REGION] ? DEFAULT_PASSWORD_EXPIRATION : null,
      [ACCESS_LEVEL.STORE]: values[ACCESS_LEVEL.STORE] ? DEFAULT_PASSWORD_EXPIRATION : null,
      [ACCESS_LEVEL.LOCAL]: values[ACCESS_LEVEL.LOCAL] ? DEFAULT_PASSWORD_EXPIRATION : null,
    },
  };
};
