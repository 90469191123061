import getValidityInitialValues from 'client/components/prizes/fieldsets/prize-diy-validity-fieldset/initialValues';
import {COUPON_TYPES} from 'client/models/prizes/constants';

export default (coupon, {timezone}) => {
  return {
    id: coupon?.id,
    internal_name: coupon?.internal_name,
    internal_code: coupon?.internal_code,
    external_name: coupon?.external_name,
    type: coupon?.type || COUPON_TYPES.CLIENT,
    description: coupon?.comment,
    loyalty_amount: coupon?.loyalty_amount?.toString(),
    loyalty_unit: coupon?.loyalty_unit,
    barcode_format: coupon?.barcode_format,
    ...getValidityInitialValues(coupon, true, {timezone}),
  };
};
