import React, {ComponentType} from 'react';

import {WrappedFieldProps} from 'redux-form';
import {BaseFieldProps} from 'redux-form/lib/Field';

import FieldWrap from 'client/common/fields/field-wrap';

export type BaseFieldExternProps = {
  className?: string;
  withWrap?: boolean;
  alwaysShowError?: boolean;
};

export type BaseFieldComponentProps<CProps extends Record<string, any>> = Omit<BaseFieldProps, 'props' | 'component'> &
  WrappedFieldProps &
  BaseFieldExternProps & {
    Component: ComponentType<CProps>;
  };

export function BaseFieldComponent<CProps extends Record<string, any> = Record<string, any>>({
  Component,
  ...props
}: BaseFieldComponentProps<CProps>) {
  const {
    input: {value, name, onChange, onBlur, onFocus},
    meta: {touched, error, warning},
    withWrap,
    className,
    alwaysShowError,
    ...otherProps
  } = props;
  const componentProps: Record<string, any> = {
    ...otherProps,
    name,
    value,
    onChange,
    onBlur,
    onFocus,
  };
  if ((touched || alwaysShowError) && error) {
    componentProps.errorMessage = error;
  }
  componentProps.warningMessage = warning || componentProps.warningMessage;

  if (withWrap) {
    return (
      <FieldWrap className={className}>
        <Component {...(componentProps as CProps)} />
      </FieldWrap>
    );
  }

  return <Component {...(componentProps as CProps)} className={className} />;
}
