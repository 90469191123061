import React from 'react';

import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';

import {doesValueExist} from 'client/services/helpers';

import {
  fetchOfflineInteractions,
  fetchPlacesWithDevices,
  updateOfflineInteraction,
} from 'client/ducks/interactions/actions';
import {getPlacesWithInteractionsAction} from 'client/ducks/places/actions';

import PaginationBar from 'client/common/paginations/pagination-bar';

import PlanMapModal from 'client/components/client-autotask/tabs/plans-tab/plan-map-modal';

import FilterBar from './filter-bar';
import PlansTable from './plans-table';

import {clearFilters} from '../data-tab/helpers';

import './plans-tab.scss';

class PlansTab extends ReactQueryParams {
  static propTypes = {
    task: PropTypes.object.isRequired,
    onPageChange: PropTypes.func.isRequired,
    getOfflineInteractions: PropTypes.func.isRequired,
    fetchOfflineInteractions: PropTypes.func.isRequired,
    updateOfflineInteraction: PropTypes.func.isRequired,
    languageState: PropTypes.object.isRequired,
    places: PropTypes.array.isRequired,
    placesWithDevices: PropTypes.shape({
      data: PropTypes.array.isRequired,
      meta: PropTypes.object.isRequired,
    }).isRequired,
    interactions: PropTypes.shape({
      data: PropTypes.array.isRequired,
      meta: PropTypes.object.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    languageState: {},
    getPlan: () => {},
    onPageChange: () => {},
    getOfflineInteractions: () => {},
    fetchOfflineInteractions: () => {},
    updateOfflineInteraction: () => {},
  };

  static modals = {
    LINK_DEVICE_MODAL: 1,
    UNLINK_DEVICE_MODAL: 2,
    MAP_VIEW_MODAL: 3,
  };

  static DEFAULT_PAGE = '1';
  static DEFAULT_PER_PAGE = '25';
  static DEFAULT_SORT = 'created_at desc';

  constructor(props) {
    super(props);

    this.state = {
      activeModal: null,
      showingPlaceId: null,
      isDevices: true,
      isPlaces: false,
      showModal: false,
      loading: false,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.task.id !== prevProps.task.id) {
      this.fetchData();
    }
  }

  getParams = () => {
    const params = this.queryParams;

    return {
      page: params.page || PlansTab.DEFAULT_PAGE,
      perPage: params.perPage || PlansTab.DEFAULT_PER_PAGE,
      sortField: params.sortField || '',
      sortOrder: params.sortOrder || '',
      search: params.search || '',
    };
  };

  fetchData = () => {
    this.toggleLoading();
    Promise.all([this.fetchOfflineInteractions(), this.fetchPlacesWithDevices(), this.fetchPlaces()]).then(
      this.toggleLoading,
    );
  };

  toggleLoading = () => this.setState(({loading}) => ({loading: !loading}));

  fetchOfflineInteractions = () => {
    const params = this.getParams();

    return this.props.getOfflineInteractions({
      page: params.page,
      per_page: params.perPage,
      q: {
        interface_automation_task_id_eq: this.props.task.id,
        s: params.sortField ? `${params.sortField} ${params.sortOrder}` : PlansTab.DEFAULT_SORT,
        g: {
          search: {
            m: 'or',
            place_name_cont: params.search,
            device_name_cont: params.search,
            interface_name_cont: params.search,
          },
        },
      },
      include: {
        device: null,
        interface: {
          interface_template: null,
        },
        place: null,
        interaction: null,
        accessible_region: null,
        accessible_store: null,
      },
      include_interaction_full_participations_count: true,
      include_interaction_full_accomplished_participations_count: true,
    });
  };

  fetchPlacesWithDevices = () => {
    const params = this.getParams();

    return this.props.getPlacesWithDevices({
      page: params.page,
      per_page: params.perPage,
      q: {
        offline_interactions_interface_automation_task_id_eq: this.props.task.id,
        s: params.sortField ? `${params.sortField} ${params.sortOrder}` : PlansTab.DEFAULT_SORT,
        g: {
          search: {
            m: 'or',
            place_name_cont: params.search,
            device_name_cont: params.search,
            interface_name_cont: params.search,
          },
        },
      },
      include: {
        device: null,
        place: null,
        interface: {
          interface_template: null,
        },
        accessible_region: null,
        accessible_store: null,
      },
      include_interaction_full_participations_count: true,
      include_interaction_full_accomplished_participations_count: true,
      distinct: true,
    });
  };

  fetchPlaces = () => {
    return this.props.getPlaces({
      include: {
        offline_interactions: ['device', 'interface', 'place', 'accessible_region', 'accessible_store'],
      },
      q: {
        automation_task_id_eq: this.props.task.id,
      },
      distinct: true,
    });
  };

  handleParamsChange = (params) => {
    this.setQueryParams(params, false);

    this.toggleLoading();
    Promise.all([this.fetchOfflineInteractions(), this.fetchPlacesWithDevices()]).then(this.toggleLoading);
  };

  handleActiveModalSet = (activeModal, state = {}) => {
    return () => this.setState({...state, activeModal});
  };

  handleActiveModalClose =
    (update = false, state = {}) =>
    () => {
      this.setState({
        ...state,
        activeModal: null,
        showingPlaceId: null,
      });

      if (update) {
        this.fetchOfflineInteractions();
        this.fetchPlacesWithDevices();
      }
    };

  showSinglePlaceOnMap = (showingPlaceId) => {
    this.handleActiveModalSet(PlansTab.modals.MAP_VIEW_MODAL, {showingPlaceId})();
  };

  handleEditDeviceClick = (editingInteraction) => {
    return this.handleActiveModalSet(PlansTab.modals.LINK_DEVICE_MODAL, {editingInteraction})();
  };

  handlePlacesViewClick = () => {
    this.setState((state) => ({isPlaces: !state.isPlaces, isDevices: !state.isDevices}));
    const params = this.getParams();
    this.handleParamsChange(
      clearFilters({
        ...params,
        page: 1,
      }),
    );
  };

  render() {
    const {
      interactions: {data, meta, full_max_visits},
      placesWithDevices,
      places,
    } = this.props;

    const {loading} = this.state;
    const params = this.getParams();
    const maxVisitsParticipationsByPlaces = placesWithDevices.full_max_visits;
    const maxVisitsParticipationsByDevices = full_max_visits;
    const {showingPlaceId, isDevices, isPlaces} = this.state;
    const placesWithDevicesData = placesWithDevices.data.map((item) => ({
      id: item.id,
      name: item.name,
      offline_interactions: item.offline_interactions.filter((value) => doesValueExist(value.interface)),
    }));

    return (
      <div className="plans-tab">
        <FilterBar
          params={params}
          totalCount={meta.total_count}
          onParamsChange={this.handleParamsChange}
          onPlacesViewClick={this.handlePlacesViewClick}
          onMapViewClick={this.handleActiveModalSet(PlansTab.modals.MAP_VIEW_MODAL)}
          isDevices={isDevices}
        />

        {isDevices && (
          <div>
            <PlansTable
              data={data}
              params={params}
              onParamsChange={this.handleParamsChange}
              showSinglePlaceOnMap={this.showSinglePlaceOnMap}
              type="devices"
              maxVisitsParticipations={maxVisitsParticipationsByDevices}
              loading={loading}
            />
            <PaginationBar
              onPageChange={this.handleParamsChange}
              data={data}
              totalPages={meta.total_pages}
              totalItems={meta.total_count}
              perPage={+params.perPage}
              currentPage={meta.current_page}
              loading={loading}
            />
          </div>
        )}

        {isPlaces && (
          <div>
            <PlansTable
              data={placesWithDevicesData}
              params={params}
              onParamsChange={this.handleParamsChange}
              showSinglePlaceOnMap={this.showSinglePlaceOnMap}
              type="places"
              maxVisitsParticipations={maxVisitsParticipationsByPlaces}
              loading={loading}
            />
            <PaginationBar
              onPageChange={this.handleParamsChange}
              data={placesWithDevicesData}
              totalPages={placesWithDevices.meta.total_pages}
              totalItems={placesWithDevices.meta.total_count}
              perPage={+params.perPage}
              currentPage={placesWithDevices.meta.current_page}
              loading={loading}
            />
          </div>
        )}

        {this.state.activeModal === PlansTab.modals.MAP_VIEW_MODAL && (
          <PlanMapModal
            show={this.state.activeModal === PlansTab.modals.MAP_VIEW_MODAL}
            onClose={this.handleActiveModalClose()}
            onEditInteraction={this.handleEditDeviceClick}
            planId={this.props.task.id}
            placeId={showingPlaceId}
            places={places}
          />
        )}
      </div>
    );
  }
}

export default connect(
  ({languageState, interactions, places}) => ({
    languageState,
    places: places.places,
    interactions: interactions.offlineInteractions,
    placesWithDevices: interactions.placesWithDevices,
  }),
  {
    getOfflineInteractions: fetchOfflineInteractions,
    getPlacesWithDevices: fetchPlacesWithDevices,
    updateOfflineInteraction,
    getPlaces: getPlacesWithInteractionsAction,
  },
)(PlansTab);
