import React from 'react';

import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage, useReduxForm} from 'client/services/hooks';

import {selectAutotask} from 'client/ducks/autotask/selectors';

import NumberField from 'client/common/fields/number-field';
import SelectField from 'client/common/fields/select-field';
import ToggleField from 'client/common/fields/toggle-field';

import {DEVICE_SEGMENTATION, ONLINE_SEGMENTATION} from 'client/components/lottery/constants';
import {LotteryPrizeModalFormName} from 'client/components/lottery/modals/lottery-prize-modal';
import {Operation} from 'client/models/operations/types';

import cssModule from './segmentation-params-fieldset.module.scss';

const b = bem('segmentation-params-fieldset', {cssModule});

type SegmentationParamsFieldsetProps = {
  operation: Operation;
  disabled: boolean;
};

const SegmentationParamsFieldset: React.FC<SegmentationParamsFieldsetProps> = (props) => {
  const {operation, disabled} = props;
  const lang = useLanguage('LOTTERY.MODALS.LOTTERY_PRIZE_MODAL.SEGMENTATION_PARAMS_FIELDSET');
  const autotask = useSelector(selectAutotask);

  const {
    formValues: {segmentationParams = {}},
  } = useReduxForm(LotteryPrizeModalFormName);

  const segmentationTitles = {
    [ONLINE_SEGMENTATION.GLOBAL]: lang.PRIZE_QUANTITY,
    [ONLINE_SEGMENTATION.PER_CHANNEL]: lang.DEFAULT_QUANTITY_PER_CHANNEL,
    [ONLINE_SEGMENTATION.PER_CAMPAIGN]: lang.DEFAULT_QUANTITY_PER_CAMPAIGN,
    [DEVICE_SEGMENTATION.PER_PLACE]: lang.DEFAULT_QUANTITY_PER_PLACE,
    [DEVICE_SEGMENTATION.PER_DEVICE]: lang.DEFAULT_QUANTITY_PER_DEVICE,
  };

  const renderTotal = (value: number) => (
    <div>
      <span className={b('total-label')}>{lang.TOTAL}</span>
      <span className={b('total-value')}>{value}</span>
    </div>
  );

  const isDevicesType = segmentationParams.deviceSegmentation !== DEVICE_SEGMENTATION.NONE;
  const isOnlineType = segmentationParams.onlineSegmentation !== ONLINE_SEGMENTATION.NONE;

  const isDeviceAndOnlineType = isDevicesType && isOnlineType;
  const isDeviceSegmentationVisible = isDevicesType && !segmentationParams.fullGlobal && !segmentationParams.hybrid;
  const isOnlineSegmentationVisible = isOnlineType && !segmentationParams.fullGlobal;

  const getDeviceTotal = () => operation.devices_count * segmentationParams.deviceQuantity;
  const getStoreTotal = () => (operation.attached_places_count || 0) * segmentationParams.deviceQuantity;
  const getOnlineTotal = () => {
    const count =
      segmentationParams.onlineSegmentation === ONLINE_SEGMENTATION.PER_CHANNEL
        ? operation.online_interactions_count
        : autotask?.interaction_groups?.filter((i) => i.type === 'Campaign').length || 0;
    return count * segmentationParams.onlineQuantity;
  };

  const isOnlineOnlyOperation = !operation?.client_interface?.device && operation?.client_interface?.online;

  if (segmentationParams.local) {
    const isHybridOperation = operation?.client_interface?.device && operation?.client_interface?.online;
    return (
      <div className={b()}>
        <p className={b('segmentation-title')}>{lang.SEGMENTATION}</p>
        {isHybridOperation && (
          <ToggleField
            name="segmentationParams.hybrid"
            label={lang.HYBRID_PER_STORE}
            borderColor="current"
            backgroundColor="current"
            className={b('main-toggle')}
            classNames={{label: b('toggle-label')}}
            disabled
            rounded
          />
        )}
        <div className={b('box', ['sibling'])}>
          <p className={b('box-title')}>{lang.STORE}</p>
          <NumberField
            name="segmentationParams.deviceQuantity"
            inputClassName={b('field', ['number'])}
            label={lang.QUANTITY}
            labelPosition="left"
            disabled={disabled}
            required
            inputHeight="small"
          />
          {segmentationParams.deviceSegmentation !== DEVICE_SEGMENTATION.GLOBAL && renderTotal(getStoreTotal())}
        </div>
      </div>
    );
  }

  return (
    <div className={b()}>
      <p className={b('segmentation-title')}>{lang.SEGMENTATION}</p>
      {isDeviceAndOnlineType && (
        <ToggleField
          name="segmentationParams.deviceOnlineToggle"
          label={lang.DEVICES_ONLINE_SEGMENTATION}
          borderColor="current"
          backgroundColor="current"
          className={b('main-toggle')}
          classNames={{label: b('toggle-label')}}
          disabled
          rounded
        />
      )}
      {segmentationParams.hybrid && !isOnlineOnlyOperation && (
        <ToggleField
          name="segmentationParams.hybrid"
          label={lang.HYBRID_PER_STORE}
          borderColor="current"
          backgroundColor="current"
          className={b('main-toggle')}
          classNames={{label: b('toggle-label')}}
          disabled
          rounded
        />
      )}
      {isDeviceSegmentationVisible && (
        <div className={b('box', {sibling: isOnlineType})}>
          {isOnlineType && <p className={b('box-title')}>{lang.DEVICES}</p>}
          <div className={b('grid-fields')}>
            <span className={b('field-title', 'segmentation')}>{lang.SEGMENTATION}</span>
            <SelectField
              name="segmentationParams.deviceSegmentation"
              className={b('field', ['select'])}
              options={[
                {
                  label: lang.NONE,
                  value: DEVICE_SEGMENTATION.GLOBAL,
                },
                {
                  label: lang.PLACE,
                  value: DEVICE_SEGMENTATION.PER_PLACE,
                },
                {
                  label: lang.DEVICE,
                  value: DEVICE_SEGMENTATION.PER_DEVICE,
                },
              ]}
              simpleValue
              disabled
            />
            <span className={b('field-title')}>
              {segmentationTitles[segmentationParams.deviceSegmentation as keyof typeof segmentationTitles]}
            </span>
            <NumberField
              name="segmentationParams.deviceQuantity"
              inputClassName={b('field', ['number'])}
              labelPosition="left"
              disabled={disabled}
              required={true}
            />
          </div>
          {segmentationParams.deviceSegmentation !== DEVICE_SEGMENTATION.GLOBAL && renderTotal(getDeviceTotal())}
        </div>
      )}
      {isOnlineSegmentationVisible && (
        <div className={b('box', {sibling: isDevicesType})}>
          {isDevicesType && <p className={b('box-title')}>{lang.ONLINE}</p>}
          <div className={b('grid-fields')}>
            <span className={b('field-title', 'segmentation')}>{lang.SEGMENTATION}</span>
            <SelectField
              name="segmentationParams.onlineSegmentation"
              className={b('field', ['select'])}
              options={[
                {
                  label: lang.ONLINE_GLOBAL,
                  value: ONLINE_SEGMENTATION.GLOBAL,
                },
                {
                  label: lang.PER_CHANNEL,
                  value: ONLINE_SEGMENTATION.PER_CHANNEL,
                },
                {
                  label: lang.PER_CAMPAIGN,
                  value: ONLINE_SEGMENTATION.PER_CAMPAIGN,
                },
              ]}
              simpleValue
              disabled
            />
            <span className={b('field-title')}>
              {segmentationTitles[segmentationParams.onlineSegmentation as keyof typeof segmentationTitles]}
            </span>
            <NumberField
              name="segmentationParams.onlineQuantity"
              inputClassName={b('field', ['number'])}
              labelPosition="left"
              disabled={disabled}
              required={true}
            />
          </div>
          {segmentationParams.onlineSegmentation !== ONLINE_SEGMENTATION.GLOBAL && renderTotal(getOnlineTotal())}
        </div>
      )}
      {segmentationParams.hybrid && (
        <div className={b('box', ['sibling'])}>
          {isDevicesType && <p className={b('box-title')}>{lang.STORES}</p>}
          <NumberField
            name="segmentationParams.deviceQuantity"
            inputClassName={b('field', ['number'])}
            label={lang.PER_STORE}
            labelPosition="left"
            disabled={disabled}
            required
            inputHeight="small"
          />
          {segmentationParams.deviceSegmentation !== DEVICE_SEGMENTATION.GLOBAL && renderTotal(getStoreTotal())}
        </div>
      )}
      {(segmentationParams.fullGlobal || segmentationParams.fullNone) && !segmentationParams.hybrid && (
        <NumberField
          name="segmentationParams.commonQuantity"
          inputClassName={b('field', ['number'])}
          label={lang.PRIZE_QUANTITY}
          labelPosition="left"
          disabled={disabled}
          required={true}
        />
      )}
    </div>
  );
};

export default SegmentationParamsFieldset;
