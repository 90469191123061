import moment from 'moment/moment';

import {Interaction} from 'client/models/interactions/types';

export const hasIntersection = (interaction: Interaction, from = '', to = '') => {
  const targetFrom = moment(from);
  const targetTo = moment(to);

  const intFrom = moment(interaction.from);
  const intTo = moment(interaction.to);

  const isNotIntersected = intFrom.isAfter(targetTo) || (intFrom.isBefore(targetFrom) && intTo.isBefore(targetFrom));

  return !isNotIntersected;
};
