import {parseDate} from 'client/services/utils/date';

const mapInitialValues = (game, {prizeMaps, autotask} = {}) => {
  const {timezone} = autotask?.operation || {};
  const prizeMap = prizeMaps && prizeMaps[0];
  const from = prizeMap ? prizeMap.from : game.default_from;
  const to = prizeMap ? prizeMap.to : game.default_to;
  let drawTime = prizeMap ? prizeMap.draw_time : game.default_draw_time;
  drawTime = drawTime || game.default_draw_time;

  return {
    name: game.name,
    game_type: game.game_type,
    validation: game.validation,
    default_from: parseDate(from, {outputFormat: 'DATE_TIME_SECOND', timezone}),
    default_to: parseDate(to, {outputFormat: 'DATE_TIME_SECOND', timezone}),
    default_draw_time: parseDate(drawTime, {outputFormat: 'DATE_TIME_SECOND', timezone}),
    levels: game.game_validation_levels.map(({interface_level_id}) => interface_level_id).join(','),
  };
};

export default mapInitialValues;
