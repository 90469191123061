import React, {Component} from 'react';

import isUndefined from 'lodash/isUndefined';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';

import {patch, post} from 'client/services/fetch';

import {ACCESS_LEVEL_TYPES} from 'client/ducks/client-users/constants';

import {API_METHODS} from 'client/common/config';
import Modal from 'client/common/modals/modal';

import CheckboxList from 'client/components/common/checkbox-list';
import CustomScrollbars from 'client/components/common/custom-scrollbars';
import Rollout from 'client/components/common/rollout';

class NewUserAccessStoresModal extends Component {
  static mapStores(stores) {
    return (
      stores &&
      stores.map((store) => ({
        checked: store.checked,
        component: (
          <div className="store-access">
            <div className="address-label">{store.street_address}</div>
            <div className="city-label">{store.city_name}</div>
          </div>
        ),
      }))
    );
  }

  constructor(props) {
    super(props);

    this.LANGUAGE = this.props.languageState.payload.AGENCY;
  }

  save = () => {
    let stores = this.props.freeStores.slice();
    for (let regs of this.props.regionsWithStores) {
      stores = stores.concat(regs.places);
    }
    const userExists = !isUndefined(this.props.clientUser.id);
    const place_accesses = stores.filter((i) => i.checked).map((i) => ({place_id: i.id}));

    if (this.props.editingUser) {
      this.props.onCreate({
        showEditCompanyAccessModal: 0,
        showEditAgencyAccessModal: 0,
        showEditUserModal: true,
        editingUser: {
          ...this.props.user,
          access_level: ACCESS_LEVEL_TYPES.STORES,
          place_accesses: this.props.user.place_accesses
            .filter((i) => i.id)
            .map(({id}) => ({id, _destroy: true}))
            .concat(place_accesses),
        },
      });
      return;
    }

    const storeNewUserData = {
      client_user: {
        ...this.props.user,
        memberships: [
          {
            ...this.props.user.memberships[0],
            place_accesses,
          },
        ],
        invited_by_id: this.props.adminId,
      },
    };
    if (userExists) {
      patch(`${API_METHODS.CLIENT_USERS}/${this.props.clientUser.id}`, storeNewUserData).then(this.props.onCreate);
    } else {
      post(API_METHODS.CLIENT_USERS, storeNewUserData).then(this.props.onCreate);
    }
  };

  handleTransition = () => {
    this.forceUpdate();
  };

  onStoreCheck = (storesList) => {
    return (index, checked) => {
      storesList[index].checked = checked;
    };
  };

  render() {
    const {freeStores, regionsWithStores, show, editingUser, totalSteps, onBack, onClose} = this.props;
    const {first_name: firstName, last_name: lastName, title: civility, email} = this.props.user;

    const mappedFreeStores = NewUserAccessStoresModal.mapStores(freeStores);
    const mappedRegionsWithStores =
      regionsWithStores &&
      regionsWithStores.map((region) => ({
        ...region,
        places: NewUserAccessStoresModal.mapStores(region.places),
      }));

    return (
      <Modal
        show={show}
        onClose={onClose}
        dialogClassName="modal-window--width-1 modal-window--theme-3"
        titleClassName="modal-window__title"
        title={
          <>
            <h3 className="title pull-left">
              {editingUser ? this.LANGUAGE.EDIT_USER_MODAL_TITLE : this.LANGUAGE.NEW_USER_MODAL_TITLE}
            </h3>
            {!editingUser && (
              <div className="pull-right modal-window__step">
                {this.LANGUAGE.NEW_USER_MODAL_STEP} {totalSteps} {this.LANGUAGE.NEW_USER_MODAL_STEP_OF} {totalSteps}
              </div>
            )}
          </>
        }
      >
        <form noValidate="noValidate" onSubmit={this.props.handleSubmit(this.save)}>
          <div className="modal-window__subtitle modal-window__email">{email}</div>
          <div className="modal-window__subtitle modal-window__name">
            {this.LANGUAGE.CIVILITY[civility]} {firstName} {lastName}
          </div>
          <div className="modal-window__descr main-text main-text--bold modal-window--margin-bottom-5">
            {this.LANGUAGE.NEW_USER_MODAL_STORES_ACCCESS}
          </div>
          <div className="content-group theme-color-1 padding-right-0">
            <CustomScrollbars
              cssModifier="custom-scrollbars--view-1"
              scrollbarProps={{
                hideTracksWhenNotNeeded: true,
                autoHeight: true,
                autoHeightMax: 482,
              }}
            >
              {freeStores && freeStores.length > 0 && (
                <Rollout
                  title={this.LANGUAGE.STORES_NOT_INSIDE_ROLLOUT}
                  className="rollout--expand-1"
                  onTransition={this.handleTransition}
                >
                  <CustomScrollbars
                    scrollbarProps={{
                      autoHeightMax: 200,
                    }}
                  >
                    <div className="list list--view-2 list--bg-1">
                      <CheckboxList items={mappedFreeStores} onCheck={this.onStoreCheck(freeStores)} />
                    </div>
                  </CustomScrollbars>
                </Rollout>
              )}
              {mappedRegionsWithStores &&
                mappedRegionsWithStores.map((region, i) => (
                  <Rollout
                    title={region.name}
                    className="rollout--expand-1"
                    onTransition={this.handleTransition}
                    key={region.id}
                  >
                    <CustomScrollbars
                      scrollbarProps={{
                        autoHeightMax: 200,
                      }}
                    >
                      <div className="list list--view-2 list--bg-1">
                        <CheckboxList items={region.places} onCheck={this.onStoreCheck(regionsWithStores[i].places)} />
                      </div>
                    </CustomScrollbars>
                  </Rollout>
                ))}
            </CustomScrollbars>
          </div>
          <div className="modal-window__footer modal-window__footer--centered">
            <button className="button button--bg-11 modal-window__footer-btn" type="button" onClick={onBack}>
              {this.LANGUAGE.NEW_USER_MODAL_BACK_BUTTON}
            </button>
            <button className="button button--bg-3 modal-window__footer-btn" type="submit">
              {editingUser ? this.LANGUAGE.NEW_USER_MODAL_NEXT_BUTTON : this.LANGUAGE.NEW_USER_MODAL_CREATE_BUTTON}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

NewUserAccessStoresModal.propTypes = {
  user: PropTypes.object,
  clientUser: PropTypes.object,
  adminId: PropTypes.number,
  freeStores: PropTypes.array,
  regionsWithStores: PropTypes.array,
  totalSteps: PropTypes.number,
  show: PropTypes.bool,
  editingUser: PropTypes.bool,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  onCreate: PropTypes.func,
  handleSubmit: PropTypes.func,
  languageState: PropTypes.object,
};

NewUserAccessStoresModal.defaultProps = {
  show: false,
  editingUser: false,
  totalSteps: 3,
  clientUser: {},
  onBack: () => {},
  onClose: () => {},
  onCreate: () => {},
};

const NewUserAccessStoresForm = reduxForm({
  form: 'NewUserAccessStoresForm',
})(NewUserAccessStoresModal);

export default connect(({languageState}) => ({
  languageState,
}))(NewUserAccessStoresForm);
