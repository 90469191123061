import React from 'react';

import classNames from 'classnames';
import {NavLink} from 'react-router-dom';

import bem from 'client/services/bem';

import {TaskMenuProps} from './types';

import cssModule from './task-menu.module.scss';

const b = bem('task-menu', {cssModule});

const TaskMenu: React.FC<TaskMenuProps> = ({tabs = [], className = ''}) => {
  return (
    <div className={classNames(b(), className)}>
      {tabs.map((tab) => (
        <NavLink
          exact
          to={tab.path}
          key={tab.path}
          activeClassName={b('link', ['active'])}
          className={b('link', {disabled: !!tab.disabled})}
        >
          {tab.label}
        </NavLink>
      ))}
    </div>
  );
};

export default React.memo(TaskMenu);
