import React, {useEffect, useCallback} from 'react';

import {useDispatch} from 'react-redux';
import {useParams} from 'react-router';

import bem from 'client/services/bem';

import {getAvailableDomains} from 'client/ducks/available-domains/actions';
import {getClientsDomains} from 'client/ducks/client-domains/actions';
import {getResultByInterface} from 'client/ducks/templates/actions';

import DIYOpDiffusionBlock from 'client/components/diy-operation/blocks/diy-op-diffusion-block';
import DIYOpElementsBlock from 'client/components/diy-operation/blocks/diy-op-elements-block';
import DiyOpGamesBlock from 'client/components/diy-operation/blocks/diy-op-games-block';
import DIYOpMainBlock from 'client/components/diy-operation/blocks/diy-op-main-block';
import {Autotask} from 'client/models/autotask/types';
import {EmailTemplateKind} from 'client/models/email-templates/types';
import {Operation} from 'client/models/operations/types';
import {ApiDispatch} from 'client/types';

import './diy-op-dashboard.scss';

const b = bem('diy-operation-dashboard');

type DIYOpDashboardProps = {
  operation: Operation;
  autotask: Autotask;
  fetchAutotask: () => Promise<void>;
  fetchOperation: () => Promise<void>;
  emailTemplateKinds: EmailTemplateKind[];
  loadingEmailTemplateKinds: boolean;
};

export const DIYOpDashboard: React.FC<DIYOpDashboardProps> = (props) => {
  const {operation, autotask, fetchAutotask, fetchOperation, emailTemplateKinds, loadingEmailTemplateKinds} = props;
  const interfaceId = operation?.client_interface?.id;
  const params = useParams<{clientId: string}>();
  const {clientId} = params;

  const dispatch: ApiDispatch = useDispatch();

  const fetchAvailableDomains = useCallback(async () => {
    await dispatch(
      getAvailableDomains({
        q: {
          g: {
            0: {
              client_id_eq: clientId,
            },
            1: {
              client_id_blank: true,
            },
          },
          m: 'or',
        },
      }),
    );
  }, [dispatch, clientId]);

  const fetchClientDomains = useCallback(async () => {
    if (interfaceId) {
      const queryParams = {
        q: {
          interface_id_eq: interfaceId,
        },
      };
      await dispatch(getClientsDomains(queryParams));
    }
  }, [dispatch, interfaceId]);

  const fetchResult = useCallback(() => {
    if (interfaceId) {
      // used in CustomizationModal in both DiyOpMainBlock and DiyOpElementsBlock
      // and contains data for all catalog and form types of modal
      dispatch(getResultByInterface(interfaceId));
    }
  }, [dispatch, interfaceId]);

  useEffect(() => {
    fetchResult();
  }, [fetchResult]);

  useEffect(() => {
    fetchClientDomains();
    fetchAvailableDomains();
  }, [fetchClientDomains, fetchAvailableDomains]);

  return (
    <div className={b()}>
      <DIYOpMainBlock
        operation={operation}
        autotask={autotask}
        fetchOperation={fetchOperation}
        fetchAutotask={fetchAutotask}
        fetchResult={fetchResult}
      />
      <DIYOpElementsBlock
        operation={operation}
        fetchResult={fetchResult}
        emailTemplateKinds={emailTemplateKinds}
        loadingEmailTemplateKinds={loadingEmailTemplateKinds}
      />
      <div className={b('row')}>
        <DIYOpDiffusionBlock operation={operation} fetchOperation={fetchOperation} />
        <DiyOpGamesBlock operation={operation} fetchAutotask={fetchAutotask} autotask={autotask} />
      </div>
    </div>
  );
};
