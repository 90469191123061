import isEqual from 'lodash/isEqual';
import moment from 'moment/moment';
import qs from 'qs';

export const areStatesEqual = (prevReduxState, reduxState) => {
  const object1 = {...prevReduxState};
  const object2 = {...reduxState};

  return isEqual(object1, object2);
};

export const getInitialValues = (defaultProps) => {
  const parsedQueryParams = qs.parse(window.location.search, {ignoreQueryPrefix: true});

  if (parsedQueryParams.filter) {
    return {...defaultProps, ...JSON.parse(parsedQueryParams.filter)};
  }

  return defaultProps;
};

export const convertDate = (dateString) => {
  if (!dateString) {
    return null;
  }

  return moment(dateString, 'DD/MM/YYYY').toDate();
};
