export const ACCESS_LEVEL = {
  NATIONAL: 'national',
  REGION: 'region',
  STORE: 'store',
  CLIENT_ADMIN: 'client_admin',
  ADMIN: 'admin',
  LOCAL: 'local',
} as const;

export const ACCESS_LEVEL_OPTIONS = {
  NATIONAL: ACCESS_LEVEL.NATIONAL,
  REGION: ACCESS_LEVEL.REGION,
  STORE: ACCESS_LEVEL.STORE,
} as const;

export const ACCESS_LEVEL_ICONS = {
  [ACCESS_LEVEL.STORE]: 'store',
  [ACCESS_LEVEL.REGION]: 'regional-access',
  [ACCESS_LEVEL.NATIONAL]: 'national',
} as const;

export const GENDER_TYPES_VALUES = {
  MALE: 'Monsieur',
  FEMALE: 'Madame',
  OTHER: 'Autre',
  NOT_SPECIFIED: 'N/A',
} as const;
