import {DEVICE_SEGMENTATION, ONLINE_SEGMENTATION} from 'client/components/lottery/constants';
import {Game} from 'client/models/prizes/types';

import {parseDate} from 'client/services/utils/date';

import {DrawParametersValues} from './types';

const mapValuesForOnlineStore = (data?: Game, isOnlineOnly?: boolean) => {
  if (!isOnlineOnly || !data) {
    return {};
  }

  return data.geo_level === DEVICE_SEGMENTATION.PER_PLACE && data.hybrid
    ? {
        device_segmentation: DEVICE_SEGMENTATION.NONE,
        online_segmentation: ONLINE_SEGMENTATION.STORE,
      }
    : {};
};

export default (data?: Game, isOnlineOnly?: boolean, timezone?: string): DrawParametersValues => {
  return {
    draw_id: data?.game_draws?.[0].id,
    name: data?.name || '',
    draw_moment: parseDate(data?.game_draws?.[0].default_draw_time, { timezone }) || '',
    from: parseDate(data?.game_draws?.[0].default_from, {timezone}) || '',
    to: parseDate(data?.game_draws?.[0].default_to, {timezone}) || '',
    send_email: data?.game_draws?.[0].loss_email || false,
    send_sms: data?.game_draws?.[0].loss_sms || false,
    device_segmentation: data?.geo_level || '',
    online_segmentation: data?.online_level || '',
    hybrid: data?.hybrid || false,
    ...mapValuesForOnlineStore(data, isOnlineOnly),
  };
};
