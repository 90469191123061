import React, {Component} from 'react';

import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reduxForm, formValueSelector, SubmissionError} from 'redux-form';

import bem from 'client/services/bem';
import {post, patch} from 'client/services/fetch';
import {transformObjToOptions} from 'client/services/formatters';
import {uid} from 'client/services/helpers';
import {parseDate} from 'client/services/utils/date';
import {required, datetime, dateTimeIsAfter} from 'client/services/validator';

import {getRegionsAction} from 'client/ducks/add-place-form/actions';
import {getDeviceFamiliesAction} from 'client/ducks/devices/actions';
import {selectDeviceFamiliesOptions} from 'client/ducks/devices/selectors';
import {getInterfacesAction} from 'client/ducks/interfaces/actions';
import {selectInterfacesWithNames} from 'client/ducks/interfaces/selectors';
import {getPlacesAction} from 'client/ducks/places/actions';
import {selectRegionsForPlanLinkModal} from 'client/ducks/places/selectors';
import {selectActivePlaces} from 'client/ducks/places/selectors';
import {addToastNotifications} from 'client/ducks/toast-notification/actions';

import AppButton from 'client/common/buttons';
import {API_METHODS} from 'client/common/config';
import {DatetimePickerField, SelectField, TextField} from 'client/common/fields';
import Modal from 'client/common/modals/modal';

import cssModule from './at-plan-link-modal.module.scss';

const b = bem('at-plan-link-modal', {cssModule});

class AtPlanLinkModal extends Component {
  static defaultProps = {
    show: false,
    devices: [],
    editingInteraction: {},
  };

  static propTypes = {
    clientId: PropTypes.number,
    regionId: PropTypes.number,
    autotaskId: PropTypes.number,
    deviceType: PropTypes.number,
    locationType: PropTypes.string,
    show: PropTypes.bool.isRequired,
    change: PropTypes.func.isRequired,
    places: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    planId: PropTypes.number.isRequired,
    editingInteraction: PropTypes.object,
    onConfirm: PropTypes.func.isRequired,
    getPlaces: PropTypes.func.isRequired,
    getRegions: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    interfaces: PropTypes.array.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onUnlinkClick: PropTypes.func.isRequired,
    getInterfaces: PropTypes.func.isRequired,
    languageState: PropTypes.object.isRequired,
    devices: PropTypes.arrayOf(PropTypes.object),
    getDeviceFamilies: PropTypes.func.isRequired,
    regionsWithStores: PropTypes.array.isRequired,
    addToastNotifications: PropTypes.func.isRequired,
    deviceFamilies: PropTypes.arrayOf(PropTypes.object).isRequired,
    timezone: PropTypes.string.isRequired,
  };

  static formName = 'AtPlanLinkModalForm';

  static ACCESS_LEVELS = {
    STORE: 'Place',
    REGION: 'Region',
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.AUTOTASK_PLAN.PLAN_LINK_MODAL;

    this.rules = {
      required: required(this.LANGUAGE.ERRORS.REQUIRED),
      from: [required(this.LANGUAGE.ERRORS.REQUIRED), datetime(this.LANGUAGE.ERRORS.INVALID_DATE_FORMAT)],
      to: [
        required(this.LANGUAGE.ERRORS.REQUIRED),
        datetime(this.LANGUAGE.ERRORS.INVALID_DATE_FORMAT),
        (value, {from}) => {
          return from && !datetime('.')(from)
            ? dateTimeIsAfter(this.LANGUAGE.ERRORS.INVALID_DATE_BORDERS, 'from')(value, {from})
            : null;
        },
      ],
    };
  }

  fetchDeviceFamilies = (clientId) => {
    const params = {
      include: {devices: null},
      q: {
        m: 'or',
        client_id_null: true,
        client_id_eq: clientId,
      },
    };
    this.props.getDeviceFamilies(params);
  };

  componentDidUpdate(prevProps) {
    const {autotaskId, show, editingInteraction, clientId} = this.props;

    if (autotaskId && autotaskId !== prevProps.autotaskId) {
      this.props.getInterfaces({
        q: {
          automation_task_id_eq: autotaskId,
        },
      });
    }

    if (clientId && clientId !== prevProps.clientId) {
      this.fetchDeviceFamilies(clientId);
    }

    if (show && !prevProps.show) {
      this.props.getPlaces({
        include: ['region'],
        q: {
          client_id_eq: clientId,
          m: 'or',
          client_id_null: 't',
        },
      });

      this.props.getRegions(clientId, true);

      if (isEmpty(editingInteraction)) {
        this.props.initialize({
          from: moment(),
          to: moment().add(1, 'days'),
          active: 'true',
        });
      } else {
        this.initInteraction(editingInteraction);
      }
    }
  }

  initInteraction = (values) => {
    const locationType = values.store_id ? AtPlanLinkModal.ACCESS_LEVELS.STORE : AtPlanLinkModal.ACCESS_LEVELS.REGION;
    const timezone = this.props.timezone;

    this.props.initialize({
      ...values,
      from: parseDate(values.from, {timezone}),
      to: parseDate(values.to, {timezone}),
      device_type: find(this.props.deviceFamilies, (family) =>
        find(family.devices, (device) => device.value === values.device_id),
      ),
      location_type: locationType,
      region_id: values.region_id,
      store_id: values.store_id,
      active: '' + values.active,
    });
  };

  handleSelectType = () => {
    this.props.change('device_id', null);
  };

  handleSelectRegion = () => {
    this.props.change('store_id', null);
  };

  buildOfflineInteraction = (values) => {
    const timezone = this.props.timezone;

    return {
      offline_interaction: {
        ...values,
        interaction_group_id: this.props.planId,
        from: parseDate(values.from, {timezone}),
        to: parseDate(values.to, {timezone}),
        place: null,
        device: null,
        interface: null,
        device_type: null,
      },
    };
  };

  mapValuesForSave = (values) => {
    const data = {...values};

    delete data.location;
    delete data.location_id;
    delete data.location_type;

    if (!data.name) {
      data.name = this.getDeviceName(data.place_id);
    }

    return data;
  };

  save = (values) => {
    const apiFunction = isEmpty(this.props.editingInteraction)
      ? (body) => post(API_METHODS.OFFLINE_INTERACTIONS, body)
      : (body) => patch(`${API_METHODS.OFFLINE_INTERACTIONS}/${this.props.editingInteraction.id}`, body);

    const data = this.mapValuesForSave(values);

    return apiFunction(this.buildOfflineInteraction(data)).then(({errors}) => {
      if (errors?.base) {
        this.props.addToastNotifications({id: uid(), type: 'error', description: errors?.base?.[0]});
      } else if (errors) {
        this.props.addToastNotifications({id: uid(), type: 'error', description: this.LANGUAGE.ERRORS.DEVICE_IS_TAKEN});
        throw new SubmissionError({
          device_id: errors.device && this.LANGUAGE.ERRORS.DEVICE_IS_TAKEN,
        });
      }

      return this.props.onConfirm();
    });
  };

  getDeviceName = (placeId) => {
    const {name, city_name} = find(this.props.places, {id: placeId}) || {};
    return [name, city_name].filter((i) => i).join(' - ');
  };

  handlePlaceChanging = (_, placeId) => {
    this.props.change('name', this.getDeviceName(placeId));
  };

  render() {
    const {
      show,
      places,
      onClose,
      devices,
      regionId,
      timezone,
      deviceType,
      interfaces,
      locationType,
      handleSubmit,
      deviceFamilies,
      regionsWithStores,
    } = this.props;
    const region = find(regionsWithStores, (reg) => reg.id === regionId) || {};
    const activeStores = region?.stores?.filter((store) => store.active) || [];
    const hasStores = regionsWithStores.some((i) => !!i.stores.length);

    return (
      <Modal show={show} onClose={onClose} className={b()} title={this.LANGUAGE.TITLE}>
        <form onSubmit={handleSubmit(this.save)}>
          <SelectField
            label={this.LANGUAGE.FORM.DEVICE_TYPE_LABEL}
            name="device_type"
            searchable={true}
            options={deviceFamilies}
            withWrap={true}
            inputHeight="small"
            onChange={this.handleSelectType}
            required={true}
          />
          {deviceType && (
            <SelectField
              label={this.LANGUAGE.FORM.DEVICE_LABEL}
              name="device_id"
              simpleValue={true}
              searchable={true}
              options={devices}
              withWrap={true}
              inputHeight="small"
              required={true}
            />
          )}
          {!!regionsWithStores.length && (
            <SelectField
              label={this.LANGUAGE.FORM.ACCESS_LEVEL_LABEL}
              name="location_type"
              simpleValue={true}
              searchable={true}
              withWrap={true}
              inputHeight="small"
              options={
                hasStores
                  ? [
                      {
                        label: this.LANGUAGE.FORM.ACCESS_LEVELS.Place,
                        value: AtPlanLinkModal.ACCESS_LEVELS.STORE,
                      },
                      {
                        label: this.LANGUAGE.FORM.ACCESS_LEVELS.Region,
                        value: AtPlanLinkModal.ACCESS_LEVELS.REGION,
                      },
                    ]
                  : [
                      {
                        label: this.LANGUAGE.FORM.ACCESS_LEVELS.Region,
                        value: AtPlanLinkModal.ACCESS_LEVELS.REGION,
                      },
                    ]
              }
              required={true}
            />
          )}
          {locationType && (
            <SelectField
              label={this.LANGUAGE.FORM.REGION_LABEL}
              name="region_id"
              simpleValue={true}
              searchable={true}
              withWrap={true}
              inputHeight="small"
              options={transformObjToOptions(
                locationType === AtPlanLinkModal.ACCESS_LEVELS.STORE
                  ? regionsWithStores.filter((i) => i.stores.length)
                  : regionsWithStores,
              )}
              onChange={this.handleSelectRegion}
            />
          )}
          {locationType === AtPlanLinkModal.ACCESS_LEVELS.STORE && !isEmpty(region) && (
            <SelectField
              label={this.LANGUAGE.FORM.STORE_LABEL}
              name="store_id"
              simpleValue={true}
              searchable={true}
              withWrap={true}
              inputHeight="small"
              options={transformObjToOptions(activeStores)}
              required={true}
            />
          )}
          <SelectField
            label={this.LANGUAGE.FORM.PLACE_LABEL}
            name="place_id"
            simpleValue={true}
            searchable={true}
            withWrap={true}
            inputHeight="small"
            options={transformObjToOptions(places)}
            onChange={this.handlePlaceChanging}
            required={true}
          />
          <SelectField
            label={this.LANGUAGE.FORM.INTERFACE_LABEL}
            name="interface_id"
            simpleValue={true}
            searchable={true}
            withWrap={true}
            inputHeight="small"
            options={transformObjToOptions(interfaces)}
            required={true}
          />
          <TextField label={this.LANGUAGE.FORM.NAME_LABEL} name="name" inputHeight="small" withWrap={true} />
          <DatetimePickerField
            className={b('datetime-picker')}
            name="from"
            label={this.LANGUAGE.FORM.BEGIN_DATE_LABEL}
            withWrap={true}
            required={true}
            timezone={timezone}
          />
          <DatetimePickerField
            className={b('datetime-picker')}
            name="to"
            label={this.LANGUAGE.FORM.END_DATE_LABEL}
            withWrap={true}
            required={true}
            timezone={timezone}
          />
          <SelectField
            label={this.LANGUAGE.FORM.STATUS_LABEL}
            name="active"
            simpleValue={true}
            searchable={true}
            withWrap={true}
            inputHeight="small"
            options={[
              {value: 'true', label: this.LANGUAGE.FORM.STATUS_ACTIVE},
              {value: 'false', label: this.LANGUAGE.FORM.STATUS_INACTIVE},
            ]}
            required={true}
          />
          {!isEmpty(this.props.editingInteraction) && (
            <AppButton
              className={b('unlink-button')}
              label={this.LANGUAGE.UNLINK_DEVICE_BUTTON}
              onClick={this.props.onUnlinkClick}
              color="scenario"
            />
          )}
          <div className={b('footer')}>
            <AppButton label={this.LANGUAGE.CANCEL_BUTTON} onClick={onClose} color="text-additional" />
            <AppButton label={this.LANGUAGE.CONFIRM_BUTTON} submit={true} color="scenario" />
          </div>
        </form>
      </Modal>
    );
  }
}

const AtPlanLinkModalForm = reduxForm({
  form: AtPlanLinkModal.formName,
})(AtPlanLinkModal);

export default connect(
  (state) => {
    const selector = formValueSelector(AtPlanLinkModal.formName);

    return {
      languageState: state.languageState,
      deviceFamilies: selectDeviceFamiliesOptions(state),
      regionId: selector(state, 'region_id'),
      devices: sortBy(selector(state, 'device_type.devices') || [], (i) => i.label.toLowerCase()),
      deviceType: selector(state, 'device_type.value'),
      locationType: selector(state, 'location_type'),
      regionsWithStores: selectRegionsForPlanLinkModal(state),
      interfaces: selectInterfacesWithNames(state),
      places: selectActivePlaces(state),
    };
  },
  {
    getDeviceFamilies: getDeviceFamiliesAction,
    getPlaces: getPlacesAction,
    getInterfaces: getInterfacesAction,
    getRegions: getRegionsAction,
    addToastNotifications,
  },
)(AtPlanLinkModalForm);
