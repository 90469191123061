import isEmpty from 'lodash/isEmpty';

import {uid} from 'client/services/helpers';

import {Store} from 'client/models/stores/types';

import {StoreInfoFormValues, Weekday} from './types';

export default (store: Store) => {
  if (!store || isEmpty(store)) {
    return {};
  }

  const openingHours: StoreInfoFormValues['opening_hours'] = {};
  Object.entries(store?.opening_hours || {}).forEach(([day, hours]) => {
    if (hours.length >= 2) {
      openingHours[day as Weekday] = [{value: `${hours[0]} ${hours[1]}`, id: uid()}];
    }

    if (hours.length === 4) {
      openingHours[day as Weekday]?.push({value: `${hours[2]} ${hours[3]}`, id: uid()});
    }
  });

  const specialHours = Object.keys(store?.special_hours || {}).map((key) => ({value: key, id: uid()}));

  return {
    app_name: store.app_name,
    app_street_address: store.app_street_address,
    app_city_name: store.app_city_name,
    app_zip: store.app_zip,
    app_phone: store.app_phone,
    app_email: store.app_email,
    app_facebook: store.app_facebook,
    app_website: store.app_website,
    opening_hours: openingHours,
    special_hours: specialHours,
  };
};
