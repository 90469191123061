import React from 'react';

import cn from 'classnames';
import find from 'lodash/find';
import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';

import bem from 'client/services/bem';
import {parseDate} from 'client/services/utils/date';

import {STATUS_COLORS as STATUS} from 'client/common/config';
import PaginationBar from 'client/common/paginations/pagination-bar';
import PerPageDropdown from 'client/common/selects/per-page-dropdown';

import ClientTable from 'client/components/common/client-table';
import ToggleButton from 'client/components/common/custom-buttons/toggle-button';
import Icon from 'client/components/common/icon';
import SearchField from 'client/components/common/search-field';

import {PRIZE_MAP_LEVEL_TYPES, PRIZE_MAP_TYPES} from 'client/components/games/game-config-modal/constants';
import PrizeMapCell from 'client/components/tables/common/cells/prize-map-cell';

import AtDevicePopover from '../../popovers/at-device-popover';
import AtStatusPopover from '../../popovers/at-status-popover/at-status-popover';

import cssModule from './at-plan-data-grid.module.scss';

const b = bem('at-plan-data-grid', {cssModule});

class AtPlanDataGrid extends ReactQueryParams {
  static propTypes = {
    readOnly: PropTypes.bool,
    perPage: PropTypes.number,
    data: PropTypes.array.isRequired,
    meta: PropTypes.object.isRequired,
    onPageChange: PropTypes.func.isRequired,
    onSortChange: PropTypes.func.isRequired,
    onMapViewClick: PropTypes.func.isRequired,
    onSearchChange: PropTypes.func.isRequired,
    lang: PropTypes.object.isRequired,
    langDevice: PropTypes.object.isRequired,
    langInterface: PropTypes.object.isRequired,
    onAddDeviceClick: PropTypes.func.isRequired,
    onEditDeviceClick: PropTypes.func.isRequired,
    onPlacesViewClick: PropTypes.func.isRequired,
    showSinglePlaceOnMap: PropTypes.func.isRequired,
    updateOfflineInteraction: PropTypes.func.isRequired,
    games: PropTypes.array.isRequired,
  };

  static LOCATION_TYPES = {
    STORE: 'Place',
    REGION: 'Region',
  };

  renderActions = ({value: place_id}) => {
    const {showSinglePlaceOnMap} = this.props;

    return (
      <div className={cn('theme-color-9', b('marker'))} onClick={() => showSinglePlaceOnMap(place_id)}>
        <Icon name="marker" />
      </div>
    );
  };

  renderDevicePopover = (device = {}) => {
    return (
      <div className="font-size-14">
        <div>
          <span className="bold">{this.props.langDevice.CODE_COLUMN}:</span> {device.code}
        </div>
        <div>
          <span className="bold">{this.props.langDevice.TYPE_COLUMN}:</span> {device.type}
        </div>
        {device.description && (
          <div>
            <span className="bold">{this.props.langDevice.DESCRIPTION_COLUMN}:</span> {device.description}
          </div>
        )}
      </div>
    );
  };

  renderInterfacePopover = (interf = {}) => {
    return (
      <div className="font-size-14">
        <div>
          <span className="bold">{this.props.langDevice.CODE_COLUMN}:</span> {interf.code}
        </div>
        {interf.interface_template && interf.interface_template.name && (
          <div>
            <span className="bold">{this.props.langInterface.NAME_COLUMN}:</span> {interf.interface_template.name}
          </div>
        )}
        {interf.description && (
          <div>
            <span className="bold">{this.props.langDevice.DESCRIPTION_COLUMN}:</span> {interf.description}
          </div>
        )}
      </div>
    );
  };

  renderDevice = ({item: interaction}) => {
    if (!interaction.device) {
      return null;
    }

    if (this.props.readOnly) {
      return (
        <AtDevicePopover overlay={this.renderDevicePopover(interaction.device)}>
          <div className="ellipsis-text">{interaction.device.name}</div>
        </AtDevicePopover>
      );
    }

    return (
      <div>
        <div className={b('device-name')} onClick={() => this.props.onEditDeviceClick(interaction)}>
          {interaction.device.name}
        </div>
        <div className="bold margin-top--10">{`${this.props.lang.ID_LABEL} = ${interaction.device.id}`}</div>
      </div>
    );
  };

  renderText = ({value}) => {
    return <div className="ellipsis-text">{value}</div>;
  };

  renderInterface = ({item: interaction}) => {
    if (this.props.readOnly) {
      return (
        <AtDevicePopover overlay={this.renderInterfacePopover(interaction?.interface)}>
          <div className="ellipsis-text">{interaction?.interface?.name}</div>
        </AtDevicePopover>
      );
    }

    return (
      <div>
        <div className="ellipsis-text">{interaction?.interface?.name}</div>
        <div className="bold margin-top--10">{`${this.props.lang.ID_LABEL} = ${interaction?.interface?.id}`}</div>
      </div>
    );
  };

  renderCity = ({value}) => {
    return (
      <div className="ellipsis-text" title={value}>
        {value}
      </div>
    );
  };

  renderDate = ({value, item}) => {
    const {timezone} = item.operation;
    const parseDateParams = {
      timezone,
      outputFormat: 'DATE_TIME_SECOND',
    };

    return parseDate(value, parseDateParams);
  };

  renderStatus1 = ({value: status}) => {
    return (
      <AtStatusPopover overlay={this.props.lang.STATUS_1[status] || ''}>
        <div
          style={{
            backgroundColor: STATUS[status || 0],
          }}
          className={b('status-square')}
        />
      </AtStatusPopover>
    );
  };

  renderStatus2 = ({value: status}) => {
    return (
      <AtStatusPopover overlay={this.props.lang.STATUS_2[status]}>
        <div
          style={{
            backgroundColor: STATUS[status],
          }}
          className={b('status-circle')}
        />
      </AtStatusPopover>
    );
  };

  renderToolbar = () => {
    const {readOnly, lang} = this.props;

    return (
      <div className="flex-container flex-justify-between flex-align-bottom">
        <SearchField
          onSearch={this.props.onSearchChange}
          onClear={() => this.props.onSearchChange('')}
          defaultSearch={this.queryParams.search}
          cssModifier={cn('input', b('search-input'))}
          placeholder={lang.SEARCH_PLACEHOLDER}
        />

        <div className={b('action-bar')}>
          {!readOnly && (
            <button className="button button--bg-4" onClick={this.props.onAddDeviceClick}>
              <Icon name="plus" className="button__icon" />
              <span>{lang.ADD_DEVICE_BUTTON}</span>
            </button>
          )}
          <button className="button button--bg-4" onClick={this.props.onMapViewClick}>
            <Icon name="marker" className="button__icon" />
            <span>{lang.MAP_VIEW_BUTTON}</span>
          </button>
          <button className="button button--bg-4" onClick={this.props.onPlacesViewClick}>
            {lang.PLACES_VIEW_BUTTON}
          </button>
        </div>
      </div>
    );
  };

  updateTestMode = (id, value) => {
    this.props.updateOfflineInteraction(id, {test_mode: value});
  };

  renderTestMode = ({item}) => {
    return <ToggleButton value={item.test_mode} onToggle={(value) => this.updateTestMode(item.id, value)} />;
  };

  renderPrizeMap =
    (winNumber) =>
    ({item}) => {
      const game = find(this.props.games, {win_number: winNumber});

      if (!game || !item?.prize_maps_present?.[`win${game?.win_number || ''}`]) {
        return null;
      }

      return (
        <PrizeMapCell
          variant="plans"
          game={game}
          levelKey="DEVICE"
          prizeMapParams={{
            type_eq: PRIZE_MAP_TYPES.GEO_PRIZE_MAP,
            geo_level_eq: PRIZE_MAP_LEVEL_TYPES.DEVICE,
            geo_source_id_eq: item.id,
          }}
        />
      );
    };

  render() {
    const {meta, onPageChange, data, onSortChange, lang} = this.props;

    return (
      <div>
        <div className={b('toolbar-wrapper')}>
          {this.renderToolbar()}
          <PerPageDropdown
            onChange={(pages) => {
              onPageChange({page: 1, perPage: pages});
            }}
            value={String(this.props.perPage)}
            simpleValue
          />
        </div>
        <ClientTable
          data={data}
          onSortChange={onSortChange}
          sortField={this.props.sort?.name}
          sortOrder={this.props.sort?.order}
          columns={[
            {name: 'device_name', label: lang.DEVICE_COLUMN, sortable: true, render: this.renderDevice, width: 180},
            {
              name: 'interface_name',
              label: lang.INTERFACE_COLUMN,
              sortable: true,
              render: this.renderInterface,
              width: 100,
            },
            {path: 'name', label: lang.NAME_COLUMN, render: this.renderText, width: 95},
            {
              name: 'place_name',
              path: 'place.name',
              label: lang.PLACE_COLUMN,
              render: this.renderText,
              sortable: true,
              width: 115,
            },
            {
              name: 'place_city_name',
              path: 'place.city_name',
              label: lang.CITY_COLUMN,
              render: this.renderCity,
              sortable: true,
              width: 100,
            },
            {
              name: 'address',
              path: 'place.street_address',
              label: lang.ADDRESS_COLUMN,
              render: this.renderText,
              width: 130,
            },
            {
              name: 'attribution',
              label: lang.ATTRIBUTION_COLUMN,
              subcolumns: [
                {
                  name: 'region',
                  path: 'accessible_region.name',
                  label: lang.REGION_COLUMN,
                  render: this.renderText,
                  width: 100,
                },
                {
                  name: 'store',
                  path: 'accessible_store.name',
                  label: lang.STORE_COLUMN,
                  render: this.renderText,
                  width: 100,
                },
              ],
            },
            {path: 'geo_prize_maps', label: `${lang.GAME} 1`, render: this.renderPrizeMap(1), width: 80},
            {path: 'geo_prize_maps', label: `${lang.GAME} 2`, render: this.renderPrizeMap(2), width: 80},
            {path: 'geo_prize_maps', label: `${lang.GAME} 3`, render: this.renderPrizeMap(3), width: 80},
            {
              name: 'from',
              path: 'from',
              label: lang.START_DATE_COLUMN,
              render: (item) => this.renderDate(item),
              sortable: true,
              width: 110,
            },
            {
              name: 'to',
              path: 'to',
              label: lang.END_DATE_COLUMN,
              render: (item) => this.renderDate(item),
              sortable: true,
              width: 110,
            },
            {path: 'participations_count', label: lang.PARTICIPATIONS_COLUMN, render: this.renderText, width: 110},
            {name: 'test_mode', label: lang.TEST_MODE_COLUMN, render: this.renderTestMode, width: 95},
            {
              name: 'current_status',
              path: 'current_status',
              label: lang.STATUS_1_COLUMN,
              render: this.renderStatus1,
              sortable: true,
              width: 95,
            },
            {
              name: 'active',
              path: 'active',
              label: lang.STATUS_2_COLUMN,
              render: this.renderStatus2,
              sortable: true,
              width: 95,
            },
            {path: 'place.id', render: this.renderActions},
          ]}
        />
        <PaginationBar
          data={data}
          perPage={this.props.perPage}
          totalPages={meta.total_pages}
          totalItems={meta.total_count}
          currentPage={meta.current_page}
          onPageChange={onPageChange}
        />
      </div>
    );
  }
}

export default connect(({languageState}) => ({
  lang: languageState.payload.AUTOTASK_PLAN.TABLE,
  langDevice: languageState.payload.DEVICES,
  langInterface: languageState.payload.INTERFACE_TEMPLATES.TABLE,
}))(AtPlanDataGrid);
