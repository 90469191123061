import React, {useEffect, useMemo, useState} from 'react';

import bem from 'client/services/bem';
import {useLanguage, useReduxFetch} from 'client/services/hooks';
import {apiAction} from 'client/services/hooks/use-redux-fetch/requests';

import {updateClientContact} from 'client/ducks/client-account/actions';

import AppButton from 'client/common/buttons';
import {API_METHODS} from 'client/common/config';
import Modal from 'client/common/modals/modal';
import SelectDropdown from 'client/common/selects/select-dropdown';

import Spinner from 'client/components/common/spinner';

import {Client} from 'client/models/client/types';
import {Membership} from 'client/models/memberships/types';

import cssModule from './update-agency-contact-modal.module.scss';

const b = bem('update-agency-contact-modal', {cssModule});

type DropdownOption = {
  label: string;
  value: number;
};

type UpdateAgencyContactModalProps = {
  client: Client;
  onClose: () => void;
  onUpdate: () => void;
};

const UpdateAgencyContactModal: React.FC<UpdateAgencyContactModalProps> = (props) => {
  const {client, onClose, onUpdate} = props;

  const lang = useLanguage('ACCOUNT_PARAMETERS.CONTACT.UPDATE_AGENCY_CONTACT_MODAL');

  const [selectedOption, setSelectedOption] = useState<number | null>(null);

  const {data: agencyMembershipsData, loading: isAgencyMembershipsLoading} = useReduxFetch<{
    agency_memberships: Membership[];
  }>({
    action: apiAction,
    actionArgs: {
      url: `${API_METHODS.AGENCY_MEMBERSHIPS}`,
      queryParams: {
        q: {
          client_id_eq: client.agency_id,
          g: {
            0: {
              all_companies_access_true: true,
              company_accesses_company_id_eq: client.id,
              m: 'or',
            },
          },
        },
        distinct: true,
      },
    },
  });

  const {fetch: fetchUpdateClientContact} = useReduxFetch<Membership[]>({
    action: updateClientContact,
    fetchOnMount: false,
    toasts: {
      success: {
        title: lang.SUCCESS_MESSAGE,
      },
      error: {
        title: lang.ERROR_MESSAGE,
      },
    },
  });

  const options = useMemo<DropdownOption[]>(() => {
    return (
      agencyMembershipsData?.agency_memberships?.map(({id, client_user: user}) => ({
        label: `${user?.first_name} ${user?.last_name}`,
        value: id,
      })) || []
    );
  }, [agencyMembershipsData?.agency_memberships]);

  const handleConfirmButtonClick = async () => {
    if (selectedOption) {
      onClose();
      const {payload} = await fetchUpdateClientContact(client.id, selectedOption);
      if (payload) {
        onUpdate();
      }
    }
  };

  useEffect(() => {
    if (options.length) {
      let currentValue = options.find(({value}) => value === client.poc_agency_membership_id)?.value;
      if (!currentValue) {
        currentValue = options[0].value;
      }
      setSelectedOption(currentValue);
    }
  }, [client, options]);

  return (
    <Modal className={b()} title={lang.TITLE} onClose={onClose} isCloseHidden={true}>
      {isAgencyMembershipsLoading ? (
        <Spinner color="primary" centered={true} />
      ) : (
        <SelectDropdown
          className={b('dropdown')}
          labelClassName={b('dropdown-label')}
          label={lang.DROPDOWN_LABEL}
          options={options}
          value={selectedOption}
          onChange={setSelectedOption}
          simpleValue={true}
        />
      )}
      <div className={b('buttons')}>
        <AppButton label={lang.CANCEL_BUTTON} transparent={true} onClick={onClose} />
        <AppButton
          label={lang.CONFIRM_BUTTON}
          color="primary"
          onClick={handleConfirmButtonClick}
          disabled={isAgencyMembershipsLoading || !selectedOption || selectedOption === client.poc_agency_membership_id}
        />
      </div>
    </Modal>
  );
};

export default UpdateAgencyContactModal;
