import {useCallback, useEffect, useState} from 'react';

import isEmpty from 'lodash/isEmpty';
import {useDispatch, useSelector} from 'react-redux';
import {useUpdateEffect} from 'react-use';

import {getTemplateForm} from 'client/ducks/templates/actions';
import {
  selectIsFetchingResult,
  selectModifiedFormItems,
  selectResult,
  selectTemplateForm,
} from 'client/ducks/templates/selectors';
import {selectUser} from 'client/ducks/user/selectors';

import {useFormElementVisibilityCheck} from 'client/components/diy-operation/modals/diy-customization-modal/diy-customization-form/useFormElementVisibleCheck';
import {getNotHideCount} from 'client/components/diy-operation/modals/diy-customization-modal/helpers';
import {CustomizationForm, CustomizationFormPage} from 'client/models/diy-customization-form/types';
import {Template} from 'client/models/templates/types';
import {ApiDispatch} from 'client/types';

const DEFAULT_LABELS = {
  title: '',
  help: '',
};

type UseFetchCustomizationFormParams = {
  formId?: number;
  clientId: number;
  template?: Template;
  online: boolean;
  device: boolean;
  disabled?: boolean;
};

const useFetchCustomizationForm = (config: UseFetchCustomizationFormParams) => {
  const {formId, template, online, clientId, disabled, device} = config;

  const form: CustomizationForm = useSelector(selectTemplateForm);
  const {locale: lang} = useSelector(selectUser);
  const result = useSelector(selectResult);
  const loadingResult = useSelector(selectIsFetchingResult);
  const modifiedFormItems = useSelector(selectModifiedFormItems);
  const templateId = template?.id;

  const dispatch: ApiDispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(0);
  const [formPages, setFormPages] = useState<CustomizationFormPage[]>([]);
  const [pagesAccesses, setPagesAccesses] = useState<CustomizationFormPage[]>([]);
  const [loading, setLoading] = useState(false);

  const checkFormItem = useFormElementVisibilityCheck({template, online, disabled, device});

  const page = formPages[currentPage];
  const pageLabels = page?.form_labels?.find((labels) => labels.language === lang) || DEFAULT_LABELS;
  const pageTitles = formPages
    .map((el) => el?.form_labels?.find((labels) => labels.language === lang) || DEFAULT_LABELS)
    .map((a) => ({
      title: a.title,
    }));

  const headerInfo = {
    labels: pageLabels,
    titles: pageTitles,
    totalSteps: formPages.length,
  };

  const updateVisiblePages = useCallback(
    (newForm: CustomizationForm) => {
      const visiblePages =
        newForm?.form_pages?.filter((formPage) => {
          const {visible} = checkFormItem(formPage, {lang: '', device: ''});

          const visibleFormItemsCount =
            formPage?.form_sections.reduce((acc, section) => {
              return section.default_hide ? acc : acc + getNotHideCount(section.form_items);
            }, 0) || 0;

          return visible && (visibleFormItemsCount > 0 || !isEmpty(formPage?.form_preview));
        }) ?? [];

      setFormPages(visiblePages);
    },
    [checkFormItem],
  );

  const fetchForm = useCallback(async () => {
    const params = {
      template_id: templateId,
      client_id: clientId,
    };
    setLoading(true);
    const promise = await dispatch<{form: CustomizationForm}>(getTemplateForm(formId, params));

    updateVisiblePages(promise.payload.form);
    setPagesAccesses(promise.payload.form?.form_pages);

    setLoading(false);
    // fetch the form only when some of form specific params are changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, dispatch, formId, templateId]);

  useEffect(() => {
    fetchForm();
  }, [fetchForm]);

  useUpdateEffect(() => {
    // revalidate visible pages when form is updated
    updateVisiblePages(form);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifiedFormItems]);

  return {
    form,
    templateId,
    checkFormItem,
    result,
    loadingResult,
    page,
    pageLabels,
    formPages,
    setFormPages,
    currentPage,
    setCurrentPage,
    loading,
    headerInfo,
    pagesAccesses,
    setPagesAccesses,
  };
};
export default useFetchCustomizationForm;
