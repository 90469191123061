import React from 'react';

import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';
import {getCurrentTimezone} from 'client/services/utils/date';

import {selectOperation} from 'client/ducks/operations/selectors';

import DatetimePickerField from 'client/common/fields/datetime-picker-field';

import cssModule from './prize-customization-campaign-period.module.scss';

const b = bem('prize-customization-campaign-period', {cssModule});

type PrizeCustomizationCampaignPeriodProps = {
  editable?: boolean;
};

const PrizeCustomizationCampaignPeriod: React.FC<PrizeCustomizationCampaignPeriodProps> = (props) => {
  const {editable} = props;
  const lang = useLanguage('PRIZES.FIELDS.PRIZE_CUSTOMIZATION_CAMPAIGN_PERIOD');

  const operation = useSelector(selectOperation);
  const timezone = operation?.timezone || getCurrentTimezone();

  return (
    <div className={b()}>
      <p className={b('title')}>{lang.TITLE}</p>
      <div className={b('period')}>
        <DatetimePickerField
          className={b('input')}
          label={lang.FROM_LABEL}
          name="from"
          disabled={!editable}
          required={true}
          timezone={timezone}
        />
        <DatetimePickerField
          className={b('input')}
          label={lang.TO_LABEL}
          name="to"
          disabled={!editable}
          required={true}
          timezone={timezone}
        />
      </div>
    </div>
  );
};

export default PrizeCustomizationCampaignPeriod;
