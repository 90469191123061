import React, {useEffect} from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useToggle, useUnmount} from 'react-use';

import {useLanguage} from 'client/services/hooks';
import {parseDate} from 'client/services/utils/date';

import {fetchCampaigns, createCampaign, clearCampaignsState} from 'client/ducks/campaigns/actions';
import {selectCampaigns} from 'client/ducks/campaigns/selectors';
import {createOnlineInteraction} from 'client/ducks/interactions/actions';

import Icon from 'client/components/common/icon';

import {PRIZE_MAP_TYPES, PRIZE_MAP_LEVEL_TYPES} from 'client/components/games/game-config-modal/constants';
import PrizeMapCard from 'client/components/games/prize-map-card';

import CampaignList from './components/lists/campaign-list';
import AtNewCampaignModal from './components/modals/at-new-campaign-modal';

import AutotaskCard from '../components/cards/autotask-card/autotask-card';

const AutotaskCampaigns = (props) => {
  const {autotask, archived, autotaskId} = props;

  const [showCreationModal, toggleShowCreationModal] = useToggle(false);
  const lang = useLanguage('AUTOTASK');
  const dispatch = useDispatch();
  const campaigns = useSelector(selectCampaigns);

  useEffect(() => {
    dispatch(
      fetchCampaigns({
        q: {
          s: 'updated_at DESC',
          automation_task_id_eq: autotaskId,
        },
      }),
    );
  }, [autotaskId, dispatch]);

  useUnmount(() => dispatch(clearCampaignsState()));

  const handleCampaignCreation = (values) => {
    dispatch(
      createCampaign({
        ...values,
        automation_task_id: autotaskId,
        from: parseDate(autotask.operation.from, {
          timezone: autotask.operation.timezone,
          timezoneForce: true,
          time: 'startDay',
        }),
        to: parseDate(autotask.operation.to, {
          timezone: autotask.operation.timezone,
          timezoneForce: true,
          time: 'endDay',
        }),
      }),
    ).then(({payload}) => {
      toggleShowCreationModal(false);

      const {campaign} = payload;
      if (campaigns.length === 0 && campaign) {
        dispatch(
          createOnlineInteraction({
            interaction_group_id: campaign.id,
            interface_id: campaign.interface_id,
            direct_access: true,
            embedding_type: 'minisite',
            kind: 'minisite',
            name: lang.DIRECT_ACCESS.toString(),
            from: parseDate(autotask.operation.from, {
              timezone: autotask.operation.timezone,
              timezoneForce: true,
              time: 'startDay',
            }),
            to: parseDate(autotask.operation.to, {
              timezone: autotask.operation.timezone,
              timezoneForce: true,
              time: 'endDay',
            }),
          }),
        );
      }
    });
  };

  return (
    <AutotaskCard
      title={lang.CAMPAIGN_CARD_TITLE}
      header={
        <button
          className={'button pull-right button--bg-6' + (archived ? ' hidden' : '')}
          onClick={toggleShowCreationModal}
        >
          <Icon name="plus" className="button__icon" />
          <span>{lang.ADD_CAMPAIGN_BUTTON}</span>
        </button>
      }
      showPagination={false}
      aboveHeader={
        <PrizeMapCard
          levelKey="ONLINE_GLOBAL"
          autotask={autotask}
          prizeMapParams={{
            type_eq: PRIZE_MAP_TYPES.ONLINE_PRIZE_MAP,
            online_level_eq: PRIZE_MAP_LEVEL_TYPES.GLOBAL,
          }}
          displayParams={get(autotask, 'prize_maps_present.online')}
        />
      }
    >
      <CampaignList items={campaigns} />

      {showCreationModal && <AtNewCampaignModal onClose={toggleShowCreationModal} onSubmit={handleCampaignCreation} />}
    </AutotaskCard>
  );
};

AutotaskCampaigns.propTypes = {
  archived: PropTypes.bool,
  autotaskId: PropTypes.number.isRequired,
  autotask: PropTypes.object.isRequired,
};

export default AutotaskCampaigns;
