export default {
  CREATE_ONLINE_INTERACTION_REQUEST: '@@interactions/CREATE_ONLINE_INTERACTION_REQUEST',
  CREATE_ONLINE_INTERACTION_SUCCESS: '@@campaigns/CREATE_ONLINE_INTERACTION_SUCCESS',
  CREATE_ONLINE_INTERACTION_ERROR: '@@campaigns/CREATE_ONLINE_INTERACTION_ERROR',

  UPDATE_ONLINE_INTERACTION_REQUEST: '@@interactions/UPDATE_ONLINE_INTERACTION_REQUEST',
  UPDATE_ONLINE_INTERACTION_SUCCESS: '@@interactions/UPDATE_ONLINE_INTERACTION_SUCCESS',
  UPDATE_ONLINE_INTERACTION_ERROR: '@@interactions/UPDATE_ONLINE_INTERACTION_ERROR',

  UPDATE_OFFLINE_INTERACTION_REQUEST: '@@interactions/UPDATE_OFFLINE_INTERACTION_REQUEST',
  UPDATE_OFFLINE_INTERACTION_SUCCESS: '@@interactions/UPDATE_OFFLINE_INTERACTION_SUCCESS',
  UPDATE_OFFLINE_INTERACTION_ERROR: '@@interactions/UPDATE_OFFLINE_INTERACTION_ERROR',

  CREATE_OFFLINE_INTERACTION_REQUEST: '@@interactions/CREATE_OFFLINE_INTERACTION_REQUEST',
  CREATE_OFFLINE_INTERACTION_SUCCESS: '@@interactions/CREATE_OFFLINE_INTERACTION_SUCCESS',
  CREATE_OFFLINE_INTERACTION_ERROR: '@@interactions/CREATE_OFFLINE_INTERACTION_ERROR',

  FETCH_ONLINE_INTERACTIONS_REQUEST: '@@interactions/FETCH_ONLINE_INTERACTIONS_REQUEST',
  FETCH_ONLINE_INTERACTIONS_SUCCESS: '@@interactions/FETCH_ONLINE_INTERACTIONS_SUCCESS',
  FETCH_ONLINE_INTERACTIONS_ERROR: '@@interactions/FETCH_ONLINE_INTERACTIONS_ERROR',

  FETCH_OFFLINE_INTERACTIONS_REQUEST: '@@interactions/FETCH_OFFLINE_INTERACTIONS_REQUEST',
  FETCH_OFFLINE_INTERACTIONS_SUCCESS: '@@interactions/FETCH_OFFLINE_INTERACTIONS_SUCCESS',
  FETCH_OFFLINE_INTERACTIONS_ERROR: '@@interactions/FETCH_OFFLINE_INTERACTIONS_ERROR',

  DELETE_ONLINE_INTERACTION_REQUEST: '@@interactions/DELETE_ONLINE_INTERACTION_REQUEST',
  DELETE_ONLINE_INTERACTION_SUCCESS: '@@interactions/DELETE_ONLINE_INTERACTION_SUCCESS',
  DELETE_ONLINE_INTERACTION_ERROR: '@@interactions/DELETE_ONLINE_INTERACTION_ERROR',

  FETCH_SPREAD_PLAN_INTERACTIONS_REQUEST: '@@interactions/FETCH_SPREAD_PLAN_INTERACTIONS_REQUEST',
  FETCH_SPREAD_PLAN_INTERACTIONS_SUCCESS: '@@interactions/FETCH_SPREAD_PLAN_INTERACTIONS_SUCCESS',
  FETCH_SPREAD_PLAN_INTERACTIONS_ERROR: '@@interactions/FETCH_SPREAD_PLAN_INTERACTIONS_ERROR',

  FETCH_PLACES_WITH_DEVICES_REQUEST: '@@interactions/FETCH_PLACES_WITH_DEVICES_REQUEST',
  FETCH_PLACES_WITH_DEVICES_SUCCESS: '@@interactions/FETCH_PLACES_WITH_DEVICES_SUCCESS',
  FETCH_PLACES_WITH_DEVICES_ERROR: '@@interactions/FETCH_PLACES_WITH_DEVICES_ERROR',

  SAVE_OFFLINE_INTERACTION_BULK_REQUEST: '@@interactions/SAVE_OFFLINE_INTERACTION_BULK_REQUEST',
  SAVE_OFFLINE_INTERACTION_BULK_SUCCESS: '@@interactions/SAVE_OFFLINE_INTERACTION_BULK_SUCCESS',
  SAVE_OFFLINE_INTERACTION_BULK_ERROR: '@@interactions/SAVE_OFFLINE_INTERACTION_BULK_ERROR',
} as const;
