import {transformDate} from 'client/services/helpers';
import {parseDate} from 'client/services/utils/date';

import {SCHEDULE_TABS} from 'client/components/prizes/fieldsets/prize-diy-schedule-fieldset/constants';

export default ({weeklyList, dates_off}, {timezone}) => {
  const {timestamps, configurationId, initialTimestamps} = weeklyList;
  const timeStampsToSubmit = initialTimestamps.map(({id}) => ({id, _destroy: true}));
  Object.entries(timestamps).forEach((entry) => {
    const [weekday, timeStamps] = entry;

    timeStamps.forEach(({value}) => {
      timeStampsToSubmit.push({
        win_time: parseDate(new Date().toISOString(), {
          time: value,
          timezone,
          timezoneForce: true,
        }),
        weekday,
      });
    });
  });

  return {
    schedule_instant_win_configuration: {
      dates_off: dates_off && dates_off.map((date) => transformDate(date.value, false)),
      prize_timestamps: timeStampsToSubmit,
      schedule_type: SCHEDULE_TABS.WEEKLY_LIST,
      id: configurationId,
      intraday_win: weeklyList?.intraday_win,
      next_day_win: weeklyList?.next_day_win,
    },
  };
};
