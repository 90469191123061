import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';

import Icon from 'client/common/icon';

import {TaskNameProps} from './types';

import cssModule from './task-name.module.scss';

const b = bem('task-name', {cssModule});

const TaskName: React.FC<TaskNameProps> = (props) => {
  const {name, handleBackClick, className = '', classNames = {}} = props;

  return (
    <div className={cn(b(), className)}>
      <span className={cn(b('task-name'), classNames?.taskName)} title={name}>
        <button className={cn(b('back'), classNames?.buttonBack)} type="button" onClick={handleBackClick}>
          <Icon name="arrow-left" className={b('back-icon')} />
        </button>
        {name}
      </span>
    </div>
  );
};
export default TaskName;
