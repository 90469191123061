import { parseDate } from 'client/services/utils/date/parseDate';
import moment from 'moment-timezone';

import {PRIZE_COUPON_VALIDITY_TYPES} from 'client/components/prizes/constants';
import {Coupon, Prize} from 'client/models/prizes/types';

const getValidityType = (coupon: Coupon | Prize) => {
  if (!coupon?.validity_duration && !coupon?.validity_from && !coupon?.validity_to) {
    return PRIZE_COUPON_VALIDITY_TYPES.NONE;
  }
  return coupon.validity_duration ? PRIZE_COUPON_VALIDITY_TYPES.DURATION : PRIZE_COUPON_VALIDITY_TYPES.FIXED_VALUE;
};

export default (coupon: Coupon | Prize | null, isCoupon = false, {timezone}: {timezone: string}) => {
  const defaultValidityType = isCoupon ? PRIZE_COUPON_VALIDITY_TYPES.FIXED_VALUE : PRIZE_COUPON_VALIDITY_TYPES.NONE;
  return {
    validity_type: coupon ? getValidityType(coupon) : defaultValidityType,
    validity_days: coupon?.validity_days?.toString(),
    valid_from: parseDate(coupon?.validity_from, {timezone})
      || parseDate(moment().toISOString(), {timezone, time: 'startDay'}),
    valid_to: parseDate(coupon?.validity_to, {timezone})
      || parseDate(moment().add(1, 'days').toISOString(), {timezone, time: 'endDay'}),
  };
};
