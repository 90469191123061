import React, {useRef, useCallback, useState, useLayoutEffect, useMemo} from 'react';

import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import {selectTemplateForm} from 'client/ducks/templates/selectors';

import NumberInput from 'client/common/inputs/number-input';
import TextareaInput from 'client/common/inputs/textarea-input';
import TextEditor from 'client/common/text-editor';
import {DEFAULT_FONT_SIZE, FONT_FAMILY_OPTIONS} from 'client/common/text-editor/constants';
import StaticToolbar from 'client/common/text-editor/static-toolbar';

import useClientFonts from 'client/components/diy-operation/modals/diy-customization-modal/useClientFonts';
import {TranslationJsx} from 'client/models/language/types';

const DEFAULT_LINE_HEIGHT = parseInt(DEFAULT_FONT_SIZE, 10) * 1.5;

const TextInputItem = (props) => {
  const {
    type,
    errorMessage,
    displayStyling,
    defaultStyle,
    isLinesLimited = false,
    displayVariables,
    variables,
    rows = 1,
    ...inputProps
  } = props;
  const {onChange, width = '100%'} = inputProps;

  const ref = useRef(null);

  const form = useSelector(selectTemplateForm);

  const {fontNames = []} = useClientFonts();
  const fontFamilyOptions = [...fontNames.sort(), ...FONT_FAMILY_OPTIONS];

  const [tempValue, setTempValue] = useState();

  const applyStyles = (element, styles) => {
    Object.assign(element.style, styles);
  };

  const clearStyles = useMemo(
    () => ({
      display: '',
      webkitBoxOrient: '',
      webkitLineClamp: '',
      wordBreak: '',
      webkitBoxDecorationBreak: '',
    }),
    [],
  );

  const setStyles = useMemo(
    () => ({
      display: '-webkit-box',
      webkitBoxOrient: 'vertical',
      webkitLineClamp: rows,
      wordBreak: 'break-all',
      webkitBoxDecorationBreak: 'clone',
    }),
    [rows],
  );

  const handleChange = useCallback(
    (value) => {
      if (isLinesLimited) {
        setTempValue(value);
      }

      onChange(value);
    },
    [isLinesLimited, onChange],
  );

  const debouncedChangeHandler = debounce(handleChange, 500);

  // resizing logic is made in useLayoutEffect instead of changeHandler to avoid event pooling
  // that prevents the box resizing on font change until content is edited.
  // try to move the logic to handleChange function in react > 16
  useLayoutEffect(() => {
    if (isLinesLimited && ref.current) {
      ref.current.style.height = '';
      const editable = ref.current.firstElementChild;

      applyStyles(editable, setStyles);

      const defaultHeight = rows * DEFAULT_LINE_HEIGHT + 16; // 16px is the sum of paddings of the text editor

      const finalHeight = editable.offsetHeight < defaultHeight ? defaultHeight : editable.offsetHeight;
      ref.current.style.height = `${finalHeight}px`;

      applyStyles(editable, clearStyles);
    }
  }, [isLinesLimited, rows, clearStyles, setStyles, defaultStyle, tempValue]);

  if (type === 'number') {
    return <NumberInput errorMessage={errorMessage} {...inputProps} />;
  }

  if (!displayStyling && !displayVariables) {
    return <TextareaInput {...inputProps} errorMessage={errorMessage} />;
  }

  return (
    <TextEditor
      {...inputProps}
      onChange={debouncedChangeHandler}
      key={defaultStyle} // re-render the editor when the default style changes to reinitialize the editor
      errorMessage={errorMessage}
      isToolbarVisible={displayStyling && !defaultStyle && !inputProps.disabled}
      editableRef={ref}
      isLinesLimited={isLinesLimited}
      editableStyle={{width}}
      variables={displayVariables ? variables : null}
    >
      <StaticToolbar colorsAccessKey={`common-colors-configuration-${form.id}`} fontFamilyOptions={fontFamilyOptions} />
    </TextEditor>
  );
};

TextInputItem.propTypes = {
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.oneOfType([PropTypes.array, TranslationJsx]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired,
  isLinesLimited: PropTypes.bool,
  width: PropTypes.string,
  rows: PropTypes.number,
};

TextInputItem.defaultProps = {
  disabled: false,
  errorMessage: '',
};

export default TextInputItem;
