import mapCustomListFieldset from 'client/components/prizes/fieldsets/prize-diy-custom-list-fieldset/mapFormValues';
import mapRatioParticipationFieldset from 'client/components/prizes/fieldsets/prize-diy-ratio-participation-fieldset/mapFormValues';
import mapRatioTimeFieldset from 'client/components/prizes/fieldsets/prize-diy-ratio-time-fieldset/mapFormValues';
import {
  SCHEDULE_TABS,
  SCHEDULE_TYPE_FIELD_NAME,
} from 'client/components/prizes/fieldsets/prize-diy-schedule-fieldset/constants';
import mapWeekScheduleFieldset from 'client/components/prizes/fieldsets/prize-diy-week-schedule-fieldset/mapFormValues';
import mapWeeklyListFieldset from 'client/components/prizes/fieldsets/prize-diy-weekly-list-fieldset/mapFormValues';

import {INSTANT_WIN_TABS, INSTANT_WIN_TYPE_FIELD_NAME} from './constants';

const scheduleFormValuesMappers = {
  [SCHEDULE_TABS.CUSTOM_LIST]: mapCustomListFieldset,
  [SCHEDULE_TABS.WEEKLY_LIST]: mapWeeklyListFieldset,
  [SCHEDULE_TABS.SCHEDULE]: mapWeekScheduleFieldset,
};

const instantWinFormValuesMappers = {
  [INSTANT_WIN_TABS.RATIO_TIME]: mapRatioTimeFieldset,
  [INSTANT_WIN_TABS.RATIO_PARTICIPATION]: mapRatioParticipationFieldset,
};

export default (values, params) => {
  // get mapper for active schedule type
  instantWinFormValuesMappers[INSTANT_WIN_TABS.SCHEDULE] = scheduleFormValuesMappers[values[SCHEDULE_TYPE_FIELD_NAME]];

  // get mapper for active instant win type
  const chosenInstantWinValuesMapper = instantWinFormValuesMappers[values[INSTANT_WIN_TYPE_FIELD_NAME]];

  return chosenInstantWinValuesMapper(values, params);
};
