import cloneDeep from 'lodash/cloneDeep';

import {GetPrizeMapsLevelType} from 'client/ducks/games/actions';

import {isEditablePrizeMap} from 'client/components/prizes/helpers';
import {PrizeMap} from 'client/models/prizes/types';

import {PrizeCustomizationModalForm} from './types';

import {parseDate} from 'client/services/utils/date';
const ADMIN_COUPON_TYPES = ['AppCoupon', 'SogecCoupon', 'WeezioCoupon'];

type InitialParams = {
  level: GetPrizeMapsLevelType;
  sourceIds: number[];
  timezone: string;
};

export default (prizeMaps: PrizeMap[], params: InitialParams): PrizeCustomizationModalForm => {
  const {level, sourceIds, timezone} = params;

  const initialPrizeMaps = prizeMaps
    ?.filter(({prize}) => !ADMIN_COUPON_TYPES.includes(prize?.coupon?.type || ''))
    ?.sort((a, b) => (a.prize?.order ?? 0) - (b.prize?.order ?? 0))
    ?.reduce<PrizeCustomizationModalForm['prizeMaps']>((acc, prizeMap) => {
      const {prize, ...restPrizeMapParams} = prizeMap;
      const {type = 'Prize', coupon, total_quantities, default_stock_offset} = prize || {};

      const prev = cloneDeep(acc[acc.length - 1]);

      if (prev && prev.prize_id === prizeMap.prize_id && prev.prize && prizeMap.prize) {
        prev.current_stock += prizeMap.current_stock;
        prev.initial_stock += prizeMap.initial_stock;
        prev.stock_offset! += prizeMap.stock_offset || default_stock_offset || 0;
        acc[acc.length - 1] = prev;
        return acc;
      }

      acc.push(
        cloneDeep({
          ...restPrizeMapParams,
          prize,
          prize_type: type,
          editable: isEditablePrizeMap(prizeMap, sourceIds, level),
          coupon: coupon?.internal_name || '',
          coupon_type_name: coupon?.type || '',
          total_prize_won_validated: total_quantities?.total_prize_won_validated || 0,
          stock_offset: prizeMap.stock_offset || default_stock_offset || 0,
        }),
      );
      return acc;
    }, []);

  const firstPrizeMap = initialPrizeMaps[0];
  return {
    prizeMaps: initialPrizeMaps,
    from: parseDate(firstPrizeMap?.from, {timezone}) || '',
    to: parseDate(firstPrizeMap?.to, {timezone}) || '',
    disabledTime: !firstPrizeMap?.time_editable || false,
  };
};
