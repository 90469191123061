import React, {useCallback, useEffect, useMemo, useState} from 'react';

import uniqBy from 'lodash/uniqBy';
import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';
import useReduxFetch from 'client/services/hooks/use-redux-fetch';

import {getClientMemberships} from 'client/ducks/client-account/actions';
import {selectClientMemberships} from 'client/ducks/client-account/selectors';
import {ACCESS_LEVEL_TYPES} from 'client/ducks/client-users/constants';
import {selectCurrentClient} from 'client/ducks/clients-list/selectors';
import {selectOperation} from 'client/ducks/operations/selectors';

import AppButton from 'client/common/buttons';
import SearchInput from 'client/common/inputs/search-input';
import Modal from 'client/common/modals/modal';
import PaginationBar from 'client/common/paginations/pagination-bar';

import ClientTable from 'client/components/common/client-table';

import {ClientAccessLevelType} from 'client/models/common/types';
import {LeadsExportReport} from 'client/models/leads-export/types';
import {Membership} from 'client/models/memberships/types';
import {ParticipationExportReport} from 'client/models/participation-export/types';
import {ApiAction, MainStates} from 'client/types';
import {PaginationMeta} from 'client/types/common/index';

import cssModule from './share-file-access-modal.module.scss';

type ShareFileAccessModalProps = {
  onClose: () => void;
  onSave: () => void;
  fileExportId: number;
  actionShare: ApiAction;
  actionGetReport: ApiAction;
  selectReport: (state: MainStates) => any;
};

const b = bem('share-file-access-modal', {cssModule});

const PER_PAGE = 7;

const searchParamName = ['client_user_email', 'client_user_first_name', 'client_user_last_name'].join('_or_') + '_cont';

const ShareFileAccessModal: React.FC<ShareFileAccessModalProps> = ({
  onClose,
  onSave,
  fileExportId,
  actionShare,
  actionGetReport,
  selectReport,
}) => {
  const [{page}, setPagination] = useState({page: 1, perPage: PER_PAGE});
  const [search, setSearch] = useState('');

  const [checkedRows, setCheckedRows] = useState<Membership[]>([]);
  const [initedChecked, setInitedChecked] = useState<Membership[]>([]);
  const [uncheckedRows, setUnheckedRows] = useState<Membership[]>([]);
  const lang = useLanguage('DATA_TAB.SHARE_FILE_ACCESS_MODAL');
  const langAccountParameters = useLanguage('ACCOUNT_PARAMETERS.USERS');

  const client = useSelector(selectCurrentClient);
  const {client_id} = useSelector(selectOperation);

  const type = client && client?.type !== 'Company' ? 'agency' : 'company';

  const {
    loading: loadingReportData,
    data: reportData,
    fetch: fetchReportData,
  } = useReduxFetch<ParticipationExportReport | LeadsExportReport>({
    action: (id) =>
      actionGetReport(id, {
        include: {
          transferable_memberships: {membership: {client_user: null}},
          participation_export: null,
          lead_transfer: null,
        },
      }),
    actionArgs: fileExportId,
    selector: selectReport,
  });

  const {loading, data} = useReduxFetch<{data: Membership[]; meta: PaginationMeta}>({
    action: getClientMemberships,
    selector: selectClientMemberships,
    actionArgs: {
      type,
      queryParams: {
        q: {
          client_id_eq: client?.id || client_id,
          client_user_id_gt: 0, // to get regular users only and avoid prize tracking users
          [searchParamName]: search,
        },
        page: page,
        per_page: PER_PAGE,
      },
    },
  });

  const allowedForAll =
    (reportData as ParticipationExportReport)?.participation_export?.allowed_for_all ||
    (reportData as LeadsExportReport)?.lead_transfer?.allowed_for_all;

  const isCheckedForever = useCallback(
    (membership: Membership) =>
      allowedForAll || reportData?.shareable || membership.client_user?.id === reportData?.user_id,
    [allowedForAll, reportData?.user_id, reportData?.shareable],
  );

  const initMemberships = useMemo(
    () =>
      loadingReportData
        ? []
        : ([
            ...((reportData?.transferable_memberships?.map((t) => t.membership).filter(Boolean) as Membership[]) || []),
            ...(data?.data.filter(isCheckedForever) || []),
          ].filter(Boolean) as Membership[]),
    [loadingReportData, data?.data, isCheckedForever, reportData?.transferable_memberships],
  );

  const {fetch, loading: loadingSave} = useReduxFetch({
    action: actionShare,
    fetchOnMount: false,
  });

  useEffect(() => {
    if (initMemberships && !loadingReportData) {
      setInitedChecked((prev) => uniqBy([...prev, ...initMemberships], (m) => m.id));
      setCheckedRows((prev) => uniqBy([...prev, ...initMemberships], (m) => m.id));
    }
  }, [initMemberships, loadingReportData]);

  const onCheck = (state: boolean, item: Membership) => {
    const initIsChecked = initedChecked.find((m) => m.id === item.id);
    if (state) {
      setCheckedRows((prev) => uniqBy([...prev, item], (m) => m.id));
      if (initIsChecked) {
        setUnheckedRows((prev) => prev.filter((row) => row.id !== item.id));
      }
    } else {
      setCheckedRows((prev) => prev.filter((row) => row.id !== item.id));
      if (initIsChecked) {
        setUnheckedRows((prev) => uniqBy([...prev, item], (m) => m.id));
      }
    }
  };

  const onCheckAll = (state: boolean) => {
    if (state) {
      setCheckedRows((prev) => uniqBy([...(data?.data || []), ...prev], (m) => m.id));
      setUnheckedRows((prev) => prev.filter((m) => !initedChecked.some((init) => init.id === m.id)));
    } else {
      setCheckedRows(data?.data.filter(isCheckedForever) || []);
      setUnheckedRows(initedChecked.filter((m) => !isCheckedForever(m)));
    }
  };

  const body = useMemo(() => {
    const uncheckedBody = uncheckedRows.map((m) => m.id);
    const checkedBody = checkedRows.filter((m) => !initedChecked.some((im) => im.id === m.id)).map((m) => m.id);

    return {
      unchecked: uncheckedBody,
      checked: checkedBody,
    };
  }, [checkedRows, initedChecked, uncheckedRows]);

  const onSubmit = async () => {
    await fetch({
      id:
        (reportData as LeadsExportReport)?.lead_transfer_id ||
        (reportData as ParticipationExportReport)?.participation_export_id,
      queryParams: {
        share_membership_ids: body.checked,
        unshare_membership_ids: body.unchecked,
      },
    });
    onClose();
    onSave();
    fetchReportData();
  };

  const accessLabels = {
    [ACCESS_LEVEL_TYPES.CLIENT_ADMIN]: langAccountParameters.CLIENT_ADMIN,
    [ACCESS_LEVEL_TYPES.NATIONAL]: langAccountParameters.NATIONAL,
    [ACCESS_LEVEL_TYPES.REGIONS]: langAccountParameters.REGIONS,
    [ACCESS_LEVEL_TYPES.STORES]: langAccountParameters.STORES,
    [ACCESS_LEVEL_TYPES.LOCAL]: langAccountParameters.LOCAL,
  };

  return (
    <Modal title={lang.TITLE} onClose={onClose} dialogClassName={b()}>
      <div className={b('search-bar')}>
        <SearchInput className={b('search-input')} onSearch={setSearch} placeholder={lang.SEARCH_PLACEHOLDER} />
        {!loading && (
          <span>
            {data?.meta?.total_count} {lang.RECORDS_FOUND}
          </span>
        )}
      </div>

      <ClientTable
        loading={loading || loadingReportData}
        columns={[
          {
            label: lang.NAME,
            path: 'client_user.full_name',
            width: 600,
            cellClassName: (membership: Membership) => b('cell', {disabled: isCheckedForever(membership)}),
          },
          {
            label: lang.ACCESS_LEVEL,
            path: 'access_level',
            cellClassName: (membership: Membership) => b('cell', {disabled: isCheckedForever(membership)}),
            render: ({value}) => accessLabels[value as ClientAccessLevelType],
          },
        ]}
        data={data?.data || []}
        commonCellClassName={b('table-cell')}
        commonHeaderClassNames={{th: b('table-header')}}
        onCheck={onCheck}
        checkedRows={checkedRows}
        onCheckAll={onCheckAll}
        checkboxProps={{
          inversionColor: true,
          color: 'primary',
        }}
        disableCheck={isCheckedForever}
        checkable
      />

      {!loading && (
        <PaginationBar
          totalPages={data?.meta.total_pages}
          totalItems={data?.meta.total_count}
          currentPage={data?.meta.current_page}
          data={data?.data || []}
          onPageChange={setPagination}
        />
      )}

      {!allowedForAll && !loadingReportData && !loading && (
        <AppButton
          label={lang.SAVE}
          size="large"
          className={b('button')}
          onClick={onSubmit}
          loading={loadingSave}
          disabled={!body.checked.length && !body.unchecked.length}
        />
      )}
    </Modal>
  );
};

export default ShareFileAccessModal;
