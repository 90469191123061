import React, {useMemo} from 'react';

import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {KPI_MODES, KPI_TYPES} from 'client/components/animations/constants';
import InstoreBarChart from 'client/components/instore/charts/instore-bar-chart';
import InstoreWeekDonutChart from 'client/components/instore/charts/instore-week-donut-chart';
import {TranslationJsx} from 'client/models/language/types';

import './animation-questionnaire-card-statistic.scss';

const b = bem('animation-questionnaire-card-statistic');

const AnimationQuestionnaireCardStatistic = ({data}) => {
  const lang = useLanguage('ANIMATIONS.CARDS.QUESTIONNAIRES');

  const sortedData = useMemo(() => {
    const result = filter(data?.data, 'answer');
    if (data?.mode === KPI_MODES.GRAPH.BAR) {
      return result;
    }

    return sortBy(result, 'value');
  }, [data]);

  const title = sortedData?.length ? data.title : lang.NO_DATA;

  if (!data) {
    return null;
  }

  return (
    <div className={b()}>
      <h3 className={b('title')}>{title}</h3>
      <div className={b('content')}>
        {data.mode === KPI_MODES.GRAPH.BAR ? (
          <InstoreBarChart data={sortedData} />
        ) : (
          <InstoreWeekDonutChart data={sortedData} />
        )}
      </div>
    </div>
  );
};

AnimationQuestionnaireCardStatistic.propTypes = {
  data: PropTypes.shape({
    title: TranslationJsx.isRequired,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,

    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.oneOf([KPI_TYPES.STAT, KPI_TYPES.GRAPH]).isRequired,
    mode: PropTypes.oneOf([KPI_MODES.STAT.TOTAL, KPI_MODES.STAT.AVERAGE, KPI_MODES.GRAPH.BAR, KPI_MODES.GRAPH.PIE])
      .isRequired,
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.number]).isRequired,
  }),
};

AnimationQuestionnaireCardStatistic.defaultProps = {
  data: null,
};

export default AnimationQuestionnaireCardStatistic;
