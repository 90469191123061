import React from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getFormValues, reduxForm} from 'redux-form';

import bem from 'client/services/bem';

import {createPrize, updatePrize, updatePrizeMap} from 'client/ducks/games/actions';

import {CheckboxField, NumberField, ToggleField} from 'client/common/fields';
import ConfirmationModal from 'client/common/modals/confirmation-modal';

import CustomScrollbars from 'client/components/common/custom-scrollbars';

import {GAME_TYPES} from 'client/components/games/game-config-modal/constants';

import {addTitleInstantWin} from './helpers';
import {getInitialValues} from './initial-values';
import {mappingValuesPrize, mappingValuesPrizeMap} from './mapping-values';
import PrizeInstantWinForm from './prize-form-instant-win';
import PrizeFormMain from './prize-form-main';
import {validate} from './validate';

import './prize-form.scss';

const b = bem('prize-form');

export class PrizeGameForm extends React.Component {
  static formName = 'PrizeForm';

  static propTypes = {
    game: PropTypes.object.isRequired,
    prize: PropTypes.object.isRequired,
    prevPrize: PropTypes.object,
    nextPrize: PropTypes.object,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    prizes: PropTypes.array.isRequired,
    isPrizeMap: PropTypes.bool.isRequired,
    autotask: PropTypes.object.isRequired,

    // from state:
    lang: PropTypes.object.isRequired,
    formValues: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
    createPrize: PropTypes.func.isRequired,
    updatePrize: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    updatePrizeMap: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    touch: PropTypes.func.isRequired,
  };

  static defaultProps = {
    formValues: {},
  };

  state = {
    showConfigInstant: false,
    confirmation: null,
  };

  componentDidMount() {
    const {change, isPrizeMap, touch} = this.props;
    const inited = getInitialValues(this.props.prize, this.props);
    if (!inited.instant_win_type && !isPrizeMap) {
      change('instant_win_type', 'time_instant_win');
      change('time_instant_win_configuration.time_unit', 'seconds');
      change('time_instant_win_configuration.units_number', '1');
    }
    if (inited.substitution) {
      inited.substitution.forEach((_, index) => {
        touch(`substitution[${index}].order`);
      });
    }
  }

  componentDidUpdate(prevProps) {
    const prevPrizeId = prevProps.prize && prevProps.prize.id;
    const nextPrizeId = this.props.prize && this.props.prize.id;

    if (prevPrizeId !== nextPrizeId) {
      this.props.initialize(getInitialValues(this.props.prize, this.props));
    }
  }

  save = () => {
    const {lang, prize, isPrizeMap} = this.props;
    if (!isPrizeMap && prize && prize.prize_maps_modified) {
      this.setState({
        confirmation: {
          message: lang.CONFIRM_SAVE_MESSAGE,
          cancelText: lang.CANCEL,
          confirmText: lang.CONFIRM_BUTTON,
          onClose: () => this.setState({confirmation: null}),
          onConfirm: this.confirmationSave,
        },
      });
    } else {
      this.confirmationSave();
    }
  };

  confirmationSave = async () => {
    const {formValues, game, prizes, prize, onSave, isPrizeMap, autotask} = this.props;

    const mapping = isPrizeMap ? mappingValuesPrizeMap : mappingValuesPrize;
    const body = mapping(
      {
        values: formValues,
        game,
        prizes,
        autotask,
      },
      prize,
    );

    if (prize) {
      if (isPrizeMap) {
        await this.props
          .updatePrizeMap(body.id, {
            prize_map: body,
          })
          .then(onSave);
      } else {
        await this.props
          .updatePrize(
            {
              prize: body,
            },
            body.id,
          )
          .then(onSave);
      }
    } else {
      await this.props
        .createPrize({
          prize: body,
        })
        .then(onSave);
    }
    this.setState({confirmation: null});
  };

  render() {
    const {lang, prize, game, isPrizeMap, formValues, prevPrize, nextPrize} = this.props;
    const {confirmation} = this.state;
    const isEdit = !!prize;
    let title = isEdit ? lang.EDIT_TITLE : lang.ADD_TITLE;
    title = isPrizeMap ? addTitleInstantWin(formValues.prize_internal_name, formValues, lang) : title;

    const fullWinIsVisible =
      game.game_type === GAME_TYPES.INSTANT_WIN &&
      ((!prevPrize && !nextPrize) || !nextPrize || prevPrize?.full_win || (!prevPrize?.full_win && nextPrize.full_win));
    const fullWinIsDisabled = isEdit && (!!prevPrize?.full_win || (nextPrize && !nextPrize?.full_win));

    return (
      <CustomScrollbars
        scrollbarProps={{
          autoHeightMax: 450,
        }}
        style={{
          height: '100%',
        }}
      >
        <div className={b()}>
          <div className={b('header')}>
            <h2 className={b('title')}>{title}</h2>
            {!isPrizeMap && (
              <div className={b('header-fields')}>
                {fullWinIsVisible && !this.state.showConfigInstant && (
                  <>
                    <ToggleField
                      name="full_win"
                      className={b('full-win')}
                      label={lang.FULL_WIN}
                      color="users"
                      disabled={fullWinIsDisabled}
                      labelPosition="right"
                    />
                    <div className={b('full-win-rate-field')}>
                      {formValues.full_win && <NumberField label={lang.FULL_WIN_RATE} name="full_win_ratio" />}
                    </div>
                  </>
                )}
                <NumberField label={lang.MIN_SCORE} name="level" className={b('level-field')} />
                <CheckboxField
                  label={lang.LOYALTY_ONLY}
                  name="loyalty_only"
                  color="games"
                  className={b('loyalty')}
                  classNames={{
                    visual: b('loyalty-visual'),
                    label: b('loyalty-label'),
                  }}
                />
              </div>
            )}
          </div>
          {this.state.showConfigInstant ? (
            <PrizeInstantWinForm
              prize={prize}
              game={game}
              isPrizeMap={isPrizeMap}
              goBack={() => this.setState({showConfigInstant: false})}
            />
          ) : (
            <PrizeFormMain
              game={game}
              prize={prize}
              onSave={this.save}
              onCancel={this.props.onCancel}
              openConfigInstant={() => this.setState({showConfigInstant: true})}
              isPrizeMap={isPrizeMap}
            />
          )}
        </div>
        {!!confirmation && (
          <ConfirmationModal
            show={!!confirmation}
            {...confirmation}
            className="theme-color-9 text-center"
            buttonConfirmClass="button--bg-4"
          />
        )}
      </CustomScrollbars>
    );
  }
}

const PrizeForm = reduxForm({
  form: PrizeGameForm.formName,
  validate,
})(PrizeGameForm);

export default connect(
  (state, props) => {
    return {
      lang: state.languageState.payload.GAMES.GAME_CONFIG_MODAL.PRIZE_FORM,
      formValues: getFormValues(PrizeGameForm.formName)(state),
      initialValues: getInitialValues(props.prize, props),
    };
  },
  {
    createPrize,
    updatePrize,
    updatePrizeMap,
  },
)(PrizeForm);
