import React, {PropsWithChildren, ReactNode} from 'react';

import {useToggle} from 'react-use';

import bem from 'client/services/bem';

import AppButton from 'client/common/buttons';
import {CheckboxInput} from 'client/common/inputs';

import cssModule from './client-store-modal-check-item.module.scss';

type ClientStoreModalCheckItemProps = PropsWithChildren<{
  getLabel: (states: {opened: boolean}) => ReactNode;
  checked: boolean;
  disabled: boolean;
  onCheck: (isChecked: boolean) => void;
  name: string;
}>;

const b = bem('client-store-modal-check-item', {cssModule});

export const ClientStoreModalCheckItem: React.FC<ClientStoreModalCheckItemProps> = (props) => {
  const {children, onCheck, checked, getLabel, name, disabled} = props;
  const [opened, toggleOpened] = useToggle(false);

  return (
    <div className={b()}>
      <div className={b('header')}>
        <CheckboxInput
          name={name}
          checked={checked}
          inversionColor
          color="primary"
          onCheck={onCheck}
          disabled={disabled}
        />
        {getLabel({opened})}
        {!!children && (
          <AppButton
            asWrap
            iconName="arrow-right"
            iconConfig={{
              width: 11,
            }}
            onClick={() => toggleOpened()}
            className={b('arrow-button', {opened})}
          />
        )}
      </div>
      {!!children && opened && <div className={b('body')}>{children}</div>}
    </div>
  );
};
