import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {getIsNational} from 'client/services/cookie-data-source';
import {getHomeDashboard} from 'client/services/helpers';

import {setBreadcrumbs, clearBreadcrumbs} from 'client/ducks/breadcrumbs/actions';
import {getGames} from 'client/ducks/games/actions';
import {selectGames} from 'client/ducks/games/selectors';
import {getPlacesAction} from 'client/ducks/places/actions';
import {selectPlacesForPlanMapView} from 'client/ducks/places/selectors';
import {getPlan} from 'client/ducks/plans/actions';
import {selectIsAdmin} from 'client/ducks/user/selectors';

import {CLIENT_PAGES, CLIENT_LEVEL_TYPES, OPERATION_STATUS_TYPES} from 'client/common/config';

import CustomLink from 'client/components/common/custom-link';
import Icon from 'client/components/common/icon';
import TitleBlock from 'client/components/common/title-block';

import {PRIZE_MAP_LEVEL_TYPES, PRIZE_MAP_TYPES} from 'client/components/games/game-config-modal/constants';
import PrizeMapCard from 'client/components/games/prize-map-card';

import AtPlanSummaryPopover from '../components/popovers/at-plan-summary-popover';
import AtPlanPlacesDataGrid from '../components/tables/at-plan-places-data-grid';
import AutotaskPlan from '../devices-view';

import './places-view.scss';

class AutotaskPlanPlaces extends ReactQueryParams {
  static propTypes = {
    id: PropTypes.number.isRequired,
    meta: PropTypes.object.isRequired,
    places: PropTypes.array.isRequired,
    getPlan: PropTypes.func.isRequired,
    ...withRouter.propTypes,
    getPlaces: PropTypes.func.isRequired,
    isNational: PropTypes.bool.isRequired,
    setBreadcrumbs: PropTypes.func.isRequired,
    languageState: PropTypes.object.isRequired,
    clearBreadcrumbs: PropTypes.func.isRequired,
    getGames: PropTypes.func.isRequired,
    games: PropTypes.array.isRequired,
  };

  static DEFAULT_SORT = 'created_at desc';

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.AUTOTASK_PLAN;
  }

  componentDidMount() {
    this.props
      .getPlan(this.props.id, {
        include_plan_prize_maps_present: true,
        include: {
          automation_task: {
            operation: {
              client: ['agency', 'dashboards'],
              dashboards: null,
            },
            dashboards: null,
          },
        },
      })
      .then(this.updateBreadcrumbs)
      .catch((response) => {
        if (AutotaskPlan.ERROR_RESPONSE_CODES.includes(response.status)) {
          this.props.history.push(CLIENT_PAGES.HOME);
        }
      });

    this.updateMe();
  }

  componentWillUnmount() {
    this.props.clearBreadcrumbs();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.plan.id && this.props.plan.id) {
      this.fetchGames();
    }
  }

  fetchGames = () => {
    return this.props.getGames({
      q: {
        automation_task_id_eq: this.props.plan.automation_task_id,
      },
      include: ['game_validation_levels', 'automation_task.operation.client'],
    });
  };

  updateBreadcrumbs = () => {
    const {
      isAdmin,
      isNational,
      languageState: {
        payload: {BREADCRUMBS},
      },
      plan: {
        name,
        automation_task: {
          id: taskId,
          type: taskType,
          name: taskName,
          dashboards: autotaskDashboards,
          operation: {
            id: operationId,
            name: operationName,
            status: operationStatus,
            dashboards: operationDashboards,
            client: {id: clientId, type: clientType, dashboards: clientDashboards, ...client},
          },
        },
      },
    } = this.props;

    const basicInfo = {
      taskId,
      taskType,
      clientId,
      clientType,
      operationId,
    };

    const autotaskHomeDashboard = getHomeDashboard(autotaskDashboards);
    const operationHomeDashboard = getHomeDashboard(operationDashboards);
    const clientHomeDashboard = getHomeDashboard(clientDashboards);

    const agencyBreadcrumb = client.agency
      ? [
          {
            href: CustomLink.createClientLink({
              clientType: client.agency.type,
              clientId: client.agency.id,
            }),
            name: client.agency.name,
          },
        ]
      : [];

    const operationStatusBreadcrumb = {
      name:
        operationStatus !== OPERATION_STATUS_TYPES.ACTIVE
          ? BREADCRUMBS.FINISHED_OPERATIONS
          : BREADCRUMBS.ONGOING_OPERATIONS,
      href:
        operationStatus !== OPERATION_STATUS_TYPES.ACTIVE
          ? CLIENT_PAGES.OPERATIONS_FINISHED
          : CLIENT_PAGES.OPERATIONS_ACTIVE,
    };

    const planNameBreadcrumb = {
      name,
    };

    const taskDashboardBreadcrumb = {
      name: get(autotaskHomeDashboard, 'name'),
      href:
        autotaskHomeDashboard &&
        CustomLink.createDashboardsLink({
          ...basicInfo,
          dashboardType: CLIENT_LEVEL_TYPES.AUTOMATION_TASK,
          dashboardId: autotaskHomeDashboard.id,
          hidden: !autotaskHomeDashboard,
        }),
    };

    switch (true) {
      case !isNational && !isAdmin:
        return this.props.setBreadcrumbs([operationStatusBreadcrumb, taskDashboardBreadcrumb, planNameBreadcrumb]);
      case !isAdmin && isNational:
        return this.props.setBreadcrumbs([
          operationStatusBreadcrumb,
          {
            name: get(clientHomeDashboard, 'name'),
            href:
              clientHomeDashboard &&
              CustomLink.createDashboardsLink({
                ...basicInfo,
                dashboardType: CLIENT_LEVEL_TYPES.CLIENT,
                dashboardId: clientHomeDashboard.id,
                hidden: !clientHomeDashboard,
              }),
          },
          {
            name: get(operationHomeDashboard, 'name'),
            href:
              operationHomeDashboard &&
              CustomLink.createDashboardsLink({
                ...basicInfo,
                dashboardType: CLIENT_LEVEL_TYPES.OPERATION,
                dashboardId: operationHomeDashboard.id,
                hidden: !operationHomeDashboard,
              }),
          },
          taskDashboardBreadcrumb,
          planNameBreadcrumb,
        ]);
      default:
        return this.props.setBreadcrumbs([
          {
            href: CLIENT_PAGES.VISUALS,
            name: BREADCRUMBS.VISUALS,
          },
          {
            href: CLIENT_PAGES.CLIENTS_LIST,
            name: BREADCRUMBS.CLIENTS,
          },
          ...agencyBreadcrumb,
          {
            name: client.name,
            href: CustomLink.createClientLink(basicInfo),
          },
          {
            name: BREADCRUMBS.CUSTOM_OPERATION,
            href: CustomLink.createCustomOperationDBLink(basicInfo),
          },
          {
            name: operationName,
            href: CustomLink.createOperationLink(basicInfo),
          },
          {
            name: taskName,
            href: CustomLink.createTaskLink(basicInfo),
          },
          planNameBreadcrumb,
        ]);
    }
  };

  updateMe = () => {
    const params = this.queryParams;

    this.props.getPlaces({
      include_place_prize_maps_present: true,
      include: ['offline_interactions.device'],
      distinct: true,
      q: {
        offline_interactions_interaction_group_id_eq: this.props.id,
        s: params.sort ? `${params.sort.name} ${params.sort.order}` : AutotaskPlanPlaces.DEFAULT_SORT,
        name_cont: params.search,
      },
      page: params.page || 1,
      per_page: params.perPage || 5,
    });
  };

  onPageChange = (params) => {
    this.setQueryParams(params, true);
    this.updateMe();
  };

  onSearchChange = (search = '') => {
    this.setQueryParams({search, page: 1}, true);
    this.updateMe();
  };

  onSortChange = ({sortField, sortOrder}) => {
    this.setQueryParams(
      {
        sort: {name: sortField, order: sortOrder},
      },
      true,
    );
    this.updateMe();
  };

  handleDevicesViewClick = () => {
    this.props.history.push(`${CLIENT_PAGES.PLANS}/${this.props.id}${CLIENT_PAGES.DEVICES}`);
  };

  render() {
    const {places, meta, plan, games} = this.props;

    return (
      <div className="theme-color-9">
        <div className="page__title-block places-view__title-block">
          <div className="places-view__title">
            <TitleBlock theme>
              <TitleBlock.Item>{plan.name}</TitleBlock.Item>
            </TitleBlock>
            <AtPlanSummaryPopover plan={plan}>
              <div className="icon-new-wrapper-square">
                <Icon name="summary" />
              </div>
            </AtPlanSummaryPopover>
          </div>
          <PrizeMapCard
            hasContainer
            autotask={plan.automation_task}
            className="places-view__prize-map"
            levelKey="PLAN"
            prizeMapParams={{
              type_eq: PRIZE_MAP_TYPES.GEO_PRIZE_MAP,
              geo_level_eq: PRIZE_MAP_LEVEL_TYPES.PLAN,
              geo_source_id_eq: plan.id,
            }}
            displayParams={plan.prize_maps_present}
          />
        </div>
        <AtPlanPlacesDataGrid
          data={places}
          meta={meta}
          perPage={+this.queryParams.perPage || 5}
          onPageChange={this.onPageChange}
          onSearchChange={this.onSearchChange}
          onSortChange={this.onSortChange}
          onDevicesViewClick={this.handleDevicesViewClick}
          games={games}
          search={this.queryParams.search}
          sort={this.queryParams.sort}
        />
      </div>
    );
  }
}

export default withRouter(
  connect(
    ({languageState, plans, places, ...state}, ownProps) => ({
      languageState,
      plan: plans.plan,
      places: selectPlacesForPlanMapView({places}, ownProps.id),
      meta: places.meta,
      isNational: getIsNational(),
      isAdmin: selectIsAdmin(state),
      games: selectGames(state),
    }),
    {
      getPlan,
      setBreadcrumbs,
      clearBreadcrumbs,
      getPlaces: getPlacesAction,
      getGames,
    },
  )(AutotaskPlanPlaces),
);
