import {parseDate} from 'client/services/utils/date';

import {SCHEDULE_TABS} from 'client/components/prizes/fieldsets/prize-diy-schedule-fieldset/constants';

export default (prize, {timezone}) => {
  const {
    prize_timestamps,
    schedule_type,
    intraday_win = true,
    next_day_win = true,
    prizes_number,
    id,
  } = prize?.schedule_instant_win_configuration || {};

  const openingHours = {
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
  };

  let configurationId;
  let initialTimestamps = [];

  if (schedule_type === SCHEDULE_TABS.SCHEDULE) {
    prize_timestamps?.forEach((timeStamp) => {
      const from = parseDate(timeStamp.win_time_from, {timezone, outputFormat: 'TIME_SECONDS'});
      const to = parseDate(timeStamp.win_time_to, {timezone, outputFormat: 'TIME_SECONDS'});

      openingHours[timeStamp.weekday].push({value: `${from} ${to}`, id: timeStamp.id});
    });

    configurationId = id;
    initialTimestamps = prize_timestamps;
  }

  return {
    intraday_win,
    next_day_win,
    openingHours,
    prizes_number,
    configurationId,
    initialTimestamps,
  };
};
