import initCustomListFieldset from 'client/components/prizes/fieldsets/prize-diy-custom-list-fieldset/initialValues';
import initRatioParticipationFieldset from 'client/components/prizes/fieldsets/prize-diy-ratio-participation-fieldset/initialValues';
import initRatioTimeFieldset from 'client/components/prizes/fieldsets/prize-diy-ratio-time-fieldset/initialValues';
import initGeneralInfoFieldset from 'client/components/prizes/fieldsets/prizemap-customization-general-fieldset/initialValues';

type Params = {defaultLanguage: string; timezone: string};

export default (values: any, {defaultLanguage, timezone}: Params) => {
  return {
    ...values,
    ...initGeneralInfoFieldset(values, {defaultLanguage}),
    ratioTime: initRatioTimeFieldset(values),
    ratioParticipation: initRatioParticipationFieldset(values),
    customList: initCustomListFieldset(values, {timezone}),
  };
};
