import React from 'react';

import cn from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import bem from 'client/services/bem';
import {transformDate} from 'client/services/helpers';
import {useCalendarUnitTranslations} from 'client/services/hooks';

import {ErrorMessage, RequiredLabel, WarningMessage} from 'client/common/inputs';

import {TranslationJsx} from 'client/models/language/types';

import cssModule from './datepicker-input.module.scss';

const b = bem('datepicker-input', {cssModule});

const DatepickerInput = (props) => {
  const {
    label,
    className,
    classNames,
    inputClassName,
    onChange,
    disabledDayBefore,
    disabledDayAfter,
    disabledDayFrom,
    disabledDayTo,
    renderDay,
    errorMessage,
    warningMessage = '',
    labelClassName,
    useDirectChange,
    errorClassName,
    required = false,
    onBlur,
    ...inputProps
  } = props;

  const dayPickerProps = {
    disabledDays: {
      before: disabledDayBefore && new Date(transformDate(disabledDayBefore, false)),
      after: disabledDayAfter && new Date(transformDate(disabledDayAfter, false)),
      from: disabledDayFrom && new Date(transformDate(disabledDayFrom, false)),
      to: disabledDayTo && new Date(transformDate(disabledDayTo, false)),
    },
    renderDay,
  };

  const calendarUnitTranslations = useCalendarUnitTranslations();

  const handleDayChange = (day) => {
    let nextValue = day?.format('DD/MM/YYYY');

    if (dayPickerProps.disabledDays.before) {
      if (day?.isBefore(moment(dayPickerProps.disabledDays.before), 'date')) {
        nextValue = '';
      }
    }
    if (dayPickerProps.disabledDays.after) {
      if (day?.isAfter(moment(dayPickerProps.disabledDays.after), 'date')) {
        nextValue = '';
      }
    }

    onChange?.(nextValue);
  };

  return (
    <div
      className={cn(
        b({
          error: !!errorMessage,
          warning: !!warningMessage,
          disabled: inputProps.disabled,
        }),
        className,
      )}
    >
      <div className={b('container')}>
        {label && <span className={cn(b('label'), labelClassName)}>{label}</span>}
        <label className={b('field-wrap')}>
          <DayPickerInput
            {...inputProps}
            format="DD/MM/YYYY"
            dayPickerProps={{
              ...calendarUnitTranslations,
              ...dayPickerProps,
            }}
            className={cn(b('field'), inputClassName)}
            onDayChange={handleDayChange}
            onBlur={onBlur}
            onChange={useDirectChange ? (e) => onChange(e.target.value) : null}
            classNames={{
              container: cn(b('input-container')),
              overlay: cn(b('overlay'), classNames?.overlay),
            }}
          />
        </label>
        {!errorMessage && required && <RequiredLabel />}
        {errorMessage && <ErrorMessage errorMessage={errorMessage} className={errorClassName} />}
        {warningMessage && !errorMessage && <WarningMessage warningMessage={warningMessage} />}
      </div>
    </div>
  );
};

DatepickerInput.propTypes = {
  label: PropTypes.node,
  labelClassName: PropTypes.string,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  value: PropTypes.string,
  format: PropTypes.string,
  disabledDayBefore: PropTypes.string,
  disabledDayAfter: PropTypes.string,
  disabledDayFrom: PropTypes.string,
  disabledDayTo: PropTypes.string,
  renderDay: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  useDirectChange: PropTypes.bool,
  errorMessage: PropTypes.oneOfType([PropTypes.array, TranslationJsx]),
  warningMessage: TranslationJsx,
  errorClassName: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  classNames: PropTypes.shape({
    overlay: PropTypes.string,
  }),
};

DatepickerInput.defaultProps = {
  errorMessage: '',
  label: '',
  labelClassName: '',
  className: '',
  inputClassName: '',
  value: '',
  disabledDayAfter: '',
  disabledDayBefore: '',
  format: 'DD/MM/YYYY',
  useDirectChange: false,
  errorClassName: null,
};

export default DatepickerInput;
