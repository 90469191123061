import React, {useCallback} from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {generatePath} from 'react-router';
import {useHistory} from 'react-router-dom';
import {routePaths} from 'routes/index';

import bem from 'client/services/bem';
import {uid} from 'client/services/helpers';
import {useLanguage, useReduxFetch} from 'client/services/hooks';

import {addOperation} from 'client/ducks/operations/actions';
import {getClientTemplates} from 'client/ducks/templates/actions';
import {selectViewMode} from 'client/ducks/user/selectors';

import AwaitContainer from 'client/common/await-container';
import {CLIENT_PAGES} from 'client/common/config';
import {useToast} from 'client/common/hooks/useToast';
import Modal from 'client/common/modals/modal';

import DiyOperationModalForm from './diy-operation-modal-form';

import cssModule from './diy-operation-modal.module.scss';

const b = bem('diy-operation-modal', {cssModule});

const DiyOperationModal = (props) => {
  const {show, onClose, generalTemplateId, client} = props;
  const lang = useLanguage('OPERATIONS.DIY_OPERATION_MODAL');
  const {id: viewUserId} = useSelector(selectViewMode);
  const dispatch = useDispatch();
  const {appendToastNotification} = useToast();
  const history = useHistory();
  const clientId = client.id;

  const {loading} = useReduxFetch({
    skip: !clientId,
    action: getClientTemplates,
    actionArgs: {
      include: ['catalog', 'creator_team'],
      q: {
        client_id_eq: clientId,
      },
    },
  });

  const handleSubmit = useCallback(
    async (values) => {
      const query = {
        include: ['automation_tasks'],
      };

      const requestData = {...values};

      if (viewUserId) {
        requestData.client_user_id = viewUserId;
        requestData.fake_client_user_id = viewUserId;
      }

      await dispatch(addOperation(requestData, query)).then((data) => {
        if (!data.error) {
          const operation = get(data, 'payload.operation', {});

          const link = generatePath(routePaths.client.ClientAutotaskPage, {
            clientId,
            operationId: operation.id,
            autotaskId: operation.automation_tasks?.[0]?.id,
            tab: CLIENT_PAGES.CLIENT_AUTOTASK_TABS.CONFIGURATION.replace('/', ''),
          });

          history.push(link);
        } else {
          appendToastNotification({
            id: uid(),
            type: 'error',
            title: lang.ADD_OPERATION_FAILED_TITLE,
          });
        }
        return data;
      });
    },
    [appendToastNotification, clientId, dispatch, history, lang.ADD_OPERATION_FAILED_TITLE, viewUserId],
  );

  return (
    <Modal
      show={show}
      onClose={onClose}
      header={lang.HEADER}
      className={b()}
      classNames={{
        header: b('header'),
        body: b('body'),
        content: b('content'),
      }}
    >
      <AwaitContainer loading={loading}>
        <DiyOperationModalForm onSubmit={handleSubmit} generalTemplateId={generalTemplateId} client={client} />
      </AwaitContainer>
    </Modal>
  );
};

DiyOperationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  generalTemplateId: PropTypes.number,
  client: PropTypes.object.isRequired,
};

export default DiyOperationModal;
