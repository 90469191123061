import {reducer as notifications} from 'react-notification-system-redux';
import {loadingBarReducer} from 'react-redux-loading-bar';
import {combineReducers} from 'redux';
import {enableBatching} from 'redux-batched-actions';
import {reducer as formReducer} from 'redux-form';

import catalogsReducer from 'client/ducks/catalogs/reducer';
import clientAccount from 'client/ducks/client-account/reducer';
import languageReducer from 'client/ducks/language/reducer';
import regionsReducer from 'client/ducks/regions/reducer';
import userPasswordExpirationReducer from 'client/ducks/user-password-expiration/reducer';

import metaReducer from './common/meta/meta.reducer';
import profileReducer from './components/profile/profile.reducer';
import addPlaceFormReducer from './ducks/add-place-form/reducer';
import adminUsersReducer from './ducks/admin-users/reducer';
import adminsListReducer from './ducks/admins-list/admins-list.reducer';
import agencies from './ducks/agencies/reducer';
import agencyUsersReducer from './ducks/agency-users/agency-users.reducer';
import agencyReducer from './ducks/agency/reducer';
import animationsReducer from './ducks/animations/reducer';
import anonymousEmailsReducer from './ducks/anonymous-emails/reducer';
import autotaskReducer from './ducks/autotask/reducer';
import availableDomains from './ducks/available-domains/reducer';
import breadcrumbs from './ducks/breadcrumbs/reducer';
import broadcastReducer from './ducks/broadcast/reducer';
import campaignsReducer from './ducks/campaigns/reducer';
import categoriesReducer from './ducks/categories/reducer';
import clientDevices from './ducks/client-devices/reducer';
import clientDomains from './ducks/client-domains/reducer';
import clientStores from './ducks/client-stores/reducer';
import clientUsersReducer from './ducks/client-users/reducer';
import clientsListReducer from './ducks/clients-list/reducer';
import columnAdaptersReducer from './ducks/column-adapters/reducer';
import companiesReducer from './ducks/companies/companies.reducer';
import companyReducer from './ducks/company/reducer';
import couponTokensReducer from './ducks/coupon-tokens/reducer';
import coupons from './ducks/coupons/reducer';
import customColumnReducer from './ducks/custom-columns/reducer';
import customOpDBReducer from './ducks/custom-operation-database/reducer';
import deviceAffectationsReducer from './ducks/device-affectation/reducer';
import devicesReducer from './ducks/devices/reducer';
import diyOperation from './ducks/diy-operation/reducer';
import emailSendersReducer from './ducks/email-senders/reducer';
import emailTemplatesReducer from './ducks/email-templates/reducer';
import errorsReducer from './ducks/errors/reducer';
import firstSourcesReducer from './ducks/first-sources/reducer';
import games from './ducks/games/reducer';
import groupsReducer from './ducks/groups/reducer';
import headerLinkBack from './ducks/header-link-back/reducer';
import instoreDashboards from './ducks/instore-dashboards/reducer';
import instoreTasksReducer from './ducks/instore-tasks/reducer';
import interactionsReducer from './ducks/interactions/reducer';
import interceptorReducer from './ducks/interceptor/reducer';
import interfaceTemplatesReducer from './ducks/interface-templates/reducer';
import interfacesReducer from './ducks/interfaces/reducer';
import internalColumnsReducer from './ducks/internal-columns/reducer';
import leadDisplayItemsReducer from './ducks/lead-display-items/reducer';
import leadHistoriesReducer from './ducks/lead-histories/reducer';
import leadReducer from './ducks/lead/reducer';
import leadsDashboard from './ducks/leads-dashboard/reducer';
import leadsExportReducer from './ducks/leads-export/reducer';
import leadsImportReducer from './ducks/leads-import/reducer';
import leadsListReducer from './ducks/leads-list/reducer';
import leadsOptInsStatistics from './ducks/leads-opt-in-statistics/reducer';
import leadsParameters from './ducks/leads-parameters/reducer';
import leadsTransfersReducer from './ducks/leads-transfers/reducer';
import linkToClientFormReducer from './ducks/link-to-client-form/reducer';
import lottery from './ducks/lottery/reducer';
import medias from './ducks/medias/reducer';
import messageEmailSmsTaskReducer from './ducks/message-email-sms-task/reducer';
import messageSendersReducer from './ducks/message-senders/reducer';
import operationsReducer from './ducks/operations/reducer';
import optInsReducer from './ducks/opt-in-columns/reducer';
import optInMappingItemsReducer from './ducks/opt-in-mapping-items/reducer';
import optOutReducer from './ducks/opt-out/reducer';
import participantsWinners from './ducks/participants-winners/reducer';
import participationDisplayItemsReducer from './ducks/participation-display-items/reducer';
import participationExportReducer from './ducks/participation-export/reducer';
import participationsStatistics from './ducks/participations-statistics/reducer';
import participationsReducer from './ducks/participations/reducer';
import placesReducer from './ducks/places/reducer';
import plansReducer from './ducks/plans/reducer';
import privacyPolicyReducer from './ducks/privacy-policies/reducer';
import prizeDistribution from './ducks/prize-distribution/reducer';
import resultsGraphs from './ducks/results-graphs/reducer';
import scenarioRunTableReducer from './ducks/scenario-run-table/reducer';
import scenarioReducer from './ducks/scenario/reducer';
import settingsReducer from './ducks/settings/reducer';
import sgInterfaceReducer from './ducks/sg-interfaces/reducer';
import slideshowsReducer from './ducks/slideshows/reducer';
import smsSendersReducer from './ducks/sms-senders/reducer';
import smsTemplates from './ducks/sms-templates/reducer';
import socialAccountsReducer from './ducks/social-accounts/reducer';
import sourcesStatistics from './ducks/sources-statistics/reducer';
import sourcesReducer from './ducks/sources/reducer';
import ssoAuthInfo from './ducks/sso-auth-info/reducer';
import statisticExports from './ducks/statistic-exports/reducer';
import storesReducer from './ducks/stores/reducer';
import subcategoriesReducer from './ducks/subcategories/reducer';
import subsidiaryReducer from './ducks/subsidiaries/reducer';
import taskWrapper from './ducks/table-wrapper/reducer';
import taskIndexItemsReducer from './ducks/task-index-items/reducer';
import tasksReducer from './ducks/tasks/reducer';
import templates from './ducks/templates/reducer';
import toastNotificatiosReducer from './ducks/toast-notification/reducer';
import translations from './ducks/translations/reducer';
import twitterTrackerReducer from './ducks/twitter-hashtag-trackers/reducer';
import unbounceInterfaceReducer from './ducks/unbounce-interface/reducer';
import allUserClientsReducer from './ducks/user-clients/reducer';
import userReducer from './ducks/user/reducer';
import visualsReducer from './ducks/visuals/reducer';
import winnerExport from './ducks/winner-export/reducer';
import winnings from './ducks/winnings/reducer';

export const reducers = {
  interceptor: interceptorReducer,
  ssoAuthInfo,
  notifications,
  lead: leadReducer,
  couponTokens: couponTokensReducer,
  plans: plansReducer,
  tasks: tasksReducer,
  errors: errorsReducer,
  places: placesReducer,
  stores: storesReducer,
  agency: agencyReducer,
  groups: groupsReducer,
  optIns: optInsReducer,
  optOut: optOutReducer,
  userState: userReducer,
  metaState: metaReducer,
  devices: devicesReducer,
  deviceAffectations: deviceAffectationsReducer,
  visuals: visualsReducer,
  company: companyReducer,
  profile: profileReducer,
  sources: sourcesReducer,
  breadcrumbs: breadcrumbs,
  scenario: scenarioReducer,
  autotask: autotaskReducer,
  campaigns: campaignsReducer,
  companies: companiesReducer,
  leadsList: leadsListReducer,
  broadcast: broadcastReducer,
  loadingBar: loadingBarReducer,
  slideshows: slideshowsReducer,
  agencies,
  interfaces: interfacesReducer,
  operations: operationsReducer,
  customOpDB: customOpDBReducer,
  adminUsers: adminUsersReducer,
  adminsList: adminsListReducer,
  categories: categoriesReducer,
  smsSenders: smsSendersReducer,
  languageState: languageReducer,
  sgInterface: sgInterfaceReducer,
  clientUsers: clientUsersReducer,
  clientsList: clientsListReducer,
  subsidiaries: subsidiaryReducer,
  agencyUsers: agencyUsersReducer,
  leadsImport: leadsImportReducer,
  leadsExport: leadsExportReducer,
  form: enableBatching(formReducer),
  addPlaceForm: addPlaceFormReducer,
  firstSources: firstSourcesReducer,
  interactions: interactionsReducer,
  settings: settingsReducer,
  emailSenders: emailSendersReducer,
  customColumns: customColumnReducer,
  subcategories: subcategoriesReducer,
  leadHistories: leadHistoriesReducer,
  participations: participationsReducer,
  emailTemplates: emailTemplatesReducer,
  socialAccounts: socialAccountsReducer,
  columnAdapters: columnAdaptersReducer,
  leadsTransfers: leadsTransfersReducer,
  taskIndexItems: taskIndexItemsReducer,
  allUserClients: allUserClientsReducer,
  messageSenders: messageSendersReducer,
  twitterTrackers: twitterTrackerReducer,
  internalColumns: internalColumnsReducer,
  anonymousEmails: anonymousEmailsReducer,
  linkToClientForm: linkToClientFormReducer,
  leadDisplayItems: leadDisplayItemsReducer,
  scenarioRunTable: scenarioRunTableReducer,
  unbounceInterface: unbounceInterfaceReducer,
  interfaceTemplates: interfaceTemplatesReducer,
  messageEmailSmsTask: messageEmailSmsTaskReducer,
  privacyPolicy: privacyPolicyReducer,
  participationDisplayItems: participationDisplayItemsReducer,
  participationsExport: participationExportReducer,
  participationsStatistics,
  sourcesStatistics,
  participantsWinners,
  clientAccount,
  resultsGraphs,
  leadsDashboard,
  coupons,
  leadsOptInsStatistics,
  games,
  clientDevices,
  medias,
  templates,
  diyOperation,
  headerLinkBack,
  instoreTasks: instoreTasksReducer,
  animations: animationsReducer,
  taskWrapper,
  optInMappingItems: optInMappingItemsReducer,
  winnings,
  instoreDashboards,
  availableDomains,
  clientDomains,
  regions: regionsReducer,
  catalogs: catalogsReducer,
  userPasswordExpiration: userPasswordExpirationReducer,
  toastNotifications: toastNotificatiosReducer,
  winnerExport,
  smsTemplates,
  translations,
  lottery,
  statisticExports,
  leadsParameters,
  prizeDistribution,
  clientStores,
};

export const mainReducer = combineReducers(reducers);
