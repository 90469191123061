import {parseDate} from 'client/services/utils/date';
import {preparePrizeLocalOrders} from 'client/components/prizes/utils/preparePrizeLocalOrders';
import {Prize} from 'client/models/prizes/types';

import {LotteryPrizeCustomizationValues} from './types';

export default (formValues: LotteryPrizeCustomizationValues, {timezone}: {timezone: string}) => {
  return {
    ...formValues,
    game_draws: formValues.game_draws.map((draw) => ({
      ...draw,
      default_draw_time: parseDate(draw.default_draw_time, {timezone, timezoneForce: true}),
      default_from: parseDate(draw.default_from, {timezone, timezoneForce: true}),
      default_to: parseDate(draw.default_to, {timezone, timezoneForce: true}),
    })),
  };
};

type MapPrizeOrderParams = {
  prizes: Prize[];
};

export const mapPrizeOrder = (values: LotteryPrizeCustomizationValues, {prizes}: MapPrizeOrderParams) => {
  return preparePrizeLocalOrders(
    prizes,
    values.prize_maps.map(({prize}) => prize!),
  ).reduce<Record<number, number>>((acc, prize) => {
    acc[prize.id] = prize.order;

    return acc;
  }, {});
};
