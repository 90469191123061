export const INPUT_TYPES = {
  FIELD: 'field',
  DROPDOWN: 'dropdown',
  TOGGLE: 'toggle',
  COLOR: 'color',
  CAROUSEL: 'carousel',
  IMAGE: 'image',
  DATE: 'date',
};

export const ANSWER_KEY_MAP = {
  [INPUT_TYPES.FIELD]: 'text_field',
  [`${INPUT_TYPES.FIELD}_number`]: 'number_field',
  [`${INPUT_TYPES.FIELD}_string`]: 'text_field',
  [INPUT_TYPES.DROPDOWN]: 'template_choices',
  [INPUT_TYPES.TOGGLE]: 'boolean_field',
  [INPUT_TYPES.COLOR]: 'string_field',
  [INPUT_TYPES.CAROUSEL]: 'template_choices',
  [INPUT_TYPES.IMAGE]: 'media_storage_item.media_storage',
  [INPUT_TYPES.DATE]: 'date_field',
};

export const SCREEN_FORMATS = {
  desktop: 'wl', // 1440 x 936
  phone: 'ws', // 375 x 667
  kiosk_vertical: 'kv', // 1080 x 1920
  kiosk_horizontal: 'kh', // 1920 x 1080,
};

export const DEFAULT_COLOR = 'FFFFFF';

export const CHECKABLE_ITEMS = [INPUT_TYPES.TOGGLE, INPUT_TYPES.FIELD, INPUT_TYPES.DROPDOWN, INPUT_TYPES.CAROUSEL];

export const OVERALL_INPUT = 'by_default';
export const FORCED_INPUT = 'forced';
export const CUSTOM_INPUTS = [OVERALL_INPUT, FORCED_INPUT];

export const SCREEN_FORMAT_PRIORITY = {
  [SCREEN_FORMATS.kiosk_vertical]: 1,
  [SCREEN_FORMATS.kiosk_horizontal]: 2,
  [SCREEN_FORMATS.desktop]: 3,
  [SCREEN_FORMATS.phone]: 4,
};

export const SPECIAL_SELECTOR_TYPES = {
  FONT: 'font',
  NONE: 'none',
};
