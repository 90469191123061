import React from 'react';

import {useToggle} from 'react-use';

import bem from 'client/services/bem';

import Offscreen from 'client/common/offscreen';

import Icon from 'client/components/common/icon';

import {Campaign} from 'client/models/campaign/types';
import {Operation} from 'client/models/operations/types';

import InteractionItem from '../interaction-item';
import {SourcesValues, SourcesErrors} from '../types';

import cssModule from './campaign-item.module.scss';

const b = bem('campaign-item', {cssModule});

type CampaignItemProps = {
  campaign: Campaign;
  operation: Operation;
  formValues: SourcesValues;
  errors: SourcesErrors;
};

const CampaignItem: React.FC<CampaignItemProps> = (props) => {
  const {campaign, operation, formValues, errors} = props;
  const [open, toggleOpen] = useToggle(false);

  const interactionIds = campaign.interactions?.map((i) => `i${i.id}`) || [];
  const hasError = Object.keys(errors).some((i) => interactionIds.includes(i));

  return (
    <div className={b()}>
      <div className={b('head')} onClick={toggleOpen}>
        <span className={b('name', {error: hasError})}>{campaign.name}</span>
        <Icon name={open ? 'arrow-up' : 'arrow-down'} />
      </div>
      <Offscreen hidden={!open}>
        {campaign.interactions?.map((interaction) => (
          <InteractionItem
            key={interaction.id}
            interaction={interaction}
            operation={operation}
            formValues={formValues}
            errors={errors}
          />
        ))}
      </Offscreen>
    </div>
  );
};

export default CampaignItem;
