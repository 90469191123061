import React, {useCallback, useMemo, useState} from 'react';

import get from 'lodash/get';
import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';
import {TranslationElement} from 'client/services/translation-element';

import {selectClientAccessLevel, selectCurrentMembership} from 'client/ducks/user-clients/selectors';

import AppButton from 'client/common/buttons';
import Popover from 'client/common/popovers/popover';

import ClientTable from 'client/components/common/client-table';
import {ClientTableColumn, ClientTableFormatter} from 'client/components/common/client-table/types';
import CustomScrollbars from 'client/components/common/custom-scrollbars';

import DeactivateMembershipModal from 'client/components/client-account-parameters/modals/deactivate-membership-modal';
import DeleteTrackingUserModal from 'client/components/client-account-parameters/modals/delete-tracking-user-modal';
import {Client} from 'client/models/client/types';
import {ACCESS_LEVEL} from 'client/models/common/constants';
import {Membership} from 'client/models/memberships/types';

import './account-users-table.scss';

const b = bem('account-users-table');

type AccountUsersTableProps = {
  data: Membership[];
  sortField: string;
  sortOrder: string;
  onSortChange: (params: {sortField: string; sortOrder: string}) => void;
  onOpenExportAccess: (membership: Membership) => void;
  onResendInvitation: (membership: Membership) => void;
  refetchData: () => void;
  onEditUser: (membership: Membership) => void;
  onEditTrackingUser: (membership: Membership) => void;
  loading: boolean;
  crudUsers?: boolean;
  canResendInvitation: (membership: Membership) => boolean;
  client: Client;
};

const AccountUsersTable: React.FC<AccountUsersTableProps> = (props) => {
  const {
    data,
    sortField,
    sortOrder,
    onSortChange,
    loading,
    client,
    onOpenExportAccess,
    refetchData,
    crudUsers,
    onEditUser,
    onResendInvitation,
    canResendInvitation,
    onEditTrackingUser,
  } = props;
  const lang = useLanguage('ACCOUNT_PARAMETERS.USERS');

  const userAccessLevel = useSelector(selectClientAccessLevel);
  const currentMembership = useSelector(selectCurrentMembership);

  const [deactivatedUser, setDeactivatedUser] = useState<null | Membership>(null);
  const [deletedUser, setDeletedUser] = useState<null | Membership>(null);

  const isAgency = client.type === 'Agency';

  const renderValue = useCallback((value: TranslationElement) => {
    return (
      <p className="account-users-table__value" title={value?.toString()}>
        {value}
      </p>
    );
  }, []);

  const renderList = useCallback(
    (list: {client: Client}[], valuePath: string) => {
      const values = list.map((item) => get(item, valuePath));

      return (
        <CustomScrollbars
          scrollbarProps={{
            autoHeightMax: 35,
          }}
        >
          {values.map(renderValue)}
        </CustomScrollbars>
      );
    },
    [renderValue],
  );

  const renderClientsAccess = useCallback(
    (membership: Membership) => {
      return membership.all_companies_access
        ? renderValue(lang.ALL_CLIENTS)
        : renderList(membership.accessible_clients || [], 'client.name');
    },
    [renderList, renderValue, lang],
  );

  const formatGender: ClientTableFormatter = useCallback(
    (value: string) => {
      const map: Record<string, TranslationElement> = {
        'M.': lang.MALE,
        'Mme.': lang.FEMALE,
      };

      return map[value] || value;
    },
    [lang],
  );

  const formatAccessLevel: ClientTableFormatter = useCallback(
    (value: string) => {
      const map: Record<string, TranslationElement> = {
        [ACCESS_LEVEL.REGION]: lang.REGIONS,
        [ACCESS_LEVEL.STORE]: lang.STORES,
        [ACCESS_LEVEL.NATIONAL]: lang.NATIONAL,
        [ACCESS_LEVEL.CLIENT_ADMIN]: lang.CLIENT_ADMIN,
        [ACCESS_LEVEL.LOCAL]: lang.LOCAL,
      };

      return map[value];
    },
    [lang],
  );

  const columns = useMemo(() => {
    const result: ClientTableColumn<Membership>[] = [
      {
        name: 'client_user_full_name',
        label: lang.NAME,
        sortable: true,
        render: ({item}) => item.prize_tracking_user?.full_name || item.client_user?.full_name,
      },
      {
        name: 'title',
        label: lang.TITLE,
        path: 'title',
        width: 130,
        render: ({item}) => (item.prize_tracking_user ? lang.PRIZE_TRACKING : item.title),
      },
      {
        name: 'gender',
        label: lang.GENDER,
        path: 'client_user.title',
        width: 94,
        formatter: formatGender,
      },
      {
        name: 'email',
        label: lang.EMAIL,
        width: 222,
        render: ({item}) => item.prize_tracking_user?.login || item.client_user?.email,
      },
      {
        name: 'phone',
        label: lang.PHONE,
        path: 'phone',
        width: 134,
      },
      {
        name: 'clients_access',
        label: lang.CLIENTS_ACCESS,
        show: isAgency,
        width: 134,
        render: ({item}) => renderClientsAccess(item),
      },
      {
        name: 'access_level',
        label: lang.ACCESS_LEVEL,
        width: 134,
        formatter: formatAccessLevel,
        render: ({item}) => !item.prize_tracking_user_id && formatAccessLevel(item.access_level),
      },
      {
        name: 'resend_invitation',
        width: 47,
        cellClassName: b('cell-button'),
        render: ({item: membership}) =>
          canResendInvitation(membership) && (
            <Popover overlay={lang.RESEND_INVITATION.HINT}>
              <AppButton
                iconConfig={{name: 'email-open', width: 24, height: 24}}
                transparent={true}
                onClick={() => onResendInvitation(membership)}
              />
            </Popover>
          ),
      },
      {
        name: 'export_access',
        width: 47,
        cellClassName: b('cell-button'),
        show: ACCESS_LEVEL.CLIENT_ADMIN === userAccessLevel,
        render: ({item: membership}) =>
          ACCESS_LEVEL.CLIENT_ADMIN !== membership.access_level &&
          !membership.prize_tracking_user_id && (
            <Popover overlay={lang.EXPORT_ACCESS}>
              <AppButton iconName="share-export" transparent onClick={() => onOpenExportAccess(membership)} />
            </Popover>
          ),
      },
      {
        name: 'edit',
        width: 47,
        cellClassName: b('cell-button'),
        render: ({item: membership}) => {
          const isTrackingUser = Boolean(membership.prize_tracking_user);
          const handler = isTrackingUser ? onEditTrackingUser : onEditUser;
          return (
            (crudUsers || isTrackingUser) && (
              <Popover overlay={lang.EDIT_USER}>
                <AppButton iconName="edit-alt" transparent onClick={() => handler(membership)} />
              </Popover>
            )
          );
        },
      },
      {
        name: 'delete',
        width: 47,
        cellClassName: b('cell-button'),
        render: ({item: membership}) => {
          const canDelete = !!membership.prize_tracking_user;
          const canDeactivate = crudUsers && membership.id !== currentMembership?.id;
          return (
            (canDelete || canDeactivate) && (
              <Popover
                overlay={canDelete ? lang.DELETE_USER : lang.DEACTIVATE_USER}
                flipOptions={{mainAxis: true}}
                shiftOptions={{mainAxis: true}}
              >
                <AppButton
                  iconName="trash"
                  transparent
                  width={19}
                  height={19}
                  onClick={() => (canDelete ? setDeletedUser(membership) : setDeactivatedUser(membership))}
                />
              </Popover>
            )
          );
        },
      },
    ];

    return result;
  }, [
    currentMembership?.id,
    lang,
    formatGender,
    isAgency,
    formatAccessLevel,
    userAccessLevel,
    crudUsers,
    renderClientsAccess,
    onOpenExportAccess,
    onEditUser,
    onResendInvitation,
    canResendInvitation,
    onEditTrackingUser,
  ]);

  return (
    <div className="account-users-table">
      <ClientTable
        data={data}
        columns={columns}
        sortField={sortField}
        sortOrder={sortOrder}
        onSortChange={onSortChange}
        loading={loading}
        loadingColor="primary"
      />

      <DeactivateMembershipModal
        clientId={client.id}
        onDeactivated={refetchData}
        membership={deactivatedUser}
        onClose={() => setDeactivatedUser(null)}
      />
      <DeleteTrackingUserModal
        onDeactivated={refetchData}
        membership={deletedUser}
        onClose={() => setDeletedUser(null)}
      />
    </div>
  );
};

export default AccountUsersTable;
