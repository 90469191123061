import React, {useState} from 'react';

import cn from 'classnames';
import {useSelector} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {selectCurrentClient} from 'client/ducks/clients-list/selectors';
import {selectClientAccessLevel, selectCurrentMembership} from 'client/ducks/user-clients/selectors';
import {selectViewMode} from 'client/ducks/user/selectors';

import Icon from 'client/common/icon';
import LayoutPanel from 'client/common/panels/layout-panel';
import Dropdown from 'client/common/selects/dropdown';

import TemplateDeleteConfirmation from 'client/components/catalog-templates/confirmations/template-delete-configmation';
import TemplateInfoModal from 'client/components/catalog-templates/modals/template-info-modal';
import {ClientTemplateType} from 'client/components/catalog-templates/types';
import DIYCustomizationModal from 'client/components/diy-operation/modals/diy-customization-modal';
import {getFormByType} from 'client/components/diy-operation/modals/diy-customization-modal/helpers';
import useCustomizationModal from 'client/components/diy-operation/modals/diy-customization-modal/useCustomizationModal';
import DiyOpTemplateModal from 'client/components/diy-operation/modals/diy-op-template-modal';
import {DiyOpTemplateMode} from 'client/components/diy-operation/modals/diy-op-template-modal/types';
import DiyOperationModal from 'client/components/operations-client/components/modals/diy-operation-modal';
import {mapBroadcasts} from 'client/components/operations-client/components/modals/diy-operation-modal/helpers';
import {Client} from 'client/models/client/types';
import {ACCESS_LEVEL} from 'client/models/common/constants';
import {FORM_TYPES} from 'client/models/diy-customization-form/constants';
import {FormType} from 'client/models/diy-customization-form/types';
import {operationHasConfigTab} from 'client/models/operations/helpers';
import {CATALOG_TEMPLATE_TABS} from 'client/models/templates/constants';
import {ClientTemplate, CatalogTemplateTabType} from 'client/models/templates/types';

import cssModule from './catalog-template-card.module.scss';

const b = bem('catalog-template-card', {cssModule});

const ACTIONS = {
  EDIT_AND_SHARE: 'EDIT_AND_SHARE',
  SHARE_WITH_CLIENTS: 'SHARE_WITH_CLIENTS',
  SHOW_INFO: 'SHOW_INFO',
  CREATE_OPERATION: 'CREATE_OPERATION',
  DUPLICATE: 'DUPLICATE',
  CONFIGURATION: 'CONFIGURATION',
  DELETE: 'DELETE',
  DESIGN: 'DESIGN',
} as const;

type CatalogTemplateCardProps = {
  className?: string;
  template: ClientTemplate;
  type: CatalogTemplateTabType;
  templateType: ClientTemplateType;
  fetchData: () => Promise<void>;
  updateTemplate: (id: number, data: Partial<ClientTemplate>) => void;
  isModule?: boolean;
  agencyClient?: Client | null;
};

const CatalogTemplateCard: React.FC<CatalogTemplateCardProps> = (props) => {
  const {className, template, type, templateType, fetchData, updateTemplate, isModule, agencyClient} = props;
  const lang = useLanguage('CATALOG_TEMPLATES.CARDS.CATALOG_TEMPLATE_CARD');

  const [openedActions, setOpenedActions] = useState(false);
  const [editingMode, setEditingMode] = useState<DiyOpTemplateMode | null>(null);
  const [showDeleteModal, toggleDeleteModal] = useToggle(false);
  const [showInfoModal, toggleInfoModal] = useToggle(false);
  const [configFormId, setConfigFormId] = useState<number | null>(null);
  const [showCreateOperationModal, toggleCreateOperationModal] = useToggle(false);

  const {onOpenCustomizationModal, onCloseCustomizationModal} = useCustomizationModal();

  const {id: viewUserId} = useSelector(selectViewMode);
  const generalClient = useSelector(selectCurrentClient);
  const accessLevel = useSelector(selectClientAccessLevel);
  const clientUserMembership = useSelector(selectCurrentMembership);

  const client = agencyClient || generalClient;
  const isAgencyView = Boolean(agencyClient);

  const hasParam = getFormByType(template, FORM_TYPES.PARAM) !== null;
  const hasDesign = getFormByType(template, FORM_TYPES.DESIGN) !== null;
  const isMyTemplate = type === CATALOG_TEMPLATE_TABS.MY_TEMPLATES;

  const sharing =
    template?.user_access_levels.includes(ACCESS_LEVEL.REGION) ||
    template?.user_access_levels.includes(ACCESS_LEVEL.STORE);

  const canCreateOperation = operationHasConfigTab({
    client,
    accessLevel,
    membershipIsEditor: clientUserMembership?.editor,
    viewUserId,
  });

  const openCustomModal = async (formType: FormType) => {
    const form = await onOpenCustomizationModal(template, formType);

    if (form) {
      setConfigFormId(form.id);
    }
  };

  const closeCustomModal = () => {
    onCloseCustomizationModal();
    setConfigFormId(null);
    setOpenedActions(false);
  };

  const broadcasts = mapBroadcasts(template.image_formats);

  return (
    <LayoutPanel className={cn(b({opened: openedActions}), className)}>
      {isModule && <Icon name={type} className={b('icon')} />}
      {showCreateOperationModal && (
        <DiyOperationModal
          show
          onClose={() => {
            setOpenedActions(false);
            toggleCreateOperationModal();
          }}
          generalTemplateId={template.id}
          client={client}
        />
      )}
      {configFormId && (
        <DIYCustomizationModal
          formId={configFormId}
          clientId={template.client_id}
          onClose={closeCustomModal}
          isAccessGranted={template?.form_accesses_editable && sharing}
          template={template}
          online={broadcasts.online}
          device={broadcasts.horizontal || broadcasts.vertical}
          disabled={false}
        />
      )}
      {editingMode && (
        <DiyOpTemplateModal
          onClose={() => {
            setEditingMode(null);
            setOpenedActions(false);
          }}
          mode={editingMode}
          type={templateType}
          template={template}
          onSave={fetchData}
          isModule={isModule}
        />
      )}
      {showInfoModal && (
        <TemplateInfoModal
          template={template}
          updateTemplate={updateTemplate}
          isModule={Boolean(isModule)}
          isMyTemplate={isMyTemplate}
          onClose={toggleInfoModal}
        />
      )}
      <TemplateDeleteConfirmation
        show={showDeleteModal}
        template={template}
        templateType={templateType}
        onClose={() => {
          toggleDeleteModal();
          setOpenedActions(false);
        }}
        onDelete={fetchData}
      />
      <figure className={b('image-container')} role="button" tabIndex={-1} onClick={toggleInfoModal}>
        {template.icon_url ? (
          <img src={template.icon_url} alt={template.title} className={b('image')} />
        ) : (
          <div className={b('image', ['placeholder'])}>
            <Icon name="image-placeholder" />
          </div>
        )}
        <div className={b('info-overlay')}>
          <Icon name="search-1" width={50} height={50} />
        </div>
      </figure>
      <figcaption className={b('footer')}>
        <span className={b('title')}>{template.title}</span>
        <Dropdown
          hideIfEmpty
          onOpen={setOpenedActions}
          options={[
            {
              label: lang.CREATE_OPERATION_ACTION,
              value: ACTIONS.CREATE_OPERATION,
              exclude: !canCreateOperation || isModule,
              onClick: toggleCreateOperationModal,
            },
            {
              label:
                accessLevel === ACCESS_LEVEL.STORE || !client.client_network
                  ? lang.EDIT_ACTION
                  : lang.EDIT_AND_SHARE_ACTION,
              value: ACTIONS.EDIT_AND_SHARE,
              exclude: !isMyTemplate || isAgencyView,
              onClick: () => setEditingMode('edit'),
            },
            {
              label: lang.SHARE_WITH_CLIENTS,
              value: ACTIONS.SHARE_WITH_CLIENTS,
              exclude: generalClient.type !== 'Agency' || isAgencyView,
              onClick: () => setEditingMode('share'),
            },
            {
              label: lang.DUPLICATE_ACTION,
              value: ACTIONS.DUPLICATE,
              exclude: isAgencyView,
              onClick: () => setEditingMode('duplicate'),
            },
            {
              label: lang.DELETE_ACTION,
              value: ACTIONS.DELETE,
              exclude: !isMyTemplate || isAgencyView,
              onClick: toggleDeleteModal,
            },
            {
              label: lang.CONFIGURATION_GROUP,
              options: [
                {
                  label: (
                    <span className={b('action')}>
                      <Icon name="settings-1" width={16} />
                      {lang.CONFIGURATION_ACTION}
                    </span>
                  ),
                  value: ACTIONS.CONFIGURATION,
                  exclude: !isMyTemplate || !hasParam || isAgencyView,
                  onClick: () => openCustomModal(FORM_TYPES.PARAM),
                },
                {
                  label: (
                    <span className={b('action')}>
                      <Icon name="design" width={16} />
                      {lang.DESIGN_ACTION}
                    </span>
                  ),
                  value: ACTIONS.DESIGN,
                  exclude: !isMyTemplate || !hasDesign || isAgencyView,
                  onClick: () => openCustomModal(FORM_TYPES.DESIGN),
                },
              ],
            },
          ]}
          triggerProps={{transparent: true, color: 'primary', iconName: null}}
          trigger={<Icon name="options" />}
        />
      </figcaption>
    </LayoutPanel>
  );
};

export default CatalogTemplateCard;
