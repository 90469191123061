import React, {PureComponent} from 'react';

import isEqual from 'lodash/isEqual';
import isNull from 'lodash/isNull';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getFormValues, reduxForm} from 'redux-form';

import bem from 'client/services/bem';

import {createCoupon, updateCoupon, deleteCoupon, testSogec} from 'client/ducks/coupons/actions';
import {COUPON_TYPES} from 'client/ducks/coupons/constants';

import AppButton from 'client/common/buttons';
import ConfirmationModal from 'client/common/modals/confirmation-modal';
import Modal from 'client/common/modals/modal';

import {ToggleField} from 'client/components/common/fields';

import AppFieldset from './app-fieldset';
import getInitialValues from './getInitialValues';
import mapFormValues from './mapFormValues';
import SogecFieldset from './sogec-fieldset';
import validate from './validate';
import WeezioFieldset from './weezio-fieldset';

import cssModule from './coupon-edit-modal.module.scss';

const b = bem('coupon-edit-modal', {cssModule});

class CouponEditModal extends PureComponent {
  static propTypes = {
    lang: PropTypes.object.isRequired,
    operation: PropTypes.object.isRequired,
    editingCoupon: PropTypes.object,
    isEdit: PropTypes.bool.isRequired,
    hasTokens: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    fetchData: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    valid: PropTypes.bool.isRequired,
    createCoupon: PropTypes.func.isRequired,
    updateCoupon: PropTypes.func.isRequired,
    deleteCoupon: PropTypes.func.isRequired,
    testSogec: PropTypes.func.isRequired,
  };

  static defaultProps = {
    editingCoupon: null,
  };

  static formName = 'CouponEditModalForm';

  state = {
    showConfirmModal: false,
    showDeleteModal: false,
    sogecSuccess: null,
    sogecError: null,
  };

  componentDidUpdate(prevProps) {
    this.handleSogecStatusClearing(prevProps);
  }

  handleSogecStatusClearing = (prevProps) => {
    if (!isEqual(this.props.formValues, prevProps.formValues) && this.state.sogecSuccess !== null) {
      this.setState({
        sogecSuccess: null,
        sogecError: null,
      });
    }
  };

  handleSave = (values) => {
    const {editingCoupon, operation} = this.props;

    const data = mapFormValues({operationId: operation?.id, ...values}, {operation});

    const promise = editingCoupon
      ? this.props.updateCoupon(editingCoupon.id, values.type, data)
      : this.props.createCoupon(values.type, data);

    return promise.then(this.props.fetchData).then(this.props.onClose);
  };

  handleDelete = () => {
    const {editingCoupon} = this.props;

    return this.props
      .deleteCoupon(editingCoupon.id, editingCoupon.coupon_type)
      .then(this.props.fetchData)
      .then(this.props.onClose);
  };

  handleTestSogec = () => {
    const {formValues} = this.props;

    const data = {
      sogec_coupon: {
        sogec_configuration: {
          login: formValues.login,
          password: formValues.password,
        },
      },
    };

    this.props.testSogec(data).then(({payload}) => {
      this.setState({
        sogecSuccess: payload.success,
        sogecError: payload.errors || null,
      });
    });
  };

  toggleConfirmModal = () => this.setState(({showConfirmModal}) => ({showConfirmModal: !showConfirmModal}));

  toggleDeleteModal = () => this.setState(({showDeleteModal}) => ({showDeleteModal: !showDeleteModal}));

  renderConfirmModal = () => {
    const {lang, handleSubmit} = this.props;

    if (!this.state.showConfirmModal) {
      return null;
    }

    return (
      <ConfirmationModal
        show={true}
        onCancel={this.toggleConfirmModal}
        onClose={this.toggleConfirmModal}
        onConfirm={handleSubmit(this.handleSave)}
        clientSide={true}
        buttonConfirm={{
          color: 'coupons',
          label: lang.CONFIRM_OK,
        }}
        buttonCancel={{
          color: 'coupons',
          label: lang.CANCEL,
        }}
        message={
          <>
            <span>{lang.CONFIRM_MESSAGE_1}</span>
            <span>{lang.CONFIRM_MESSAGE_2}</span>
          </>
        }
      />
    );
  };

  renderDeleteModal = () => {
    const {lang} = this.props;

    if (!this.state.showDeleteModal) {
      return null;
    }

    return (
      <ConfirmationModal
        show={true}
        onCancel={this.toggleDeleteModal}
        onClose={this.toggleDeleteModal}
        onConfirm={this.handleDelete}
        message={lang.DELETE_MESSAGE}
        clientSide={true}
        buttonConfirm={{
          color: 'coupons',
          label: lang.DELETE_OK,
        }}
        buttonCancel={{
          color: 'coupons',
          label: lang.CANCEL,
        }}
      />
    );
  };

  render() {
    const {lang, isEdit, hasTokens, onClose, formValues, handleSubmit, valid, editingCoupon} = this.props;
    const {sogecSuccess, sogecError} = this.state;

    const hasPrizes = isEdit && editingCoupon.prizes.length > 0;

    return (
      <Modal classNames={b({wide: formValues.type === COUPON_TYPES.WEEZIO})} onClose={onClose}>
        <form onSubmit={handleSubmit(this.handleSave)}>
          {this.renderConfirmModal()}
          {this.renderDeleteModal()}
          <div className={b('header')}>
            <p className={b('title')}>{isEdit ? lang.EDIT_COUPON : lang.ADD_COUPON}</p>
            {isEdit && <ToggleField name="test_mode" label={lang.TEST_MODE} inline withWrap={false} />}
            {isEdit && !hasTokens && (
              <AppButton
                iconName="trash"
                onClick={this.toggleDeleteModal}
                disabled={hasPrizes}
                width={19}
                height={19}
                rounded={true}
                color="text-additional"
              />
            )}
          </div>
          <div className={b('content')}>
            <AppFieldset {...this.props} />
            {formValues.type === COUPON_TYPES.WEEZIO && <WeezioFieldset {...this.props} />}
            {formValues.type === COUPON_TYPES.SOGEC && <SogecFieldset {...this.props} />}
          </div>
          <div className={b('buttons')}>
            {isEdit && <AppButton label={lang.CANCEL} onClick={onClose} transparent={true} color="coupons" />}
            {formValues.type === COUPON_TYPES.SOGEC && (
              <div>
                <AppButton
                  label={lang.TEST}
                  onClick={this.handleTestSogec}
                  disabled={!valid}
                  color="coupons"
                  {...(!isNull(sogecSuccess) && {
                    iconConfig: {name: sogecSuccess ? 'sogec-success' : 'sogec-error'},
                  })}
                />
                <p className={b('sogec-test-error')}>{sogecError}</p>
              </div>
            )}
            {isEdit && (
              <AppButton
                label={lang.SAVE_CHANGES}
                submit={!hasTokens}
                onClick={hasTokens ? this.toggleConfirmModal : null}
                disabled={!valid}
                color="coupons"
              />
            )}
            {!isEdit && <AppButton color="coupons" label={lang.CREATE_COUPON} submit={true} disabled={!valid} />}
          </div>
        </form>
      </Modal>
    );
  }
}

const CouponEditModalForm = reduxForm({
  form: CouponEditModal.formName,
  shouldValidate: () => true,
  validate,
})(CouponEditModal);

export default connect(
  (state, props) => ({
    lang: {
      ...state.languageState.payload.OPERATION_PAGE.COUPONS_CARD.COUPON_FIELDS,
      ...state.languageState.payload.OPERATION_PAGE.COUPONS_CARD.COUPON_EDIT_MODAL,
    },
    formValues: getFormValues(CouponEditModal.formName)(state) || {},
    initialValues: getInitialValues(props.editingCoupon, {timezone: props.operation.timezone}),
    isEdit: !!props.editingCoupon,
    hasTokens: !!props.editingCoupon && props.editingCoupon.number_of_generated_tokens > 0,
  }),
  {
    createCoupon,
    updateCoupon,
    deleteCoupon,
    testSogec,
  },
)(CouponEditModalForm);
