import {LangsByPath} from 'client/services/hooks/useLanguage/types';
import {email, isNotEmpty, required} from 'client/services/validator';

import {USER_MEMBERSHIP} from 'client/common/config';

import {Client} from 'client/models/client/types';
import {ACCESS_LEVEL} from 'client/models/common/constants';
import {AccessLevelType} from 'client/models/common/types';
import {User} from 'client/models/users/types';

import {ClientUserModalForm} from './types';

export default (
  values: ClientUserModalForm,
  params: {
    lang: LangsByPath<['CLIENT_ACCOUNT_PARAMETERS.MODALS.CLIENT_USER.ERRORS']>;
    currentStep: number;
    client: Client;
    userByEmail: User | null;
    hasEmailStep: boolean;
  },
) => {
  const {lang, client, currentStep, userByEmail, hasEmailStep} = params;
  const isAgency = client.type === 'Agency';
  const skipGeneralStep = !!userByEmail;

  const emailStepIndex = 0;
  const generalStepIndex = hasEmailStep ? 1 : 0;
  const membershipStepIndex = skipGeneralStep ? 1 : 2;
  const companyStepIndex = skipGeneralStep ? 2 : 3;
  const regionStepIndex = companyStepIndex;
  const placeStepIndex = companyStepIndex;

  const formMembership = values.memberships[0];

  const hasClientsStep =
    isAgency && formMembership?.access_level === USER_MEMBERSHIP.NATIONAL && !formMembership.all_companies_access;

  const hasRegionsStep =
    !!formMembership?.access_level &&
    ([ACCESS_LEVEL.REGION, ACCESS_LEVEL.LOCAL] as AccessLevelType[]).includes(formMembership?.access_level);
  const hasStoresStep = formMembership?.access_level === USER_MEMBERSHIP.STORE;

  const errors: Record<string, any> = {memberships: [{}]};

  if (currentStep === emailStepIndex) {
    errors.email = email(lang.EMAIL_FORMAT_INCORRECT)(values.email) || required(lang.REQUIRED)(values.email);
  }
  if (currentStep === generalStepIndex) {
    errors.first_name = required(lang.REQUIRED)(values.first_name);
    errors.last_name = required(lang.REQUIRED)(values.last_name);
  }
  if (currentStep === membershipStepIndex) {
    errors.memberships[0].access_level = required(lang.REQUIRED)(formMembership.access_level);
    errors.last_name = required(lang.REQUIRED)(values.last_name);
  }
  if (isAgency) {
    if (hasClientsStep && currentStep === companyStepIndex) {
      errors.memberships[0].company_accesses = isNotEmpty(lang.REQUIRED)(formMembership.company_accesses);
    }
  } else if (hasRegionsStep && currentStep === regionStepIndex) {
    errors.memberships[0].region_accesses = isNotEmpty(lang.REQUIRED)(formMembership.region_accesses);
  } else if (hasStoresStep && currentStep === placeStepIndex) {
    errors.memberships[0].place_accesses = isNotEmpty(lang.REQUIRED)(formMembership.place_accesses);
  }

  return errors;
};
