import React, {Component} from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {formatNumberSpace} from 'client/services/formatters';
import {doesValueExist} from 'client/services/helpers';

import Popover from 'client/common/popovers/popover';

import LineChart from 'client/components/common/charts/line-chart';
import ClientTable from 'client/components/common/client-table';
import Icon from 'client/components/common/icon';
import Tooltip from 'client/components/common/tooltips/tooltip';

import DatetimeCell from 'client/components/tables/common/cells/datetime-cell';
import {MAP_ICONS_INTERACTION_STATUSES} from 'client/models/interactions/constants';

import './plans-table.scss';

class PlansTable extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    languageState: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    showSinglePlaceOnMap: PropTypes.func.isRequired,
    onParamsChange: PropTypes.func.isRequired,
    type: PropTypes.string,
    maxVisitsParticipations: PropTypes.number,
    loading: PropTypes.bool.isRequired,
  };

  static TABLE_TYPES = {
    DEVICES: 'devices',
    PLACES: 'places',
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.AUTOTASK_PLAN.CLIENT_PLACES;

    this.devicesColumns = [
      {
        name: 'interface_name',
        label: this.LANGUAGE.INTERFACE_COLUMN,
        path: 'interface.name',
        sortable: true,
        width: '110px',
        hintRender: this.renderTooltipInterface,
      },
      {
        name: 'current_status',
        label: this.LANGUAGE.STATUS,
        path: 'current_status',
        width: '80px',
        hintRender: this.renderTooltipStatus,
        render: this.renderStatus,
      },
      {
        name: 'place_name',
        label: this.LANGUAGE.PLACE_COLUMN,
        path: 'place.name',
        sortable: true,
        width: '110px',
        initialSortOrder: 'asc',
      },
      {
        name: 'place_city_name',
        label: this.LANGUAGE.CITY_COLUMN,
        path: 'place.city_name',
        sortable: true,
        width: '100px',
      },
      {
        name: 'place_street_address',
        label: this.LANGUAGE.ADDRESS_COLUMN,
        path: 'place.street_address',
        rowSpan: 2,
        width: '115px',
      },
      {
        name: 'last_participation',
        label: this.LANGUAGE.ATTRIBUTION_COLUMN,
        subcolumns: [
          {
            name: 'accessible_region_name',
            label: this.LANGUAGE.REGION_COLUMN,
            sortable: true,
            path: 'location_of_Region_type_name',
            width: '80px',
            initialSortOrder: 'asc',
            render: (item) => <div className="client-table__value">{this.renderRegion(item)}</div>,
          },
          {
            name: 'accessible_store_name',
            label: this.LANGUAGE.STORE_COLUMN,
            sortable: true,
            width: '80px',
            path: 'location_of_Place_type_name',
            initialSortOrder: 'asc',
            render: (item) => <div className="client-table__value">{this.renderStore(item)}</div>,
          },
        ],
      },
      {
        name: 'from',
        label: this.LANGUAGE.START_DATE_COLUMN,
        path: 'from',
        sortable: true,
        width: '115px',
        render: (item) => DatetimeCell(item),
      },
      {
        name: 'to',
        label: this.LANGUAGE.END_DATE_COLUMN,
        path: 'to',
        sortable: true,
        width: '115px',
        render: (item) => DatetimeCell(item),
      },
      {
        name: 'full_participations_count',
        label: this.LANGUAGE.VISITS_COLUMN,
        path: 'full_participations_count',
        width: '105px',
        formatter: formatNumberSpace,
      },
      {
        name: 'full_accomplished_participations_count',
        label: this.LANGUAGE.PARTICIPATIONS_COLUMN,
        path: 'full_accomplished_participations_count',
        width: '105px',
        formatter: formatNumberSpace,
      },
      {
        name: 'visits_participations_count',
        label: this.LANGUAGE.PARTICIPATIONS_VISITS_COLUMN,
        width: '120px',
        render: (el) => {
          const dataVisitsParticipations = [
            {
              value: el.item.full_participations_count,
              color: '#8ED2F8',
            },
            {
              value: el.item.full_accomplished_participations_count,
              color: '#5DC4C4',
            },
          ];

          return (
            <div className="client-table__value">
              <LineChart data={dataVisitsParticipations} total={this.props.maxVisitsParticipations} />
            </div>
          );
        },
      },
      {
        name: 'actions',
        label: '',
        path: 'actions',
        width: '50px',
        render: this.renderActions,
      },
    ];

    this.placesColumns = [
      {
        name: 'name',
        label: this.LANGUAGE.PLACE_COLUMN,
        path: 'name',
        sortable: true,
        width: '110px',
        initialSortOrder: 'asc',
      },
      {
        name: 'interface_name',
        label: this.LANGUAGE.INTERFACE_COLUMN,
        sortable: true,
        width: '110px',
        render: ({item}) =>
          item.offline_interactions.map((interaction) => (
            <div key={interaction.id} className="plans-table__cell-line">
              {doesValueExist(interaction.interface) && (
                <Popover position="top" overlay={this.renderTooltipInterface(interaction)}>
                  {interaction.interface.name}
                </Popover>
              )}
            </div>
          )),
      },
      {
        name: 'current_status',
        label: this.LANGUAGE.STATUS,
        path: 'current_status',
        width: '80px',
        render: ({item}) =>
          item.offline_interactions.map((interaction) => (
            <div key={interaction.id} className="plans-table__cell-line">
              <Popover position="top" overlay={this.renderTooltipStatus(interaction)}>
                {this.renderStatus({item: interaction})}
              </Popover>
            </div>
          )),
      },
      {
        name: 'place_city_name',
        label: this.LANGUAGE.CITY_COLUMN,
        path: 'place.city_name',
        width: '100px',
        sortable: true,
        render: ({item}) =>
          item.offline_interactions[0] && (
            <div key={item.offline_interactions[0].id} className="plans-table__cell-line">
              {item.offline_interactions[0].place.city_name}
            </div>
          ),
      },
      {
        name: 'place_street_address',
        label: this.LANGUAGE.ADDRESS_COLUMN,
        path: 'place.street_address',
        width: '115px',
        render: ({item}) =>
          item.offline_interactions[0] && (
            <div
              key={item.offline_interactions[0].id}
              className="plans-table__cell-line"
              title={item.offline_interactions[0].place.street_address}
            >
              {item.offline_interactions[0].place.street_address}
            </div>
          ),
      },
      {
        name: 'last_participation',
        label: this.LANGUAGE.ATTRIBUTION_COLUMN,
        subcolumns: [
          {
            name: 'accessible_region_name',
            label: this.LANGUAGE.REGION_COLUMN,
            width: '80px',
            path: 'location_of_Region_type_name',
            sortable: true,
            initialSortOrder: 'asc',
            render: ({item}) =>
              item.offline_interactions.map((interaction) => (
                <div key={interaction.id} className="plans-table__cell-line">
                  {this.renderRegion({item: interaction})}
                </div>
              )),
          },
          {
            name: 'accessible_store_name',
            label: this.LANGUAGE.STORE_COLUMN,
            sortable: true,
            width: '80px',
            path: 'location_of_Place_type_name',
            initialSortOrder: 'asc',
            render: ({item}) =>
              item.offline_interactions.map((interaction) => (
                <div key={interaction.id} className="plans-table__cell-line">
                  {this.renderStore({item: interaction})}
                </div>
              )),
          },
        ],
      },
      {
        name: 'from',
        label: this.LANGUAGE.START_DATE_COLUMN,
        path: 'from',
        width: '115px',
        sortable: true,
        render: (el) => {
          return el.item.offline_interactions.map((item) => (
            <div key={item.id} className="plans-table__cell-line">
              <DatetimeCell key={item.id} value={item.from} />
            </div>
          ));
        },
      },
      {
        name: 'to',
        label: this.LANGUAGE.END_DATE_COLUMN,
        path: 'to',
        width: '115px',
        sortable: true,
        render: (el) => {
          return el.item.offline_interactions.map((item) => (
            <div key={item.id} className="plans-table__cell-line">
              <DatetimeCell key={item.id} value={item.to} />
            </div>
          ));
        },
      },
      {
        name: 'full_participations_count',
        label: this.LANGUAGE.VISITS_COLUMN,
        path: 'full_participations_count',
        width: '105px',
        render: ({item}) => {
          const full_participations_count_total = item.offline_interactions
            .map((interaction) => interaction.full_participations_count)
            .reduce((sum, i) => sum + i, 0);

          return (
            item.offline_interactions[0] && (
              <div key={item.offline_interactions[0].id} className="client-table__value">
                {formatNumberSpace(full_participations_count_total)}
              </div>
            )
          );
        },
      },
      {
        name: 'full_accomplished_participations_count',
        label: this.LANGUAGE.PARTICIPATIONS_COLUMN,
        path: 'full_accomplished_participations_count',
        width: '105px',
        render: ({item}) => {
          const full_accomplished_participations_count_total = item.offline_interactions
            .map((interaction) => interaction.full_accomplished_participations_count)
            .reduce((sum, i) => sum + i, 0);

          return (
            item.offline_interactions[0] && (
              <div key={item.offline_interactions[0].id} className="client-table__value">
                {formatNumberSpace(full_accomplished_participations_count_total)}
              </div>
            )
          );
        },
      },
      {
        name: 'visits_participations_count',
        label: this.LANGUAGE.PARTICIPATIONS_VISITS_COLUMN,
        width: '120px',
        render: ({item}) => {
          const allVisits = item.offline_interactions.reduce((sum, i) => sum + i.full_participations_count, 0);
          const allParticipations = item.offline_interactions.reduce(
            (sum, i) => sum + i.full_accomplished_participations_count,
            0,
          );
          const dataVisitsParticipations = [
            {
              value: allVisits,
              color: '#8ED2F8',
            },
            {
              value: allParticipations,
              color: '#5DC4C4',
            },
          ];
          return (
            item.offline_interactions[0] && (
              <div key={item.offline_interactions[0].id}>
                <LineChart data={dataVisitsParticipations} total={this.props.maxVisitsParticipations} />
              </div>
            )
          );
        },
      },
      {
        name: 'actions',
        label: '',
        path: 'actions',
        width: '50px',
        render: ({item}) =>
          item.offline_interactions[0] && (
            <div key={item.offline_interactions[0].id} className="plans-table__cell-line">
              {this.renderActions({item: item.offline_interactions[0]})}
            </div>
          ),
      },
    ];
  }

  renderRegion = ({item}) => {
    return get(item, 'accessible_region.name');
  };

  renderStore = ({item}) => {
    return get(item, 'accessible_store.name');
  };

  renderStatus = ({item}) => (
    <Icon name={MAP_ICONS_INTERACTION_STATUSES[item.current_status || 0]} style={{color: '#00629C'}} />
  );

  renderTooltipInterface = ({device = {}}) => <Tooltip title={this.LANGUAGE.DEVICE_NAME} name={device.name} />;

  renderTooltipStatus = ({current_status}) => <p>{this.LANGUAGE.STATUS_1[current_status] || ''}</p>;

  renderActions = ({item}) => {
    const {showSinglePlaceOnMap} = this.props;

    return (
      <button className="client-table__button-map" onClick={() => showSinglePlaceOnMap(item.place.id)}>
        <Icon name="map" />
      </button>
    );
  };

  render() {
    const {data, params, type, onParamsChange, loading} = this.props;

    return (
      <div className={`plans-table${type === PlansTable.TABLE_TYPES.PLACES ? ' plans-table--places' : ''}`}>
        <ClientTable
          data={data}
          columns={type === PlansTable.TABLE_TYPES.DEVICES ? this.devicesColumns : this.placesColumns}
          sortField={params.sortField}
          sortOrder={params.sortOrder}
          onSortChange={onParamsChange}
          loading={loading}
          loadingColor="participants"
          iconClassName="plans-table__empty-icon"
        />
      </div>
    );
  }
}

export default connect(({languageState}) => ({languageState}))(PlansTable);
