import React, {useState, useCallback} from 'react';

import {useDispatch, useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';
import {parseDate} from 'client/services/utils/date';

import {selectCurrentClient} from 'client/ducks/clients-list/selectors';
import {updateGame} from 'client/ducks/games/actions';
import {selectOperation} from 'client/ducks/operations/selectors';
import {selectViewMode} from 'client/ducks/user/selectors';

import AppButton from 'client/common/buttons';
import DatepickerInput from 'client/common/inputs/datepicker-input';

import {DIY_OPERATION_STATUSES} from 'client/components/diy-operation/constants';
import {Translation} from 'client/models/language/types';
import {ApiDispatch} from 'client/types';

import {validateDate} from './helpers';

import cssModule from './diy-op-prizes-block-header.module.scss';

const b = bem('diy-op-prizes-block-header', {cssModule});

type DiyOpPrizesBlockHeaderProps = {
  openAddPrizeModal: () => void;
  fetchPrizes: () => void;
  date_from?: string;
  date_to?: string;
  gameId?: number;
  isOperationArchived: boolean;
};

type DatesItem = {
  value_from?: string;
  value_to?: string;
  error_from: Translation | null;
  error_to: Translation | null;
};

const DiyOpPrizesBlockHeader: React.FC<DiyOpPrizesBlockHeaderProps> = (props) => {
  const {openAddPrizeModal, date_from = '', date_to = '', gameId, fetchPrizes, isOperationArchived} = props;
  const dispatch: ApiDispatch = useDispatch();
  const lang = useLanguage('DIY_OPERATION.BLOCKS.DIY_OP_PRIZES_BLOCK.DIY_OP_PRIZES_BLOCK_HEADER');

  const operation = useSelector(selectOperation);
  const client = useSelector(selectCurrentClient);
  const {on: isViewMode} = useSelector(selectViewMode);
  const timezone = operation.timezone;

  const initialFrom = date_from ? parseDate(date_from, {timezone, outputFormat: 'DATE'}) : '';
  const initialTo = date_to ? parseDate(date_to, {timezone, outputFormat: 'DATE'}) : '';

  const [dates, setDates] = useState<DatesItem>({
    value_from: initialFrom,
    value_to: initialTo,
    error_from: date_from || !gameId ? null : lang.ERRORS.REQUIRED,
    error_to: date_to || !gameId ? null : lang.ERRORS.REQUIRED,
  });

  const setDatesItem = (item: DatesItem) => {
    setDates((prev) => ({
      ...prev,
      ...item,
    }));
  };

  const updateDates = useCallback(
    (from, to) => {
      const data = {
        default_from: parseDate(from, {timezone, outputFormat: 'ISO', time: 'startDay', timezoneForce: true}),
        default_to: parseDate(to, {timezone, outputFormat: 'ISO', time: 'endDay', timezoneForce: true}),
      };
      dispatch(updateGame(gameId, data)).then(fetchPrizes);
    },
    [dispatch, fetchPrizes, gameId, timezone],
  );

  const handleDateChange = (value: string, type: 'from' | 'to') => {
    if (dates[`value_${type}`] !== value) {
      const updatedDates = {
        value_from: dates.value_from,
        value_to: dates.value_to,
        [`value_${type}`]: value,
      } as Record<string, string>;

      const fromError = validateDate(updatedDates, 'from');
      const toError = validateDate(updatedDates, 'to');

      setDatesItem({
        [`value_${type}`]: value,
        error_from: lang.ERRORS[fromError as keyof typeof lang.ERRORS],
        error_to: lang.ERRORS[toError as keyof typeof lang.ERRORS],
      });

      if (!fromError && !toError) {
        updateDates(updatedDates.value_from, updatedDates.value_to);
      }
    }
  };

  const handlePublish = async () => {
    const data = {published_at: new Date().toISOString()};
    await dispatch(updateGame(gameId, data));
    fetchPrizes();
  };

  const areDatesValid = !dates.error_from && !dates.error_to;

  return (
    <div className={b()}>
      <div className={b('block')}>
        <AppButton
          color="light-clients"
          label={`+ ${lang.ADD_PRIZE}`}
          onClick={openAddPrizeModal}
          disabled={!operation.editable || !gameId || !areDatesValid || isOperationArchived}
          title={areDatesValid ? null : lang.FILL_FROM_AND_TO}
        />
        <div>
          <div>{lang.TYPE}:</div>
          <div className={b('type')}>{lang.INSTANT_WIN}</div>
        </div>
      </div>
      <div className={b('block')}>
        <DatepickerInput
          className={b('datepicker')}
          name="from"
          label={lang.FROM}
          value={dates.value_from}
          onChange={(value) => handleDateChange(value, 'from')}
          disabled={!operation.editable || !gameId || isOperationArchived}
          errorMessage={dates.error_from}
          errorClassName={b('date-error')}
          useDirectChange={true}
          required={true}
        />
        <DatepickerInput
          className={b('datepicker')}
          name="to"
          label={lang.TO}
          value={dates.value_to}
          onChange={(value) => handleDateChange(value, 'to')}
          disabled={!operation.editable || !gameId || isOperationArchived}
          errorMessage={dates.error_to}
          errorClassName={b('date-error')}
          useDirectChange={true}
          required={true}
        />
      </div>

      <div className={b('block-vertical')}>
        {(!client.auto_configuration || isViewMode) && (
          <AppButton
            className={b('publish-button')}
            label={lang.PUBLISH}
            iconName="publish"
            onClick={handlePublish}
            disabled={
              operation.status === DIY_OPERATION_STATUSES.FINISHED || !gameId || !areDatesValid || isOperationArchived
            }
          />
        )}
      </div>
    </div>
  );
};

export default DiyOpPrizesBlockHeader;
