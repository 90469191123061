import mapMainValues from './main-params-fieldset/mapFormValues';
import mapSegmentationValues from './segmentation-params-fieldset/mapFormValues';
import {LotteryPrizeValues} from './types';
import mapWinValues from './win-params-fieldset/mapFormValues';

export default async (values: LotteryPrizeValues, {timezone}: {timezone: string}) => {
  const winValues = await mapWinValues(values.winParams);

  return {
    ...mapMainValues(values.mainParams, {timezone}),
    ...mapSegmentationValues(values.segmentationParams),
    ...winValues,
    store_id: values.storeId,
  };
};
