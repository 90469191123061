import {parseDate} from 'client/services/utils/date';

import {TYPE_VALUE} from './constants';

export default (channel, operation, channelIndex = 0) => {
  if (channel) {
    return {
      name: channel.name,
      kind: channel.kind === TYPE_VALUE.IFRAME ? TYPE_VALUE.IFRAME : TYPE_VALUE.MINISITE,
      from: parseDate(channel.from, {timezone: operation.timezone}),
      to: parseDate(channel.to, {timezone: operation.timezone}),
      permanent_source: channel.permanent_source_id && {
        id: channel.permanent_source_id,
      },
      region: channel.region_id && {
        value: channel.region_id,
        label: channel.region_name,
      },
      store: channel.store_id && {
        value: channel.store_id,
        label: channel.store_name,
      },
    };
  }
  return {
    name: `Channel ${channelIndex + 1}`,
    kind: TYPE_VALUE.MINISITE,
    from: parseDate(operation?.from || '', {timezone: operation.timezone, time: 'startDay', timezoneForce: true}),
    to: parseDate(operation?.to || '', {timezone: operation.timezone, time: 'endDay', timezoneForce: true}),
  };
};
