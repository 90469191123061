import set from 'lodash/set';

import {LangsByPath} from 'client/services/hooks/useLanguage/types';
import {isEmptyString} from 'client/services/validator';

import {SourcesValues, SourcesErrors} from './types';

export default (
  values: SourcesValues,
  lang: LangsByPath<['CLIENT_STORES.MODALS.CLIENT_STORES_SOURCES_MODAL.ERRORS']>,
) => {
  const errors: SourcesErrors = {};

  Object.entries(values).forEach(([key, value]) => {
    if (isEmptyString(value.from)) {
      set(errors, `${key}.from`, lang.REQUIRED);
    }
    if (isEmptyString(value.to)) {
      set(errors, `${key}.to`, lang.REQUIRED);
    }
  });

  return errors;
};
