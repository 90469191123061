import moment from 'moment';

import {Interaction} from 'client/models/interactions/types';

export const getSourceStoresPeriod = (interaction?: Interaction) => {
  const fromDates: string[] = [];
  const toDates: string[] = [];

  interaction?.source_stores?.forEach((i) => {
    fromDates.push(i.from);
    toDates.push(i.to);
  });

  const minFrom = fromDates.sort()?.[0];
  const maxTo = toDates.sort()?.reverse()?.[0];

  return {
    from: minFrom ? moment(minFrom).add(1, 'day').toISOString() : minFrom,
    to: maxTo ? moment(maxTo).subtract(1, 'day').toISOString() : maxTo,
  };
};
