import React, {useEffect, useCallback, useState} from 'react';

import merge from 'lodash/merge';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useToggle, useUnmount} from 'react-use';

import {useQueryParams, useAppMedia} from 'client/services/hooks';

import {clearAnimations} from 'client/ducks/animations/actions';

import CardList from 'client/common/cards/card-list';
import InfiniteScroll from 'client/common/various/infinite-scroll';

import InstoreAnimationContainer from 'client/components/animations/containers/animation-config-container';
import AnimationCard from 'client/components/instore/cards/animation-card';
import {useFetchAnimations} from 'client/components/instore/hooks/use-fetch-animations';
import InstoreCampaignSelect from 'client/components/instore/inputs/instore-campaign-select';
import InstoreAnimationTable from 'client/components/instore/tables/instore-animation-table';
import {DEFAULT_PER_PAGE} from 'client/components/instore/tables/instore-animation-table/constants';

import InstorePlanningAnimationListHeader from './instore-planning-animation-list-header';

const InstorePlanningAnimationList = (props) => {
  const {campaignId, campaigns, onChangeCampaign, fetchAnimationsStatistics, taskId} = props;
  const dispatch = useDispatch();
  const [queryParams, setQueryParams] = useQueryParams(
    null,
    {},
    {
      stringify: {
        skipEmptyString: true,
      },
    },
  );
  const {isTablet} = useAppMedia();
  const [showAnimationModal, toggleShowAnimationModal] = useToggle(false);
  const [animation, setAnimation] = useState(null);
  const [page, setPage] = useState(1);
  const [allAnimationsData, setAllAnimationsData] = useState([]);

  const {loading, animationsData, animationsMeta, fetchAnimations} = useFetchAnimations({
    filters: merge(queryParams?.filters, {
      ...queryParams?.filters,
      interaction_group_id: campaignId,
      taskId,
      statuses: [queryParams.filters?.status],
    }),
    sortField: queryParams?.sortField,
    sortOrder: queryParams?.sortOrder,
    search: queryParams?.search,
    page: isTablet ? page : null,
    perPage: isTablet ? DEFAULT_PER_PAGE : null,
    isCampaignRequired: true,
  });

  useEffect(() => {
    if (page === 1) {
      setAllAnimationsData(animationsData);
    } else {
      setAllAnimationsData((prevData) => [...prevData, ...animationsData]);
    }
  }, [animationsData, isTablet, page]);

  useEffect(() => {
    setPage(1);
  }, [campaignId]);

  useUnmount(() => {
    dispatch(clearAnimations());
  });

  const onEdit = (item) => {
    setAnimation(item);
    toggleShowAnimationModal();
  };

  const onSave = () => {
    dispatch(clearAnimations());
    if (page === 1) {
      fetchAnimations();
    } else {
      setPage(1);
    }
    fetchAnimationsStatistics();
    toggleShowAnimationModal();
    setAnimation(null);
  };

  const onClose = useCallback(() => {
    toggleShowAnimationModal();
    setAnimation(null);
  }, [toggleShowAnimationModal]);

  const onSubmitFilters = (filterFields) => {
    setQueryParams({filters: filterFields});
  };

  const listHeader = (
    <InstorePlanningAnimationListHeader
      onSearch={setQueryParams}
      onSubmitFilters={onSubmitFilters}
      onClickAdd={toggleShowAnimationModal}
      searchValue={queryParams.search}
      recordsFound={animationsData.length}
      defaultFilters={queryParams.filters}
      loading={loading}
    />
  );

  return (
    <div>
      {isTablet ? (
        <>
          {!showAnimationModal && (
            <>
              <InstoreCampaignSelect campaigns={campaigns} value={campaignId} onChange={onChangeCampaign} />
              {listHeader}
              <InfiniteScroll loading={loading} page={page} totalPages={animationsMeta.total_pages} setPage={setPage}>
                <CardList Component={AnimationCard} data={allAnimationsData} />
              </InfiniteScroll>
            </>
          )}
        </>
      ) : (
        <>
          {listHeader}
          <InstoreAnimationTable
            animations={animationsData}
            onEdit={onEdit}
            onSortChange={setQueryParams}
            sortField={queryParams.sortField}
            sortOrder={queryParams.sortOrder}
            loading={loading}
          />
        </>
      )}
      {showAnimationModal && <InstoreAnimationContainer onClose={onClose} animation={animation} onSave={onSave} />}
    </div>
  );
};

InstorePlanningAnimationList.propTypes = {
  campaignId: PropTypes.number,
  fetchAnimationsStatistics: PropTypes.func.isRequired,
  campaigns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChangeCampaign: PropTypes.func.isRequired,
  taskId: PropTypes.number.isRequired,
};

InstorePlanningAnimationList.defaultProps = {
  campaignId: null,
};

export default InstorePlanningAnimationList;
