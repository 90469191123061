import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';

import {TaskInfoProps} from './types';

import cssModule from './task-info.module.scss';

const b = bem('task-info', {cssModule});

const TaskInfo: React.FC<TaskInfoProps> = (props) => {
  const {label = '', value = '', className = '', classNames = {}} = props;

  return (
    <div className={cn(b(), className)}>
      {label && <span className={cn(b('label'), classNames?.label)}>{label}</span>}
      {value && (
        <span className={cn(b('value'), classNames?.value)} title={value.toString()}>
          {value}
        </span>
      )}
    </div>
  );
};
export default TaskInfo;
