import React, {memo} from 'react';

import bem from 'client/services/bem';
import {dateToString} from 'client/services/helpers';

import Icon from 'client/components/common/icon';

import {DeviceAffectation} from 'client/models/device-affectations';
import {MAP_ICONS_INTERACTION_STATUSES} from 'client/models/interactions/constants';
import {Translation} from 'client/models/language/types';

import {MAP_ICONS_DEVICE_ACTIVITY} from '../../../constants';

import cssModule from './devices-map-marker-tooltip.module.scss';

const b = bem('devices-map-marker-tooltip', {cssModule});

type DevicesMapMarkerTooltipProps = {
  lang: Record<string, Translation>;
  affectation: DeviceAffectation;
};

export const DevicesMapMarkerTooltip: React.FC<DevicesMapMarkerTooltipProps> = memo(({lang, affectation}) => {
  const device = affectation.device;
  const region = affectation.place?.region;
  const place = affectation.place;
  const interaction = device?.interactions?.find((i) => i.current);
  const autotask = device?.current_interaction?.automation_task;

  const name = device?.media_status !== 'game' ? lang[device?.media_status || ''] : autotask?.name;

  return (
    <div className={b()}>
      {region && (
        <div className={b('row')}>
          <div className={b('col', ['gray'])}>{region.name}</div>
        </div>
      )}

      {place && (
        <div className={b('row')}>
          <div>{place?.city_name}</div>
        </div>
      )}

      <div className={b('row', ['gap'])}>
        <div className={b('col-full', ['bold', 'dark'])}>
          {place?.name} - {device?.code}
        </div>
      </div>

      <div className={b('row', ['gap'])}>
        <div className={b('title', ['dark'])}>{name}</div>
        {device?.media_status === 'game' && interaction?.current_status && (
          <div>
            <Icon name={MAP_ICONS_INTERACTION_STATUSES[interaction?.current_status]} />
          </div>
        )}
      </div>

      <div className={b('row', ['gap'])}>
        <div className={b('participations')}>{interaction?.full_accomplished_participations_count?.toString()}</div>
      </div>

      {device?.media_status === 'game' && (
        <div className={b('row')}>
          <div>
            {dateToString(affectation.from)} - {dateToString(affectation.to)}
          </div>
        </div>
      )}

      {device?.status_activity && (
        <Icon name={MAP_ICONS_DEVICE_ACTIVITY[device?.status_activity]} className={b('status-icon')} />
      )}
    </div>
  );
});
