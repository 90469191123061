import React from 'react';

import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {selectAutotask} from 'client/ducks/autotask/selectors';
import {selectClient} from 'client/ducks/leads-list/selectors';

import ArticlePanel from 'client/common/panels/article-panel';

import DiyOpDiffusionCard from 'client/components/diy-operation/cards/diy-op-diffusion-card';
import {Operation} from 'client/models/operations/types';

import cssModule from './diy-op-diffusion-block.module.scss';

const b = bem('diy-op-diffusion-block', {cssModule});

type DIYOpDiffusionBlockProps = {
  operation: Operation;
  fetchOperation: () => Promise<void>;
};

const DIYOpDiffusionBlock: React.FC<DIYOpDiffusionBlockProps> = (props) => {
  const {operation, fetchOperation} = props;
  const lang = useLanguage('DIY_OPERATION.BLOCKS.DIY_OP_DIFFUSION_BLOCK');
  const client = useSelector(selectClient);
  const autotask = useSelector(selectAutotask);

  const isDevice = Boolean(operation?.client_interface?.device);
  const isWebApp = Boolean(operation?.client_interface?.online);
  const isStore = Boolean(client.client_network && client.available_places_count);

  const isDevicesCardConfigurable = autotask?.interaction_groups?.some((interaction) => interaction.type === 'Plan');
  const isWebAppCardConfigurable = autotask?.interaction_groups?.some((interaction) => interaction.type === 'Campaign');

  return (
    <ArticlePanel className={b()} title={lang.TITLE}>
      {isStore && (
        <DiyOpDiffusionCard
          title={lang.STORES_TITLE}
          value={operation.attached_places_count}
          type="stores"
          total={client.available_places_count}
          className={b('card')}
          showButton={!client.auto_configuration}
          fetchOperation={fetchOperation}
        />
      )}
      {isDevice && (
        <DiyOpDiffusionCard
          title={lang.DEVICES_TITLE}
          value={operation.devices_count}
          type="devices"
          total={client.devices_count}
          className={b('card')}
          disabledConfigurationPrize={isWebApp && !isDevice}
          disabledConfiguration={!isDevicesCardConfigurable}
          showButton={!client.auto_configuration}
          fetchOperation={fetchOperation}
        />
      )}
      {isWebApp && (
        <DiyOpDiffusionCard
          title={lang.ONLINE_TITLE}
          value={operation.online_interactions_count || 0}
          type="online"
          className={b('card')}
          disabledConfigurationPrize={!isWebApp && isDevice}
          disabledConfiguration={!isWebAppCardConfigurable}
          showButton={!client.auto_configuration}
          fetchOperation={fetchOperation}
        />
      )}
    </ArticlePanel>
  );
};

export default DIYOpDiffusionBlock;
