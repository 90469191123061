import {parseDate} from 'client/services/utils/date';

const fields = [
  'kind',
  'source_id',
  'name',
  'comment',
  'url',
  'parameter1',
  'parameter2',
  'parameter3',
  'region_id',
  'store_id',
  'from',
  'to',
  'active',
];

const defaultValues = {
  fromTime: '00:00:00',
  toTime: '23:59:59',
};

export default (onlineInteraction = {}, timezone) => {
  return Object.entries(onlineInteraction).reduce(
    (formValues, [key, value]) => {
      if (fields.includes(key)) {
        if (['from', 'to'].includes(key)) {
          formValues[key] = parseDate(value, {timezone, outputFormat: 'DATE'});
          formValues[`${key}Time`] =
            parseDate(value, {timezone, outputFormat: 'TIME_SECONDS'}) || defaultValues[`${key}Time`];
        } else {
          formValues[key] = value;
        }
      }
      return formValues;
    },
    {...defaultValues},
  );
};
