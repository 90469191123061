export const DATE_FORMAT = 'DD/MM/YYYY HH:mm:ss';

export const GAME_TYPES = {
  INSTANT_WIN: 'instant_win',
  PRIZE_DRAW: 'prize_draw',
  ATTRIBUTION: 'attribution_step',
};

export const PRIZE_MAP_TYPES = {
  GEO_PRIZE_MAP: 'GeoPrizeMap',
  ONLINE_PRIZE_MAP: 'OnlinePrizeMap',
  HYBRID_PRIZE_MAP: 'HybridPrizeMap',
};

export const PRIZE_MAP_LEVEL_TYPES = {
  GLOBAL: 'global',
  PLAN: 'plan',
  DEVICE: 'device',
  PLACE: 'place',
  CAMPAIGN: 'campaign',
  SOURCE: 'source',
};
