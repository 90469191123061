import React from 'react';

import cn from 'classnames';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import {TranslationJsx} from 'client/models/language/types';

import './toggle-input.scss';

const b = bem('toggle-input');

const ToggleInput = (props) => {
  const {
    label,
    labelPosition,
    onChange,
    rounded,
    color,
    borderColor,
    backgroundColor,
    className,
    classNames,
    checked,
    disabled,
    title,
    ...inputProps
  } = props;

  return (
    <label className={cn(b({rounded}), className)}>
      {label && <span className={cn(b('text', [labelPosition]), classNames?.label)}>{label}</span>}
      <input
        type="checkbox"
        className={b('input')}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
        {...omit(inputProps, 'errorMessage', 'warningMessage')}
      />
      <div
        className={b('toggle', {
          [color]: true,
          [`border-${borderColor}`]: true,
          colored: backgroundColor === 'current',
          checked,
          disabled,
        })}
        title={title}
      />
    </label>
  );
};

// can be extended with color variable names from ui-kit
ToggleInput.colorOptions = ['primary', 'success', 'visuals', 'interfaces', 'operationsAdmin', 'users'];

ToggleInput.borderColorOptions = ['current', 'textFieldBorders'];
ToggleInput.bgColorOptions = ['current', 'white'];
ToggleInput.positionOptions = ['left', 'right'];

ToggleInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: TranslationJsx,
  labelPosition: PropTypes.oneOf(ToggleInput.positionOptions),
  checked: PropTypes.bool,
  disabled: PropTypes.bool,

  // styling props
  rounded: PropTypes.bool,
  className: PropTypes.string,
  classNames: PropTypes.shape({
    label: PropTypes.string,
  }),
  color: PropTypes.oneOf(ToggleInput.colorOptions),
  borderColor: PropTypes.oneOf(ToggleInput.borderColorOptions),
  backgroundColor: PropTypes.oneOf(ToggleInput.bgColorOptions),
  title: PropTypes.string,
};

ToggleInput.defaultProps = {
  label: '',
  checked: false,
  disabled: false,
  className: '',
  classNames: null,
  color: 'primary',
  labelPosition: 'left',
  rounded: false,
  borderColor: 'textFieldBorders',
  backgroundColor: 'white',
  title: null,
};

export default ToggleInput;
