import React, {Component} from 'react';

import cn from 'classnames';
import find from 'lodash/find';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import bem from 'client/services/bem';

import PaginationBar from 'client/common/paginations/pagination-bar';
import PerPageDropdown from 'client/common/selects/per-page-dropdown';

import ClientTable from 'client/components/common/client-table';
import CustomScrollbars from 'client/components/common/custom-scrollbars';
import SearchField from 'client/components/common/search-field';

import {PRIZE_MAP_LEVEL_TYPES, PRIZE_MAP_TYPES} from 'client/components/games/game-config-modal/constants';
import PrizeMapCell from 'client/components/tables/common/cells/prize-map-cell';

import cssModule from './at-plan-places-data-grid.module.scss';

const b = bem('at-plan-places-data-grid', {cssModule});

class AtPlanPlacesDataGrid extends Component {
  static propTypes = {
    perPage: PropTypes.number,
    data: PropTypes.array.isRequired,
    meta: PropTypes.object.isRequired,
    onPageChange: PropTypes.func.isRequired,
    onSortChange: PropTypes.func.isRequired,
    onSearchChange: PropTypes.func.isRequired,
    lang: PropTypes.object.isRequired,
    onDevicesViewClick: PropTypes.func.isRequired,
    games: PropTypes.array.isRequired,
  };

  renderText = ({value}, key = 0) => {
    return (
      <div className="ellipsis-text" key={key}>
        {value}
      </div>
    );
  };

  renderDevices = ({value}) => {
    return (
      <CustomScrollbars
        scrollBarProps={{
          autoHeightMax: 200,
        }}
      >
        {value?.map?.(({device}) => this.renderText({value: device.name}, device.id))}
      </CustomScrollbars>
    );
  };

  renderToolbar = () => {
    const {lang} = this.props;

    return (
      <div className="flex-container flex-justify-between flex-align-bottom">
        <SearchField
          onSearch={this.props.onSearchChange}
          onClear={() => this.props.onSearchChange('')}
          defaultSearch={this.props.search}
          cssModifier={cn('input', b('search-input'))}
          placeholder={lang.SEARCH_PLACEHOLDER}
        />

        <div className="flex-container flex-justify-end">
          <button className="button button--bg-4" onClick={this.props.onDevicesViewClick}>
            {lang.DEVICES_VIEW_BUTTON}
          </button>
        </div>
      </div>
    );
  };

  renderPrizeMap =
    (winNumber) =>
    ({item}) => {
      const game = find(this.props.games, {win_number: winNumber});

      if (!game || !item?.prize_maps_present?.[`win${game?.win_number || ''}`]) {
        return null;
      }

      return (
        <PrizeMapCell
          variant="plans"
          game={game}
          levelKey="PLACE"
          prizeMapParams={{
            type_in: [PRIZE_MAP_TYPES.GEO_PRIZE_MAP, PRIZE_MAP_TYPES.HYBRID_PRIZE_MAP],
            geo_level_eq: PRIZE_MAP_LEVEL_TYPES.PLACE,
            geo_source_id_eq: item.id,
          }}
        />
      );
    };

  render() {
    const {meta, onPageChange, data, onSortChange, lang} = this.props;

    return (
      <div>
        <div className={b('toolbar-wrapper')}>
          {this.renderToolbar()}
          <PerPageDropdown
            onChange={(pages) => {
              onPageChange({page: 1, perPage: pages});
            }}
            value={String(this.props.perPage)}
            simpleValue
          />
        </div>
        <ClientTable
          data={data}
          onSortChange={onSortChange}
          sortField={this.props.sort?.name}
          sortOrder={this.props.sort?.order}
          columns={[
            {
              name: 'name',
              path: 'name',
              render: this.renderText,
              label: lang.PLACE_NAME_COLUMN,
              sortable: true,
              width: 200,
            },
            {
              name: 'city_name',
              path: 'city_name',
              render: this.renderText,
              label: lang.CITY_COLUMN,
              sortable: true,
              width: 200,
            },
            {
              path: 'street_address',
              render: this.renderText,
              label: lang.ADDRESS_COLUMN,
              width: 200,
            },
            {path: 'offline_interactions', render: this.renderDevices, label: lang.DEVICES_COLUMN, width: 600},
            {label: `${lang.GAME} 1`, render: this.renderPrizeMap(1), width: 79},
            {label: `${lang.GAME} 2`, render: this.renderPrizeMap(2), width: 79},
            {label: `${lang.GAME} 3`, render: this.renderPrizeMap(3), width: 79},
          ]}
        />
        <PaginationBar
          data={data}
          perPage={this.props.perPage}
          totalPages={meta.total_pages}
          totalItems={meta.total_count}
          currentPage={meta.current_page}
          onPageChange={onPageChange}
        />
      </div>
    );
  }
}

export default connect((state) => ({
  lang: state.languageState.payload.AUTOTASK_PLAN.PLACES_VIEW_TABLE,
}))(AtPlanPlacesDataGrid);
