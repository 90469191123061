import {useMemo} from 'react';

import {useLanguage} from 'client/services/hooks';
import { Translation } from 'client/models/language/types';

const useCalendarUnitTranslations = () => {
  const m = useLanguage('COMMON.CALENDAR_UNITS.MONTHS');
  const w = useLanguage('COMMON.CALENDAR_UNITS.WEEKDAYS');
  const ws = useLanguage('COMMON.CALENDAR_UNITS.WEEKDAYS_SHORT');

  const result = useMemo(() => {
    const getString = (value: Translation | string) => (typeof value === 'string' ? value : value?.toString?.() || '');

    return {
      months: [
        getString(m.JANUARY),
        getString(m.FEBRUARY),
        getString(m.MARCH),
        getString(m.APRIL),
        getString(m.MAY),
        getString(m.JUNE),
        getString(m.JULY),
        getString(m.AUGUST),
        getString(m.SEPTEMBER),
        getString(m.OCTOBER),
        getString(m.NOVEMBER),
        getString(m.DECEMBER),
      ],
      weekdaysLong: [
        getString(w.SUNDAY),
        getString(w.MONDAY),
        getString(w.TUESDAY),
        getString(w.WEDNESDAY),
        getString(w.THURSDAY),
        getString(w.FRIDAY),
        getString(w.SATURDAY),
      ],
      weekdaysShort: [
        getString(ws.SUNDAY),
        getString(ws.MONDAY),
        getString(ws.TUESDAY),
        getString(ws.WEDNESDAY),
        getString(ws.THURSDAY),
        getString(ws.FRIDAY),
        getString(ws.SATURDAY),
      ],
    };
  }, [m, w, ws]);

  return result;
};

export default useCalendarUnitTranslations;
