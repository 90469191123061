import React, {useCallback, useMemo} from 'react';

import isNumber from 'lodash/isNumber';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {CheckboxField, NumberField, ToggleField} from 'client/common/fields';
import Icon from 'client/common/icon';
import Popover from 'client/common/popovers/popover';

import ClientTable from 'client/components/common/client-table';
import {ClientTableColumn, RenderCallback} from 'client/components/common/client-table/types';

import {LotteryPrizeCustomizationPrizeMap} from 'client/components/lottery/modals/lottery-prize-customization-modal/types';

import cssModule from './lottery-prize-maps-table.module.scss';

const b = bem('lottery-prize-maps-table', {cssModule});

type LotteryPrizeMapTableProps = {
  className?: string;
  data: LotteryPrizeCustomizationPrizeMap[];
  location: 'DRAW' | 'PRIZE_CUSTOMIZATION';
  level?: 'GLOBAL' | 'ONLINE' | 'DEVICE';
  hasSegmentation: boolean;
  disabled: boolean;
  onOpenPrizeModal: (prizeId?: number) => void;
  onDragEnd: (params: {oldIndex: number; newIndex: number}) => void;
};

/**
 * LotteryPrizeMapsTable - Component is for displaying prize maps of lottery game.
 * This component works only with redux-form
 */
const LotteryPrizeMapsTable: React.FC<LotteryPrizeMapTableProps> = (props) => {
  const {className, data, location, level, hasSegmentation, disabled, onOpenPrizeModal, onDragEnd} = props;

  const lang = useLanguage('LOTTERY.TABLES.PRIZE_MAPS');

  const isActive = (item: LotteryPrizeCustomizationPrizeMap) => item.active && item.prize_draw_active && item.editable;

  const renderInput = useCallback(
    (name: string): RenderCallback<LotteryPrizeCustomizationPrizeMap> =>
      ({index, item}) => (
        <NumberField
          hoveringMode
          inputHeight="small"
          name={`prize_maps[${index}].${name}`}
          inputClassName={b('field', {
            [`prize-active`]: isActive(item),
            local: item.local,
          })}
          key={item.active?.toString()}
          readOnly={!isActive(item) || disabled}
          min={0}
        />
      ),
    [disabled],
  );

  const columns = useMemo(() => {
    const result: ClientTableColumn<LotteryPrizeCustomizationPrizeMap>[] = [
      {
        name: 'prize_internal_name',
        path: 'prize_internal_name',
        label: lang.NAME_COLUMN,
        cellClassName: () => b('cell', {name: true}),
        render: ({item, value}) => {
          return item.local ? (
            <button type="button" className={b('name-button')} onClick={() => onOpenPrizeModal(item.prize_id)}>
              {value}
            </button>
          ) : (
            value
          );
        },
      },
    ];

    if (location === 'PRIZE_CUSTOMIZATION') {
      result.push(
        {
          name: 'current_stock',
          path: 'current_stock',
          width: 110,
          label: lang.CURRENT_STOCK_COLUMN,
        },
        {
          name: 'initial_stock',
          path: 'initial_stock',
          width: 110,
          label: lang.INITIAL_STOCK_COLUMN,
          render: renderInput('initial_stock'),
        },
      );
    } else if (hasSegmentation) {
      result.push({
        name: 'total_quantity',
        width: 110,
        label: lang.TOTAL_QUANTITY_COLUMN,
      });

      if (level === 'GLOBAL') {
        result.push(
          {
            name: 'online_quantity',
            width: 110,
            label: lang.ONLINE_QUANTITY_COLUMN,
            render: renderInput('online_quantity'),
          },
          {
            name: 'device_quantity',
            width: 110,
            label: lang.DEVICE_QUANTITY_COLUMN,
            render: renderInput('device_quantity'),
          },
        );
      } else {
        result.push({
          name: 'default_quantity',
          width: 110,
          label: lang.DEFAULT_QUANTITY_COLUMN,
          render: renderInput('default_quantity'),
        });
      }
    } else {
      result.push({
        name: 'quantity',
        width: 110,
        label: lang.QUANTITY_COLUMN,
        render: renderInput('quantity'),
      });
    }

    result.push(
      {
        label: lang.WIN_ANNOUNCEMENT_COLUMN,
        name: 'win_announce',
        width: 160,
        subcolumns: [
          {
            name: 'email_send',
            path: `prize_maps`,
            width: 80,
            label: lang.EMAIL_COLUMN,
            render: ({index, item}) => (
              <CheckboxField
                name={`prize_maps[${index}].email_send`}
                key={item?.email_send?.toString()}
                disabled={!isActive(item) || disabled}
              />
            ),
          },
          {
            name: 'sms_send',
            label: lang.SMS_COLUMN,
            width: 80,
            render: ({index, item}) => (
              <CheckboxField
                name={`prize_maps[${index}].sms_send`}
                key={item?.sms_send?.toString()}
                disabled={!isActive(item) || disabled}
              />
            ),
          },
        ],
      },
      {
        name: 'toggle',
        path: 'active',
        width: 150,
        label: lang.ACTIONS_COLUMN,
        labelClassName: b('cell', {'prize-active': true, toggle: true}),
        cellClassName: (item) => b('cell', {'prize-active': item.prize_draw_active, toggle: true}),
        render: ({index, item}) => {
          return (
            <>
              <ToggleField
                name={`prize_maps[${index}].active`}
                color="primary"
                rounded
                disabled={!item.prize_draw_active || disabled || !item.editable}
              />
              {item.local && (
                <Popover position="top" overlay={lang.LOCAL_PRIZE} shiftLeft={15}>
                  <Icon className={b('local-prize-icon')} name="marker" />
                </Popover>
              )}
            </>
          );
        },
      },
    );

    return result;
  }, [hasSegmentation, lang, level, location, renderInput, disabled, onOpenPrizeModal]);

  const excludeRowsFromDndSorting = useMemo(() => {
    return data.map((prizeMap, index) => !!prizeMap.prize?.full_win && index).filter(isNumber);
  }, [data]);

  const disabledRowsFromDndSorting = useMemo(() => {
    return data.map((prizeMap, index) => !prizeMap.prize?.local && index).filter(isNumber);
  }, [data]);

  return (
    <div className={className}>
      <ClientTable
        data={data}
        columns={columns}
        className={b()}
        classNames={{
          cell: (item) => b('cell', {'prize-active': isActive(item), local: item.local}),
        }}
        draggable
        onDragEnd={onDragEnd}
        noDataText={lang.NO_DATA}
        disabledRowsForDndSorting={disabledRowsFromDndSorting}
        excludeRowsFromDndSorting={excludeRowsFromDndSorting}
      />
    </div>
  );
};

export default LotteryPrizeMapsTable;
