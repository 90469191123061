import React from 'react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import AppButton from 'client/common/buttons';
import Icon from 'client/common/icon';
import Popover from 'client/common/popovers/popover';
import Dropdown from 'client/common/selects/dropdown';

import {GAME_TYPES} from 'client/models/prizes/constants';
import {GameType} from 'client/models/prizes/types';

import cssModule from './prize-map-selecting-button.module.scss';

type PrizeMapSelectingButtonProps = {
  budget?: boolean;
  onSelect: (type: GameType) => void;
  exclude?: {
    instantWinGame?: boolean;
    prizeDrawGame?: boolean;
  };
  className?: string;
};

const b = bem('prize-map-selecting-button', {cssModule});

/**
 * PrizeMapSelectingButton - common component is used for selecting type of customization button
 * in DIY devices list, DIY Campaign block, DIY Channel block, DIY Stores list
 */
const PrizeMapSelectingButton: React.FC<PrizeMapSelectingButtonProps> = (props) => {
  const {budget, onSelect, exclude, className} = props;
  const lang = useLanguage('PRIZES.CONTROLS.PRIZE_MAP_SELECTING_BUTTON');

  const triggerProps = {
    classNameIcon: className,
    iconName: 'prize',
    iconConfig: {
      budget,
    },
    asWrap: true,
    transparent: true,
    color: 'primary',
  };

  if (exclude?.instantWinGame && exclude?.prizeDrawGame) {
    return null;
  }

  if (exclude?.instantWinGame || exclude?.prizeDrawGame) {
    const type = exclude?.instantWinGame ? GAME_TYPES.PRIZE_DRAW : GAME_TYPES.INSTANT_WIN;
    return <AppButton {...triggerProps} onClick={() => onSelect(type)} />;
  }

  return (
    <Dropdown
      className={b()}
      triggerProps={triggerProps}
      position="bottom"
      trigger={
        <Popover overlay={lang.TOOLTIP} position="top">
          <Icon name="prize" budget={budget} />
        </Popover>
      }
      options={[
        {
          label: lang.INSTANT_WIN_PRIZE_TYPE,
          exclude: exclude?.instantWinGame,
          onClick: () => onSelect(GAME_TYPES.INSTANT_WIN),
        },
        {
          label: lang.PRIZE_DRAW_PRIZE_TYPE,
          exclude: exclude?.prizeDrawGame,
          onClick: () => onSelect(GAME_TYPES.PRIZE_DRAW),
        },
      ]}
    />
  );
};

export default PrizeMapSelectingButton;
