import React from 'react';

import {IMaskMixin, ReactMaskOpts} from 'react-imask';
import {IMask} from 'react-imask';
import {useDispatch, useSelector} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';
import useReduxForm, {reduxForm} from 'client/services/hooks/use-redux-form';
import {ReduxFormFC} from 'client/services/hooks/use-redux-form/types';
import {REPEATABLE_DATE_REGEX} from 'client/services/validator';

import {patchStoreAction} from 'client/ducks/stores/actions';
import {selectClientUserMembership} from 'client/ducks/user-clients/selectors';

import AppButton from 'client/common/buttons';
import {TextField, TextareaField} from 'client/common/fields';
import DateTimeListField from 'client/common/fields/datetime-list-field';
import {BaseInputProps} from 'client/common/inputs/base-input';
import {IMASK_TIME_PATTERNS} from 'client/common/inputs/datetime-input';
import Modal from 'client/common/modals/modal';

import {Store} from 'client/models/stores/types';
import {ApiDispatch} from 'client/types';

import initForm from './initial';
import mapFormValues from './mapFormValues';
import {StoreInfoFormValues} from './types';
import validate from './validate';

import cssModule from './store-info-modal.module.scss';

const b = bem('store-info-modal', {cssModule});

type StoreInfoModalProps = {
  onClose: () => void;
  store: Store;
  onSave: () => void;
};

const StoreInfoFormName = 'StoreInfoForm';

type MaskOpts = Omit<BaseInputProps, 'label'> & {label: string} & ReactMaskOpts;

const PhoneInputField = IMaskMixin<HTMLInputElement, MaskOpts>((props) => <TextField {...props} />);

const StoreInfoModal: ReduxFormFC<StoreInfoModalProps, StoreInfoFormValues> = (props) => {
  const {onClose, store, handleSubmit, onSave} = props;

  const lang = useLanguage('ACCOUNT_PARAMETERS.ACCOUNT_STORES.STORE_INFO_MODAL');
  const dispatch: ApiDispatch = useDispatch();
  const [loading, toggleLoading] = useToggle(false);
  const userMembership = useSelector(selectClientUserMembership);
  const storeEditable = userMembership?.editor;

  const {errors} = useReduxForm<StoreInfoFormValues>(StoreInfoFormName, {
    validate: (values) => validate(values, {lang}),
    initialValues: initForm(store),
  });

  const onSubmit = (values: StoreInfoFormValues) => {
    toggleLoading();
    const body = mapFormValues(values);

    dispatch(patchStoreAction({...body, id: store.id}))
      .then(() => {
        onSave();
      })
      .finally(() => {
        toggleLoading();
      });
  };

  return (
    <Modal show={true} onClose={onClose} title={lang.TITLE} className={b()}>
      <form className={b('form')} onSubmit={handleSubmit(onSubmit)}>
        <TextField label={lang.STORE_NAME} name="app_name" disabled={!storeEditable} required={true} />

        <div>
          <p className={b('title')}>{lang.ADDRESS}</p>
          <TextareaField
            label={lang.STREET}
            name="app_street_address"
            height={58}
            withWrap={true}
            disabled={!storeEditable}
          />
          <div className={b('address-info')}>
            <TextField label={lang.CITY} name="app_city_name" disabled={!storeEditable} required={true} />
            <TextField label={lang.ZIP} name="app_zip" disabled={!storeEditable} />
          </div>
        </div>

        <div>
          <p className={b('title')}>{lang.CONTACT_INFO}</p>
          <div className={b('contact-info')}>
            <PhoneInputField
              label={lang.PHONE?.toString()}
              name="app_phone"
              placeholder="+33 (6) 12345678"
              mask="+{33} (#) 00000000"
              definitions={{'#': /[67]/}}
              disabled={!storeEditable}
            />
            <TextField label={lang.EMAIL} name="app_email" disabled={!storeEditable} />
          </div>
        </div>

        <TextField label={lang.FACEBOOK_URL} name="app_facebook" disabled={!storeEditable} />
        <TextField label={lang.WEBSITE_URL} name="app_website" disabled={!storeEditable} />

        <div>
          <p className={b('title')}>{lang.OPENING_HOURS}</p>
          <div className={b('weekdays')}>
            {Object.entries(lang.WEEKDAYS).map(([weekKey, weekDay]) => {
              return (
                <div key={weekKey}>
                  <p className={b('weekday-title')}>{weekDay}</p>
                  <DateTimeListField
                    name={`opening_hours[${weekKey.toLowerCase()}]`}
                    type="time-short"
                    period
                    maxLength={2}
                    className={b('weekday-input-wrapper')}
                    inputClassName={b('weekday-input', {
                      'invalid-first': Boolean(errors.opening_hours?.[weekKey.toLowerCase()]?.[0]),
                      'invalid-second': Boolean(errors.opening_hours?.[weekKey.toLowerCase()]?.[1]),
                    })}
                    placeholder={{from: lang.FROM, to: lang.TO}}
                    disabled={!storeEditable}
                  />
                </div>
              );
            })}
          </div>
        </div>

        <div className={b('exclude-days')}>
          <span className={b('exclude-days-title')}>{lang.EXCLUDE_DAYS}:</span>
          <DateTimeListField
            name="special_hours"
            className={b('exclude-input')}
            type="date"
            validationPattern={REPEATABLE_DATE_REGEX}
            mask={IMASK_TIME_PATTERNS.date}
            blocks={{
              DD: {mask: [{mask: IMask.MaskedRange, from: 1, to: 31}]},
              MM: {mask: [{mask: /^\*$/}, {mask: IMask.MaskedRange, from: 1, to: 12}]},
              YYYY: {mask: [{mask: /^\*$/}, {mask: IMask.MaskedRange, from: 2020, to: 2999}]},
            }}
            lazy={true}
            placeholder={lang.DATE_PLACEHOLDER}
            disabled={!storeEditable}
          />
        </div>

        {storeEditable && (
          <AppButton label={lang.SAVE_BUTTON_LABEL} className={b('save-button')} submit={true} loading={loading} />
        )}
      </form>
    </Modal>
  );
};

export default reduxForm<StoreInfoModalProps, StoreInfoFormValues>({form: StoreInfoFormName})(StoreInfoModal);
