import React, {useCallback, useEffect, useState} from 'react';

import {arrayMove} from '@dnd-kit/sortable';
import cloneDeep from 'lodash/cloneDeep';
import {useDispatch, useSelector} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage, useReduxFetch} from 'client/services/hooks';
import useReduxForm, {reduxForm} from 'client/services/hooks/use-redux-form';
import {ReduxFormFC} from 'client/services/hooks/use-redux-form/types';

import {
  fetchGame,
  getPrizeMapsByLevel,
  GetPrizeMapsLevelType,
  getPrizes,
  updateGame,
  updatePrizeMaps,
  updatePrizeOrder,
} from 'client/ducks/games/actions';
import {selectOperation} from 'client/ducks/operations/selectors';

import AwaitContainer from 'client/common/await-container';
import AppButton from 'client/common/buttons';
import Modal from 'client/common/modals/modal';
import LayoutPanel from 'client/common/panels/layout-panel';

import {LotteryDrawDropdown} from 'client/components/lottery/fields';
import {LotteryCampaignDateTimeFieldset} from 'client/components/lottery/fieldsets';
import useFetchLotteryPrizes from 'client/components/lottery/hooks/useFetchLotteryPrizes';
import LotteryPrizeModal from 'client/components/lottery/modals/lottery-prize-modal';
import LotteryPrizeMapsTable from 'client/components/lottery/tables/lottery-prize-maps-table';
import {isGameDrawClosed} from 'client/models/prizes/helpers';
import {Game, GameDraw, Prize, PrizeMap} from 'client/models/prizes/types';
import {ApiDispatch} from 'client/types';

import initial from './initial';
import mapFormValues, {mapPrizeOrder} from './mapFormValues';
import {LotteryPrizeCustomizationValues} from './types';
import validate from './validate';

import cssModule from './lottery-prize-customization-modal.module.scss';

const b = bem('lottery-prize-customization-modal', {cssModule});

export const LotteryPrizeCustomizationModalFormName = 'LotteryPrizeCustomizationModalForm';

type LotteryPrizeCustomizationModalProps = {
  onClose: () => void;
  level: GetPrizeMapsLevelType;
  game: Game;
  sourceIds?: number[];
  sourceName?: string;
  disabled: boolean;
};

const LotteryPrizeCustomizationModal: ReduxFormFC<
  LotteryPrizeCustomizationModalProps,
  LotteryPrizeCustomizationValues
> = (props) => {
  const {handleSubmit, game: initialGame, level, sourceIds, sourceName, onClose, disabled} = props;
  const lang = useLanguage('LOTTERY.MODALS.LOTTERY_PRIZE_CUSTOMIZATION_MODAL');
  const langErrors = useLanguage('LOTTERY.MODALS.LOTTERY_PRIZE_CUSTOMIZATION_MODAL.ERRORS');
  const [submitting, toggleSubmitting] = useToggle(false);
  const [draw, setDraw] = useState<GameDraw | null>(null);
  const dispatch: ApiDispatch = useDispatch();
  const operation = useSelector(selectOperation);

  const drawIsDisabled = isGameDrawClosed(draw);

  const isReadOnly = disabled || !operation.editable || drawIsDisabled;

  const [showPrizeModal, setShowPrizeModal] = useState(false);
  const [editingPrizeId, setEditingPrizeId] = useState<number | null>(null);

  const openPrizeModal = useCallback((prizeId?: number) => {
    if (prizeId) {
      setEditingPrizeId(prizeId);
    }
    setShowPrizeModal(true);
  }, []);

  const closePrizeModal = useCallback(() => {
    setEditingPrizeId(null);
    setShowPrizeModal(false);
  }, []);

  const {loading: loadingGame, data: {game} = {}} = useReduxFetch<{game: Game}>({
    action: fetchGame,
    actionArgs: {
      id: initialGame.id,
      queryParams: {
        include: ['game_draws'],
      },
    },
  });

  const {
    fetch: fetchPrizeMaps,
    loading: loadingPrizeMaps,
    data: {prize_maps = []} = {},
  } = useReduxFetch<{prize_maps: PrizeMap[]}>({
    action: getPrizeMapsByLevel,
    actionArgs: {
      sourceIds,
      level,
      queryParams: {
        include_prize_map_prize_draw_active: null,
        include: ['prize'],
        q: {
          game_draw_id_eq: draw?.id,
        },
      },
    },
    skip: !draw?.id,
  });

  const {data: dataPrizes, fetch: fetchAllPrizes} = useReduxFetch<{prizes: Prize[]}>({
    action: getPrizes,
    actionArgs: {
      q: {
        game_id_eq: game?.id,
      },
    },
    skip: !game?.id,
  });

  const {invalid, initialize, formValues, change} = useReduxForm<LotteryPrizeCustomizationValues>(
    LotteryPrizeCustomizationModalFormName,
    {
      initialValues: initial({game: initialGame, prize_maps, level, sourceIds, timezone: operation.timezone}),
      validate: (values) => validate(values, {lang: langErrors}),
    },
  );
  const prizeMaps = formValues.prize_maps;

  const gameDrawIndex = game?.game_draws?.findIndex((d) => draw?.id && d.id === +draw?.id);

  useEffect(() => {
    if (!loadingGame && !loadingPrizeMaps && game) {
      initialize(initial({game, prize_maps, level, sourceIds, timezone: operation.timezone}));
    }
  }, [game, prize_maps, initialize, loadingGame, loadingPrizeMaps, level, sourceIds, operation.timezone]);

  const {prizes, fetchPrizes} = useFetchLotteryPrizes(game?.id);

  const fetchPrizeMapsAndPrizes = useCallback(() => {
    return Promise.all([fetchPrizeMaps(), fetchAllPrizes(), fetchPrizes()]);
  }, [fetchAllPrizes, fetchPrizeMaps, fetchPrizes]);

  const handleSave = handleSubmit(async (values) => {
    toggleSubmitting(true);

    try {
      const body = mapFormValues(values, {timezone: operation.timezone});
      const ordersBody = mapPrizeOrder(values, {prizes: dataPrizes?.prizes || []});
      await Promise.all([
        dispatch(updateGame(initialGame.id, {game: body})),
        values.prize_maps?.length ? dispatch(updatePrizeMaps({prize_map: values.prize_maps})) : Promise.resolve(),

        dispatch(updatePrizeOrder(draw?.game_id, ordersBody)),
      ]);
      onClose();
    } finally {
      toggleSubmitting(false);
    }
  });

  const handleDragEnd = (params: {oldIndex: number; newIndex: number}) => {
    const {oldIndex, newIndex} = params;
    const nextPrizes = arrayMove(cloneDeep(prizeMaps), oldIndex, newIndex);

    change('prize_maps', nextPrizes);
  };

  const levelName = sourceName || (level && lang.LEVELS[level as keyof typeof lang.LEVELS]);

  return (
    <Modal
      className={b()}
      onClose={onClose}
      isCloseHidden
      title={
        <>
          {lang.TITLE}
          {level && <span className={b('level')}>{[initialGame?.name, levelName].filter(Boolean).join(' / ')}</span>}
        </>
      }
      titleButton={
        <div className={b('buttons')}>
          <AppButton
            color="clients"
            transparent
            label={isReadOnly ? lang.CLOSE_BUTTON : lang.CANCEL_BUTTON}
            onClick={onClose}
          />
          {!isReadOnly && (
            <AppButton
              color="clients"
              label={lang.SAVE_BUTTON}
              onClick={handleSubmit(handleSave)}
              loading={submitting}
              disabled={invalid}
            />
          )}
        </div>
      }
    >
      <AwaitContainer loading={loadingGame || loadingPrizeMaps} className={b('body')}>
        <LotteryDrawDropdown
          className={b('block', ['draw'])}
          value={draw?.id}
          onChange={setDraw}
          game={game}
          disabled={isReadOnly}
        />
        <LayoutPanel className={b('block')}>
          <LotteryCampaignDateTimeFieldset
            name={`game_draws[${gameDrawIndex}]`}
            disabled={isReadOnly}
            timezone={operation.timezone}
          />
        </LayoutPanel>

        <LayoutPanel className={b('block', ['prize-maps'])}>
          <h3 className={b('prize-maps-title')}>{lang.PRIZE_TABLE_TITLE}</h3>
          <div className={b('description-row')}>
            {!!prizeMaps?.length && <p className={b('prize-maps-description')}>{lang.PRIZE_TABLE_DESCRIPTION}</p>}
            <AppButton label={lang.ADD_LOCAL_PRIZE} onClick={openPrizeModal} className={b('local-prize-button')} />
          </div>
          <LotteryPrizeMapsTable
            data={prizeMaps}
            location="PRIZE_CUSTOMIZATION"
            hasSegmentation={false}
            className={b('prize-maps-table')}
            disabled={isReadOnly}
            onDragEnd={handleDragEnd}
            onOpenPrizeModal={openPrizeModal}
          />
        </LayoutPanel>
      </AwaitContainer>
      {showPrizeModal && game && (
        <LotteryPrizeModal
          game={game}
          operation={operation}
          editingPrize={prizes.find((i) => i.id === editingPrizeId) || null}
          onSave={fetchPrizeMapsAndPrizes}
          onClose={closePrizeModal}
          disabled={disabled}
          storeId={sourceIds?.[0]}
          isLocal
        />
      )}
    </Modal>
  );
};

export default reduxForm<LotteryPrizeCustomizationModalProps, LotteryPrizeCustomizationValues>({
  form: LotteryPrizeCustomizationModalFormName,
})(LotteryPrizeCustomizationModal);
