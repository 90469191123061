import {fetchFile, getFileName} from 'client/services/helpers';

import {MEDIA_MIME_TYPES} from 'client/common/config';

export const convertUrlToFile = async (url: string) => {
  const filename = getFileName(url);
  const res = url?.startsWith('http') ? await fetchFile(url) : await fetch(url);
  const blob = await res.blob();

  // Check if it's an HTML file and force the type to 'text/html' if needed
  const fileType =
    filename?.endsWith('.html') && blob.type === MEDIA_MIME_TYPES.app ? MEDIA_MIME_TYPES.html : blob.type;

  return new File([blob], filename, {type: fileType});
};
