import React from 'react';

import bem from 'client/services/bem';

import CustomScrollbars from 'client/components/common/custom-scrollbars';

import TabSwitcherItem, {type TabSwitcherTab} from './tab-switcher-item';

import './tab-switcher.scss';

const b = bem('tab-switcher');

type TabSwitcherProps = {
  onTabClick: (activeTab: any) => void;
  activeTab: string;
  style?: 'title' | 'base';
  tabs: TabSwitcherTab[];
  className?: string;
};

const TabSwitcher: React.FC<TabSwitcherProps> = (props) => {
  const {tabs, onTabClick, activeTab, className, style = 'base'} = props;

  return (
    <CustomScrollbars cssModifier={className} horTrackCssClass={b('track')}>
      <nav className={b([style])}>
        {tabs
          .filter((item) => {
            if (item.children) {
              if (item.exclude) {
                return false;
              }
              return item.children.some((child) => !child.exclude);
            }
            return !item.exclude;
          })
          .map((tab) => {
            return (
              <TabSwitcherItem key={tab.value} {...tab} activeTab={activeTab} onClick={onTabClick} style={style} />
            );
          })}
      </nav>
    </CustomScrollbars>
  );
};

export default TabSwitcher;

export type {TabSwitcherTab} from './tab-switcher-item';
