import React, {useCallback, useState} from 'react';

import cn from 'classnames';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {transformDate} from 'client/services/helpers';
import {useLanguage} from 'client/services/hooks';
import {parseDate} from 'client/services/utils/date';

import {changeAutotaskValidated} from 'client/ducks/autotask/actions';
import {selectAutotask} from 'client/ducks/autotask/selectors';
import {updateOperation, archiveOperation} from 'client/ducks/operations/actions';

import AppButton from 'client/common/buttons';
import {useToast} from 'client/common/hooks/useToast';
import {TextInput, DatepickerInput} from 'client/common/inputs';
import ConfirmationModal from 'client/common/modals/confirmation-modal';

import {DIY_OPERATION_STATUSES} from 'client/components/diy-operation/constants';
import {isOperationArchived} from 'client/models/operations/helpers';
import {Operation} from 'client/models/operations/types';
import {ApiDispatch} from 'client/types';

import DiyOpMainBlockInput from './diy-op-main-block-input';

import './diy-op-main-block-inputs.scss';

const b = bem('diy-op-main-block-inputs');

type DiyOpMainBlockInputsProps = {
  operation: Operation;
  fetchOperation: () => void;
  className?: string;
};

const DiyOpMainBlockInputs: React.FC<DiyOpMainBlockInputsProps> = (props) => {
  const {operation, fetchOperation, className} = props;
  const {id, name, from, to} = operation;

  const lang = useLanguage('DIY_OPERATION.BLOCKS.DIY_OP_MAIN_BLOCK.INPUTS');
  const langStatuses = useLanguage('DIY_OPERATION.BLOCKS.DIY_OP_STATUS_BLOCK');

  const dispatch: ApiDispatch = useDispatch();

  const autotask = useSelector(selectAutotask);

  const [publishConfirmation, setPublishConfirmation] = useState('');
  const {appendToastNotification} = useToast();
  const [showArchiveConfirmation, toggleArchiveConfirmation] = useToggle(false);

  const isOpArchived = isOperationArchived(operation);

  const updateOperationField = useCallback(
    (key, value) => {
      const isDateField = key === 'from' || key === 'to';

      dispatch(
        updateOperation(id, {
          operation: {
            [key]: isDateField ? transformDate(value, false) : value,
          },
        }),
      ).then(() => fetchOperation());
    },
    [id, dispatch, fetchOperation],
  );

  const handleArchive = async () => {
    await dispatch(archiveOperation(operation.id))
      .then((data) => {
        if (data?.error) {
          appendToastNotification({
            type: 'error',
            title: lang.ERROR_MESSAGE,
          });
        } else {
          appendToastNotification({
            type: 'success',
            title: langStatuses.SUCCESS_ARCHIVE_MESSAGE,
          });
        }
      })
      .then(fetchOperation);
  };

  const confirmationModal = async () => {
    await dispatch(changeAutotaskValidated(autotask.id, true));
  };

  const handleCloseConfirmationMessage = () => {
    setPublishConfirmation('');
    fetchOperation();
  };

  return (
    <div className={cn(b(), className)}>
      <div className={b('text-inputs')}>
        <DiyOpMainBlockInput
          label={lang.NAME_LABEL}
          name="name"
          className={b('input')}
          component={TextInput}
          initialValue={name}
          updateOperation={updateOperationField}
          disabled={!operation.editable || isOpArchived}
        />
      </div>
      <div className={b('date-inputs')}>
        <DiyOpMainBlockInput
          label={lang.FROM_LABEL}
          name="from"
          className={b('input')}
          component={DatepickerInput}
          initialValue={parseDate(from || '', {
            timezone: operation.timezone,
            outputFormat: 'DATE',
            timezoneForce: true,
          })}
          updateOperation={updateOperationField}
          disabled={!operation.editable || isOpArchived}
        />
        <DiyOpMainBlockInput
          label={lang.TO_LABEL}
          name="to"
          className={b('input')}
          component={DatepickerInput}
          initialValue={parseDate(to || '', {timezone: operation.timezone, outputFormat: 'DATE', timezoneForce: true})}
          updateOperation={updateOperationField}
          disabled={!operation.editable || isOpArchived}
        />

        {operation.status === DIY_OPERATION_STATUSES.ACTIVE && (
          <AppButton
            label={langStatuses.ARCHIVE}
            className={b('archive')}
            onClick={toggleArchiveConfirmation}
            transparent={true}
            fontSize="small"
            color="primary"
            link={true}
          />
        )}
      </div>

      <ConfirmationModal
        show={!!publishConfirmation}
        onClose={handleCloseConfirmationMessage}
        onConfirm={confirmationModal}
        messageClassName={b('message')}
        message={langStatuses.CONFIRM_PUBLISH_MESSAGE}
        confirmationCloseDelay={10000}
        confirmMessage={`${langStatuses.SUCCESS_PUBLISH_MESSAGE} ${moment().format('DD/MM/YYYY')}`}
        buttonConfirm={{
          label: langStatuses.CONFIRM_MODAL,
          color: 'clients',
        }}
        buttonCancel={{
          label: langStatuses.CANCEL_MODAL,
          color: 'clients',
          transparent: true,
        }}
        clientSide
      />
      <ConfirmationModal
        show={showArchiveConfirmation}
        onClose={toggleArchiveConfirmation}
        onCancel={toggleArchiveConfirmation}
        title={lang.ARCHIVING_CONFIRMATION.TITLE}
        message={lang.ARCHIVING_CONFIRMATION.MESSAGE}
        cancelText={lang.ARCHIVING_CONFIRMATION.CANCEL}
        confirmText={lang.ARCHIVING_CONFIRMATION.ARCHIVE}
        onConfirm={() => {
          handleArchive();
          toggleArchiveConfirmation();
        }}
        clientSide
      />
    </div>
  );
};

export default DiyOpMainBlockInputs;
