import {blobToBase64} from 'client/services/blobToBase64';
import {parseDate} from 'client/services/utils/date';

export default async (formValues, {interactionId, id, timezone}) => {
  if (interactionId) {
    const devicePrizes = await Promise.all(
      formValues.device_prizes
        ?.filter((p) => p.imports?.data)
        .map(async (devicePrize) => ({
          id: devicePrize.id,
          prize_id: devicePrize.prize?.id,
          device_token_list: {
            filename: devicePrize.imports.data.name,
            data: await blobToBase64(devicePrize.imports.data),
          },
        })),
    );

    return {
      device: {
        interactions: [
          {
            id: interactionId,
            from: parseDate(formValues?.from, {timezone}),
            to: parseDate(formValues?.to, {timezone}),
            default_language: formValues?.default_language,
          },
        ],
        device_prizes: devicePrizes,
      },
    };
  }
  return {
    ids: [id],
    from: parseDate(formValues?.from, {timezone}),
    to: parseDate(formValues?.to, {timezone}),
  };
};
