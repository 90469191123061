import isEmpty from 'lodash/isEmpty';

import types from './types';

const initialState = {
  opt_in_leads_statistic: {
    leads: {
      total: 0,
    },
    opt_ins_rate: null,
    opt_ins: {
      total: 0,
      total_structural: 0,
      opt_in_by_types: [],
    },
  },
};

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case types.GET_LEADS_OPTIN_STATISTICS_SUCCESS:
      if (isEmpty(payload.result)) {
        return {...initialState};
      }
      return {
        ...payload.result,
      };

    default:
      return state;
  }
};
