import React from 'react';

import {arrayMove} from '@dnd-kit/sortable';
import cloneDeep from 'lodash/cloneDeep';

import bem from 'client/services/bem';
import useReduxForm from 'client/services/hooks/use-redux-form';

import PrizeCustomizationCampaignPeriod from 'client/components/prizes/fields/prize-customization-campaign-period';
import {PrizeCustomizationModalFormName} from 'client/components/prizes/modals/prize-customization-modal';
import {PrizeCustomizationModalForm} from 'client/components/prizes/modals/prize-customization-modal/types';
import PrizeCustomizationPrizesTable from 'client/components/prizes/tables/prize-customization-prizes-table';

import cssModule from './prize-customization-form.module.scss';

const b = bem('prize-customization-form', {cssModule});

type PrizeCustomizationFormProps = {
  editable: boolean;
  disabled: boolean;
  allowCreateLocal: boolean;
  onSelectPrizeMap: (index: number) => void;
  onOpenPrizeModal: (prizeId?: number) => void;
};

const PrizeCustomizationForm: React.FC<PrizeCustomizationFormProps> = (props) => {
  const {editable, disabled, allowCreateLocal, onSelectPrizeMap, onOpenPrizeModal} = props;
  const {formValues, change} = useReduxForm<PrizeCustomizationModalForm>(PrizeCustomizationModalFormName);
  const {prizeMaps} = formValues;

  const handleDragEnd = (params: {oldIndex: number; newIndex: number}) => {
    const {oldIndex, newIndex} = params;
    const nextPrizes = arrayMove(cloneDeep(prizeMaps), oldIndex, newIndex);

    change('prizeMaps', nextPrizes);
  };

  return (
    <div className={b()}>
      <PrizeCustomizationCampaignPeriod editable={editable && !formValues.disabledTime} />
      <PrizeCustomizationPrizesTable
        allowCreateLocal={allowCreateLocal}
        prizes={formValues.prizeMaps || []}
        editable={editable && !disabled}
        className={b('table')}
        onSelectPrizeMap={onSelectPrizeMap}
        onOpenPrizeModal={onOpenPrizeModal}
        onDragEnd={handleDragEnd}
      />
    </div>
  );
};

export default PrizeCustomizationForm;
