import get from 'lodash/get';
import moment from 'moment';

import {LangsByPath} from 'client/services/hooks/useLanguage/types';
import {EMAIL_REGEX, PHONE_REGEX_FR, URL_REGEX} from 'client/services/validator';

import {Period, StoreInfoFormValues} from './types';

type ValidateProps = (
  values: StoreInfoFormValues,
  config: {lang: LangsByPath<['ACCOUNT_PARAMETERS.ACCOUNT_STORES.STORE_INFO_MODAL']>},
) => Record<string, string>;

export default ((values, {lang}) => {
  const errors: Partial<StoreInfoFormValues> = {};

  if (!values?.app_name) {
    errors.app_name = lang.REQUIRED?.toString();
  }

  if (!values?.app_city_name) {
    errors.app_city_name = lang.REQUIRED?.toString();
  }

  if (values?.app_phone && !PHONE_REGEX_FR.test(values?.app_phone)) {
    errors.app_phone = lang.INCORRECT_FORMAT?.toString();
  }

  if (values?.app_email && !EMAIL_REGEX.test(values?.app_email)) {
    errors.app_email = lang.INCORRECT_FORMAT?.toString();
  }

  if (values?.app_facebook && !URL_REGEX.test(values?.app_facebook)) {
    errors.app_facebook = lang.INCORRECT_LINK?.toString();
  }

  if (values?.app_website && !URL_REGEX.test(values?.app_website)) {
    errors.app_website = lang.INCORRECT_LINK?.toString();
  }

  Object.entries(values?.opening_hours || []).forEach(([weekDay, periods]) => {
    (periods as Period[]).forEach(({value}, index) => {
      const [from, to] = value.split(' ');
      if (!from || !to) {
        return;
      }

      const momentFrom = moment(from, 'HH:mm');
      const momentTo = moment(to, 'HH:mm');

      // check period validity
      if (momentTo.diff(momentFrom) <= 0) {
        const newErrors: string[] = get(errors, `opening_hours[${weekDay}]`, []);
        newErrors[index] = 'Invalid period';

        errors.opening_hours = {
          ...errors.opening_hours,
          [weekDay]: newErrors,
        };
      }

      // check periods intersection
      if (index === 1) {
        const previousPeriod = (periods[0] as Period)?.value;
        const [, prevTo] = previousPeriod.split(' ');

        const momentPrevTo = moment(prevTo, 'HH:mm');

        if (momentFrom.diff(momentPrevTo) < 0) {
          const newErrors = ['Intersecting period', 'Intersecting period'];
          errors.opening_hours = {
            ...errors.opening_hours,
            [weekDay]: newErrors,
          };
        }
      }
    });
  });

  return errors;
}) as ValidateProps;
