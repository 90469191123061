import React, {useState, useCallback, useEffect} from 'react';

import get from 'lodash/get';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';
import {formatOperationTimezone, parseDate} from 'client/services/utils/date';

import {setHeaderLinkBack, clearHeaderLinkBack} from 'client/ducks/header-link-back/actions';
import {selectClientParams} from 'client/ducks/operations/selectors';

import LayoutPanel from 'client/common/panels/layout-panel';
import Popover from 'client/common/popovers/popover';

import CustomLink from 'client/components/common/custom-link';
import Icon from 'client/components/common/icon';

import ClientAutotaskMenu from 'client/components/client-autotask/client-autotask-menu';

import ClientAutotaskSubheaderOperationInfoBlock from './blocks/client-autotask-subheader-operation-info-block';
import {ClientAutotaskSubheaderProps} from './types';

import cssModule from './client-autotask-subheader.module.scss';

const b = bem('client-autotask-subheader', {cssModule});

// TODO: we need join this and TaskHeader component in one
const ClientAutotaskSubheader: React.FC<ClientAutotaskSubheaderProps> = (props) => {
  const {autotask, operation, activeTab, accessLevel} = props;

  const history = useHistory();
  const dispatch = useDispatch();

  const [scrolled, setScrolled] = useState(false);

  const lang = useLanguage('CLIENT_AUTOTASK_SUBHEADER');

  const clientParams = useSelector(selectClientParams);

  const operationFrom = parseDate(operation?.from, {
    timezone: operation?.timezone,
    outputFormat: 'DATE_SHORT',
    timezoneForce: true,
  });
  const operationTo = parseDate(operation?.to, {
    timezone: operation?.timezone,
    outputFormat: 'DATE_SHORT',
    timezoneForce: true,
  });

  const handleScroll = useCallback(() => {
    const offset = window.scrollY;

    if (offset > 1 && !scrolled) {
      setScrolled(true);
    } else if (offset === 0 && scrolled) {
      setScrolled(false);
    }
  }, [scrolled]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    dispatch(
      setHeaderLinkBack({
        name: autotask.name,
        rootPage: false,
      }),
    );

    return () => {
      window.removeEventListener('scroll', handleScroll);
      dispatch(clearHeaderLinkBack());
    };
  }, [dispatch, handleScroll, autotask.name]);

  const handleBackClick = () => {
    const link = CustomLink.createClientOperationsLink({
      clientId: clientParams?.clientId || operation.client_id,
    });
    history.push(link + clientParams?.qs || '');
  };

  const imageUrl = get(autotask, 'image.url');

  return (
    <header className={b({scrolled})}>
      <div className={b('content')}>
        <div className={b('autotask')}>
          <div className={b('task-main-info')}>
            <figure className={b('image')}>
              {imageUrl ? (
                <img className={b('image-pic')} src={imageUrl} alt={autotask.name} />
              ) : (
                <Icon className={b('image-pic')} name="task-image-placeholder" />
              )}
            </figure>
            <div className={b('autotask-name')} title={autotask.name}>
              <Icon name="arrow-left" className={b('back')} onClick={handleBackClick} />
              {autotask.name}
            </div>
          </div>
          <ClientAutotaskMenu
            autotask={autotask}
            operation={operation}
            activeTab={activeTab}
            accessLevel={accessLevel}
            className={b('menu')}
          />
        </div>
        <LayoutPanel className={b('operation')}>
          <ClientAutotaskSubheaderOperationInfoBlock label={lang.OPERATION.toString()} value={operation.name} />
          <ClientAutotaskSubheaderOperationInfoBlock label={`${operationFrom} - ${operationTo}`} />
          <Popover
            overlay={<p className={b('popover-text')}>{formatOperationTimezone(operation?.timezone).fullLabel}</p>}
            position="top"
            shiftTop={-10}
            shiftLeft={-5}
            arrowConfig={{
              fill: '#4384AB',
              stroke: '#8996ac',
              tipRadius: 1,
              width: 35,
              height: 16,
            }}
          >
            <ClientAutotaskSubheaderOperationInfoBlock label={formatOperationTimezone(operation?.timezone).label} />
          </Popover>
        </LayoutPanel>
      </div>
    </header>
  );
};

export default ClientAutotaskSubheader;
