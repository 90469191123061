import React, {useMemo} from 'react';

import cn from 'classnames';
import isNumber from 'lodash/isNumber';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import AppButton from 'client/common/buttons/app-button';
import {NumberField, ToggleField} from 'client/common/fields';
import Icon from 'client/common/icon';
import LayoutPanel from 'client/common/panels/layout-panel';
import Popover from 'client/common/popovers/popover';

import ClientTable from 'client/components/common/client-table';
import {ClientTableColumn} from 'client/components/common/client-table/types';

import {PRIZE_TYPES} from 'client/components/prizes/constants';
import {PrizeCustomizationModalForm} from 'client/components/prizes/modals/prize-customization-modal/types';
import {PrizeMap} from 'client/models/prizes/types';

import cssModule from './prize-customization-prizes-table.module.scss';

const b = bem('prize-customization-prizes-table', {cssModule});

type PrizeCustomizationPrizesTableProps = {
  className?: string;
  editable?: boolean;
  allowCreateLocal: boolean;
  prizes: PrizeMap[];
  onSelectPrizeMap: (index: number) => void;
  onDragEnd: (params: {oldIndex: number; newIndex: number}) => void;
  onOpenPrizeModal: (prizeId?: number) => void;
};

type TablePrizeMap = PrizeCustomizationModalForm['prizeMaps'][number];

const PrizeCustomizationPrizesTable: React.FC<PrizeCustomizationPrizesTableProps> = (props) => {
  const {className, prizes, editable, onSelectPrizeMap, onOpenPrizeModal, onDragEnd, allowCreateLocal} = props;

  const lang = useLanguage('PRIZES.MODALS.CUSTOMIZATION_MODAL.TABLE');

  const columns: ClientTableColumn<TablePrizeMap>[] = useMemo(
    () => [
      {
        name: 'prize_internal_name',
        path: 'prize_internal_name',
        label: lang.NAME_COLUMN,
        cellClassName: (item) => b('cell', {active: item.active && item.prize_type !== PRIZE_TYPES.COUPON, name: true}),
        render: ({item, value, index}) => (
          <button
            type="button"
            className={b('name')}
            onClick={() => (item.local ? onOpenPrizeModal(item.prize_id) : onSelectPrizeMap(index))}
            disabled={!item.local && (!item.active || item.prize_type === PRIZE_TYPES.COUPON || !item.editable)}
          >
            {value}
          </button>
        ),
      },
      {
        name: 'coupon',
        path: 'coupon',
        label: lang.COUPON_COLUMN,
        cellClassName: (item) => b('cell', {active: item.active}),
      },
      {
        name: 'current_stock',
        path: 'current_stock',
        width: 150,
        label: lang.CURRENT_STOCK_COLUMN,
        cellClassName: (item) => b('cell', {active: item.active}),
      },
      {
        name: 'initial_stock',
        path: 'initial_stock',
        label: lang.INITIAL_STOCK_COLUMN,
        width: 150,
        render: ({index, item}) => (
          <NumberField
            hoveringMode
            inputHeight="small"
            name={`prizeMaps[${index}].initial_stock`}
            inputClassName={b('field', {
              'initial-stock': true,
              active: item.active && editable && item.editable,
              local: item.local,
            })}
            key={item.active?.toString()}
            readOnly={!item.active || !editable || !item.editable}
            min={0}
          />
        ),
      },
      {
        name: 'active',
        path: 'active',
        label: lang.ACTIONS_COLUMN,
        width: 150,
        render: ({index, item}) => {
          return (
            <>
              {item.editable && (
                <ToggleField
                  name={`prizeMaps[${index}].active`}
                  color="primary"
                  rounded
                  disabled={!editable || !item.editable}
                />
              )}
              {item.local && (
                <Popover position="top" overlay={lang.LOCAL_PRIZE} shiftLeft={15}>
                  <Icon className={b('local-prize-icon')} name="marker" />
                </Popover>
              )}
            </>
          );
        },
      },
    ],
    [lang, editable, onSelectPrizeMap, onOpenPrizeModal],
  );

  const excludeRowsFromDndSorting = useMemo(() => {
    return prizes.map((prizeMap, index) => !!prizeMap.prize?.full_win && index).filter(isNumber);
  }, [prizes]);

  const disabledRowsFromDndSorting = useMemo(() => {
    return prizes.map((prizeMap, index) => !prizeMap.prize?.local && index).filter(isNumber);
  }, [prizes]);

  return (
    <LayoutPanel className={cn(b(), className)}>
      <p className={b('title')}>{lang.PRIZES_TITLE}</p>
      <div className={b('description-row')}>
        <p className={b('description')}>{lang.DESCRIPTION}</p>
        {allowCreateLocal && <AppButton label={lang.ADD_LOCAL_PRIZE} onClick={onOpenPrizeModal} />}
      </div>
      <ClientTable
        classNames={{
          cell: (item: TablePrizeMap) => b('cell', {local: item.local, editable: item.editable}),
        }}
        data={prizes}
        columns={columns}
        noDataText={lang.NO_DATA}
        className={b('table')}
        draggable
        onDragEnd={onDragEnd}
        disabledRowsForDndSorting={disabledRowsFromDndSorting}
        excludeRowsFromDndSorting={excludeRowsFromDndSorting}
      />
    </LayoutPanel>
  );
};

export default PrizeCustomizationPrizesTable;
