import React from 'react';

import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import bem from 'client/services/bem';

import {selectAccessLevelsByMemberships, selectCurrentOrganization} from 'client/ducks/user-clients/selectors';

import SelectDropdown from 'client/common/selects/select-dropdown';

import {Client} from 'client/models/client/types';

import cssModule from './client-switcher.module.scss';

const b = bem('client-switcher', {cssModule});

type ClientSwitcherProps = {
  clients: Client[];
  onClientChange: (client: Client, access: string) => void;
};

const ClientSwitcher: React.FC<ClientSwitcherProps> = (props) => {
  const {clients, onClientChange} = props;
  const history = useHistory();
  const currentOrganization = useSelector(selectCurrentOrganization);
  const userMemberships = useSelector(selectAccessLevelsByMemberships);

  const handleChange = (id: number) => {
    const client = clients.find((i) => i.id === id);
    if (client) {
      history.push(`/clients/${client.id}/operations`);
      onClientChange(client, userMemberships[client.id]);
    }
  };

  if (!currentOrganization) {
    return null;
  }

  return (
    <div className={b()}>
      {clients.length < 2 ? (
        <p className={b('simple-name')}>{currentOrganization?.name || ''}</p>
      ) : (
        <SelectDropdown
          value={currentOrganization.id}
          options={clients.map((client) => ({label: client?.name, value: client.id}))}
          onChange={handleChange}
          className={b('select')}
          classNames={{
            control: () => b('control'),
            menu: () => b('menu'),
            menuList: () => b('menu-list'),
            option: () => b('option'),
            valueContainer: () => b('value-container'),
          }}
          simpleValue
        />
      )}
    </div>
  );
};

export default ClientSwitcher;
