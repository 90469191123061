import {callApi} from 'client/services/call-api';

import {API_METHODS, API_PATH} from 'client/common/config';

import {Campaign} from 'client/models/campaign/types';
import {ApiAction} from 'client/types';

import types from './types';

export const getClientPlaces: ApiAction =
  ({clientId, operationId, page, perPage, regionId, placeId}) =>
  (dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.PLACES}`,
      method: 'GET',
      noCache: true,
      types: [types.GET_CLIENT_PLACES_REQUEST, types.GET_CLIENT_PLACES_SUCCESS, types.GET_CLIENT_PLACES_ERROR],
      queryParams: {
        page,
        per_page: perPage,
        operation_id: operationId,
        q: {
          client_id_eq: clientId,
          region_id_in: regionId,
          id_in: placeId,
        },
        include_place_region_name: null,
        include_place_device_affectations_count: null,
        include_place_attached_device_affectations_count: null,
        include_place_attached_online_interactions_count: null,
        include_place_attached_offline_interactions_from: null,
        include_place_attached_offline_interactions_to: null,
        include_place_attached_source_stores_from: null,
        include_place_attached_source_stores_to: null,
        include_place_prize_maps_modified: null,
        include_place_prize_maps_present: null,
        include: {
          current_source_stores: {
            operation: null,
          },
          device_affectations: {
            current_interaction: {
              operation: null,
            },
            interactions: {
              operation: null,
            },
          },
        },
      },
    });
  };

export const linkPlaces: ApiAction =
  ({operationId, placeIds}) =>
  (dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.PLACES}/link`,
      method: 'POST',
      noCache: true,
      types: [types.LINK_PLACES_REQUEST, types.LINK_PLACES_SUCCESS, types.LINK_PLACES_ERROR],
      queryParams: {
        q: {
          id_in: placeIds,
        },
      },
      body: {
        operation_id: operationId,
      },
    });
  };

export const getCampaigns: ApiAction<{campaigns: Campaign[]}> =
  ({interfaceId}) =>
  (dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.CAMPAIGNS}`,
      method: 'GET',
      noCache: true,
      types: [types.GET_CAMPAIGNS_REQUEST, types.GET_CAMPAIGNS_SUCCESS, types.GET_CAMPAIGNS_ERROR],
      queryParams: {
        q: {
          interface_id_eq: interfaceId,
        },
        include: {
          interactions: {
            source_stores: null,
          },
        },
      },
    });
  };

export const updateSourceStores: ApiAction =
  ({body}) =>
  (dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.SOURCE_STORES}/bulk_action`,
      method: 'PATCH',
      noCache: true,
      types: [types.UPDATE_SOURCE_STORES_REQUEST, types.UPDATE_SOURCE_STORES_SUCCESS, types.UPDATE_SOURCE_STORES_ERROR],
      body,
    });
  };
