import React, {useMemo} from 'react';

import cn from 'classnames';
import isFunction from 'lodash/isFunction';

import bem from 'client/services/bem';

import Dropdown from 'client/common/selects/dropdown';

import {Translation} from 'client/models/language/types';

import cssModule from './tab-switcher-item.module.scss';

export type TabSwitcherTab = {
  title?: Translation;
  value?: string;
  children?: Omit<TabSwitcherTab, 'children'>[];
  className?: ((params: {isActive: boolean}) => void) | string;
  exclude?: boolean;
  disabled?: boolean;
  hasError?: boolean;
  customElement?: React.ReactElement;
};

type TabSwitcherItemProps = TabSwitcherTab & {
  activeTab?: string;
  style?: 'base' | 'title';
  onClick?: (tab: string) => void;
};

const b = bem('tab-switcher-item', {cssModule});

const TabSwitcherItem: React.FC<TabSwitcherItemProps> = (props) => {
  const {
    value,
    title,
    className: tabClassName,
    customElement,
    hasError,
    activeTab,
    style = 'base',
    onClick,
    disabled,
    children = [],
  } = props;

  const filteredChildren = useMemo(() => {
    return children
      .filter((child) => !child.exclude)
      .map((child) => ({
        value: child.value,
        title: child.title,
        label: child.title,
        active: child.value === activeTab,
        onClick: () => onClick?.(child.value || ''),
      }));
  }, [activeTab, children, onClick]);

  const isActive = value === activeTab || children?.some((child) => child.value === activeTab);

  const externClassName = isFunction(tabClassName) ? tabClassName({isActive}) : tabClassName;
  const className = cn(b({active: isActive, error: hasError, [style]: true}), externClassName);

  if (customElement) {
    return customElement;
  }

  if (filteredChildren?.length > 1) {
    return <Dropdown showToggleArrow options={filteredChildren} classNames={{trigger: className}} trigger={title} />;
  } else if (filteredChildren[0]) {
    return <TabSwitcherItem {...props} {...(filteredChildren[0] || {})} children={[]} />;
  }

  return (
    <button type="button" className={className} key={value} disabled={disabled} onClick={() => onClick?.(value || '')}>
      {title}
    </button>
  );
};

export default TabSwitcherItem;
