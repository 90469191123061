import React, {Fragment, useState, useEffect, useCallback, useMemo} from 'react';

import PropTypes from 'prop-types';
import qs from 'qs';
import {useDispatch, useSelector} from 'react-redux';
import {withRouter} from 'react-router';
import {useMedia} from 'react-use';

import bem from 'client/services/bem';
import {getAccessLevel} from 'client/services/cookie-data-source';
import {useLanguage, useQueryParams} from 'client/services/hooks';

import {
  getActiveOperations,
  getFinishedOperations,
  getConfigurationOperations,
  setClientParams,
} from 'client/ducks/operations/actions';
import {
  selectActiveOperations,
  selectFinishedOperations,
  selectConfigurationOperations,
} from 'client/ducks/operations/selectors';
import {selectClientUserMembership} from 'client/ducks/user-clients/selectors';
import {selectViewMode} from 'client/ducks/user/selectors';

import {ToggleInput} from 'client/common/inputs';
import SearchInput from 'client/common/inputs/search-input';
import Offscreen from 'client/common/offscreen';
import TabSwitcher from 'client/common/tab-switcher';

import Icon from 'client/components/common/icon';
import Spinner from 'client/components/common/spinner';
import Typography from 'client/components/common/typography';

import AgencyClientsBar from 'client/components/agencies/various/agency-clients-bar';
import OperationsCards from 'client/components/operations-client/components/cards/operation-cards';
import OperationsFilters from 'client/components/operations-client/components/operations-filters';
import {ACCESS_LEVEL} from 'client/models/common/constants';
import {operationHasConfigTab} from 'client/models/operations/helpers';

import cssModule from './client-operations-list.module.scss';

const b = bem('client-operations-list', {cssModule});

const UPPER_ACCESS_LEVELS = [ACCESS_LEVEL.REGION, ACCESS_LEVEL.NATIONAL, ACCESS_LEVEL.CLIENT_ADMIN];
const ACTIVE_DEFAULT_PER_PAGE = 10;
const DEFAULT_PER_PAGE = 5;

const PAGE_INITIAL_VALUES = {
  activePage: null,
  finishedPage: null,
  configurationPage: null,
};

const ALL_INITIAL_VALUES = {
  ...PAGE_INITIAL_VALUES,
  activeSort: null,
  finishedSort: null,
  configurationSort: null,
  selectedOperationId: null,
  withSublevels: null,
  regions: null,
  stores: null,
  search: null,
};

const tabs = {
  OPERATIONS: 'operations',
  WEEK_VIEW: 'week_view',
};

const ClientOperationsList = (props) => {
  const {generalClient, agencyClient} = props;
  const lang = useLanguage('OPERATIONS');
  const dispatch = useDispatch();
  const isWide = useMedia('(min-width: 768px)');

  const [isFetchingOperations, setIsFetchingOperations] = useState(true);
  const [activeTab, setActiveTab] = useState(tabs.OPERATIONS);

  const activeOperations = useSelector(selectActiveOperations);
  const configurationOperations = useSelector(selectConfigurationOperations);
  const finishedOperations = useSelector(selectFinishedOperations);
  const {id: viewUserId} = useSelector(selectViewMode);
  const clientUserMembership = useSelector(selectClientUserMembership);

  const [queryParams, setQueryParams] = useQueryParams(props, {withSublevels: false}, {parse: {parseBooleans: true}});
  const {search, withSublevels, selectedOperationId, activeSort, configurationSort, finishedSort, regions, stores} =
    queryParams;

  const client = agencyClient || generalClient;
  const showCompanyElements = !generalClient.isAgency || Boolean(agencyClient);

  const {client_network, user_access_levels = [], auto_configuration} = client || {};

  const getCommonParams = useCallback(() => {
    const params = {
      include: ['automation_tasks', 'instore_tasks', 'membership', 'client'],
      q: {
        client_id_eq: client.id,
        name_cont: search,
      },
      include_operation_editable: true,
      include_operation_show_configuration: true,
      include_operation_available_membership: true,
    };

    if (withSublevels) {
      params.with_sublevels = true;
      params.include.push('membership.regions', 'membership.places');

      params.regions_id_in = regions;
      params.places_id_in = stores;
    }

    return params;
  }, [client.id, search, withSublevels, regions, stores]);

  const fetchOperations = useCallback(async () => {
    setIsFetchingOperations(true);

    const {activePage, configurationPage, finishedPage, activePerPage, configurationPerPage, finishedPerPage} =
      queryParams;

    const activeParams = getCommonParams();
    activeParams.q.s = `${activeSort || 'from'} desc`;
    activeParams.page = activePage || 1;
    activeParams.per_page = activePerPage || ACTIVE_DEFAULT_PER_PAGE;
    activeParams.q.status_eq = 'active';
    activeParams.q.g = [
      {
        client_interface_id_null: true,
        client_interface_web_app_state_in: ['published', 'republished'],
        client_interface_device_state_in: ['published', 'republished'],
        m: 'or',
      },
    ];

    const configurationParams = getCommonParams();
    configurationParams.q.s = `${configurationSort || 'from'} desc`;
    configurationParams.page = configurationPage || 1;
    configurationParams.per_page = configurationPerPage || DEFAULT_PER_PAGE - 1;
    configurationParams.q.status_eq = 'active';
    configurationParams.q.client_interface_id_not_null = true;
    configurationParams.q.client_interface_web_app_state_not_in = ['published', 'republished'];
    configurationParams.q.client_interface_device_state_not_in = ['published', 'republished'];

    const finishedParams = getCommonParams();
    finishedParams.q.s = `${finishedSort || 'to'} desc`;
    finishedParams.page = finishedPage || 1;
    finishedParams.per_page = finishedPerPage || DEFAULT_PER_PAGE;

    await Promise.all([
      dispatch(getActiveOperations(activeParams)),
      dispatch(getFinishedOperations(finishedParams)),
      dispatch(getConfigurationOperations(configurationParams)),
    ]);
    return setIsFetchingOperations(false);
  }, [queryParams, getCommonParams, activeSort, configurationSort, finishedSort, dispatch]);

  const tabsConfig = useMemo(
    () => [
      {title: lang.OPERATION_STATUS_TITLES.ACTIVE, value: tabs.OPERATIONS, className: b('tab')},
      {title: lang.OPERATION_STATUS_TITLES.WEEK_VIEW, value: tabs.WEEK_VIEW, className: b('tab'), disabled: true},
    ],
    [lang.OPERATION_STATUS_TITLES],
  );

  const activeOperationsData = useMemo(() => {
    return isWide
      ? activeOperations.operations
      : activeOperations.operations.filter((el) => el.instore_tasks.length > 0);
  }, [activeOperations.operations, isWide]);

  useEffect(() => {
    fetchOperations();
  }, [fetchOperations]);

  const onSelectOperation = useCallback(
    (id) => {
      setQueryParams({selectedOperationId: id});

      const updatedQueryParams = {...queryParams, selectedOperationId: id};
      const updatedQueryString = qs.stringify(updatedQueryParams, {addQueryPrefix: true});

      dispatch(
        setClientParams({
          qs: updatedQueryString,
          clientId: generalClient.id,
        }),
      );
    },
    [setQueryParams, queryParams, dispatch, generalClient.id],
  );

  const handleFiltersChange = useCallback(
    (values) => {
      setQueryParams({...PAGE_INITIAL_VALUES, ...values});
    },
    [setQueryParams],
  );

  const handleSublevelsChange = useCallback(
    (event) => {
      const {checked} = event.target;

      setQueryParams({...PAGE_INITIAL_VALUES, withSublevels: checked});
    },
    [setQueryParams],
  );

  const handleSearchChange = useCallback(
    (value) => {
      setQueryParams({...PAGE_INITIAL_VALUES, search: value || null});
    },
    [setQueryParams],
  );

  const accessLevel = getAccessLevel();
  const hasSubLevels = UPPER_ACCESS_LEVELS.includes(accessLevel);

  const hasActiveOperations = activeOperations.operations.length > 0;
  const hasFinishedOperations = finishedOperations.operations.length > 0;
  const hasConfigurationOperations = configurationOperations.operations.length > 0;

  const isConfigurationBlockVisible =
    user_access_levels.includes(accessLevel) ||
    auto_configuration ||
    (hasConfigurationOperations && generalClient.isAgency && !agencyClient);

  let canCreateOperation =
    operationHasConfigTab({
      client,
      accessLevel,
      membershipIsEditor: clientUserMembership?.editor,
      viewUserId,
    }) && showCompanyElements;

  // TODO: for realization isWeekViewMode
  const isWeekViewMode = false;

  const hasAnyData = hasActiveOperations || hasFinishedOperations || isConfigurationBlockVisible;

  const activeOperationsContent = (
    <OperationsCards
      className={b('operations-content')}
      title={lang.OPERATION_STATUS_TITLES.ACTIVE}
      data={activeOperationsData}
      totalPages={activeOperations.meta.total_pages}
      totalItems={activeOperations.meta.total_count}
      onPageChange={(page) => setQueryParams({activePage: page})}
      currentPage={activeOperations.meta.current_page}
      onSortChange={(value) => setQueryParams({activeSort: value, activePage: null})}
      sort={activeSort || 'from'}
      onSelectOperation={onSelectOperation}
      selectedOperationId={selectedOperationId}
      isWide={isWide}
      headerId="op-active"
      client={client}
    />
  );

  const finishedOperationsContent = (
    <OperationsCards
      className={b('operations-content')}
      title={lang.OPERATION_STATUS_TITLES.FINISHED}
      data={finishedOperations.operations}
      totalPages={finishedOperations.meta.total_pages}
      totalItems={finishedOperations.meta.total_count}
      onPageChange={(page) => setQueryParams({finishedPage: page})}
      currentPage={finishedOperations.meta.current_page}
      onSortChange={(value) => setQueryParams({finishedSort: value, finishedPage: null})}
      sort={finishedSort || 'to'}
      onSelectOperation={onSelectOperation}
      selectedOperationId={selectedOperationId}
      headerId="op-finished"
      client={client}
    />
  );

  const configurationOperationsContent = (
    <OperationsCards
      className={b('operations-content')}
      title={lang.OPERATION_STATUS_TITLES.ON_CONFIGURATION}
      data={configurationOperations.operations}
      totalPages={configurationOperations.meta.total_pages}
      totalItems={configurationOperations.meta.total_count}
      onPageChange={(page) => setQueryParams({configurationPage: page})}
      currentPage={configurationOperations.meta.current_page}
      onSortChange={(value) => setQueryParams({configurationSort: value, configurationPage: null})}
      sort={configurationSort || 'from'}
      onSelectOperation={onSelectOperation}
      selectedOperationId={selectedOperationId}
      canCreateOperation={canCreateOperation}
      headerId="op-config"
      client={client}
    />
  );

  return (
    <div className={b({agency: generalClient.isAgency})}>
      {generalClient.isAgency && (
        <AgencyClientsBar
          agencyId={generalClient.id}
          onChange={(params) => {
            setQueryParams({...ALL_INITIAL_VALUES, ...params});
          }}
          allOption
        />
      )}
      {isWide && (
        <header className={b('header')}>
          <div className={b('search')}>
            <SearchInput
              placeholder={lang.SEARCH_BY_OPERATION_PLACEHOLDER}
              onSearch={handleSearchChange}
              searchDefault={search}
            />
          </div>

          {client_network && hasSubLevels && showCompanyElements && (
            <Fragment>
              {withSublevels && (
                <OperationsFilters
                  className={b('filters-button')}
                  onFiltersChange={handleFiltersChange}
                  clientId={client.id}
                />
              )}

              <ToggleInput
                label={lang.SUB_LEVELS_LABEL}
                color="primary"
                className={b('toggle')}
                checked={withSublevels}
                onChange={handleSublevelsChange}
              />
            </Fragment>
          )}
        </header>
      )}
      {!isWide && (
        <TabSwitcher className={b('tab-switcher')} tabs={tabsConfig} activeTab={activeTab} onTabClick={setActiveTab} />
      )}

      {!isFetchingOperations && (
        <article className={b('operations')}>
          {hasActiveOperations &&
            (isWide ? (
              activeOperationsContent
            ) : (
              <Offscreen hidden={activeTab !== tabs.OPERATIONS}>{activeOperationsContent}</Offscreen>
            ))}

          {isConfigurationBlockVisible && (isWide ? configurationOperationsContent : <></>)}

          {hasFinishedOperations && (isWide ? finishedOperationsContent : <></>)}

          {isWeekViewMode &&
            (isWide ? (
              <p>{tabs.WEEK_VIEW}</p>
            ) : (
              <Offscreen hidden={activeTab !== tabs.WEEK_VIEW}>{tabs.WEEK_VIEW}</Offscreen>
            ))}

          {!hasAnyData && (
            <section className={b(['no-cards'])}>
              <Icon name="home-page--filled" />
              <Typography variant="text-additional">{lang.NO_OPERATIONS}</Typography>
            </section>
          )}
        </article>
      )}
      {isFetchingOperations && <Spinner color="primary" className={b('spinner')} />}
    </div>
  );
};

ClientOperationsList.propTypes = {
  generalClient: PropTypes.object.isRequired,
  agencyClient: PropTypes.object,
  // from withRouter
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(ClientOperationsList);
