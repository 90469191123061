import React from 'react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';
import {parseDate} from 'client/services/utils/date';

import AppButton from 'client/common/buttons';

import ClientTable from 'client/components/common/client-table';

import {CouponsGridProps} from './types';

import cssModule from './coupons-grid.module.scss';

const b = bem('coupons-grid', {cssModule});

const CouponsGrid: React.FC<CouponsGridProps> = (props) => {
  const {data, timezone, onNameClick, onEditClick} = props;

  const lang = useLanguage('OPERATION_PAGE.COUPONS_CARD.COUPON_FIELDS');

  return (
    <ClientTable
      data={data}
      className={b('table')}
      columns={[
        {
          name: 'internal_name',
          label: lang.NAME,
          width: '75px',
          render: ({index, item: {internal_name}}) => (
            <AppButton
              className={b('name')}
              label={internal_name}
              onClick={() => onNameClick(data[index])}
              color="text-normal"
              link={true}
              transparent={true}
              fontSize="small"
            />
          ),
        },
        {
          name: 'internal_code',
          path: 'internal_code',
          label: lang.CODE,
          width: '75px',
        },
        {
          name: 'coupon_type',
          label: lang.TYPE,
          render: ({item: {coupon_type}}) => lang[('TYPE_' + coupon_type.toUpperCase()) as keyof typeof lang],
        },
        {
          name: 'validity_to',
          label: lang.VALIDITY_TO,
          render: ({item: {validity_to}}) => parseDate(validity_to, {timezone, outputFormat: 'DATE'}),
        },
        {
          name: 'number_of_generated_tokens',
          label: lang.TOKENS_NUMBER,
          width: '110px',
          render: ({index, item: {number_of_generated_tokens}}) => (
            <div className={b('tokens-number')}>
              <span>{number_of_generated_tokens}</span>
              <AppButton iconName="edit" onClick={() => onEditClick(data[index])} rounded={true} color="coupons" />
            </div>
          ),
        },
      ]}
    />
  );
};

export default CouponsGrid;
