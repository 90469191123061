import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';
import {parseDate, normalizeDate} from 'client/services/utils/date';

import cssModule from './date-time.module.scss';

const b = bem('date-time', {cssModule});

type DateTimeProps = {
  value?: Date | string;
  oneLine?: boolean;
  fullWidth?: boolean;
  showBase?: boolean;
  className?: string;
  timezone?: string;
};

export const DateTime: React.FC<DateTimeProps> = (props) => {
  const {value = '', oneLine, fullWidth, timezone, showBase = true, className = ''} = props;

  const parsedValue = normalizeDate(value) || '';

  if ((!parsedValue || parsedValue === ' ') && showBase) {
    return null;
  }

  return (
    <div
      className={cn(b({'one-line': oneLine, 'full-width': fullWidth}), className)}
      title={`${parseDate(parsedValue, {timezone, outputFormat: 'DATE'})} ${parseDate(parsedValue, {
        timezone,
        outputFormat: 'TIME',
      })}`}
    >
      <div className={b('date')}>{parseDate(parsedValue, {timezone, outputFormat: 'DATE'})}</div>
      <div className={b('time')}>{parseDate(parsedValue, {timezone, outputFormat: 'TIME_SECONDS'})}</div>
    </div>
  );
};
