import moment from 'moment';

import {Interaction} from 'client/models/interactions/types';
import {Operation} from 'client/models/operations/types';
import {Place} from 'client/models/places/types';

const getOperation = (place: Place) => {
  const currentInteractions =
    place.device_affectations?.reduce((res: Interaction[], item) => {
      return [...res, ...(item.current_interaction || [])];
    }, []) || [];

  for (const interaction of currentInteractions) {
    if (interaction?.place_id === place.id && interaction?.operation) {
      return interaction.operation;
    }
  }

  for (const sourceStore of place.current_source_stores || []) {
    if (sourceStore.store_id === place.id && sourceStore.operation) {
      return sourceStore.operation;
    }
  }

  return null;
};

const getDates = (place: Place) => {
  return {
    from:
      [place.attached_offline_interactions_from, place.attached_source_stores_from].filter((i) => i).sort()?.[0] || '',
    to:
      [place.attached_offline_interactions_to, place.attached_source_stores_to]
        .filter((i) => i)
        .sort()
        ?.reverse()?.[0] || '',
  };
};

export const getCheckable = (operation: Operation) => {
  const isHybrid = operation.client_interface?.device && operation.client_interface?.online;
  const isDeviceOnly = !isHybrid && operation.client_interface?.device;
  const isOnlineOnly = !isHybrid && operation.client_interface?.online;

  if (isOnlineOnly) {
    return !!operation.client_interface?.interactions?.some((i) => i.track_stores);
  }

  if (isDeviceOnly) {
    return !!operation.client_interface?.interactions?.some((i) => i.type === 'OfflineInteraction');
  }

  return true;
};

const getShowDisabled = (place: Place, operation: Operation, showCheckbox: boolean) => {
  const isOnlineOnly = !operation.client_interface?.device && operation.client_interface?.online;

  if (!showCheckbox || isOnlineOnly) {
    return false;
  }

  const interactions =
    place.device_affectations?.reduce((res: Interaction[], item) => {
      return [...res, ...(item.interactions || [])];
    }, []) || [];

  const opFrom = moment(operation.from);
  const opTo = moment(operation.to);

  const hasIntersection = interactions.some((i) => {
    const intFrom = moment(i.from);
    const intTo = moment(i.to);
    const isNotIntersected = intFrom.isAfter(opTo) || (intFrom.isBefore(opFrom) && intTo.isBefore(opFrom));
    return !isNotIntersected;
  });

  return hasIntersection;
};

export const getCalculations = (place: Place, operation: Operation) => {
  const checkable = getCheckable(operation);
  const showDisabled = getShowDisabled(place, operation, checkable);

  return {
    operation: getOperation(place),
    ...getDates(place),
    showDisabled,
    canCheck: checkable && !showDisabled,
  };
};
