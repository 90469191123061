import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import isUndefined from 'lodash/isUndefined';
import {stringify} from 'qs';
import {Action, Dispatch} from 'redux';
import {RSAA, RSAAAction, RSAACall, RSAAResultAction} from 'redux-api-middleware';

import {getViewModeStorageParam} from 'client/services/userViewModeService';

type CallApiParams = Omit<RSAACall, 'body'> & {
  isAuth?: boolean;
  fake_client_user_id?: number | string;
  queryParams?: Record<string, any>;
  noCache?: boolean;
  body?: object | string | boolean | Blob | FormData;
};

export const callApi = <ResultPayload>(
  dispatch: Dispatch<Action<ResultPayload>>,
  params: CallApiParams,
  withCatch = false,
) => {
  if (params.body && isObject(params.body)) {
    params.body = JSON.stringify(params.body);
  }

  if (isUndefined(params.isAuth)) {
    params.isAuth = true;
  }

  let qp: Record<string, string> = {};
  const id = getViewModeStorageParam().id;

  if (id && ['GET', 'POST'].includes(params.method)) {
    qp.fake_client_user_id = id;
  }
  if (params.queryParams) {
    qp = {
      ...qp,
      ...params.queryParams,
    };
  }
  if (!isEmpty(qp)) {
    params.endpoint += `?${stringify(qp, {arrayFormat: 'brackets', encode: false})}`;
  }
  delete params.queryParams;

  return dispatch({[RSAA]: params} as RSAAAction<any, ResultPayload>).then((action) => {
    if (withCatch && action.error && action.payload) {
      throw action.payload;
    }

    return action;
  }) as Promise<RSAAResultAction<ResultPayload>>;
};
