export default {
  GET_CLIENT_PLACES_REQUEST: '@@client-stores/GET_CLIENT_PLACES_REQUEST',
  GET_CLIENT_PLACES_SUCCESS: '@@client-stores/GET_CLIENT_PLACES_SUCCESS',
  GET_CLIENT_PLACES_ERROR: '@@client-stores/GET_CLIENT_PLACES_ERROR',

  LINK_PLACES_REQUEST: '@@client-stores/LINK_PLACES_REQUEST',
  LINK_PLACES_SUCCESS: '@@client-stores/LINK_PLACES_SUCCESS',
  LINK_PLACES_ERROR: '@@client-stores/LINK_PLACES_ERROR',

  GET_CAMPAIGNS_REQUEST: '@@client-stores/GET_CAMPAIGNS_REQUEST',
  GET_CAMPAIGNS_SUCCESS: '@@client-stores/GET_CAMPAIGNS_SUCCESS',
  GET_CAMPAIGNS_ERROR: '@@client-stores/GET_CAMPAIGNS_ERROR',

  UPDATE_SOURCE_STORES_REQUEST: '@@client-stores/UPDATE_SOURCE_STORES_REQUEST',
  UPDATE_SOURCE_STORES_SUCCESS: '@@client-stores/UPDATE_SOURCE_STORES_SUCCESS',
  UPDATE_SOURCE_STORES_ERROR: '@@client-stores/UPDATE_SOURCE_STORES_ERROR',
};
