import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {generatePath} from 'react-router';
import {useHistory, useParams} from 'react-router-dom';
import {routePaths} from 'routes/index';

import bem from 'client/services/bem';
import {useLanguage, useQueryParams, useReduxFetch} from 'client/services/hooks';

import {setCurrentAgencyClientId} from 'client/ducks/catalogs/actions';
import {selectCurrentAgencyClientId} from 'client/ducks/catalogs/selectors';
import {getClientTemplates} from 'client/ducks/templates/actions';
import {groupClientTemplateByTabs} from 'client/ducks/templates/mappers';
import {selectClientAccessLevel, selectClientUsersLoading} from 'client/ducks/user-clients/selectors';

import AwaitContainer from 'client/common/await-container';
import TabSwitcher from 'client/common/tab-switcher';

import useFetchSelectedAgencyClient from 'client/components/agencies/hooks/useFetchSelectedAgencyClient';
import AgencyClientsBar from 'client/components/agencies/various/agency-clients-bar';
import {SORT_BY_VALUES} from 'client/components/catalog-templates/components/catalog-template-toolbar';
import CatalogTemplatesEmptyPanel from 'client/components/catalog-templates/panels/catalog-templates-empty-panel';
import CatalogTemplatesNoAccessPanel from 'client/components/catalog-templates/panels/catalog-templates-no-access-panel';
import CatalogTemplatesTabsContainer from 'client/components/catalog-templates/tabs/catalog-templates-tabs-container';
import {CLIENT_TYPES} from 'client/models/client/constants';
import {CATALOG_TEMPLATE_TABS} from 'client/models/templates/constants';
import {CatalogTemplateTabType, ClientTemplate} from 'client/models/templates/types';

import cssModule from './catalog-templates.module.scss';

const b = bem('catalog-templates', {cssModule});

const CatalogTemplates: React.FC = () => {
  const dispatch = useDispatch();
  const lang = useLanguage('CATALOG_TEMPLATES');
  const history = useHistory();
  const {type} = useParams<{type: CatalogTemplateTabType}>();
  const {clientId} = useParams<{clientId: string}>();
  const [queryParams, setQueryParams] = useQueryParams(null, {sortBy: SORT_BY_VALUES.DATE});
  const [templatesList, setTemplatesList] = useState<ClientTemplate[]>([]);
  const {generalClient, agencyClient, loading: loadingAgencyClient} = useFetchSelectedAgencyClient(Number(clientId));

  const currentAgencyClientId = useSelector(selectCurrentAgencyClientId);

  const templatesClientId = +(queryParams.agencyClientId || currentAgencyClientId || clientId);

  const isAgency = generalClient.type === CLIENT_TYPES.AGENCY;

  const {loading, fetch, data} = useReduxFetch<{client_templates: ClientTemplate[]}>({
    skip: !templatesClientId || !type,
    action: getClientTemplates,
    actionArgs: {
      include_template_form_accesses_editable: true,
      include: ['creator_team', 'client_user', 'catalog.forms'],
      include_template_screenshots: null,
      q: {
        client_id_eq: templatesClientId,
        catalog_type_eq: 'CatalogTemplate',
        s: [type === CATALOG_TEMPLATE_TABS.WEEZIO ? `display_order` : '', `${queryParams.sortBy} desc`].filter(
          (i) => i,
        ),
      },
    },
  });

  useEffect(() => {
    const templates = data?.client_templates;
    if (Array.isArray(templates)) {
      setTemplatesList(templates);
    }
  }, [data?.client_templates]);

  useEffect(() => {
    if (currentAgencyClientId && currentAgencyClientId !== +queryParams.agencyClientId) {
      setQueryParams({agencyClientId: currentAgencyClientId});
    }
  }, [currentAgencyClientId, queryParams.agencyClientId, setQueryParams]);

  const updateTemplate = useCallback((templateId: number, patchData: Partial<ClientTemplate>) => {
    setTemplatesList((prevState) => {
      const template = prevState.find(({id}) => id === templateId);
      if (template) {
        const templateIndex = prevState.indexOf(template);
        return [
          ...prevState.slice(0, templateIndex),
          {
            ...template,
            ...patchData,
          },
          ...prevState.slice(templateIndex + 1),
        ];
      }
      return prevState;
    });
  }, []);

  const isAccessLoading = useSelector(selectClientUsersLoading);
  const access = useSelector(selectClientAccessLevel);
  const templates = useMemo(() => groupClientTemplateByTabs(templatesList, {access}), [access, templatesList]);

  const isEmptyTemplates = !templates['my-templates'].length && !templates.shared.length && !templates.weezio.length;

  const agencyClientQs = queryParams.agencyClientId ? `?agencyClientId=${queryParams.agencyClientId}` : '';

  const handleSwitchTab = (value: CatalogTemplateTabType) => {
    history.replace(
      generatePath(routePaths.client.CatalogTemplatesPage, {
        type: value,
        clientId,
      }) + agencyClientQs,
    );
  };

  useEffect(() => {
    if ((!loading && !isEmptyTemplates && access && !templates[type]?.length) || !type) {
      const redirectionTypesOrder = ['my-templates', 'shared', 'weezio'];
      const nonEmptyType = redirectionTypesOrder.find((el) => templates[el]?.length) || CATALOG_TEMPLATE_TABS.WEEZIO;
      if (nonEmptyType) {
        history.replace(
          generatePath(routePaths.client.CatalogTemplatesPage, {
            type: nonEmptyType,
            clientId,
          }) + agencyClientQs,
        );
      }
    }
  }, [access, agencyClientQs, clientId, history, isEmptyTemplates, loading, templates, type]);

  if (!isAccessLoading && !access) {
    return <CatalogTemplatesNoAccessPanel />;
  }

  return (
    <div className={b()}>
      {isAgency && (
        <AgencyClientsBar
          agencyId={Number(clientId)}
          title={lang.TITLE}
          agencyOption={true}
          agencyClientId={templatesClientId}
          onChange={(params) => {
            setQueryParams(params);
            dispatch(setCurrentAgencyClientId(+params?.agencyClientId));
          }}
        />
      )}
      <AwaitContainer
        loading={isAccessLoading || loadingAgencyClient}
        overlay={true}
        overlayClassName={b('await-container')}
      >
        <header className={b('header', {agency: isAgency})}>
          {!isAgency && <h1 className={b('title')}>{lang.TITLE}</h1>}
          <TabSwitcher
            className={b('tabs')}
            style="title"
            tabs={[
              {
                title: lang.TAB_WEEZIO.TITLE,
                exclude: !isEmptyTemplates && !templates[CATALOG_TEMPLATE_TABS.WEEZIO].length,
                value: CATALOG_TEMPLATE_TABS.WEEZIO,
              },
              {
                title: lang.TAB_SHARED.TITLE,
                exclude: !templates[CATALOG_TEMPLATE_TABS.SHARED].length,
                value: CATALOG_TEMPLATE_TABS.SHARED,
              },
              {
                title: lang.TAB_MY_TEMPLATES.TITLE,
                exclude: !templates[CATALOG_TEMPLATE_TABS.MY_TEMPLATES].length,
                value: CATALOG_TEMPLATE_TABS.MY_TEMPLATES,
              },
            ]}
            onTabClick={handleSwitchTab}
            activeTab={type}
          />
        </header>

        {isEmptyTemplates && !loading ? (
          <CatalogTemplatesEmptyPanel />
        ) : (
          <AwaitContainer loading={loading} overlay={true} overlayClassName={b('await-container')}>
            <CatalogTemplatesTabsContainer
              type={type}
              fetchData={fetch}
              templates={templates}
              updateTemplate={updateTemplate}
              agencyClient={agencyClient}
            />
          </AwaitContainer>
        )}
      </AwaitContainer>
    </div>
  );
};

export default CatalogTemplates;
