import {Reducer} from 'redux';

import {ACCESS_LEVEL} from 'client/models/common/constants';

import types from './types';

export const DEFAULT_PASSWORD_EXPIRATION = 180;

export type UserPasswordExpirationConfig = {
  [ACCESS_LEVEL.NATIONAL]: number;
  [ACCESS_LEVEL.REGION]: number;
  [ACCESS_LEVEL.STORE]: number;
  [ACCESS_LEVEL.LOCAL]: number;
  id?: number;
};

const initialState: UserPasswordExpirationConfig = {
  [ACCESS_LEVEL.NATIONAL]: DEFAULT_PASSWORD_EXPIRATION,
  [ACCESS_LEVEL.REGION]: DEFAULT_PASSWORD_EXPIRATION,
  [ACCESS_LEVEL.STORE]: DEFAULT_PASSWORD_EXPIRATION,
  [ACCESS_LEVEL.LOCAL]: DEFAULT_PASSWORD_EXPIRATION,
};

const userPasswordExpirationReducer: Reducer<UserPasswordExpirationConfig> = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PASSWORD_EXPIRATION_SUCCESS:
      return {
        ...initialState,
        ...action.payload.user_password_expiration_configs[0],
      };
    case types.SET_PASSWORD_EXPIRATION_SUCCESS:
      return {
        ...state,
        ...action.payload.user_password_expiration_config,
      };
    default:
      return state;
  }
};

export default userPasswordExpirationReducer;
