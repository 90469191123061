import find from 'lodash/find';

import {parseDate} from 'client/services/utils/date';

import {COUPON_TYPES, VALIDITY_TYPES, CHARACTERS, CHARACTER_FORMATS, PREFIXES} from 'client/ducks/coupons/constants';

const toString = (value) => (value ? value.toString() : '');

const convertDate = (value, timezone) => (value ? parseDate(value, {timezone, outputFormat: 'DATE'}) : null);

const getAppInitialValues = (coupon, timezone) => {
  if (coupon) {
    return {
      internal_name: coupon.internal_name,
      internal_code: coupon.internal_code,
      external_name: coupon.external_name,
      type: coupon.coupon_type,
      validity_type: coupon.validity_duration ? VALIDITY_TYPES.DURATION : VALIDITY_TYPES.FIXED_VALUE,
      validity_days: toString(coupon.validity_days),
      validity_from: convertDate(coupon.validity_from, timezone),
      validity_to: convertDate(coupon.validity_to, timezone),
      comment: coupon.comment,
    };
  }

  return {
    validity_type: VALIDITY_TYPES.FIXED_VALUE,
  };
};

const getWeezioInitialValues = (coupon) => {
  if (coupon && coupon.coupon_type === COUPON_TYPES.WEEZIO) {
    const prefix1 = find(coupon.coupon_prefixes, {number: 1});
    const prefix2 = find(coupon.coupon_prefixes, {number: 2});

    return {
      weezio_maximum_tokens_number: toString(coupon.max_number_of_tokens),
      length_without_prefix: toString(coupon.token_length),
      characters: coupon.allowed_characters,
      characters_format: coupon.default_characters,
      exclude_characters: coupon.excluded_characters,
      prefix1_id: prefix1.id,
      prefix1: prefix1.prefix_type,
      prefix1_fixed_value: prefix1.value,
      prefix2_id: prefix2.id,
      prefix2: prefix2.prefix_type,
      prefix2_fixed_value: prefix2.value,
    };
  }

  return {
    characters: CHARACTERS.ALPHANUMERIC,
    characters_format: CHARACTER_FORMATS.MIXED,
    prefix1_id: null,
    prefix1: PREFIXES.WITHOUT,
    prefix2_id: null,
    prefix2: PREFIXES.WITHOUT,
  };
};

const getSogecInitialValues = (coupon) => {
  if (coupon && coupon.coupon_type === COUPON_TYPES.SOGEC) {
    return {
      sogec_maximum_tokens_number: toString(coupon.max_number_of_tokens),
      coupon_id: coupon.sogec_configuration.external_coupon_id,
      campaign_id: coupon.sogec_configuration.external_campaign_id,
      login: coupon.sogec_configuration.login,
      password: coupon.sogec_configuration.password,
      sogec_configuration_id: coupon.sogec_configuration.id,
    };
  }

  return {
    sogec_configuration_id: null,
  };
};

export default (coupon, {timezone}) => ({
  test_mode: coupon ? coupon.test_mode : true,
  ...getAppInitialValues(coupon, timezone),
  ...getWeezioInitialValues(coupon),
  ...getSogecInitialValues(coupon),
});
