import React, {Component} from 'react';

import find from 'lodash/find';
import PropTypes from 'prop-types';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {connect} from 'react-redux';

import bem from 'client/services/bem';
import {parseDate} from 'client/services/utils/date';

import {selectIsAdmin} from 'client/ducks/user/selectors';

import AppButton from 'client/common/buttons/app-button';
import Icon from 'client/common/icon';
import ToggleInput from 'client/common/inputs/toggle-input';
import PaginationBar from 'client/common/paginations/pagination-bar';
import PerPageDropdown from 'client/common/selects/per-page-dropdown';

import ClientTable from 'client/components/common/client-table';

import {PRIZE_MAP_LEVEL_TYPES, PRIZE_MAP_TYPES} from 'client/components/games/game-config-modal/constants';
import PrizeMapCell from 'client/components/tables/common/cells/prize-map-cell';

import './camp-data-grid.scss';

const b = bem('camp-data-grid');

class CampDataGrid extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    lang: PropTypes.object.isRequired,
    campaign: PropTypes.object.isRequired,
    perPage: PropTypes.number.isRequired,
    archived: PropTypes.bool,
    isAdmin: PropTypes.bool,
    currentPage: PropTypes.number,
    totalPages: PropTypes.number,
    totalItems: PropTypes.number,
    onPageChange: PropTypes.func.isRequired,
    onSortChange: PropTypes.func.isRequired,
    onSearchChange: PropTypes.func,
    onDeleteSource: PropTypes.func.isRequired,
    onOpenSourceModal: PropTypes.func.isRequired,
    updateOnlineInteraction: PropTypes.func.isRequired,
    games: PropTypes.array.isRequired,
    sort: PropTypes.shape({
      name: PropTypes.string,
      order: PropTypes.string,
    }),
  };

  state = {
    showEditSourceModal: false,
    sourceId: null,
  };

  renderToolbar = () => {
    const {onOpenSourceModal, isAdmin} = this.props;

    return (
      <>
        {isAdmin && (
          <button
            className={`button button--bg-6${this.props.archived ? ' visibility-hidden' : ''}`}
            onClick={onOpenSourceModal()}
          >
            <Icon name="plus" className="button__icon" />
            <span>{this.props.lang.ADD_SOURCE_BUTTON}</span>
          </button>
        )}
      </>
    );
  };

  renderSource = ({value: source, item: interaction}) => {
    const {onDeleteSource, isAdmin} = this.props;

    return (
      <div className="camp-data-grid__source theme-color-10">
        <div className="main-text main-text--underline">{source && source.name}</div>
        {isAdmin && !interaction.direct_access && (
          <div className="camp-data-grid__source-clear" onClick={onDeleteSource(interaction.id)}>
            <Icon className="camp-data-grid__source-clear-icon" name="clear" />
          </div>
        )}
      </div>
    );
  };

  renderURL = ({value: url}) => {
    return <div className="camp-data-grid__url ellipsis-text">{url}</div>;
  };

  renderStatus = ({value: active}) => {
    return <div className={b('status-icon', {active})} />;
  };

  renderSourceId = ({item: interaction}) => {
    const id = interaction.name ? interaction.id : interaction.source_id;

    return (
      <CopyToClipboard text={id}>
        <button className="button button--bg-6">{this.props.lang.COPY_BUTTON}</button>
      </CopyToClipboard>
    );
  };

  updateTestMode = (id, value) => {
    this.props.updateOnlineInteraction(id, {test_mode: value});
  };

  renderTestMode = ({item: {id, test_mode}}) => {
    return <ToggleInput checked={test_mode} onChange={() => this.updateTestMode(id, !test_mode)} />;
  };

  updateNetworkTracking = (id, value) => {
    this.props.updateOnlineInteraction(id, {track_stores: value});
  };

  renderNetworkTracking = ({item: {id, track_stores, finished}}) => {
    return (
      <ToggleInput
        checked={track_stores}
        onChange={() => this.updateNetworkTracking(id, !track_stores)}
        disabled={finished}
      />
    );
  };

  renderPrizeMap =
    (winNumber) =>
    ({item: value}) => {
      const game = find(this.props.games, {win_number: winNumber});

      if (!game || !value?.prize_maps_present?.[`win${game?.win_number || ''}`]) {
        return null;
      }

      return (
        <PrizeMapCell
          variant="campaigns"
          game={game}
          levelKey="SOURCE"
          prizeMapParams={{
            type_eq: PRIZE_MAP_TYPES.ONLINE_PRIZE_MAP,
            online_level_eq: PRIZE_MAP_LEVEL_TYPES.SOURCE,
            online_source_id_eq: value.id,
          }}
        />
      );
    };

  renderEditSource = ({item: interaction}) => {
    const {onOpenSourceModal} = this.props;
    const id = interaction.name ? interaction.id : interaction.source_id;

    return <AppButton onClick={onOpenSourceModal(id)} transparent iconName="edit" color="campaigns" />;
  };

  render() {
    const {onPageChange, data, onSortChange, perPage, currentPage, totalPages, totalItems, lang, sort, campaign} =
      this.props;
    const timezone = campaign.automation_task?.operation?.timezone;

    return (
      <div className={b()}>
        <div className={b('toolbar')}>
          {this.renderToolbar()}
          <PerPageDropdown
            simpleValue
            value={perPage?.toString()}
            onChange={(value) => onPageChange({perPage: value, page: 1})}
          />
        </div>
        <ClientTable
          data={data}
          sortField={sort?.name}
          sortOrder={sort?.order}
          onSortChange={({sortField, sortOrder}) => onSortChange(sortField, sortOrder)}
          columns={[
            {
              label: lang.EDIT_SOURCE_BUTTON,
              name: 'edit',
              path: 'source_id',
              width: 110,
              render: this.renderEditSource,
            },
            {
              label: lang.NAME_COLUMN,
              name: 'name',
              path: 'name',
              width: 110,
            },
            {
              label: lang.SOURCE_COLUMN,
              name: 'source',
              path: 'source',
              width: 140,
              render: this.renderSource,
            },
            {
              label: lang.SOURCE_KEY,
              name: 'source_key',
              path: 'source_key',
              width: 90,
            },
            {
              label: lang.URL_COLUMN,
              name: 'url',
              path: 'url',
              width: 130,
              render: this.renderURL,
            },
            {
              label: lang.PARTS_COLUMN,
              name: 'participations_count',
              path: 'participations_count',
              width: 90,
              sortable: true,
            },
            {
              label: lang.VISITS_COLUMN,
              name: 'visits_count',
              path: 'visits_count',
              width: 90,
              sortable: true,
            },
            {
              label: lang.VIEWERS_COLUMN,
              name: 'views_count',
              path: 'views_count',
              width: 90,
              sortable: true,
            },
            {
              label: lang.TEST_MODE_COLUMN,
              name: 'test_mode',
              path: 'test_mode',
              width: 90,
              render: this.renderTestMode,
            },
            {
              label: lang.NETWORK_TRACKING,
              name: 'track_stores',
              path: 'track_stores',
              width: 90,
              render: this.renderNetworkTracking,
            },
            {
              label: lang.PARAM_1_COLUMN,
              name: 'parameter1',
              path: 'parameter1',
              width: 100,
            },
            {
              label: lang.PARAM_2_COLUMN,
              name: 'parameter2',
              path: 'parameter2',
              width: 100,
            },
            {
              label: lang.PARAM_3_COLUMN,
              name: 'parameter3',
              path: 'parameter3',
              width: 100,
            },
            {
              label: `${lang.GAME} 1`,
              name: 'online_prize_map_1',
              path: 'online_prize_maps',
              width: 90,
              render: this.renderPrizeMap(1),
            },
            {
              label: `${lang.GAME} 2`,
              name: 'online_prize_map_2',
              path: 'online_prize_maps',
              width: 90,
              render: this.renderPrizeMap(2),
            },
            {
              label: `${lang.GAME} 3`,
              name: 'online_prize_map_3',
              path: 'online_prize_maps',
              width: 90,
              render: this.renderPrizeMap(3),
            },
            {
              label: lang.START_DATE_COLUMN,
              name: 'from',
              path: 'from',
              width: 100,
              sortable: true,
              render: ({value}) => parseDate(value, {timezone, outputFormat: 'DATE'}),
            },
            {
              label: lang.END_DATE_COLUMN,
              name: 'to',
              path: 'to',
              width: 100,
              sortable: true,
              render: ({value}) => parseDate(value, {timezone, outputFormat: 'DATE'}),
            },
            {
              label: lang.REGION_COLUMN,
              name: 'accessible_region',
              path: 'accessible_region.name',
              width: 100,
            },
            {
              label: lang.STORE_COLUMN,
              name: 'accessible_store',
              path: 'accessible_store.name',
              width: 100,
            },
            {
              label: lang.STATUS_COLUMN,
              name: 'active',
              path: 'active',
              width: 90,
              sortable: true,
              render: this.renderStatus,
            },
            {
              label: lang.SOURCE_ID_COLUMN,
              name: 'source_id',
              path: 'source_id',
              width: 120,
              render: this.renderSourceId,
            },
          ]}
        />
        <PaginationBar
          data={data}
          perPage={perPage}
          currentPage={currentPage}
          totalPages={totalPages}
          totalItems={totalItems}
          onPageChange={onPageChange}
        />
      </div>
    );
  }
}

export default connect(({languageState, ...state}) => ({
  lang: languageState.payload.CAMPAIGN.TABLE,
  isAdmin: selectIsAdmin(state),
}))(CampDataGrid);
