import React, {PureComponent} from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {formatNumberSpace} from 'client/services/formatters';
import {dateToString, timeToString} from 'client/services/helpers';

import Popover from 'client/common/popovers/popover';

import CustomScrollbars from 'client/components/common/custom-scrollbars';

import PlaceAddressHint from '../place-address-hint';

import './spread-table.scss';

class SpreadTable extends PureComponent {
  static propTypes = {
    data: PropTypes.array.isRequired,
    languageState: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.SPREAD_PLAN;

    this.columns = [
      {
        size: 'big',
        headerText: this.LANGUAGE.PLACE,
        valuePath: 'place.name',
        hintRender: PlaceAddressHint,
      },
      {
        size: 'big',
        headerText: this.LANGUAGE.CITY,
        valuePath: 'place.city_name',
        hintRender: PlaceAddressHint,
      },
      {
        size: 'start',
        headerText: this.LANGUAGE.START_DATE,
        valuePath: 'from',
        valueRender: this.renderDateTimeValue,
      },
      {
        size: 'visits',
        headerText: this.LANGUAGE.VISITS,
        valuePath: 'full_participations_count',
        valueRender: this.renderNumberValue,
      },
      {
        size: 'participations',
        headerText: this.LANGUAGE.PARTICIPATIONS,
        valuePath: 'full_accomplished_participations_count',
        valueRender: this.renderNumberValue,
      },
    ];
  }

  renderHeaderCell = (item) => {
    const {size, headerText} = item;

    return (
      <th key={headerText} className={`spread-table__column-${size} spread-table__th`}>
        {headerText}
      </th>
    );
  };

  renderDateTimeValue = (config, item) => {
    const value = get(item, config.valuePath);

    return (
      <div>
        <div>{dateToString(value)}</div>
        <div className="spread-table__time-text">{timeToString(value)}</div>
      </div>
    );
  };

  renderNumberValue = (config, item) => {
    const value = get(item, config.valuePath);

    return formatNumberSpace(value);
  };

  renderValueCell = (config, item) => {
    const {size, valuePath, valueRender, hintRender, headerText} = config;

    const value = valueRender ? valueRender(config, item) : get(item, valuePath);

    return (
      <td key={headerText} className={`spread-table__column-${size} spread-table__td`}>
        {hintRender ? (
          <Popover
            position="top"
            overlay={hintRender(item)}
            overlayContentStyle={{
              color: '#404f6b',
              padding: '15px 20px',
            }}
            flipOptions={{
              mainAxis: true,
              crossAxis: true,
            }}
          >
            {value}
          </Popover>
        ) : (
          value
        )}
      </td>
    );
  };

  renderRow = (item) => {
    return <tr key={item.id}>{this.columns.map((column) => this.renderValueCell(column, item))}</tr>;
  };

  render() {
    const {data} = this.props;

    return (
      <div className="spread-table">
        <table className="spread-table__table">
          <thead>
            <tr>{this.columns.map(this.renderHeaderCell)}</tr>
          </thead>
        </table>
        <CustomScrollbars scrollbarProps={{autoHeightMax: 440}}>
          <table className="spread-table__table">
            <tbody>{data.map(this.renderRow)}</tbody>
          </table>
        </CustomScrollbars>
      </div>
    );
  }
}

export default connect((state) => ({
  languageState: state.languageState,
}))(SpreadTable);
