import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import ErrorMessage from 'client/common/inputs/error-message';
import RequiredLabel from 'client/common/inputs/required-label';
import WarningMessage from 'client/common/inputs/warning-message';
import {components, groupedComponents} from 'client/common/selects/select-dropdown/components';
import {SelectCommonProps, SelectOption} from 'client/common/selects/types';

import {getDefaultValue, getSimpleValues} from './helpers';

// We can't use cssModules because there are react-select which doesn't support css-modules
import './select-dropdown.scss';

const b = bem('select-dropdown');

/**
 * @type {React.FC<InferProps<typeof SelectDropdown.propTypes>>}
 */
const SelectDropdown = (props) => {
  const {
    label,
    options,
    value,
    onChange,
    name,
    className,
    labelClassName,
    selectClassName,
    prefixClassName,
    disabled,
    multi,
    clearable,
    noResultsText,
    placeholder,
    size,
    type,
    plainly,
    searchable,
    errorMessage,
    warningMessage = '',
    onBlur,
    Component,
    simpleValue,
    grouped,
    required = false,
    inputHeight = 'default',
    menuPlacement = 'auto',
    ...selectProps
  } = props;

  const lang = useLanguage('COMMON.SELECTS.SELECT_DROPDOWN');

  const selectClassNames = cn(
    b({
      'is-disabled': disabled,
      error: !!errorMessage,
      warning: !!warningMessage,
      plainly: plainly,
      grouped,
      [size]: size,
      [type]: type,
    }),
    className,
  );

  const handlerOnChange = (optionValues) => {
    onChange?.(simpleValue ? getSimpleValues(optionValues) : optionValues);
  };

  const Select = Component || ReactSelect;

  return (
    <div className={selectClassNames}>
      {label && (
        <label htmlFor={name} className={cn(b('label'), labelClassName)}>
          {label}
        </label>
      )}
      <div className={b('select-element')}>
        <Select
          name={name}
          options={options}
          value={getDefaultValue(value, options)}
          isMulti={multi}
          isDisabled={disabled}
          isClearable={clearable}
          isSearchable={searchable}
          className={cn(b('field'), selectClassName)}
          classNamePrefix={prefixClassName ? prefixClassName : b()}
          noOptionsMessage={() => noResultsText || lang.DROPDOWN_NO_DATA}
          placeholder={placeholder}
          menuPlacement={menuPlacement}
          onChange={handlerOnChange}
          blurInputOnSelect={false} // fixed ios focus bug
          onBlur={() => onBlur?.(value)}
          components={grouped ? groupedComponents : components}
          required={false}
          hideSelectedOptions={false}
          {...selectProps}
          classNames={{
            ...selectProps.classNames,
            input: (...args) => cn(b('input', [inputHeight]), selectProps.classNames?.input?.(...args)),
            menu: (...args) => cn(b('menu'), selectProps.classNames?.menu?.(...args)),
            control: (...args) => cn(b('control', [inputHeight]), selectProps.classNames?.control?.(...args)),
            menuList: (...args) => cn(b('menu-list'), selectProps.classNames?.menuList?.(...args)),
            singleValue: (...args) => cn(b('single-value'), selectProps.classNames?.singleValue?.(...args)),
            option: (...args) => cn(b('option', {['is-multi']: multi}), selectProps.classNames?.option?.(...args)),
          }}
        />
        {!errorMessage && required && <RequiredLabel />}
        {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
        {warningMessage && !errorMessage && <WarningMessage warningMessage={warningMessage} />}
      </div>
    </div>
  );
};

const SelectDropdownValueType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.object,
  SelectOption,
  PropTypes.arrayOf(PropTypes.string),
  PropTypes.arrayOf(PropTypes.number),
  PropTypes.arrayOf(PropTypes.object),
  PropTypes.arrayOf(SelectOption),
]);

SelectDropdown.propTypes = {
  ...SelectCommonProps,
  options: PropTypes.arrayOf(SelectOption),
  value: SelectDropdownValueType,
  searchable: PropTypes.bool,
  Component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  grouped: PropTypes.bool,
  menuHeaderElement: PropTypes.node,
  menuFooterElement: PropTypes.node,
  required: PropTypes.bool,
  defaultValue: SelectDropdownValueType,
  inputHeight: PropTypes.oneOf(['small', 'default']),
  menuPlacement: PropTypes.oneOf(['auto', 'bottom', 'top']),
};

SelectDropdown.defaultProps = {
  name: null,
  onChange: null,
  onBlur: null,
  options: [],
  defaultValue: [],
  label: null,
  className: '',
  labelClassName: '',
  selectClassName: '',
  prefixClassName: null,
  disabled: false,
  multi: false,
  clearable: false,
  searchable: false,
  plainly: false,
  noResultsText: null,
  placeholder: '',
  errorMessage: '',
  size: null,
  type: null,
  Component: null,
  simpleValue: false,
  grouped: false,
  menuHeaderElement: null,
  menuFooterElement: null,
};

export default SelectDropdown;
