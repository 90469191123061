import React, {useMemo} from 'react';

import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage, useReduxForm} from 'client/services/hooks';

import {selectAutotaskTypes} from 'client/ducks/autotask/selectors';
import {selectOperation} from 'client/ducks/operations/selectors';

import {NumberField, SelectField, ToggleField} from 'client/common/fields';

import {SEGMENTATION_TYPES} from 'client/components/prizes/constants';
import {PrizeDiyModalFormName} from 'client/components/prizes/modals/prize-diy-modal';

import {isAnyLevelRequired} from './validate';

import './prize-diy-segmentation-fieldset.scss';

const b = bem('prize-diy-segmentation-fieldset');

const PrizeDiySegmentationFieldset = (props) => {
  const {hasWins, disabled} = props;
  const lang = useLanguage('PRIZES.FIELDSETS.PRIZE_DIY_SEGMENTATION_FIELDSET');
  const {
    formValues: {segmentation: segmentationFormValues = {}},
  } = useReduxForm(PrizeDiyModalFormName);
  const {isDevicesType, isOnlineType} = useSelector(selectAutotaskTypes);
  const {online_interactions_count} = useSelector(selectOperation);

  const segmentationTitles = useMemo(
    () => ({
      [SEGMENTATION_TYPES.PER_CAMPAIGN]: lang.PER_CAMPAIGN_DOTATION,
      [SEGMENTATION_TYPES.PER_CHANNEL]: lang.PER_CHANNEL_DOTATION,
      [SEGMENTATION_TYPES.PER_DEVICE]: lang.DEVICE_DOTATION,
      [SEGMENTATION_TYPES.PER_PLACE]: lang.PLACE_DOTATION,
      [SEGMENTATION_TYPES.STORE]: lang.PER_STORE,
      [SEGMENTATION_TYPES.NONE]: lang.TOTAL_DOTATION,
    }),
    [lang],
  );

  const isDeviceAndOnlineType = isDevicesType && isOnlineType;
  const isDeviceSegmentationVisible = isDeviceAndOnlineType
    ? segmentationFormValues.device_online_segmentation
    : isDevicesType;
  const isOnlineSegmentationVisible = isDeviceAndOnlineType
    ? segmentationFormValues.device_online_segmentation
    : isOnlineType;
  const isTotalDotationVisible =
    isDeviceAndOnlineType && !segmentationFormValues.device_online_segmentation && !segmentationFormValues.hybrid;

  const isDeviceLevelSet = segmentationFormValues.geo_level !== SEGMENTATION_TYPES.NONE;
  const isOnlineLevelSet = segmentationFormValues.online_level !== SEGMENTATION_TYPES.NONE;

  const isAnyLevelReq = isAnyLevelRequired(segmentationFormValues);
  const isDeviceReq =
    !isDeviceAndOnlineType || isDeviceLevelSet || (isAnyLevelReq && !segmentationFormValues.online_stock);
  const isOnlineReq =
    !isDeviceAndOnlineType || isOnlineLevelSet || (isAnyLevelReq && !segmentationFormValues.device_stock);

  const isMainToggleDisabled =
    segmentationFormValues.device_online_segmentation && (isDeviceLevelSet || isOnlineLevelSet);

  if (segmentationFormValues.local) {
    return (
      <div className={b()}>
        <div className={b('main-toggles', ['align-right'])}>
          {isDeviceAndOnlineType && (
            <ToggleField
              name="segmentation.hybrid"
              label={lang.HYBRID_PER_STORE}
              borderColor="current"
              backgroundColor="current"
              className={b('main-toggle')}
              rounded
              disabled={hasWins || isMainToggleDisabled || disabled}
            />
          )}
        </div>
        <div className={b('box', ['sibling'])}>
          <p className={b('box-title')}>{lang.STORE}</p>
          <NumberField
            name="segmentation.device_stock"
            inputClassName={b('field', ['number'])}
            label={lang.QUANTITY}
            labelPosition="left"
            disabled={disabled}
            required
            inputHeight="small"
          />
        </div>
      </div>
    );
  }

  return (
    <div className={b()}>
      {isDeviceAndOnlineType && (
        <div className={b('main-toggles')}>
          <ToggleField
            name="segmentation.device_online_segmentation"
            label={lang.DEVICES_ONLINE_SEGMENTATION}
            borderColor="current"
            backgroundColor="current"
            className={b('main-toggle')}
            rounded
            disabled={hasWins || isMainToggleDisabled || disabled || segmentationFormValues.hybrid}
          />
          {!segmentationFormValues.device_online_segmentation && (
            <ToggleField
              name="segmentation.hybrid"
              label={lang.HYBRID_PER_STORE}
              borderColor="current"
              backgroundColor="current"
              className={b('main-toggle')}
              rounded
              disabled={hasWins || isMainToggleDisabled || disabled}
            />
          )}
        </div>
      )}
      {isDeviceSegmentationVisible && (
        <div className={b('box', {sibling: isOnlineType})}>
          {isOnlineType && <p className={b('box-title')}>{lang.DEVICES}</p>}
          <div className={b('row')}>
            <span className={b('segmentation-label')}>{lang.SEGMENTATION}</span>
            <SelectField
              name="segmentation.geo_level"
              className={b('field', ['select'])}
              options={[
                {label: lang.SEGMENTATION_SELECT_LABELS.NONE, value: SEGMENTATION_TYPES.NONE},
                {label: lang.SEGMENTATION_SELECT_LABELS.PLACE, value: SEGMENTATION_TYPES.PER_PLACE},
                {label: lang.SEGMENTATION_SELECT_LABELS.DEVICE, value: SEGMENTATION_TYPES.PER_DEVICE},
              ]}
              disabled={hasWins || disabled}
              simpleValue={true}
              inputHeight="small"
              menuPlacement={isDeviceAndOnlineType ? 'auto' : 'top'}
            />
          </div>

          <NumberField
            name="segmentation.device_stock"
            inputClassName={b('field', ['number'])}
            label={segmentationTitles[segmentationFormValues.geo_level]}
            labelPosition="left"
            disabled={disabled}
            required={isDeviceReq}
            inputHeight="small"
          />
        </div>
      )}
      {isOnlineSegmentationVisible && (
        <div className={b('box', {sibling: isDevicesType})}>
          {isDevicesType && <p className={b('box-title')}>{lang.ONLINE}</p>}

          <div className={b('row')}>
            <span className={b('segmentation-label')}>{lang.SEGMENTATION}</span>
            <SelectField
              name="segmentation.online_level"
              className={b('field', ['select'])}
              options={[
                {
                  label: lang.SEGMENTATION_SELECT_LABELS.PER_CHANNEL,
                  value: SEGMENTATION_TYPES.PER_CHANNEL,
                  disabled: online_interactions_count < 1,
                },
                {
                  label: lang.SEGMENTATION_SELECT_LABELS.PER_CAMPAIGN,
                  value: SEGMENTATION_TYPES.PER_CAMPAIGN,
                },
                {
                  label: lang.SEGMENTATION_SELECT_LABELS.NONE,
                  value: SEGMENTATION_TYPES.NONE,
                },
                isOnlineType && !isDevicesType
                  ? {
                      label: lang.SEGMENTATION_SELECT_LABELS.STORE,
                      value: SEGMENTATION_TYPES.STORE,
                    }
                  : null,
              ].filter((i) => i)}
              disabled={hasWins || disabled}
              isOptionDisabled={(option) => option.disabled}
              simpleValue={true}
              inputHeight="small"
              menuPlacement="top"
            />
          </div>

          <NumberField
            name="segmentation.online_stock"
            inputClassName={b('field', ['number'])}
            label={segmentationTitles[segmentationFormValues.online_level]}
            labelPosition="left"
            disabled={disabled}
            required={isOnlineReq}
            inputHeight="small"
          />
        </div>
      )}

      {segmentationFormValues.hybrid && (
        <div className={b('box', ['sibling'])}>
          {isDevicesType && <p className={b('box-title')}>{lang.STORES}</p>}
          <NumberField
            name="segmentation.device_stock"
            inputClassName={b('field', ['number'])}
            label={lang.PER_STORE}
            labelPosition="left"
            disabled={disabled}
            required
            inputHeight="small"
          />
        </div>
      )}

      {isTotalDotationVisible && (
        <div className={b('box', ['sibling'])}>
          <p className={b('box-title')}>{lang.GLOBAL}</p>
          <NumberField
            name="segmentation.default_initial_stock"
            inputClassName={b('field', ['number'])}
            label={lang.TOTAL_DOTATION}
            labelPosition="left"
            disabled={disabled}
            required={true}
            inputHeight="small"
          />
        </div>
      )}
    </div>
  );
};

PrizeDiySegmentationFieldset.propTypes = {
  hasWins: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default PrizeDiySegmentationFieldset;
