import {LangsByPath} from 'client/services/hooks/useLanguage/types';

export const languageKeys = {
  en: 'ENGLISH',
  fr: 'FRANCE',
  es: 'SPANISH',
  it: 'ITALIAN',
  pt: 'PORTUGUESE',
  nl: 'NETHERLANDS',
  de: 'GERMAN',
  pl: 'POLISH',
} as const;

export const getLanguageTranslations = (lang: LangsByPath<['COMMON.LANGUAGES']>) => ({
  en: lang.ENGLISH,
  fr: lang.FRANCE,
  es: lang.SPANISH,
  it: lang.ITALIAN,
  pt: lang.PORTUGUESE,
  nl: lang.NETHERLANDS,
  de: lang.GERMAN,
  pl: lang.POLISH,
});
