import moment from 'moment';

import {uid} from 'client/services/helpers';

import getCommonCouponFieldsetValues from 'client/components/prizes/fieldsets/prize-diy-common-coupon-fieldset/initialValues';
import getCouponFieldsetValues from 'client/components/prizes/fieldsets/prize-diy-coupon-fieldset/initialValues';
import getCouponImportsFieldsetValues from 'client/components/prizes/fieldsets/prize-diy-coupon-imports-fieldset/initialValues';
import getCustomListFieldsetValues from 'client/components/prizes/fieldsets/prize-diy-custom-list-fieldset/initialValues';
import getEmailFieldsetValues from 'client/components/prizes/fieldsets/prize-diy-email-fieldset/initialValues';
import getInstantWinFieldsetValues from 'client/components/prizes/fieldsets/prize-diy-instant-win-fieldset/initialValues';
import getMainFieldsetValues from 'client/components/prizes/fieldsets/prize-diy-main-fieldset/initialValues';
import getRatioParticipationFieldsetValues from 'client/components/prizes/fieldsets/prize-diy-ratio-participation-fieldset/initialValues';
import getRatioTimeFieldsetValues from 'client/components/prizes/fieldsets/prize-diy-ratio-time-fieldset/initialValues';
import getScheduleFieldsetValues from 'client/components/prizes/fieldsets/prize-diy-schedule-fieldset/initialValues';
import getSegmentationFieldsetValues from 'client/components/prizes/fieldsets/prize-diy-segmentation-fieldset/initialValues';
import getTicketFieldsetValues from 'client/components/prizes/fieldsets/prize-diy-ticket-fieldset/initialValues';
import getWeekScheduleFieldsetValues from 'client/components/prizes/fieldsets/prize-diy-week-schedule-fieldset/initialValues';
import getWeeklyListFieldsetValues from 'client/components/prizes/fieldsets/prize-diy-weekly-list-fieldset/initialValues';
import getWinFieldsetValues from 'client/components/prizes/fieldsets/prize-diy-win-fieldset/initialValues';

export default ({state, storeId, prize = {}, isLocal = false, timezone}) => {
  const segmentation = getSegmentationFieldsetValues({prize, state, isLocal});

  return {
    main: getMainFieldsetValues({prize}, state, {timezone}),
    coupon: {
      ...getCouponFieldsetValues(prize?.coupon, {timezone}),
      ...getCommonCouponFieldsetValues(prize?.coupon),
      imports: getCouponImportsFieldsetValues(prize?.coupon || {}, segmentation),
    },
    win_pop_up: getWinFieldsetValues(prize, state),
    email: getEmailFieldsetValues(prize, state),
    ticket: getTicketFieldsetValues(prize, state),
    ratioTime: getRatioTimeFieldsetValues(prize),
    ratioParticipation: getRatioParticipationFieldsetValues(prize),
    schedule_type: getScheduleFieldsetValues(prize),
    customList: getCustomListFieldsetValues(prize, {timezone}),
    weeklyList: getWeeklyListFieldsetValues(prize, {timezone}),
    weekSchedule: getWeekScheduleFieldsetValues(prize, {timezone}),
    instant_win: getInstantWinFieldsetValues(prize),
    segmentation,
    order: prize?.order,
    store_id: prize?.store_id || storeId,

    // dates_off field is common for Weekly List and Week Schedule fieldsets
    dates_off:
      prize?.schedule_instant_win_configuration?.dates_off?.map((date) => ({
        value: moment(date).format('DD/MM/YYYY'),
        id: uid(),
      })) || [],
  };
};
