import React from 'react';

import bem from 'client/services/bem';

import cssModule from './client-autotask-subheader-operation-info-block.module.scss';

const b = bem('client-autotask-subheader-operation-info-block', {cssModule});

type ClientAutotaskSubheaderOperationInfoBlockProps = {
  label?: string;
  value?: string;
};

const ClientAutotaskSubheaderOperationInfoBlock: React.FC<ClientAutotaskSubheaderOperationInfoBlockProps> = (props) => {
  const {label = '', value = ''} = props;

  return (
    <p className={b()}>
      {label && <span className={b('text')}>{label}</span>}
      {value && (
        <span className={b('text', ['has-value'])} title={value}>
          {value}
        </span>
      )}
    </p>
  );
};

export default ClientAutotaskSubheaderOperationInfoBlock;
