import React, {ChangeEvent, ChangeEventHandler, useImperativeHandle, useRef, useState} from 'react';

import cn from 'classnames';
import add from 'lodash/add';
import subtract from 'lodash/subtract';

import bem from 'client/services/bem';

import Icon from 'client/common/icon';

import BaseInput, {BaseInputProps} from '../base-input';

import cssModule from './number-input.module.scss';

type NumberInputProps = Omit<BaseInputProps, 'type' | 'onHoveredChange' | 'onChange'> & {
  onChange?: ChangeEventHandler<HTMLInputElement>;
};

const b = bem('number-input', {cssModule});

const NumberInput: React.FC<NumberInputProps> = ({inputClassName, inputRef, ...props}) => {
  const [isHovered, setIsHovered] = useState(false);
  const ref = useRef<HTMLInputElement>(null);

  useImperativeHandle(inputRef, () => ref.current as HTMLInputElement, []);

  const handleArrowClick = (event: object, operator: (a: number, b: number) => void) => {
    const input = ref.current;
    if (!input) {
      return;
    }

    const newValue = String(operator(Number(input.value || 0), 1));
    input.value = newValue; // for non-controlled components

    // simulate input change event to preserve the parameter for onChange function similar to other inputs
    const customEvent = {...event, target: input, currentTarget: input};
    // copy preventDefault and stopPropagation methods as redux-form uses them to differentiate the event and string value params
    Object.setPrototypeOf(customEvent, event.constructor.prototype);

    props.onChange?.(customEvent as ChangeEvent<HTMLInputElement>); // for controlled components
  };

  const arrowButtons = (
    <div className={b('arrow-icons')} onMouseDown={(event) => event.preventDefault()}>
      <Icon name="arrow-up" width={12} className={b('arrow-icon')} onClick={(event) => handleArrowClick(event, add)} />
      <Icon
        name="arrow-down"
        width={12}
        className={b('arrow-icon')}
        onClick={(event) => handleArrowClick(event, subtract)}
      />
    </div>
  );

  return (
    <BaseInput
      type="number"
      {...props}
      inputRef={ref}
      onHoveredChange={setIsHovered}
      inputClassName={cn(
        b('field', {hovered: !props.hoveringMode || isHovered, 'read-only': props.readOnly}),
        inputClassName,
      )}
      elements={props.disabled ? null : arrowButtons}
    />
  );
};

export default NumberInput;
