import React, {Component} from 'react';

import flow from 'lodash/flow';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {reset} from 'redux-form';

import {selectGames} from 'client/ducks/games/selectors';
import {getPlans, clearPlansState} from 'client/ducks/plans/actions';
import {selectPlans} from 'client/ducks/plans/selectors';

import {CLIENT_PAGES} from 'client/common/config';

import Icon from 'client/components/common/icon';

import {PRIZE_MAP_LEVEL_TYPES, PRIZE_MAP_TYPES} from 'client/components/games/game-config-modal/constants';
import PrizeMapCard from 'client/components/games/prize-map-card';

import PlanList from './components/lists/plan-list';
import NewPlanModal from './components/modals/new-plan-modal';

import AutotaskCard from '../components/cards/autotask-card/autotask-card';

class AutotaskPlans extends Component {
  static propTypes = {
    clearPlansState: PropTypes.func.isRequired,
    languageState: PropTypes.object.isRequired,
    ...withRouter.propTypes,
    getPlans: PropTypes.func.isRequired,
    plans: PropTypes.array.isRequired,
    reset: PropTypes.func.isRequired,
    autotaskId: PropTypes.number,
    archived: PropTypes.bool,
    games: PropTypes.array.isRequired,
    autotask: PropTypes.object.isRequired,
  };

  static Modals = {
    NEW_PLAN_MODAL: {
      id: 'NEW_PLAN_MODAL',
      form: NewPlanModal.formName,
    },
  };

  constructor(props) {
    super(props);

    this.state = {
      activeModal: null,
    };

    this.LANGUAGE = props.languageState;
  }

  componentDidMount() {
    this.props.getPlans({
      q: {
        automation_task_id_eq: this.props.autotaskId,
      },
    });
  }

  componentWillUnmount() {
    this.props.clearPlansState();
  }

  handleActiveModalOpen = (key) =>
    this.setState({
      activeModal: key,
    });

  handleActiveModalClose = () => {
    this.setState(
      {
        activeModal: null,
      },
      () => Object.values(AutotaskPlans.Modals).forEach((item) => this.props.reset(item.form)),
    );
  };

  createNewPlan = (planId) => {
    this.props.history.push(`${CLIENT_PAGES.PLANS}/${planId}${CLIENT_PAGES.DEVICES}`);
  };

  render() {
    const {activeModal} = this.state;
    const {autotaskId, plans, archived, autotask} = this.props;

    return (
      <AutotaskCard
        title={this.LANGUAGE.PLAN_CARD_TITLE}
        header={
          <button
            className={'button pull-right button--bg-4' + (archived ? ' hidden' : '')}
            onClick={() => this.handleActiveModalOpen(AutotaskPlans.Modals.NEW_PLAN_MODAL.id)}
          >
            <Icon name="plus" className="button__icon" />
            <span>{this.LANGUAGE.ADD_PLAN_BUTTON}</span>
          </button>
        }
        showPagination={false}
        aboveHeader={
          <PrizeMapCard
            levelKey="GEOGRAPHIC_GLOBAL"
            autotask={autotask}
            prizeMapParams={{
              type_eq: PRIZE_MAP_TYPES.GEO_PRIZE_MAP,
              geo_level_eq: PRIZE_MAP_LEVEL_TYPES.GLOBAL,
            }}
            displayParams={get(autotask, 'prize_maps_present.geo')}
          />
        }
      >
        <PlanList plans={plans} />

        <NewPlanModal
          show={activeModal === AutotaskPlans.Modals.NEW_PLAN_MODAL.id}
          autotaskId={autotaskId}
          onClose={this.handleActiveModalClose}
          onConfirm={this.createNewPlan}
        />
      </AutotaskCard>
    );
  }
}

const mapStateToProps = (state) => ({
  languageState: {
    ADD_PLAN_BUTTON: state.languageState.payload.AUTOTASK.ADD_PLAN_BUTTON,
    PLAN_CARD_TITLE: state.languageState.payload.AUTOTASK.PLAN_CARD_TITLE,
  },
  plans: selectPlans(state),
  games: selectGames(state),
});

const mapDispatchToProps = {
  clearPlansState,
  reset,
  getPlans,
};

export default flow([withRouter, connect(mapStateToProps, mapDispatchToProps)])(AutotaskPlans);
