import React, {useState, useEffect} from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';
import {getCurrentTimezone} from 'client/services/utils/date';

import {TimeInput, DatepickerInput, ErrorMessage, RequiredLabel, WarningMessage} from 'client/common/inputs';

import {getTime, getDate, getValue} from './helpers';
import {DatetimePickerProps} from './types';

import cssModule from './datetime-picker.module.scss';

const b = bem('datetime-picker', {cssModule});

const DatetimePicker: React.FC<DatetimePickerProps> = (props) => {
  const {
    label = '',
    value,
    onChange,
    className = '',
    errorMessage = null,
    warningMessage = '',
    withSeconds = true,
    timePattern = '',
    disabled = false,
    required = false,
    timezone = getCurrentTimezone(),
    classNames,
    datepickerProps,
  } = props;
  const [dateTime, setDateTime] = useState({
    date: getDate(value, timezone),
    time: getTime(value, withSeconds, timezone),
  });

  useEffect(() => {
    setDateTime({
      date: getDate(value, timezone),
      time: getTime(value, withSeconds, timezone),
    });
  }, [value, timezone, withSeconds]);

  const handleDateChange = (dateArg: string) => {
    const newValue = getValue(dateArg, dateTime.time, timezone) || '';

    setDateTime({...dateTime, date: dateArg, time: getTime(newValue, withSeconds, timezone)});
    onChange(newValue);
  };

  const handleTimeChange = (timeValue: string) => {
    const newValue = getValue(dateTime.date, timeValue, timezone) || '';

    setDateTime({...dateTime, time: timeValue});
    onChange(newValue);
  };

  return (
    <div className={cn(className, b())}>
      {label && <span className={cn(b('label'), classNames?.label)}>{label}</span>}
      <div className={cn(b('row'), classNames?.row)}>
        <DatepickerInput
          {...datepickerProps}
          className={cn(b('field', ['date']), classNames?.dateField)}
          value={dateTime.date}
          onChange={handleDateChange}
          disabled={disabled}
        />
        <TimeInput
          key={dateTime.date}
          className={cn(b('field', {'time-long': withSeconds, 'time-short': !withSeconds}), classNames?.timeField)}
          value={dateTime.time}
          pattern={timePattern}
          onChange={handleTimeChange}
          withSeconds={withSeconds}
          disabled={disabled}
        />
      </div>
      {!errorMessage && required && <RequiredLabel className={classNames?.requiredLabel} />}
      {errorMessage && <ErrorMessage className={classNames?.errorMessage} errorMessage={errorMessage} />}
      {warningMessage && !errorMessage && (
        <WarningMessage className={classNames?.warningMessage} warningMessage={warningMessage} />
      )}
    </div>
  );
};

export default React.memo(DatetimePicker);
