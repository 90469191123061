import React, {useCallback, useEffect, useRef, useState} from 'react';

import flow from 'lodash/flow';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import {connect, useDispatch, useSelector} from 'react-redux';
import {reduxForm} from 'redux-form';

import bem from 'client/services/bem';
import {uid} from 'client/services/helpers';
import {useLanguage} from 'client/services/hooks';
import {useReduxForm} from 'client/services/hooks';

import {selectAutotask} from 'client/ducks/autotask/selectors';
import {selectClient} from 'client/ducks/leads-list/selectors';
import {selectOperation} from 'client/ducks/operations/selectors';
import {getOptInMappingItems} from 'client/ducks/opt-in-mapping-items/actions';
import {getOptInColumns} from 'client/ducks/opt-in-mapping-items/actions';
import {deleteOptInMappingItem} from 'client/ducks/opt-in-mapping-items/actions';
import {updateOptInMappingItems} from 'client/ducks/opt-in-mapping-items/actions';
import {selectOptInMappingItems} from 'client/ducks/opt-in-mapping-items/selectors';
import {selectOptInColumns} from 'client/ducks/opt-in-mapping-items/selectors';

import AppButton from 'client/common/buttons';
import ConfirmationModal from 'client/common/modals/confirmation-modal';
import Modal from 'client/common/modals/modal';

import {TranslationJsx} from 'client/models/language/types';
import {isOperationArchived} from 'client/models/operations/helpers';

import {NEW_OPT_IN_ID_PREFIX, MAX_OPT_INS_LENGTH} from './constants';
import DiyOpOptinsModalField from './diy-op-optins-modal-field';
import {updateOrdersForNonMandatory, sortOptIns} from './helpers';
import mapFormValues from './mapFormValues';
import validate from './validate';

import './diy-op-optins-modal.scss';

const b = bem('diy-op-optins-modal');

const DiyOpOptinsModal = (props) => {
  const {
    title,
    handleSubmit,
    change,
    onClose,
    interfaceId,
    form: formName,
    defaultLanguage,
    languageOptions,
    columnOptions,
    invalid,
  } = props;

  const lang = useLanguage('DIY_OPERATION.MODALS.OPTINS_MODAL');
  const dispatch = useDispatch();

  const [optInToDelete, setOptInToDelete] = useState(null);

  const {
    formValues: {optIns},
  } = useReduxForm(formName);
  const {id: clientId} = useSelector(selectClient);

  const operation = useSelector(selectOperation);
  const isModalDisabled = !operation.editable;

  const isOpArchived = isOperationArchived(operation);

  // callback
  const handleAddOptin = useCallback(() => {
    const length = optIns.length;

    if (length >= MAX_OPT_INS_LENGTH || length >= columnOptions.length) {
      return;
    }

    const newOptins = [
      ...optIns,
      {
        id: uid(NEW_OPT_IN_ID_PREFIX),
        activeLanguage: defaultLanguage,
        order: length + 1,
        mandatory_order: null,
      },
    ];

    change('optIns', sortOptIns(newOptins));
  }, [optIns, change, defaultLanguage, columnOptions]);

  const handleSave = useCallback(
    async (values) => {
      const data = mapFormValues(values, interfaceId);
      await dispatch(updateOptInMappingItems(data));
      onClose();
    },
    [onClose, dispatch, interfaceId],
  );

  const closeDeleteConfirmationModal = () => setOptInToDelete(null);

  const handleDelete = () => {
    if (!String(optInToDelete).startsWith(NEW_OPT_IN_ID_PREFIX)) {
      const optInInterfaceId = optIns.find((optin) => optin.id === optInToDelete).interface_item_id;
      dispatch(deleteOptInMappingItem(optInInterfaceId));
    }
    const newOptIns = optIns.filter((optin) => optin.id !== optInToDelete);
    change('optIns', newOptIns);
    closeDeleteConfirmationModal();
  };

  const handleColumnChange = (adapterId, optInId) => {
    const updatedOptIns = optIns.map((optIn) => {
      if (optIn.id !== optInId) {
        return optIn;
      }

      const column = columnOptions.find((option) => option.value === adapterId);
      const mandatoryOrder = column.mandatory_order;
      const hasMandatoryOrder = column.mandatory_order !== null;

      return {
        ...optIn,
        ...(hasMandatoryOrder && {order: mandatoryOrder}),
        editable: column.editable,
        column_adapter_id: adapterId,
        hasMandatoryOrder,
        mandatory_order: mandatoryOrder,
        opt_in_text_translations: column.opt_in_text_translations,
        readable_name: column.name,
      };
    });

    change('optIns', sortOptIns(sortOptIns(updatedOptIns)));
  };

  const draggedItemRef = useRef(null);

  const handleDragStart = (event, item) => {
    draggedItemRef.current = item;
    const wrapperNode = event.target.parentNode.parentNode;

    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.setData('text/plain', wrapperNode);
    event.dataTransfer.setDragImage(wrapperNode, 20, 20);
  };

  const handleDragEnter = (event, position) => {
    event.preventDefault();
    const draggedItem = draggedItemRef.current;

    // ignore if item is dragged over itself
    if (
      !optIns[position] ||
      !draggedItem ||
      optIns[position]?.id === draggedItem?.id ||
      optIns[position]?.hasMandatoryOrder
    ) {
      return;
    }

    let updatedOptins = optIns.filter((optin) => optin.id !== draggedItem.id);
    updatedOptins.splice(position, 0, draggedItem);

    updatedOptins = updateOrdersForNonMandatory(updatedOptins); // update orders by position for non mandatory
    updatedOptins = sortOptIns(updatedOptins); // sort by order and mandatory order to bring mandatory to correct position

    change('optIns', updatedOptins);
  };

  const handleDragEnd = () => {
    draggedItemRef.current = null;
  };

  useEffect(() => {
    dispatch(getOptInMappingItems(interfaceId));
    dispatch(
      getOptInColumns({
        include: ['opt_in_column_adapter'],
        q: {
          client_id_eq: clientId,
        },
      }),
    );
  }, [dispatch, interfaceId, clientId]);

  const freeColumnOptions = columnOptions.filter((option) => {
    const isUsed = optIns?.some((optIn) => optIn.column_adapter_id === option.value);

    return !isUsed;
  });

  const isAddButtonDisabled = isModalDisabled || optIns?.length >= MAX_OPT_INS_LENGTH;

  return (
    <Modal className={b()} onClose={onClose} title={title} isCloseHidden={!isModalDisabled}>
      <form className={b('form')} onSubmit={handleSubmit(handleSave)}>
        <div className={b('fields')}>
          {optIns?.map((optIn, index) => {
            // columns cannot be used twice
            const selectedColumn = columnOptions.find((column) => column.value === optIn.column_adapter_id); // add current column
            const availableColumns = selectedColumn ? [...freeColumnOptions, selectedColumn] : freeColumnOptions;

            return (
              <DiyOpOptinsModalField
                key={`${optIn.id}${index}`} // re-render on position change
                index={index}
                columns={availableColumns}
                onDragStart={(event) => handleDragStart(event, optIn)}
                onDragEnter={(event) => handleDragEnter(event, index)}
                onDragEnd={handleDragEnd}
                languageOptions={languageOptions}
                activeLanguage={optIn.activeLanguage}
                hasMandatoryOrder={optIn.hasMandatoryOrder}
                textEditable={optIn.editable}
                onDeleteClick={() => setOptInToDelete(optIn.id)}
                disabled={isModalDisabled || isOpArchived}
                onColumnChange={(adapterId) => handleColumnChange(adapterId, optIn.id)}
                isNewOptIn={String(optIn.id).startsWith(NEW_OPT_IN_ID_PREFIX)}
                columnAdapterId={optIn.column_adapter_id}
              />
            );
          })}
        </div>
        <AppButton
          iconName="plus-simple"
          color="light-clients"
          label={lang.ADD_OPTIN}
          onClick={handleAddOptin}
          disabled={isAddButtonDisabled || isOpArchived}
          size="full"
        />
        {!isModalDisabled && (
          <div className={b('buttons')}>
            <AppButton label={lang.CANCEL_BUTTON} onClick={onClose} transparent />
            <AppButton label={lang.SAVE_BUTTON} submit disabled={invalid || isEmpty(optIns) || isOpArchived} />
          </div>
        )}
      </form>
      <ConfirmationModal
        show={Boolean(optInToDelete)}
        clientSide
        onConfirm={handleDelete}
        onClose={closeDeleteConfirmationModal}
        message={lang.DELETE_CONFIRMATION_TEXT}
        buttonConfirm={{
          label: lang.YES_BUTTON,
        }}
        buttonCancel={{
          label: lang.NO_BUTTON,
          onClick: closeDeleteConfirmationModal,
        }}
      />
    </Modal>
  );
};

DiyOpOptinsModal.propTypes = {
  title: TranslationJsx.isRequired,
  onClose: PropTypes.func.isRequired,
  interfaceId: PropTypes.number.isRequired,
  defaultLanguage: PropTypes.string.isRequired,
  languageOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  columnOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,

  // from redux-form
  change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  invalid: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const {languages, default_language} = selectAutotask(state)?.interfaces?.[0] || {};

  // saving active language in form state is required to not lose it on re-render of optin card on drag&drop
  const optInsWithAdditionalFields = selectOptInMappingItems(state)?.map((optIn) => {
    const {editable, mandatory_order} = optIn.column_adapter.opt_in_column;
    const hasMandatoryOrder = mandatory_order !== null;

    return {
      ...optIn,
      order: hasMandatoryOrder ? mandatory_order : optIn.order, // order may have old value of mandatory_order if the last one was changed
      // custom fields, need for development
      activeLanguage: default_language,
      mandatory_order: mandatory_order,
      hasMandatoryOrder,
      editable,
    };
  });
  const sortedOptIns = sortOptIns(optInsWithAdditionalFields);

  const languageOptions = languages?.map((language) => ({value: language, label: language})) || [];

  const columnOptions =
    selectOptInColumns(state)?.map((column) => ({
      ...column,
      label: column.name,
      value: column.opt_in_column_adapter?.id,
    })) || [];

  return {
    initialValues: {
      optIns: isEmpty(sortedOptIns)
        ? [
            {
              id: `${NEW_OPT_IN_ID_PREFIX}-1`,
              activeLanguage: default_language,
              order: 1,
              mandatory_order: null,
            },
          ]
        : sortedOptIns,
    },
    defaultLanguage: default_language,
    languageOptions,
    columnOptions,
    validationLang: state.languageState.payload.DIY_OPERATION.MODALS.OPTINS_MODAL,
  };
};

export default flow([
  reduxForm({form: 'DiyOptinsModalForm', enableReinitialize: true, validate, shouldValidate: () => true}),
  connect(mapStateToProps),
])(DiyOpOptinsModal);
